export const CREATE_DATE_FILTER_OPTION = {
  id: 'createDate',
  label: 'Created Date'
}
export const SHIP_DATE_FILTER_OPTION = {
  id: 'shipDate',
  label: 'Ship Date'
}
export const NONE_DATE_FILTER_OPTION = {
  id: 'none',
  label: 'None'
}
export const APPROVED_DATE_FILTER_OPTION = {
  id: 'approvedDate',
  label: 'Approved Date'
}
