export enum KnownChageTypes {
  BaseRate = 1,
  Discount = 2,
  Fsc = 3,
  Accesorials = 4,
  UnknownAccesorials = 79,
  Taxes = 15,
  Duties = 16,
  CustomerFees = 89,
  LineHaul = -2,
  NetCharge = -3
}
