import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { on } from '@ngrx/store'
import { AuditAsyncOperations } from '../../../domain/audits/audits-loading.model'
import { AuditsState, auditsAdapter } from '../audits.reducer'
import { loadAuditActions } from './load-audit.actions'

export const loadAuditReducers = [
  on(loadAuditActions.loadAudit, (state: AuditsState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AuditAsyncOperations.loadAudit,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    loadAuditActions.loadAuditSuccess,
    (state: AuditsState, { audit }): AuditsState =>
      auditsAdapter.upsertOne(audit, {
        ...state,
        selectedId: audit.id,
        loading: updateLoadingState(state.loading, {
          operation: AuditAsyncOperations.loadAudit,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    loadAuditActions.loadAuditFail,
    (state: AuditsState): AuditsState => ({
      ...state,
      selectedId: undefined,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.loadAudit,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to load audit.'
      })
    })
  ),
  on(
    loadAuditActions.resetGetAuditLoadingState,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.loadAudit,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
