import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { MapOrderChargeRequest } from '../../domain/invoices/map-order-charge.request'

export class ToMapOrderChargeRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'id'> & {
        charge: InvoiceForm['chargesToMap'][number]
      },
      MapOrderChargeRequest | undefined
    >
{
  convert(
    request: Pick<InvoiceForm, 'id'> & {
      charge: InvoiceForm['chargesToMap'][number]
    }
  ): MapOrderChargeRequest | undefined {
    if (isNaN(Number(request.charge.chargeId))) return undefined
    return {
      charge: {
        chargeTypeId: Number(request.charge.typeId),
        orderChargeId: Number(request.charge.chargeId),
        code: request.charge.code ?? null,
        description: request.charge.description ?? null
      },
      orderId: request.id as number
    }
  }
}
