import { Injectable, computed, inject } from '@angular/core'
import { BehaviorSubject, map } from 'rxjs'
import { InvoicesFacade } from '../+state/invoices/invoices.facade'
import { CurrentUserFacade } from '@navix/current-user/domain'
import { toSignal } from '@angular/core/rxjs-interop'

@Injectable({
  providedIn: 'root'
})
export class InvoiceDetailService {
  private readonly invoicesFacade = inject(InvoicesFacade)
  private readonly currentUserFacade = inject(CurrentUserFacade)
  private reasonId$ = new BehaviorSubject<null | number>(null)

  IS_NOT_OWNER_MESSAGE = 'Take Ownership to Enable.'

  setReasonId = (reasonId: number | null) => {
    this.reasonId$.next(reasonId)
  }

  getReasonId = () => this.reasonId$

  owner = computed(() => this.invoicesFacade.selectedInvoice()?.ownerUserEmail)

  currentUser = toSignal(
    this.currentUserFacade.userProfile$.pipe(map(user => user.email))
  )

  isOwner = computed(() => this.currentUser() === this.owner() || !this.owner())
}
