export type OrderFilterOption = {
  id: number
  label: string
}

export type OrdersDataFilters = Partial<{
  search: string
  itemsPerPage: number
  page: number
  status: OrderStatusFilter
  sortBy: string
  sortDirection: 'asc' | 'desc'
  customers: OrderFilterOption[]
  vendors: OrderFilterOption[]
}>

export enum OrderStatusFilter {
  unmatched
}
