import { on } from '@ngrx/store'
import { InvoicesState } from '../invoices.reducer'
import { invoiceRelatedDocumentsActions } from './invoice-related-documents.actions'

export const invoiceRelatedDocumentsReducers = [
  on(
    invoiceRelatedDocumentsActions.loadInvoiceRelatedDocumentsSuccess,
    (state: InvoicesState, { relatedDocuments }) => ({
      ...state,
      relatedDocuments
    })
  )
]
