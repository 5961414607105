import { HttpErrorResponse } from '@angular/common/http'
import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { DataFilters } from '../../../domain/invoices/data-filters.model'
import { InvoiceForm } from '../../../domain/invoices/invoice-form.model'
import { Invoice } from '../../../domain/invoices/invoice.model'

export const invoiceNotesActions = createActionGroup({
  source: 'Invoice Audit History',
  events: {
    'load invoice notes': props<{
      invoiceId: number
      filters?: Partial<DataFilters>
    }>(),
    'load invoice notes success': props<{
      notes: Invoice['notes']
      invoiceId: number
    }>(),
    'load invoice notes fail': props<{ error: unknown }>(),

    'update invoice notes mark as complete': props<{
      note: Invoice['notes'][number]
      invoiceId: number
    }>(),
    'update invoice notes mark as complete success': props<{
      invoiceId: number
      isDisputed: boolean
    }>(),
    'update invoice notes mark as complete fail': props<{
      error: HttpErrorResponse
    }>(),

    'add invoice notes': props<{
      invoice: Pick<InvoiceForm, 'notes' | 'id'>
    }>(),
    'add invoice notes success': props<{
      invoice: Pick<InvoiceForm, 'notes' | 'id'>
      isDisputed: boolean
    }>(),
    'add invoice notes fail': props<{ error: HttpErrorResponse }>(),

    'update invoice notes': props<{
      invoice: Pick<InvoiceForm, 'notes' | 'id'>
    }>(),
    'update invoice notes success': props<{
      invoice: Pick<InvoiceForm, 'notes' | 'id'>
      isDisputed: boolean
    }>(),
    'update invoice notes fail': props<{ error: HttpErrorResponse }>(),

    'delete invoice notes': props<{
      invoiceId: number
      noteId: number
    }>(),
    'delete invoice notes success': props<{ invoiceId: number }>(),
    'delete invoice notes fail': props<{ error: HttpErrorResponse }>(),

    'add invoice notes and send email': props<{
      invoice: Pick<InvoiceForm, 'notes' | 'id'>
    }>(),
    'add invoice notes and send email success': props<{
      invoice: Pick<InvoiceForm, 'notes' | 'id'>
    }>(),
    'add invoice notes and send email fail': props<{
      error: HttpErrorResponse
    }>(),
    addInvoiceLegacyNotes: props<{
      invoice: Pick<InvoiceForm, 'notes' | 'id'>
    }>(),
    addInvoiceLegacyNotesSuccess: props<{
      invoice: Pick<InvoiceForm, 'notes' | 'id'>
    }>(),
    addInvoiceLegacyNotesFail: props<{ error: HttpErrorResponse }>(),
    resetAddInvoiceLegacyNotes: emptyProps()
  }
})
