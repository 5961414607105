import { on } from '@ngrx/store'
import { invoiceExceptionsActions } from './invoice-exceptions.actions'
import { InvoicesState, invoicesAdapter } from '../invoices.reducer'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'

export const invoiceExceptionsReducers = [
  on(
    invoiceExceptionsActions.loadInvoiceExceptionsSuccess,
    (state: InvoicesState, { invoiceExceptions, invoiceId }) =>
      invoicesAdapter.upsertOne(
        {
          id: invoiceId,
          exceptions: invoiceExceptions
        } as Invoice,
        state
      )
  ),
  on(invoiceExceptionsActions.loadWorkInstructions, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.getWorkInstructions,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    invoiceExceptionsActions.loadWorkInstructionsSuccess,
    (state: InvoicesState, { invoiceWithWorkInstructions }): InvoicesState =>
      invoicesAdapter.upsertOne(invoiceWithWorkInstructions, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.getWorkInstructions,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    invoiceExceptionsActions.loadWorkInstructionsFail,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getWorkInstructions,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load work instructions."
      })
    })
  ),
  on(
    invoiceExceptionsActions.resetLoadWorkInstructionsLoadingStatus,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getWorkInstructions,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    invoiceExceptionsActions.loadWorkInstructionsPhase2,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getWorkInstructionsPhase2,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceExceptionsActions.loadWorkInstructionsPhase2Success,
    (state: InvoicesState, { invoiceWithWorkInstructions }): InvoicesState =>
      invoicesAdapter.upsertOne(invoiceWithWorkInstructions, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.getWorkInstructionsPhase2,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    invoiceExceptionsActions.loadWorkInstructionsPhase2Fail,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getWorkInstructionsPhase2,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load work instructions."
      })
    })
  ),
  on(
    invoiceExceptionsActions.resetLoadWorkInstructionsPhase2LoadingStatus,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getWorkInstructionsPhase2,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(invoiceExceptionsActions.addAttestation, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.addAttestation,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    invoiceExceptionsActions.addAttestationSuccess,
    (state: InvoicesState, { invoiceId, invoiceUuid, areAllAttested }) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addAttestation,
        status: LoadingStatuses.Completed,
        metadata: { invoiceId, invoiceUuid, areAllAttested }
      })
    })
  ),
  on(
    invoiceExceptionsActions.addAttestationFail,
    (state: InvoicesState, { error }) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addAttestation,
        status: LoadingStatuses.Failed,
        message: error ?? "There's an error trying to add attestation."
      })
    })
  ),
  on(
    invoiceExceptionsActions.resetAddAttestationLoadingStatus,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addAttestation,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
