import { IStrategy } from '@navix/utils/adapter'
import { GetOrdersResponse } from '../../domain/orders/get-orders.response'
import { Order } from '../../domain/orders/order.model'

export class FromGetOrdersResponse
  implements IStrategy<GetOrdersResponse, Order[]>
{
  convert = ({ value }: GetOrdersResponse) =>
    value.map(
      order =>
        <Order>{
          id: order.Uuid,
          legacyId: order.OrderId,
          customerCharge: order.CustomerCharge,
          customerId: order.CustomerId,
          customer: {
            name: order.CustomerName
          },
          deliveryDate: this.toDate(order.DeliveryDate),
          destinationCity: order.DestinationCity,
          destinationName: order.DestinationName,
          destinationState: order.DestinationState,
          destinationZip: order.DestinationZip,
          orderNumber: order.OrderNumber,
          originCity: order.OriginCity,
          originName: order.OriginName,
          originState: order.OriginState,
          originZip: order.OriginZip,
          shipDate: this.toDate(order.ShipDate),
          vendorCharge: order.VendorCharge,
          vendors: order.VendorIds.map<Order['vendors'][0]>(vendorId => ({
            vendorId,
            charges: [],
            purchaseInvoices: [],
            externalId: '',
            name: '',
            billTo: {
              address1: '',
              address2: '',
              city: '',
              countryCode: '',
              name: '',
              stateCode: '',
              zip: ''
            },
            remitTo: {
              address1: '',
              address2: '',
              city: '',
              countryCode: '',
              name: '',
              stateCode: '',
              zip: ''
            },
            chargeTerms: '',
            service: {
              mode: '',
              type: '',
              level: ''
            },
            stops: []
          })),
          shipmentNumber: order.ShipmentNumber,
          withDetails: false,
          createDate: this.toDate(order.CreateDate)
        }
    )

  toDate = (date: string | null) => (date ? new Date(date) : undefined)
}
