import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, map, of, switchMap } from 'rxjs'
import { AuditsService } from '../../../infrastructure/audits.service'
import { MS_BEFORE_RESET_LOADING_STATE } from '../audits.constants'
import { updateAuditActions } from './update-audit.actions'
import { toUpdateAuditRequest } from '../../../adapter/audits/to-update-audit.request'
import { toUpdateAuditChargesRequest } from '../../../adapter/audits/to-update-audit-charges.request'
import { toAddAuditChargesMappingsRequest } from '../../../adapter/audits/to-add-audit-charges-mappings.request'
import { toUpdateAuditChargesV2Request } from '../../../adapter/audits/to-update-audit-charges-v2.request'
import { Audit } from '../../../domain/audits/audit.model'
import { update } from 'libs/charge-codes/domain/src/lib/+state/vendor-charge-codes/vendor-charge-codes.effects'

export const updateAudit = createEffect(
  (actions$ = inject(Actions), service = inject(AuditsService)) => {
    return actions$.pipe(
      ofType(updateAuditActions.updateAudit),

      switchMap(payload =>
        service.getAuditByOrderId(payload.orderUuid).pipe(
          switchMap(response =>
            service
              .updateAudit(
                payload.orderUuid,
                toUpdateAuditRequest(payload, response),
                payload.vendorInvoiceId
              )
              .pipe(
                map(() => updateAuditActions.updateAuditSuccess()),
                catchError(error => {
                  let message = error.error?.message || error.message
                  if (error.message && error.message.status === 403) {
                    message = error.message.error.message
                  }
                  return of(
                    updateAuditActions.updateAuditFail({ error: message })
                  )
                })
              )
          ),
          catchError(error => of(updateAuditActions.updateAuditFail({ error })))
        )
      )
    )
  },
  {
    functional: true
  }
)

export const updateAuditCharges = createEffect(
  (actions$ = inject(Actions), service = inject(AuditsService)) => {
    return actions$.pipe(
      ofType(updateAuditActions.updateAuditCharges),
      switchMap(payload =>
        service.getAuditByOrderId(payload.orderUuid).pipe(
          switchMap(response => {
            const request = toUpdateAuditChargesRequest(payload, response)
            if (request === false)
              return of(
                updateAuditActions.updateAuditChargesFail({
                  error:
                    'We have detected an issue in the vendor charges.  Please refresh the browser and try again.',
                  isMissingOrderAuditDocument: false
                })
              )

            return service
              .updateAudit(payload.orderUuid, request, payload.vendorInvoiceId)
              .pipe(
                map(() => updateAuditActions.updateAuditChargesSuccess()),
                catchError(error =>
                  of(
                    updateAuditActions.updateAuditChargesFail({
                      error,
                      isMissingOrderAuditDocument: false
                    })
                  )
                )
              )
          }),
          catchError(error => {
            return of(
              updateAuditActions.updateAuditChargesFail({
                error,
                isMissingOrderAuditDocument: error.status === 404
              })
            )
          })
        )
      )
    )
  },
  {
    functional: true
  }
)

export const updateAuditChargesMappings = createEffect(
  (actions$ = inject(Actions), service = inject(AuditsService)) => {
    return actions$.pipe(
      ofType(updateAuditActions.addAuditChargesMappings),
      switchMap(payload =>
        service.getAuditByOrderId(payload.orderUuid).pipe(
          switchMap(response =>
            service
              .updateAudit(
                payload.orderUuid,
                toAddAuditChargesMappingsRequest(payload, response),
                payload.vendorInvoiceId
              )
              .pipe(
                map(() => updateAuditActions.addAuditChargesMappingsSuccess()),
                catchError(error => {
                  let message = error.error?.message || error.message
                  if (error.message && error.status === 403) {
                    message = error.error.message
                  }
                  console.log(message)
                  console.log(error)
                  return of(
                    updateAuditActions.addAuditChargesMappingsFail({
                      error: message
                    })
                  )
                })
              )
          )
        )
      )
    )
  },
  {
    functional: true
  }
)

export const resetUpdateAuditChargesMappings = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        updateAuditActions.addAuditChargesMappingsSuccess,
        updateAuditActions.addAuditChargesMappingsFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => updateAuditActions.resetAddAuditChargesMappingsLoadingState())
    )
  },
  {
    functional: true
  }
)

export const resetUpdateAudit = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        updateAuditActions.updateAuditSuccess,
        updateAuditActions.updateAuditFail,
        updateAuditActions.updateAuditChargesSuccess,
        updateAuditActions.updateAuditChargesFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => updateAuditActions.resetUpdateAuditLoadingState())
    )
  },
  {
    functional: true
  }
)

export const updateAuditChargesV2 = createEffect(
  (actions$ = inject(Actions), service = inject(AuditsService)) => {
    return actions$.pipe(
      ofType(updateAuditActions.updateAuditChargesV2),
      switchMap(payload =>
        service.getAuditByOrderId(payload.orderUuid).pipe(
          switchMap(response =>
            service
              .updateAuditChargesV2(
                payload.auditUuid,
                payload.invoiceUuid,
                toUpdateAuditChargesV2Request(payload, response)
              )
              .pipe(
                map(() => updateAuditActions.updateAuditChargesV2Success()),
                catchError(error => {
                  let message = error.error?.message || error.message
                  if (error.message && error.status === 403) {
                    message = error.error.message
                  } else if (error.message && error.status === 409) {
                    message = error.error.message
                  }
                  return of(
                    updateAuditActions.updateAuditChargesV2Fail({
                      error: message,
                      isMissingOrderAuditDocument: error.status === 404,
                      isMissingVendorInvoiceCharges: error.status === 409
                    })
                  )
                })
              )
          ),
          catchError(error => {
            return of(
              updateAuditActions.updateAuditChargesFail({
                error,
                isMissingOrderAuditDocument: error.status === 404
              })
            )
          })
        )
      )
    )
  },
  {
    functional: true
  }
)

export const resetUpdateAuditChargesV2 = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        updateAuditActions.updateAuditChargesV2Success,
        updateAuditActions.updateAuditChargesV2Fail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => updateAuditActions.resetupdateAuditChargesV2LoadingState())
    )
  },
  {
    functional: true
  }
)

// TODO update this effect so you don't need to pass in the VendorId
export const updateAuditVendor = createEffect(
  (actions$ = inject(Actions), service = inject(AuditsService)) => {
    return actions$.pipe(
      ofType(updateAuditActions.updateAuditVendor),
      switchMap(payload =>
        service.getAuditByOrderId(payload.orderUuid).pipe(
          switchMap(response =>
            service
              .updateAuditVendor(payload.auditUuid, payload.invoiceUuid, {
                vendorExternalId: payload.vendorExternalId,
                auditDocumentEtag: response.eTag ?? ''
              })
              .pipe(
                map((updateVendorResponse: Audit) => {
                  return updateAuditActions.updateAuditVendorSuccess({
                    newVendorId:
                      updateVendorResponse.vendors[payload.vendorExternalId]
                        .vendorId
                  })
                }),
                catchError(error => {
                  let message = error.error?.message || error.message
                  if (error.message && error.status === 403) {
                    message = error.error.message
                  }
                  return of(
                    updateAuditActions.updateAuditVendorFail({
                      error: message,
                      isMissingOrderAuditDocument: error.status === 404
                    })
                  )
                })
              )
          ),
          catchError(error => {
            return of(
              updateAuditActions.updateAuditChargesFail({
                error,
                isMissingOrderAuditDocument: error.status === 404
              })
            )
          })
        )
      )
    )
  },
  {
    functional: true
  }
)

export const resetUpdateAuditVendor = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        updateAuditActions.updateAuditVendorSuccess,
        updateAuditActions.updateAuditVendorFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => updateAuditActions.resetUpdateAuditVendorLoadingState())
    )
  },
  {
    functional: true
  }
)
