import { invoiceVendorDetailsActions } from './invoice-vendor-details.actions'
import { invoicesAdapter, InvoicesState } from '../invoices.reducer'
import { on } from '@ngrx/store'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { HttpStatusCode } from '@angular/common/http'

export const invoiceVendorDetailsReducers = [
  on(
    invoiceVendorDetailsActions.updateInvoiceVendorStops,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceVendorStops,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceVendorDetailsActions.updateInvoiceVendorStopsSuccess,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceVendorStops,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(
    invoiceVendorDetailsActions.updateInvoiceVendorStopsFail,
    (state: InvoicesState, httpError): InvoicesState => {
      const {
        error: { error }
      } = httpError

      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.updateInvoiceVendorStops,
          status: LoadingStatuses.Failed,
          message: error.message
        })
      }
    }
  ),
  on(
    invoiceVendorDetailsActions.updateInvoiceVendor,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceVendorName,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceVendorDetailsActions.updateInvoiceVendorSuccess,
    (state: InvoicesState, { newVendorId, fromModal }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceVendorName,
        status: LoadingStatuses.Completed,
        message: 'Invoice vendor updated successfully.',
        metadata: { newVendorId, fromModal }
      })
    })
  ),
  on(
    invoiceVendorDetailsActions.updateInvoiceVendorFail,
    (state: InvoicesState, { error }): InvoicesState => {
      const defaultErrorMessage = `There's an error trying to update invoice vendor.`
      const isErrorMessageFromBackend =
        error.status === HttpStatusCode.Forbidden
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.updateInvoiceVendorName,
          status: LoadingStatuses.Failed,
          message: isErrorMessageFromBackend
            ? error.error.message ?? defaultErrorMessage
            : defaultErrorMessage
        })
      }
    }
  ),
  on(
    invoiceVendorDetailsActions.updateInvoiceVendorInState,
    (state: InvoicesState, { id, newVendor }): InvoicesState =>
      invoicesAdapter.updateOne(
        {
          id: id,
          changes: {
            vendorId: newVendor.id,
            vendorExternalId: newVendor.externalId,
            vendorUuid: newVendor.uuid
          }
        },
        state
      )
  ),
  on(
    invoiceVendorDetailsActions.resetUpdateInvoiceVendorLoadingState,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceVendorName,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
