import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Invoice } from '../../../domain/invoices/invoice.model'

export const invoiceExceptionsActions = createActionGroup({
  source: 'Invoice Exceptions',
  events: {
    'load invoice exceptions': props<{ invoiceId: number }>(),
    'load invoice exceptions success': props<{
      invoiceExceptions: Invoice['exceptions']
      invoiceId: number
    }>(),
    'load invoice exceptions fail': props<{ error: unknown }>(),
    loadWorkInstructions: props<{ invoiceUuid: string; invoiceId: number }>(),
    loadWorkInstructionsSuccess: props<{
      invoiceWithWorkInstructions: Invoice
    }>(),
    loadWorkInstructionsFail: props<{ error: unknown }>(),
    resetLoadWorkInstructionsLoadingStatus: emptyProps(),
    loadWorkInstructionsPhase2: props<{
      invoiceUuid: string
      invoiceId: number
    }>(),
    loadWorkInstructionsPhase2Success: props<{
      invoiceWithWorkInstructions: Invoice
    }>(),
    loadWorkInstructionsPhase2Fail: props<{ error: unknown }>(),
    resetLoadWorkInstructionsPhase2LoadingStatus: emptyProps(),
    addAttestation: props<{
      invoiceUuid: string
      invoiceId: number
      workInstructionsIds: number[]
      areAllAttested: boolean
    }>(),
    addAttestationSuccess: props<{
      invoiceUuid: string
      invoiceId: number
      areAllAttested: boolean
    }>(),
    addAttestationFail: props<{ error?: string }>(),
    resetAddAttestationLoadingStatus: emptyProps()
  }
})
