import { platform_v2 } from '@navix/api-models'
import { Audit } from '../../domain/audits/audit.model'

export function fromGetAuditResponse(
  response: platform_v2.Navix_Services_Sdk_Audit
): Audit {
  const audit = <Audit>{
    id: response.uuid,
    eTag: response.eTag,
    orderId: response.orderUuid,
    notes:
      response.notes?.map(note => {
        return {
          createdAt: toDate(note.createdAt),
          createdBy: note.createdBy,
          text: note.text
        }
      }) ?? [],
    payables: Object.entries(response.vendors ?? {}).flatMap(
      ([vendorUuid, vendor]) =>
        vendor.invoices?.map<Audit['payables'][0]>(invoice => ({
          invoiceUuid: invoice.source ?? '',
          vendorExternalId: vendorUuid,
          adjustedAmount:
            invoice.charges?.vendor?.chargeAdjustments?.reduce(
              (sum, adjustment) => sum + (adjustment.adjustment ?? 0),
              0
            ) ?? 0
        }))
    ),
    receivables: Object.entries(response.vendors ?? {}).flatMap(
      ([vendorUuid, vendor]) =>
        vendor.invoices?.map<Audit['receivables'][0]>(invoice => ({
          invoiceUuid: invoice.source ?? '',
          vendorUuid: vendorUuid,
          adjustedAmount:
            invoice.charges?.customer?.chargeAdjustments?.reduce(
              (sum, adjustment) => sum + (adjustment.adjustment ?? 0),
              0
            ) ?? 0
        }))
    ),
    chargeSets:
      //Todo(ladaj): Core needs to update sdk models and we need to generate api models
      (response as unknown as any).customer?.charges?.sets?.map(
        (chargeSet: {
          Uuid: any
          finalizedAt: string | number | Date
          finalizedBy: any
          charges: any[]
        }) =>
          <Audit['chargeSets'][0]>{
            uuid: chargeSet.Uuid,
            finalizedDate: new Date(chargeSet.finalizedAt),
            finalizedByUuid: chargeSet.finalizedBy,
            charges: chargeSet.charges.map(
              (charge: { type: any; code: any; amount: any }) => ({
                chargeTypeUuid: charge.type,
                code: charge.code,
                amount: charge.amount
              })
            )
          }
      ) ?? [],
    vendors: {}
  }

  return audit
}

function toDate(date: string | null | undefined) {
  return date ? new Date(date) : undefined
}
