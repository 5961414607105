import { Injectable, Signal, computed, inject } from '@angular/core'
import { Store, select } from '@ngrx/store'

import { LoadingStatuses, loadingStateEqualFn } from '@navix/shared/loading'
import { getRouterSelectors } from '@ngrx/router-store'
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  skipWhile,
  take,
  withLatestFrom
} from 'rxjs'
import {
  DataFilters,
  StatusFilter
} from '../../domain/invoices/data-filters.model'
import { InvoiceCharge } from '../../domain/models/invoice-charge.model'
import {
  InvoiceForm,
  InvoiceFormControls
} from '../../domain/invoices/invoice-form.model'
import { Invoice } from '../../domain/invoices/invoice.model'
import {
  AsyncOperations,
  InvoicesLoadingState
} from '../../domain/invoices/invoices-loading.model'
import { invoiceAuditHistoryActions } from './invoice-audit-history/invoice-audit-history.actions'
import { invoiceChargesActions } from './invoice-charges/invoice-charges.actions'
import { invoiceCurrencyActions } from './invoice-currency/invoice-currency.actions'
import { invoiceCustomerDetailsActions } from './invoice-customer-details/invoice-customer-details.actions'
import { invoiceDetailsActions } from './invoice-details/invoice-details.actions'
import { invoiceDocumentsActions } from './invoice-documents/invoice-documents.actions'
import { invoiceDuplicatesActions } from './invoice-duplicates/invoice-duplicates.actions'
import { invoiceExceptionsActions } from './invoice-exceptions/invoice-exceptions.actions'
import { invoiceLineItemsActions } from './invoice-line-items/invoice-line-items.actions'
import { invoiceNotesActions } from './invoice-notes/invoice-notes.actions'
import { invoiceReferenceNumbersActions } from './invoice-reference-numbers/invoice-reference-numbers.actions'
import { invoiceRelatedDocumentsActions } from './invoice-related-documents/invoice-related-documents.actions'
import { invoiceVendorDetailsActions } from './invoice-vendor-details/invoice-vendor-details.actions'
import { invoicesActions } from './invoices.actions'
import { DEFAULT_ALL_OPTIONS, FIRST_PAGE_VALUE } from './invoices.constants'
import * as InvoicesSelectors from './invoices.selectors'
import * as DisputesSelectors from '../disputes/disputes.selectors'
import { operationsActions } from './operations/operations.actions'
import { NONE_DATE_FILTER_OPTION, defaultStatusFilters } from './static'
import {
  filterTabsData,
  filterTabsDataObj
} from './static/default-advanced-filters-configuration'
import { KnownInvoiceStatuses } from './static/known-invoice-statuses'
import { invoicesTypesActions } from './types/types.actions'
import { toSignal } from '@angular/core/rxjs-interop'
import { invoiceDisputesActions } from './invoice-disputes/invoice-disputes.actions'
import { EmailTemplate } from '../../domain/invoices/email-template-type.model'
import { concatLatestFrom } from '@ngrx/operators'
import { disputesActions } from '../disputes/disputes.actions'
import { GetPaginatedDisputesRequest } from '../../domain/disputes/get-paginated-disputes.request'
import { PaginatedDisputeItemResponse } from '../../domain/disputes/get-paginated-disputes.response'
import { Actions, ofType } from '@ngrx/effects'

const EXCEPTION_TYPE_DUPLICATE_ID = 3
const EXCEPTION_TYPE_WORK_INSTRUCTION_ID = 8

const { selectQueryParams, selectRouteData, selectRouteParams } =
  getRouterSelectors()

@Injectable()
export class InvoicesFacade {
  private readonly store: Store = inject(Store)
  private readonly actions$ = inject(Actions)

  routeData$ = this.store.pipe(select(selectRouteData))
  routeQueryParams$ = this.store.pipe(select(selectQueryParams))
  routeParams$ = this.store.pipe(select(selectRouteParams))
  routeParams = this.store.selectSignal(selectRouteParams)
  routeQueryParams = this.store.selectSignal(selectQueryParams)

  allInvoices$ = this.store.pipe(select(InvoicesSelectors.selectAllInvoices))
  allInvoices = this.store.selectSignal(InvoicesSelectors.selectAllInvoices)
  allInvoiceEntities$ = this.store.pipe(
    select(InvoicesSelectors.selectInvoicesEntities)
  )

  selectedInvoice$ = this.store.pipe(
    select(InvoicesSelectors.selectEntity),
    filter(invoice => invoice != undefined),
    map(invoice => invoice as Invoice)
  )

  selectedInvoiceWithDetails$ = this.store.pipe(
    select(InvoicesSelectors.selectEntity),
    filter(invoice => invoice != undefined),
    filter(invoice => invoice?.withDetails === true),
    map(invoice => invoice ?? ({} as Invoice))
  )

  selectedInvoice = this.store.selectSignal(InvoicesSelectors.selectEntity)

  allCustomerCharges$ = this.selectedInvoiceWithDetails$.pipe(
    map(invoice =>
      Object.values(invoice?.legacyCharges.customer).flatMap(charge =>
        this.getDetails(charge)
      )
    )
  )

  allVendorCharges$ = this.selectedInvoiceWithDetails$.pipe(
    map(invoice =>
      Object.values(invoice?.legacyCharges.vendor).flatMap(charge =>
        this.getDetails(charge)
      )
    )
  )

  pagesCount$ = this.store.pipe(select(InvoicesSelectors.selectPagesCount))
  standaloneWindowOpen$ = this.store.pipe(
    select(InvoicesSelectors.selectStandAloneWindowOpen)
  )

  getDetails(charge: InvoiceCharge): InvoiceCharge[] {
    if (charge.details && charge.details.length > 0) {
      return charge.details.flatMap(x => this.getDetails(x))
    }
    return [charge]
  }

  invoiceIncompleteDisputes$ = this.selectedInvoiceWithDetails$.pipe(
    map(invoice =>
      invoice.notes.filter(
        note => note.isDisputed === true && note.isCompleted === false
      )
    )
  )

  hasInvoiceOwnerUser$ = this.selectedInvoiceWithDetails$.pipe(
    map(invoice => {
      const isUserInvoiceOwner = invoice.ownerUserId != undefined

      return isUserInvoiceOwner
    })
  )

  totalCount$ = this.store.pipe(select(InvoicesSelectors.selectTotalCount))

  /**
   * @deprecated use loading$ instead
   * @see loading$
   */
  simpleLoading$ = this.store.pipe(
    select(InvoicesSelectors.selectLoading),
    map(loading =>
      Object.entries(loading).reduce<
        Record<keyof typeof AsyncOperations, boolean>
      >(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value.status === LoadingStatuses.InProgress
        }),
        {} as Record<keyof typeof AsyncOperations, boolean>
      )
    )
  )

  /**
   * @description this loading status machine uses LoadingStatuses enum and LoadingState interface.
   * @requires state to be designed with new loading state
   * @see LoadingStatuses
   * @see LoadingState
   */
  loading$ = this.store.pipe(select(InvoicesSelectors.selectLoading))
  loading = this.store.selectSignal(InvoicesSelectors.selectLoading)

  filters$ = this.store.pipe(select(InvoicesSelectors.selecFilters))
  filters = this.store.selectSignal(InvoicesSelectors.selecFilters)
  filtersMemory$ = this.store.pipe(
    select(InvoicesSelectors.selectFiltersMemory)
  )

  typesOfExceptions$ = this.store.pipe(
    select(InvoicesSelectors.selectTypesOfExceptions),
    filter(statuses => statuses.length > 0),
    map(exceptionTypes =>
      exceptionTypes.filter(
        type =>
          type.id !== EXCEPTION_TYPE_DUPLICATE_ID &&
          type.id !== EXCEPTION_TYPE_WORK_INSTRUCTION_ID
      )
    )
  )

  disputedReasons$ = this.store.pipe(
    select(InvoicesSelectors.selectDisputedReasons),
    filter(statuses => statuses.length > 0)
  )

  invoiceStatuses$ = this.store.pipe(
    select(InvoicesSelectors.selectInvoiceStatuses),
    filter(statuses => statuses.length > 0)
  )

  freightChargeTerms$ = this.store.pipe(
    select(InvoicesSelectors.selectFreightTermsSelector)
  )

  lineItemClasses$ = this.store.pipe(
    select(InvoicesSelectors.selectLineItemClasses)
  )

  documentTypes$ = this.store.pipe(
    select(InvoicesSelectors.selectDocumentTypes),
    filter(statuses => statuses.length > 0)
  )

  documentTypes = this.store.selectSignal(InvoicesSelectors.selectDocumentTypes)

  documents = computed(() => {
    const invoice = this.selectedInvoice()
    const documentTypes = this.documentTypes()
    if (!invoice || !invoice.documents || !documentTypes) {
      return []
    }

    const documents = documentTypes.flatMap(type =>
      invoice.documents
        .filter(document => document.typeId === type.id)
        .map(document => ({
          id: document.id,
          name: `${type.name}${document.isRedacted ? ' - (Redacted)' : ''}, ${document.name}`,
          url: document.url
        }))
    )

    return documents
  })

  documentTypesGrouped = computed(() => {
    const invoice = this.selectedInvoice()
    const documentTypes = this.documentTypes()

    if (!invoice || !invoice.documents || !documentTypes) return []

    const documentsByType: {
      typeId: number
      description: string
      documentIds: number[]
      documentUrls: string[]
    }[] = []

    documentTypes.forEach(type => {
      const documentIds = invoice.documents
        .filter(document => document.typeId === type.id)
        .map(document => document.id)

      const documentUrls = invoice.documents
        .filter(document => document.typeId === type.id)
        .map(document => document.url)

      documentsByType.push({
        typeId: type.id,
        description: type.name,
        documentIds,
        documentUrls
      })
    })

    return documentsByType
  })

  emailTemplates$ = this.store.pipe(
    select(InvoicesSelectors.selectEmailTemplateTypes),
    filter(templates => templates.length > 0)
  )

  emailTemplates = this.store.selectSignal(
    InvoicesSelectors.selectEmailTemplates
  )

  emailTemplatesGrouped = computed(() =>
    this.emailTemplates().reduce(
      (acc, value) => {
        const party = value.party
        acc[party] = acc[party] || []
        acc[party].push(value)
        return acc
      },
      {} as Record<string, EmailTemplate[]>
    )
  )

  emailTemplatesGroupedByType = computed(() =>
    this.emailTemplates().reduce(
      (acc, value) => {
        const party = value.party
        const type = value.type
        const partyType = `${party}-${type}`
        acc[partyType] = acc[partyType] || []
        acc[partyType].push(value)
        return acc
      },
      {} as Record<string, EmailTemplate[]>
    )
  )

  emailTemplatesAsDictionary$ = this.store.pipe(
    select(InvoicesSelectors.selectEmailTemplateTypesAsDictionary)
  )

  emailTemplatesAsDictionary = this.store.selectSignal(
    InvoicesSelectors.selectEmailTemplateTypesAsDictionary
  )

  terminateReasons$ = this.store.pipe(
    select(InvoicesSelectors.selectTerminateReasons),
    filter(statuses => statuses.length > 0)
  )

  parentInvoiceId$ = this.selectedInvoiceWithDetails$.pipe(
    map(invoice => {
      const hasParentId = invoice.parentInvoiceId != undefined
      return (hasParentId ? invoice.parentInvoiceId : invoice.id) as number
    })
  )

  duplicateInvoices$ = this.allInvoices$.pipe(
    skipWhile(
      invoices => !invoices.some(({ fromDuplicates }) => fromDuplicates)
    ),
    filter(invoices => invoices.length >= 2),
    withLatestFrom(this.parentInvoiceId$),

    map(([invoices, parentInvoiceId]) => {
      const duplicatenvoices = invoices
        .filter(
          invoice =>
            invoice.id === parentInvoiceId ||
            invoice.parentInvoiceId === parentInvoiceId
        )
        .toSorted((a, b) => b.createdDate?.getDate() - a.createdDate?.getDate())
      return duplicatenvoices
    }),

    debounceTime(300),
    distinctUntilChanged((previous, current) => {
      const previousUniqueSign = previous
        .map(
          invoice =>
            `${invoice.id}_${invoice.documents?.length ?? 0}_${invoice.netCharge ?? 0}`
        )
        .join('')
      const currentUniqueSign = current
        .map(
          invoice =>
            `${invoice.id}_${invoice.documents?.length ?? 0}_${invoice.netCharge ?? 0}`
        )
        .join('')
      return previousUniqueSign == currentUniqueSign
    })
  )

  duplicateInvoicesWithoutDistinct$ = this.allInvoices$.pipe(
    map(invoices => invoices.filter(invoice => invoice.fromDuplicates)),
    concatLatestFrom(() => this.parentInvoiceId$),
    map(([invoices, parentInvoiceId]) => {
      const duplicatenvoices = invoices
        .filter(
          invoice =>
            invoice.id === parentInvoiceId ||
            invoice.parentInvoiceId === parentInvoiceId
        )
        .toSorted((a, b) => b.createdDate?.getDate() - a.createdDate?.getDate())
      return duplicatenvoices
    })
  )

  parentInvoiceId = toSignal(this.parentInvoiceId$)

  duplicateInvoicesWithDocuments$ = this.duplicateInvoicesWithoutDistinct$.pipe(
    filter(invoices =>
      invoices.every(invoice => invoice.documents !== undefined)
    )
  )

  duplicateInvoices = toSignal(this.duplicateInvoices$)

  vendorInvoiceTypes$ = this.store.pipe(
    select(InvoicesSelectors.selectInvoiceTypes),
    filter(vendorInvoiceTypes => vendorInvoiceTypes.length > 0)
  )

  badgeFiltersList$ = this.filters$.pipe(
    distinctUntilChanged((prev, curr) => {
      return (
        prev.status === curr.status &&
        prev.from === curr.from &&
        prev.to === curr.to &&
        prev.remitTo === curr.remitTo &&
        Object.keys(filterTabsDataObj).every(key => {
          const typedKey = key as keyof typeof filterTabsDataObj
          return prev[typedKey] === curr[typedKey]
        })
      )
    }),
    map(filters => {
      return Object.values(filterTabsData).map(badgeItem => {
        const isCustomBadge = (
          key: string,
          badgeKeys: (keyof typeof filterTabsDataObj)[]
        ) => badgeKeys.includes(badgeItem.key as any)

        const customDateRangeRulesBadges: (keyof typeof filterTabsDataObj)[] = [
          'dateRangeTypes'
        ]

        const skipBadge =
          badgeItem.skipFor.includes(filters.status) ||
          badgeItem.readOnlyFor.includes(filters.status)
        let value = ''
        if (isCustomBadge(badgeItem.key, customDateRangeRulesBadges)) {
          value = this.getCustomBadgeDescriptionForDateRangeTypes(filters)
          return { ...badgeItem, skip: skipBadge, value }
        }
        const filterValue = filters[badgeItem.key]
        if (typeof filterValue === 'string') {
          value = filterValue.trim()
        }
        if (Array.isArray(filterValue)) {
          value = filterValue
            .filter(x => x.id !== DEFAULT_ALL_OPTIONS.id && x.id !== '')
            ?.map(filter => filter.label)
            .join(', ')
        }
        return { ...badgeItem, skip: skipBadge, value }
      })
    }),
    map(badges => badges.filter(badge => badge.value !== '' && !badge.skip))
  )

  allReferenceNumberTypes$ = this.store.pipe(
    select(InvoicesSelectors.selectAllReferenceNumberTypes),
    filter(referenceNumberTypes => referenceNumberTypes.length > 0)
  )

  selectedInvoiceId = this.store.selectSignal(
    InvoicesSelectors.selectSelectedId
  )

  selectedInvoiceId$ = this.store.pipe(
    select(InvoicesSelectors.selectSelectedId),
    filter(invoiceId => invoiceId != undefined),
    map(invoiceId => invoiceId as number)
  )

  allDuplicateActionTypes$ = this.store.pipe(
    select(InvoicesSelectors.selectDuplicateActionTypes)
  )

  isSelectedInvoiceEditable$ = this.selectedInvoiceWithDetails$.pipe(
    map(invoice => {
      const editStatuses = [
        KnownInvoiceStatuses.Draft,
        KnownInvoiceStatuses.Ready,
        KnownInvoiceStatuses.Unmatched,
        KnownInvoiceStatuses.Matched,
        KnownInvoiceStatuses.DuplicateUnprocessed,
        KnownInvoiceStatuses.Audit,
        KnownInvoiceStatuses.DuplicateProcessed
      ]

      return editStatuses.includes(invoice.statusId)
    })
  )
  isSelectedInvoiceEditable = toSignal(this.isSelectedInvoiceEditable$, {
    initialValue: false
  })

  isSelectedInvoiceFinalized$ = this.selectedInvoiceWithDetails$.pipe(
    map(invoice => {
      const finalStatuses = [
        KnownInvoiceStatuses.Terminated,
        KnownInvoiceStatuses.Approved,
        KnownInvoiceStatuses.Exported,
        KnownInvoiceStatuses.Paid
      ]

      return finalStatuses.includes(invoice.statusId)
    })
  )

  isSelectedInvoiceFinalized = toSignal(this.isSelectedInvoiceFinalized$)
  selectedInvoiceStatusId = computed(() => this.selectedInvoice()?.statusId)
  canSelectedInvoiceAddDisputeNotes = computed(
    () =>
      this.selectedInvoiceStatusId() !==
        KnownInvoiceStatuses.DuplicateUnprocessed &&
      !this.isSelectedInvoiceFinalized()
  )

  canSelectedInvoiceTerminate = computed(
    () =>
      this.selectedInvoiceStatusId() !== KnownInvoiceStatuses.Approved &&
      this.selectedInvoiceStatusId() !== KnownInvoiceStatuses.Terminated &&
      this.selectedInvoiceStatusId() !==
        KnownInvoiceStatuses.DuplicateUnprocessed
  )

  reAuditLoadingState = computed(() => this.loading().reAudit, {
    equal: (compare, comparer) => compare.status === comparer.status
  })

  isReAuditInProgress = computed(
    () => this.reAuditLoadingState().status === LoadingStatuses.InProgress
  )

  relatedDocuments$ = this.store.pipe(
    select(InvoicesSelectors.selectRelatedDocuments)
  )

  isFirstPage$ = combineLatest([
    this.filters$.pipe(
      map(f => f.page),
      map(x => x as number)
    ),
    this.pagesCount$
  ]).pipe(
    map(
      ([currentPage, pagesCount]) =>
        currentPage === FIRST_PAGE_VALUE && pagesCount
    )
  )

  isLastPage$ = combineLatest([
    this.filters$.pipe(
      map(f => f.page),
      map(x => x as number)
    ),
    this.pagesCount$
  ]).pipe(map(([currentPage, pagesCount]) => currentPage === pagesCount))

  isFirstRecordInList$ = combineLatest([
    this.allInvoices$,
    this.selectedInvoiceId$
  ]).pipe(map(([invoices, currentId]) => invoices.at(0)?.id === currentId))

  isLastRecordInList$ = combineLatest([
    this.allInvoices$,
    this.selectedInvoiceId$
  ]).pipe(
    map(
      ([invoices, currentId]) => invoices[invoices.length - 1]?.id === currentId
    )
  )

  canSelectedInvoiceReAudit = computed(
    () =>
      this.isSelectedInvoiceEditable() &&
      this.selectedInvoiceStatusId() !==
        KnownInvoiceStatuses.DuplicateUnprocessed &&
      (this.selectedInvoiceStatusId() === KnownInvoiceStatuses.Audit ||
        this.selectedInvoiceStatusId() === KnownInvoiceStatuses.Ready)
  )

  paginatedDisputesState$ = this.store.pipe(
    select(DisputesSelectors.selectPaginatedDisputesState)
  )
  paginatedDisputesState = toSignal(this.paginatedDisputesState$)

  paginatedDisputesCurrentDispute$ = this.store.pipe(
    select(DisputesSelectors.selectCurrentDispute)
  )

  paginatedDisputesloading$ = this.store.pipe(
    select(DisputesSelectors.selectPaginatedDisputesLoadingStatus)
  )
  paginatedDisputesloading = toSignal(this.paginatedDisputesloading$, {
    initialValue: false
  })

  paginatedDisputeRequest$ = this.store.pipe(
    select(DisputesSelectors.selectPaginatedDisputeRequest)
  )
  paginatedDisputeRequest = this.store.selectSignal(
    DisputesSelectors.selectPaginatedDisputeRequest
  )

  paginatedDisputesTotalRecords$ = this.store.pipe(
    select(DisputesSelectors.selectPaginatedDisputesTotalRecords)
  )
  paginatedDisputesTotalRecords = this.store.selectSignal(
    DisputesSelectors.selectPaginatedDisputesTotalRecords
  )

  paginatedDisputeItems$ = this.store.pipe(
    select(DisputesSelectors.selectPaginatedDisputeItems)
  )

  paginatedDisputeItems = toSignal(this.paginatedDisputeItems$, {
    initialValue: []
  })

  async paginatedDisputesRequest(
    request: Partial<GetPaginatedDisputesRequest>
  ) {
    const currentPaginatedDisputesRequest = await firstValueFrom(
      this.paginatedDisputeRequest$
    )
    const newPaginatedDisputesRequest = {
      ...currentPaginatedDisputesRequest,
      ...(request ?? {})
    }

    this.store.dispatch(
      disputesActions.patchPaginatedDisputesRequest({
        request: newPaginatedDisputesRequest
      })
    )
    this.store.dispatch(
      disputesActions.loadPaginatedDisputes({
        request: newPaginatedDisputesRequest
      })
    )
  }

  async patchPaginatedDisputesRequest(
    filters: Partial<GetPaginatedDisputesRequest>
  ) {
    const currentPaginatedDisputesRequest = await firstValueFrom(
      this.paginatedDisputeRequest$
    )
    const newPaginatedDisputesRequest = {
      ...currentPaginatedDisputesRequest,
      ...(filters ?? {})
    }

    this.store.dispatch(
      disputesActions.patchPaginatedDisputesRequest({
        request: newPaginatedDisputesRequest
      })
    )
  }

  clearPaginatedDisputes() {
    this.store.dispatch(disputesActions.clearPaginatedDisputes())
  }

  loadPaginatedDisputes(request: GetPaginatedDisputesRequest) {
    this.store.dispatch(disputesActions.loadPaginatedDisputes({ request }))
  }

  startPaginatedDisputeLoading() {
    this.store.dispatch(
      disputesActions.setPaginatedDisputesLoading({
        loading: true
      })
    )
  }
  endPaginatedDisputeLoading() {
    this.store.dispatch(
      disputesActions.setPaginatedDisputesLoading({
        loading: false
      })
    )
  }

  setCurrentDisputeFromPaginatedDisputes(
    dispute: PaginatedDisputeItemResponse | undefined,
    index: number
  ) {
    this.store.dispatch(disputesActions.setCurrentDispute({ dispute, index }))
  }

  private getCustomBadgeDescriptionForDateRangeTypes(
    filters: DataFilters
  ): string {
    const isEmpty = filters.dateRangeTypes.length === 0
    const isNoneSelected =
      filters.dateRangeTypes.at(0)?.id === NONE_DATE_FILTER_OPTION.id
    const from = ` from: ${new Date(filters.from!).toLocaleDateString('en-US')}`
    const to =
      filters.to !== undefined
        ? ' - to: ' + new Date(filters.to).toLocaleDateString('en-US')
        : ''
    return isEmpty || isNoneSelected
      ? ''
      : `${filters.dateRangeTypes[0].label}${from}${to}`
  }

  selectInvoice(invoiceId: number) {
    this.store.dispatch(
      invoiceDetailsActions.loadInvoiceDetails({
        invoiceId
      })
    )

    this.store.dispatch(invoiceNotesActions.loadInvoiceNotes({ invoiceId }))
    this.store.dispatch(invoicesTypesActions.loadDisputedReasons())
  }
  setSelectedId(invoiceId: number) {
    this.store.dispatch(
      invoicesActions.setSelectedId({ selectedId: invoiceId })
    )
  }

  loadInvoiceNotes(invoiceId: number) {
    this.store.dispatch(invoiceNotesActions.loadInvoiceNotes({ invoiceId }))
  }

  loadInvoiceAuditHistory(invoiceId: number) {
    this.store.dispatch(
      invoiceAuditHistoryActions.loadInvoiceAuditHistory({ invoiceId })
    )
  }

  loadInvoiceExceptions(invoiceId: number) {
    this.store.dispatch(
      invoiceExceptionsActions.loadInvoiceExceptions({ invoiceId })
    )
  }

  loadInvoiceReferenceNumbers(invoiceId: number) {
    this.store.dispatch(
      invoiceReferenceNumbersActions.loadInvoiceReferenceNumbers({ invoiceId })
    )
  }

  loadInvoiceDetails(invoiceId: number) {
    this.store.dispatch(
      invoiceDetailsActions.loadInvoiceDetails({
        invoiceId
      })
    )
  }

  loadInvoiceDetailsWithoutChangeSelectedInvoiceId(invoiceId: number) {
    this.store.dispatch(
      invoiceDetailsActions.loadInvoiceDetails({
        invoiceId,
        ignoreNewSelectedId: true
      })
    )
  }

  loadInvoiceDocuments(invoiceId: number) {
    this.store.dispatch(
      invoiceDocumentsActions.loadInvoiceDocuments({ invoiceId })
    )
  }

  loadInvoiceLineItems(invoiceId: number) {
    this.store.dispatch(
      invoiceLineItemsActions.loadInvoiceLineItems({ invoiceId })
    )
  }

  reloadInvoiceData(
    invoiceId: number,
    uselegacy: boolean,
    purchaseInvoicesUuid: string | undefined
  ) {
    this.selectInvoice(invoiceId)
    firstValueFrom(
      this.selectedInvoiceWithDetails$.pipe(
        filter(invoice => invoice.id === invoiceId)
      )
    ).then(invoice => {
      const invoiceCentricView = invoice.ordersIds?.length !== 1

      if (uselegacy || invoiceCentricView)
        this.loadLegacyInvoiceCharges({
          invoiceId: invoice.id,
          orderId: invoice.ordersIds?.at(0)?.legacyId ?? 0,
          vendorId: invoice.vendorId
        })
      else
        this.loadInvoiceCharges({
          invoiceId: invoice.id,
          invoiceUuid: invoice.uuid,
          orderId: invoice.ordersIds?.at(0)?.legacyId as number,
          orderUuid: invoice.ordersIds?.at(0)?.id as string,
          vendorId: invoice.vendorId,
          vendorExternalId: invoice.vendorExternalId,
          purchaseInvoiceUuid: purchaseInvoicesUuid
        })
    })

    this.store.dispatch(
      invoiceLineItemsActions.loadInvoiceLineItems({ invoiceId })
    )
    this.store.dispatch(
      invoiceDocumentsActions.loadInvoiceDocuments({ invoiceId })
    )
    this.store.dispatch(
      invoiceReferenceNumbersActions.loadInvoiceReferenceNumbers({ invoiceId })
    )
    this.store.dispatch(
      invoiceExceptionsActions.loadInvoiceExceptions({ invoiceId })
    )
  }

  loadInvoices(filters: DataFilters) {
    this.store.dispatch(
      invoicesActions.loadInvoices({ filters, strategy: 'set' })
    )
  }

  async loadInvoicesWithCurrentFilters(strategy: 'set' | 'upsert' = 'set') {
    const filters = await firstValueFrom(this.filters$)
    this.store.dispatch(invoicesActions.loadInvoices({ filters, strategy }))
  }

  upsertInvoices(filters: DataFilters) {
    this.store.dispatch(
      invoicesActions.loadInvoices({ filters, strategy: 'upsert' })
    )
  }

  upsertInvoicesWithNavigation(filters: DataFilters) {
    this.store.dispatch(
      invoicesActions.loadInvoices({
        filters,
        strategy: 'upsertWithNavigation'
      })
    )
  }

  loadTypesOfExceptions() {
    this.store.dispatch(invoicesTypesActions.loadTypesOfExceptions())
  }

  loadDisputedReasons() {
    this.store.dispatch(invoicesTypesActions.loadDisputedReasons())
  }

  loadLegacyInvoiceCharges(payload: {
    invoiceId: number
    orderId: number
    vendorId: number | undefined
  }) {
    this.store.dispatch(
      invoiceChargesActions.loadLegacyInvoiceCharges({
        invoiceId: payload.invoiceId,
        orderId: payload.orderId,
        vendorId: payload.vendorId
      })
    )
    this.loadRelatedDocuments()
  }
  loadInvoiceCharges(payload: {
    invoiceId: number
    invoiceUuid: string
    orderId: number
    orderUuid: string
    vendorId: number | undefined
    vendorExternalId: string | undefined
    purchaseInvoiceUuid: string | undefined
  }) {
    this.store.dispatch(
      invoiceChargesActions.loadInvoiceCharges({
        invoiceId: payload.invoiceId,
        invoiceUuid: payload.invoiceUuid,
        orderUuid: payload.orderUuid,
        vendorExternalId: payload.vendorExternalId,
        purchaseInvoiceUuid: payload.purchaseInvoiceUuid
      })
    )

    this.loadRelatedDocuments()
  }

  loadReferenceNumberTypes() {
    this.store.dispatch(invoicesTypesActions.loadReferenceNumbersTypes())
  }

  loadInvoiceDocumentTypes() {
    this.store.dispatch(invoicesTypesActions.loadDocumentTypes())
  }

  loadEmailTemplateTypes() {
    this.store.dispatch(invoicesTypesActions.loadEmailTemplateTypes())
  }

  loadEmailTemplateDetails(templateId: number) {
    this.store.dispatch(
      invoicesTypesActions.loadEmailTemplateTypeDetails({ templateId })
    )
  }

  loadEmailTemplates() {
    this.store.dispatch(invoicesTypesActions.loadEmailTemplates())
  }

  loadTerminateReasons() {
    this.store.dispatch(invoicesTypesActions.loadTerminateReasons())
  }

  startLoading(operation: AsyncOperations) {
    this.store.dispatch(
      invoicesActions.setLoading({
        operation,
        loading: LoadingStatuses.InProgress
      })
    )
  }

  endLoading(operation: AsyncOperations) {
    this.store.dispatch(
      invoicesActions.setLoading({
        operation,
        loading: LoadingStatuses.NotStarted
      })
    )
  }

  resetLoading(operation: AsyncOperations) {
    this.store.dispatch(
      invoicesActions.resetLoading({
        operation
      })
    )
  }

  patchListFilters(filters: Partial<DataFilters>) {
    this.store.dispatch(invoicesActions.patchListFilters({ filters }))
    this.patchListFiltersMemory(filters)
  }

  initFiltersMemory({
    userUuid,
    tenantUuid
  }: {
    userUuid: string
    tenantUuid: string
  }) {
    this.store.dispatch(
      invoicesActions.setCurrentFiltersKey({
        userUuid,
        tenantUuid
      })
    )
    this.store.dispatch(invoicesActions.initListFiltersMemory())
    this.store.dispatch(invoicesActions.initListFilters())
  }

  initStandAloneWindow() {
    this.store.dispatch(invoicesActions.initStandaloneWindowOpen())
  }

  async updateListFiltersMemory(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.filters$)

    this.store.dispatch(
      invoicesActions.setListFiltersMemory({
        filters: filters,
        status: currentFilters.status as StatusFilter
      })
    )
  }

  async patchListFiltersMemory(filters: Partial<DataFilters>) {
    const currentFiltersMemory = await firstValueFrom(this.filtersMemory$)
    const currentFilters = await firstValueFrom(this.filters$)

    this.store.dispatch(
      invoicesActions.setListFiltersMemory({
        filters: {
          ...currentFiltersMemory[currentFilters.status as StatusFilter],
          ...filters
        },
        status: currentFilters.status as StatusFilter
      })
    )
  }

  async clearFilterByKey(key: keyof DataFilters) {
    const newFilters = {
      [key]: [],
      hasNoExceptions:
        key === 'typesOfExceptions' ? false : this.filters().hasNoExceptions,
      from: key === 'dateRangeTypes' ? undefined : this.filters().from,
      to: key === 'dateRangeTypes' ? undefined : this.filters().to,
      parties: key === 'disputeReasonsV2' ? [] : this.filters().parties,
      remitTo: key === 'remitTo' ? undefined : this.filters().remitTo
    } satisfies Partial<DataFilters>
    await this.patchListFilters(newFilters)
  }

  async updateListStatusFilters(status: StatusFilter) {
    const currentFilters = await firstValueFrom(this.filters$)
    const filtersMemory = await firstValueFrom(this.filtersMemory$)
    const newFilters = {
      ...currentFilters,
      ...defaultStatusFilters[status],
      ...filtersMemory[status]
    }
    this.store.dispatch(invoicesActions.setListFilters({ filters: newFilters }))
  }

  async updateInvoiceVendor(newVendorId: number, fromModal = false) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceVendorDetailsActions.updateInvoiceVendor({
        id: invoice.id,
        newVendor: {
          id: newVendorId
        },
        fromModal
      })
    )
  }

  updateInvoiceVendorInState(
    invoiceId: number,
    vendorId: number,
    vendorUuid: string,
    vendorExternalId: string
  ) {
    this.store.dispatch(
      invoiceVendorDetailsActions.updateInvoiceVendorInState({
        id: invoiceId,
        newVendor: {
          id: vendorId,
          externalId: vendorExternalId,
          uuid: vendorUuid
        }
      })
    )
  }

  async addTag(tag: Record<string, object | null>) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceDetailsActions.addTags({
        invoiceId: invoice.id,
        tag: tag
      })
    )

    // Wait for the server call to succcess
    await firstValueFrom(
      this.actions$.pipe(ofType(invoiceDetailsActions.addTagsSuccess), take(1))
    )
  }

  async removeTag(tag: Record<string, object | null>) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceDetailsActions.removeTags({
        invoiceId: invoice.id,
        tag: tag
      })
    )

    // Wait for the server call to succcess
    await firstValueFrom(
      this.actions$.pipe(
        ofType(invoiceDetailsActions.removeTagsSuccess),
        take(1)
      )
    )
  }

  async updateFreightChargeTerms(newFreightChargeTermsDescription: string) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceVendorDetailsActions.updateInvoiceFreightChargeTerms({
        id: invoice.id,
        newFreightChageTerms: {
          description: newFreightChargeTermsDescription
        }
      })
    )
  }

  async updateVendorDates(payload: {
    shipDate: Date
    deliveryDate?: Date
    invoiceDate: Date
  }) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceVendorDetailsActions.updateInvoiceVendorDates({
        id: invoice.id,
        shipDate: payload.shipDate,
        deliveryDate: payload.deliveryDate,
        invoiceDate: payload.invoiceDate
      })
    )
  }

  async updateInvoiceVendorStops(stops: InvoiceForm['stops']) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceVendorDetailsActions.updateInvoiceVendorStops({
        id: invoice.id,
        stops
      })
    )
  }

  async addLineItems(lineItems: InvoiceForm['lineItems']) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceLineItemsActions.addInvoiceLineItems({
        invoice: { lineItems, id: invoice.id as number }
      })
    )
  }

  async updateLineItems(lineItems: InvoiceForm['lineItems']) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceLineItemsActions.updateInvoiceLineItems({
        invoice: { lineItems, id: invoice.id as number }
      })
    )
  }

  async updateInvoiceCharges(
    vendorCharges: InvoiceForm['charges'],
    customerCharges: InvoiceForm['charges']
  ) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice

    this.store.dispatch(
      invoiceChargesActions.updateInvoiceVendorCharges({
        id: invoice.id,
        charges: vendorCharges
      })
    )

    this.store.dispatch(
      invoiceChargesActions.updateInvoiceCustomerCharges({
        id: invoice.id,
        charges: customerCharges,
        currencyId: invoice.currencyId
      })
    )
  }

  async deleteLineItems(lineItemId: number) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceLineItemsActions.deleteInvoiceLineItems({
        lineItemId,
        invoiceId: invoice.id as number
      })
    )
  }

  async updateVendorInvoiceNotesMarkAsComplete(note: Invoice['notes'][number]) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceNotesActions.updateInvoiceNotesMarkAsComplete({
        note,
        invoiceId: invoice.id
      })
    )
  }

  async addInvoiceNotes(notes: InvoiceForm['notes']) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceNotesActions.addInvoiceNotes({
        invoice: { notes, id: invoice.id as number }
      })
    )
  }

  async updateInvoiceNotes(notes: InvoiceForm['notes']) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceNotesActions.updateInvoiceNotes({
        invoice: { notes, id: invoice.id as number }
      })
    )
  }

  async deleteNotes(noteId: number) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceNotesActions.deleteInvoiceNotes({
        noteId,
        invoiceId: invoice.id as number
      })
    )
  }

  async updateInvoiceReferenceNumbers(
    referenceNumbers: InvoiceForm['referenceNumbers'],
    dialogId: string
  ) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceReferenceNumbersActions.updateInvoiceReferenceNumbers({
        id: invoice.id,
        referenceNumbers,
        dialogId
      })
    )
  }

  attachDocumentsToInvoice(documents: InvoiceForm['documents']) {
    const invoiceUuid = this.selectedInvoice()?.uuid
    this.store.dispatch(
      invoiceDocumentsActions.attachDocumentsToInvoice({
        documents,
        uuid: invoiceUuid
      })
    )
  }

  redactDocument(blob: Blob, documentUuid: string) {
    const invoiceUuid = this.selectedInvoice()?.uuid as string
    this.store.dispatch(
      invoiceDocumentsActions.redactDocument({
        invoiceUuid,
        blob,
        originalDocumentUuid: documentUuid
      })
    )
  }

  async updateInvoiceDocuments(documents: InvoiceForm['documents']) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceDocumentsActions.updateInvoiceDocuments({
        documents,
        id: invoice.id as number
      })
    )
  }

  async deleteInvoiceDocuments(documentId: number) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceDocumentsActions.deleteInvoiceDocuments({
        documentId,
        invoiceId: invoice.id as number
      })
    )
  }

  async updateInvoiceCustomer(newCustomerId: number) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceCustomerDetailsActions.updateInvoiceCustomer({
        id: invoice.id,
        newCustomer: {
          id: newCustomerId
        }
      })
    )
  }

  async updateInvoiceOtherDetails(otherDetails: InvoiceForm['otherDetails']) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceDetailsActions.updateInvoiceOtherDetails({
        invoice: { otherDetails, id: invoice.id as number }
      })
    )
  }

  downloadDocument(documentId: number) {
    this.store.dispatch(
      invoiceDocumentsActions.downloadInvoiceDocument({ documentId })
    )
  }
  downloadInvoices() {
    this.store.dispatch(operationsActions.downloadInvoices())
  }

  async addDocuments(documents: InvoiceForm['documents']) {
    this.store.dispatch(
      invoiceDocumentsActions.addInvoiceDocuments({ documents })
    )
  }

  async sendEmailNotification(
    emailNotification: InvoiceForm['emailNotification']
  ) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      operationsActions.sendEmailNotification({
        emailNotification,
        id: invoice.id as number
      })
    )
  }

  async reAudit() {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(operationsActions.reAudit({ invoiceId: invoice.id }))
  }

  async approveInvoice(useLegacy = false) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      operationsActions.approveInvoice({
        invoiceId: invoice.id,
        invoiceUuid: invoice.uuid,
        useLegacy
      })
    )
  }

  async terminateInvoice(
    terminateInvoice: InvoiceForm['terminateInvoice'],
    useLegacy = false
  ) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      operationsActions.terminateInvoice({
        terminateInvoice,
        id: invoice.id as number,
        uuid: invoice.uuid,
        useLegacy
      })
    )
  }

  loadIncompleteDisputes(invoiceId: number) {
    this.store.dispatch(
      invoiceNotesActions.loadInvoiceNotes({
        invoiceId,
        filters: { trueFlags: ['isDisputed'], falseFlags: ['IsCompleted'] }
      })
    )
  }

  loadDuplicateInvoices(parentInvoiceId: number) {
    this.store.dispatch(
      invoiceDuplicatesActions.loadInvoiceDuplicates({
        parentInvoiceId
      })
    )
  }

  loadVendorInvoiceTypesResponse() {
    this.store.dispatch(invoicesTypesActions.loadInvoiceTypes())
  }

  async updateDuplicateInvoices(
    duplicates: InvoiceForm['duplicates'],
    useLegacy = false
  ) {
    this.store.dispatch(
      invoiceDuplicatesActions.updateInvoiceDuplicates({
        duplicates,
        useLegacy
      })
    )
  }

  addInvoice(details: InvoiceForm['details']) {
    this.store.dispatch(invoicesActions.addInvoice({ details }))
  }

  async addInvoiceDefaultCharges(
    defaultCharges: InvoiceForm['defaultCharges']
  ) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceChargesActions.addInvoiceDefaultCharges({
        defaultCharges,
        id: invoice.id
      })
    )
  }

  mapInvoiceCharges(
    payload: Parameters<typeof invoiceChargesActions.mapInvoiceCharges>['0']
  ) {
    this.store.dispatch(
      invoiceChargesActions.mapInvoiceCharges({
        ...payload
      })
    )
  }

  async setInvoiceStatusToReady() {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice

    this.store.dispatch(
      invoicesActions.setInvoiceStatusToReady({ invoiceId: invoice.id })
    )
  }

  async addInvoiceNotesAndSendEmail(notes: InvoiceForm['notes']) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceNotesActions.addInvoiceNotesAndSendEmail({
        invoice: { notes, id: invoice.id as number }
      })
    )
  }

  async updateCurrency(newCurrencyId: number) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceCurrencyActions.updateInvoiceCurrency({
        id: invoice.id,
        newCurrency: {
          id: newCurrencyId
        }
      })
    )
  }

  addInvoiceFromOrder(
    details: InvoiceForm['details'],
    stops: InvoiceForm['stops'],
    lineItems: Invoice['lineItems'],
    charges: InvoiceForm['defaultCharges']
  ) {
    this.store.dispatch(
      invoicesActions.addInvoiceFromOrder({
        invoice: {
          details,
          stops,
          defaultCharges: charges
        },
        lineItems
      })
    )
  }

  async regenerateInvoice() {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      operationsActions.regenerateInvoice({ invoiceId: invoice.id })
    )
  }

  async loadRelatedDocuments() {
    const invoice = (await firstValueFrom(
      this.selectedInvoiceWithDetails$
    )) as Invoice
    this.store.dispatch(
      invoiceRelatedDocumentsActions.loadInvoiceRelatedDocuments({
        invoiceUuid: invoice.uuid,
        invoiceId: invoice.id
      })
    )
  }

  async navigateToNextInvoice() {
    const invoice = await firstValueFrom(this.selectedInvoice$)
    const invoices = await firstValueFrom(this.allInvoices$)
    const filters = await firstValueFrom(this.filters$)

    const currentInvoiceIndex = invoices.findIndex(
      ({ id }) => id === invoice.id
    )
    const nextInvoice = invoices.at(currentInvoiceIndex + 1)
    const nextInvoiceState: {
      requireToLoadList: boolean
      currentInvoiceIndex: number
      nextInvoice?: Invoice
    } = {
      currentInvoiceIndex,
      nextInvoice,
      requireToLoadList: false
    }

    if (currentInvoiceIndex === -1 || nextInvoice === undefined)
      nextInvoiceState.requireToLoadList = true

    if (
      nextInvoiceState.currentInvoiceIndex > -1 &&
      nextInvoiceState.nextInvoice === undefined
    ) {
      this.patchListFilters({
        page: (filters.page ?? 0) + 1
      })
    }

    this.store.dispatch(
      operationsActions.navigateToNextInvoice(nextInvoiceState)
    )
  }
  navigateToPreviousInvoice() {
    this.store.dispatch(operationsActions.navigateToPreviousInvoice())
  }

  async updateInvoiceOwnership(ownInvoice: boolean) {
    const invoice = await firstValueFrom(this.selectedInvoice$)
    this.store.dispatch(
      invoicesActions.updateInvoiceOwnership({
        invoiceId: invoice.id,
        invoiceUuid: invoice.uuid,
        ownInvoice
      })
    )
  }

  terminateMultipleInvoices(
    invoices: { id: number; uuid: string }[],
    terminateInvoice: InvoiceForm['terminateInvoice'],
    useLegacy = false
  ) {
    this.store.dispatch(
      operationsActions.terminateMultipleInvoices({
        invoices,
        terminateInvoice,
        useLegacy
      })
    )
  }

  approveMultipleInvoices(
    invoices: { id: number; uuid: string }[],
    useLegacy: boolean
  ) {
    this.store.dispatch(
      operationsActions.approveMultipleInvoices({
        invoices,
        useLegacy
      })
    )
  }

  reauditMultipleInvoices(invoiceIds: number[]) {
    this.store.dispatch(
      operationsActions.reAuditMultipleInvoices({
        invoiceIds
      })
    )
  }

  generateDefaultInvoiceDocument() {
    this.store.dispatch(
      invoiceDocumentsActions.generateDefaultInvoiceDocument()
    )
  }

  getTenantIdFromInvoice(invoiceId: number) {
    this.store.dispatch(
      invoiceDetailsActions.loadInvoiceTenantId({
        invoiceId
      })
    )
  }

  mergeInvoiceDocuments(
    requests: { invoiceId: number; documentIds: number[] }[]
  ) {
    this.store.dispatch(
      invoiceDocumentsActions.mergeInvoiceDocuments({
        requests
      })
    )
  }

  setStandaloneWindow(standAloneWindowOpen: boolean) {
    this.store.dispatch(
      invoicesActions.setStandaloneWindowOpen({ standAloneWindowOpen })
    )
  }

  clearInvoices() {
    this.store.dispatch(invoicesActions.clearInvoices())
  }

  getLoadingStateSignal<K extends keyof typeof AsyncOperations>(operation: K) {
    return computed(() => this.loading()[operation], {
      equal: loadingStateEqualFn
    }) satisfies Signal<InvoicesLoadingState[K]>
  }

  async loadInvoiceDisputes() {
    const invoice = await firstValueFrom(this.selectedInvoiceWithDetails$)
    this.store.dispatch(
      invoiceDisputesActions.loadInvoiceDisputes({
        invoiceId: invoice.id,
        invoiceUuid: invoice.uuid
      })
    )
  }

  async loadInvoiceDisputesCommunication(disputeId: string) {
    const invoice = await firstValueFrom(this.selectedInvoiceWithDetails$)
    this.store.dispatch(
      invoiceDisputesActions.loadInvoiceDisputesCommunication({
        invoiceId: invoice.id,
        invoiceUuid: invoice.uuid,
        disputeId
      })
    )
  }

  async resolveDisputeReasons(
    disputeId: string,
    reasons: { description: string }[]
  ) {
    const invoice = await firstValueFrom(this.selectedInvoiceWithDetails$)
    this.store.dispatch(
      invoiceDisputesActions.resolveDisputeReasons({
        invoiceUuid: invoice.uuid,
        disputeId,
        reasons
      })
    )
  }

  async addInvoiceDisputes(disputes: InvoiceForm['disputes']) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceDisputesActions.addInvoiceDisputes({
        invoiceUuid: invoice.uuid,
        disputes
      })
    )
  }

  async sendReplyCommunication(
    disputeId: string,
    communication: InvoiceForm['communication']
  ) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceDisputesActions.sendReplyCommunication({
        invoiceUuid: invoice.uuid,
        disputeId,
        communication
      })
    )
  }

  downloadAttachment(attachmentUrl: string, fileName: string) {
    this.store.dispatch(
      invoiceDisputesActions.downloadAttachment({ attachmentUrl, fileName })
    )
  }

  async addInvoiceLegacyNotes(notes: InvoiceForm['notes']) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceNotesActions.addInvoiceLegacyNotes({
        invoice: { notes, id: invoice.id as number }
      })
    )
  }

  async manageLineItems(lineItems: InvoiceForm['manageLineItems']) {
    const invoice = (await firstValueFrom(this.selectedInvoice$)) as Invoice
    this.store.dispatch(
      invoiceLineItemsActions.manageLineItems({
        invoiceId: invoice.id,
        lineItems
      })
    )
  }

  reOrderInvoicesList(invoices: Invoice[]) {
    this.store.dispatch(
      invoicesActions.reOrderInvoicesList({
        invoices
      })
    )
  }

  loadWorkInstructions(invoiceUuid: string, invoiceId: number) {
    this.store.dispatch(
      invoiceExceptionsActions.loadWorkInstructions({
        invoiceUuid,
        invoiceId
      })
    )
  }

  loadWorkInstructionsPhase2(invoiceUuid: string, invoiceId: number) {
    this.store.dispatch(
      invoiceExceptionsActions.loadWorkInstructionsPhase2({
        invoiceUuid,
        invoiceId
      })
    )
  }

  addAttestation(workInstructionsIds: number[], areAllAttested = false) {
    const invoiceUuid = this.selectedInvoice()?.uuid ?? ''
    const invoiceId = this.selectedInvoice()?.id ?? 0
    this.store.dispatch(
      invoiceExceptionsActions.addAttestation({
        invoiceUuid,
        invoiceId,
        workInstructionsIds,
        areAllAttested
      })
    )
  }

  loadDuplicateInvoicesOnNavigation(parentInvoiceId: number) {
    this.store.dispatch(
      invoiceDuplicatesActions.loadInvoiceDuplicatesOnNavigation({
        parentInvoiceId
      })
    )
  }

  loadDuplicateInvoicesForInvoiceCentric(parentInvoiceId: number) {
    this.store.dispatch(
      invoiceDuplicatesActions.loadInvoiceDuplicatesForInvoiceCentric({
        parentInvoiceId
      })
    )
  }

  async loadMultipleInvoicesDisputes(invoicesUuids: string[]) {
    this.store.dispatch(
      invoiceDisputesActions.loadMultipleInvoicesDisputes({
        invoicesUuids
      })
    )
  }
}
