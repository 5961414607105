import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { InvoiceForm } from '../../../domain/invoices/invoice-form.model'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { HttpErrorResponse } from '@angular/common/http'

export const invoiceChargesActions = createActionGroup({
  source: 'Invoice Charges',
  events: {
    loadLegacyInvoiceCharges: props<{
      invoiceId: number
      orderId: number
      vendorId?: number
    }>(),

    loadLegacyInvoiceChargesSuccess: props<{
      invoiceCharges: Invoice['legacyCharges']
      invoiceId: number
    }>(),

    loadLegacyInvoiceChargesFail: props<{ error: unknown }>(),

    loadInvoiceCharges: props<{
      invoiceId: number
      invoiceUuid: string
      orderUuid: string
      vendorExternalId?: string
      purchaseInvoiceUuid?: string
    }>(),

    loadInvoiceChargesSuccess: props<{
      invoiceCharges: Invoice['legacyCharges']
      invoiceId: number
    }>(),

    loadInvoiceChargesFail: props<{ error: unknown }>(),

    resetGetChargesLoadingState: emptyProps(),

    'update invoice vendor charges':
      props<Pick<InvoiceForm, 'charges' | 'id'>>(),

    'update invoice customer charges':
      props<Pick<InvoiceForm, 'charges' | 'id' | 'currencyId'>>(),
    'update invoice charges success': emptyProps(),
    'update invoice charges fail': props<{
      error?: HttpErrorResponse
      errorMessage?: string
    }>(),
    resetUpdateInvoiceChargesLoadingState: emptyProps(),
    'add invoice default charges':
      props<Pick<InvoiceForm, 'defaultCharges' | 'id'>>(),
    'add invoice default charges success': emptyProps(),
    'add invoice default charges fail': props<{ error: string }>(),

    'map invoice charges': props<{
      vendorAdjustedCharge?: Pick<InvoiceForm, 'id'> &
        Omit<InvoiceForm['chargesToMap'][number], 'isDefault'>
      vendorOrderCharge?: Pick<InvoiceForm, 'id'> &
        Omit<InvoiceForm['chargesToMap'][number], 'isDefault'>
      customerAdjustedCharge?: Pick<InvoiceForm, 'id'> &
        Omit<InvoiceForm['chargesToMap'][number], 'isDefault'>
      customerOrderCharge?: Pick<InvoiceForm, 'id'> &
        Omit<InvoiceForm['chargesToMap'][number], 'isDefault'>
    }>(),
    'map invoice charges success': emptyProps(),
    'map invoice charges fail': props<{ error: HttpErrorResponse }>(),
    resetMapInvoiceChargesLoadingState: emptyProps(),
    'add vendor adjusted charge': props<
      Pick<InvoiceForm, 'id'> & {
        charge: InvoiceForm['chargesToMap'][number]
      }
    >(),
    'add vendor adjusted charge success': emptyProps(),
    'add vendor adjusted charge fail': props<{ error: unknown }>()
  }
})
