import { IStrategy } from '@navix/utils/adapter'
import { GetOrderDetailsResponse } from '../../domain/orders/get-order-details.response'
import { Order } from '../../domain/orders/order.model'

export class FromGetOrderDetailsResponse
  implements IStrategy<GetOrderDetailsResponse, Order>
{
  convert(response: GetOrderDetailsResponse): Order {
    return {
      billType: 'N/A',
      createDate: response.createDate,

      customer: {
        name: response.customerDescription ?? 'N/A'
      },
      deliveryDate: response.deliveredDate,
      freightChargeTermsDescription: response.freightChargeTerms,
      metadata: this.jsonToYaml(this.getMetadata(response.metadata)),
      mode: response.transportationModeDescription ?? 'N/A',
      serviceLevel: response.transportationServiceLevelDescription ?? 'N/A',
      serviceType: response.transportationServiceTypeDescription ?? 'N/A',
      shipDate: response.shipDate,
      tenantName: response.tenantDescription ?? 'N/A',
      vendors: response.vendorIds.map(id => ({
        vendorId: id
      })),
      weight: response.weight
        ? `${Math.round(response.weight)} ${response.weightUnit}`
        : 'N/A',
      withDetails: true,
      shipmentNumber: response.shipmentNumber,
      orderNumber: response.orderNumber
    } as Order
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsonToYaml(json: Record<string, any>): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function convert(obj: Record<string, any>, indent: string): string {
      let yamlString = ''
      for (const key in obj) {
        if (Object.hasOwn(obj, key)) {
          if (typeof obj[key] === 'object') {
            yamlString += `${indent}${key}:\n`
            yamlString += convert(obj[key], `${indent}  `)
          } else {
            yamlString += `${indent}${key}: ${obj[key]}\n`
          }
        }
      }
      return yamlString
    }

    const yamlString = convert(json, '')
    return yamlString
  }

  private getMetadata(metadataString?: string): {
    [key: string]: string
  } {
    try {
      return JSON.parse(metadataString ?? '{}')
    } catch (error) {
      console.error(
        '[NavixApp] Error parsing metadata: ',
        metadataString,
        error
      )

      return {}
    }
  }
}
