import { on } from '@ngrx/store'
import { invoiceReferenceNumbersActions } from './invoice-reference-numbers.actions'
import { InvoicesState, invoicesAdapter } from '../invoices.reducer'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'

export const invoiceReferenceNumbersReducers = [
  on(
    invoiceReferenceNumbersActions.updateInvoiceReferenceNumbers,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceReferenceNumbers,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceReferenceNumbersActions.updateInvoiceReferenceNumbersSuccess,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceReferenceNumbers,
        status: LoadingStatuses.Completed
      })
    })
  ),

  on(
    invoiceReferenceNumbersActions.updateInvoiceReferenceNumbersFail,
    (state: InvoicesState, httpError) => {
      const message: string =
        httpError.error.error?.message ??
        'An error occurred while updating invoice reference numbers.'
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.updateInvoiceReferenceNumbers,
          status: LoadingStatuses.Failed,
          message
        })
      }
    }
  ),
  on(
    invoiceReferenceNumbersActions.loadInvoiceReferenceNumbersSuccess,
    (state: InvoicesState, { referenceNumbers, invoiceId }) =>
      invoicesAdapter.upsertOne(
        {
          id: invoiceId,
          referenceNumbers
        } as Invoice,
        state
      )
  )
]
