import { createActionGroup, props } from '@ngrx/store'
import { InvoiceForm } from '../../../domain/invoices/invoice-form.model'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { HttpErrorResponse } from '@angular/common/http'

export const invoiceReferenceNumbersActions = createActionGroup({
  source: 'Invoice Reference Numbers',
  events: {
    'load invoice reference numbers': props<{ invoiceId: number }>(),
    'load invoice reference numbers success': props<{
      referenceNumbers: Invoice['referenceNumbers']
      invoiceId: number
    }>(),
    'load invoice reference numbers fail': props<{ error: unknown }>(),

    'update invoice reference numbers':
      props<Pick<InvoiceForm, 'id' | 'referenceNumbers' | 'dialogId'>>(),
    'update invoice reference numbers success': props<{
      invoiceId: number
      dialogId: string
    }>(),
    'update invoice reference numbers fail': props<{
      error: HttpErrorResponse
    }>()
  }
})
