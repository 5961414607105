import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { DocumentType } from '../../../domain/invoices/document-type.model'
import {
  EmailTemplate,
  EmailTemplateType
} from '../../../domain/invoices/email-template-type.model'
import { ReferenceNumberType } from '../../../domain/invoices/reference-number-type.model'
import { VendorInvoiceType } from '../../../domain/invoices/vendor-invoice-type.model'
import {
  DisputeReason,
  DisputedReason
} from '../../../domain/invoices/disputed-reason.model'
import { TerminateReason } from '../../../domain/invoices/terminate-reason.model'
import { TypeOfException } from '../../../domain/invoices/type-of-exception.model'

export const invoicesTypesActions = createActionGroup({
  source: 'Invoices Types',
  events: {
    'load document types': emptyProps(),
    'load document types success': props<{
      documentTypes: DocumentType[]
    }>(),
    'load document types fail': props<{ error: unknown }>(),

    // TODO: REMOVE ONCE OTHER EMAIL TEMPLATES WORK
    'load email template types': emptyProps(),
    'load email template types success': props<{
      emailTemplateTypes: EmailTemplateType[]
    }>(),
    'load email template types fail': props<{ error: string }>(),

    'load email template type details': props<{ templateId: number }>(),
    'load email template type details success': props<{
      emailTemplateType: EmailTemplateType
    }>(),
    'load email template type details fail': props<{ error: string }>(),
    //FINISH TODO

    'load reference numbers types': emptyProps(),
    'load reference numbers types success': props<{
      referenceNumberTypes: ReferenceNumberType[]
    }>(),
    'load reference numbers types fail': props<{ error: unknown }>(),

    'load invoice types': emptyProps(),
    'load invoice types success': props<{
      invoiceTypes: VendorInvoiceType[]
    }>(),
    'load invoice types fail': props<{ error: unknown }>(),

    'load disputed reasons': emptyProps(),
    'load disputed reasons success': props<{
      disputedReasons: DisputedReason[]
    }>(),
    'load disputed reasons fail': props<{ error: unknown }>(),

    'load terminate reasons': emptyProps(),
    'load terminate reasons success': props<{
      terminateReasons: TerminateReason[]
    }>(),
    'load terminate reasons fail': props<{ error: string }>(),

    'load types of exceptions': emptyProps(),
    'load types of exceptions success': props<{
      typesOfExceptions: TypeOfException[]
    }>(),
    'load types of exceptions fail': props<{ error: unknown }>(),

    //NEW EMAIL TEMPLATES
    'load email templates': emptyProps(),
    'load email templates success': props<{
      emailTemplates: EmailTemplate[]
    }>(),
    'load email templates fail': props<{ error: string }>()
  }
})
