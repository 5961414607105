import { on } from '@ngrx/store'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { InvoicesState, invoicesAdapter } from '../invoices.reducer'
import { invoiceNotesActions } from './invoice-notes.actions'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'

export const invoiceNotesReducers = [
  on(
    invoiceNotesActions.loadInvoiceNotesSuccess,
    (state: InvoicesState, { notes, invoiceId }) =>
      invoicesAdapter.upsertOne(
        <Invoice>{
          id: invoiceId,
          notes
        },
        state
      )
  ),
  on(
    invoiceNotesActions.addInvoiceNotesAndSendEmail,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceNotesAndSendEmail,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceNotesActions.addInvoiceNotesAndSendEmailSuccess,
    (state: InvoicesState, data) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceNotesAndSendEmail,
        status: LoadingStatuses.Completed,
        message: 'Vendor invoice notes has been added successfully.',
        metadata: data.invoice
      })
    })
  ),
  on(
    invoiceNotesActions.addInvoiceNotesAndSendEmailFail,
    (state: InvoicesState, httpError) => {
      const message: string = String(httpError.error)
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.addInvoiceNotesAndSendEmail,
          status: LoadingStatuses.Failed,
          message:
            message ?? "There's an error trying to create vendor invoice note."
        })
      }
    }
  ),
  on(invoiceNotesActions.addInvoiceLegacyNotes, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.addInvoiceLegacyNotes,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    invoiceNotesActions.addInvoiceLegacyNotesSuccess,
    (state: InvoicesState, data) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceLegacyNotes,
        status: LoadingStatuses.Completed,
        message: 'Vendor invoice notes has been added successfully.',
        metadata: data.invoice
      })
    })
  ),
  on(
    invoiceNotesActions.addInvoiceLegacyNotesFail,
    (state: InvoicesState, httpError) => {
      const message = String(httpError.error)
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.addInvoiceLegacyNotes,
          status: LoadingStatuses.Failed,
          message:
            message ?? "There's an error trying to create vendor invoice note."
        })
      }
    }
  ),
  on(
    invoiceNotesActions.resetAddInvoiceLegacyNotes,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceLegacyNotes,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
