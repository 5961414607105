import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { InvoicesService } from '../../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import {
  EMPTY,
  catchError,
  exhaustMap,
  finalize,
  iif,
  map,
  of,
  switchMap,
  tap
} from 'rxjs'
import {
  EmailTemplate,
  EmailTemplateType
} from '../../../../domain/invoices/email-template-type.model'
import { Store } from '@ngrx/store'
import { selectEmailTemplateTypesAsDictionary } from '../../invoices.selectors'
import { invoicesTypesActions } from '../types.actions'

@Injectable()
export class EmailTemplateTypesEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)
  private store = inject(Store)

  loadEmailTemplateTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesTypesActions.loadEmailTemplateTypes),

      exhaustMap(() =>
        this.invoicesService.getEmailTemplateTypes().pipe(
          map(response =>
            response
              .map(
                item =>
                  <EmailTemplateType>{
                    id: item.emailTemplateId,
                    description: item.description
                  }
              )
              .sort((a, b) => a.description.localeCompare(b.description))
          ),
          map(emailTemplateTypes =>
            invoicesTypesActions.loadEmailTemplateTypesSuccess({
              emailTemplateTypes
            })
          ),
          catchError(error =>
            of(invoicesTypesActions.loadEmailTemplateTypesFail({ error }))
          )
        )
      )
    )
  )

  loadEmailTemplateTypesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoicesTypesActions.loadEmailTemplateTypesFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load email template types."
          })
        })
      ),
    { dispatch: false }
  )

  loadEmailTemplateTypeDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesTypesActions.loadEmailTemplateTypeDetails),
      concatLatestFrom(() =>
        this.store.select(selectEmailTemplateTypesAsDictionary)
      ),
      switchMap(([payload, templates]) =>
        iif(
          () => templates[payload.templateId]?.bodyTemplate !== undefined,
          EMPTY.pipe(
            finalize(() =>
              this.store.dispatch({ type: `[CACHED]${payload.type}` })
            )
          ),
          of(payload)
        )
      ),
      exhaustMap(({ templateId }) =>
        this.invoicesService.getEmailTemplate(templateId).pipe(
          map(
            response =>
              <EmailTemplateType>{
                id: response.emailTemplateId,
                description: response.description,
                bodyTemplate: response.bodyTemplate,
                subject: response.subject
              }
          ),
          map(emailTemplateType =>
            invoicesTypesActions.loadEmailTemplateTypeDetailsSuccess({
              emailTemplateType
            })
          ),
          catchError(error =>
            of(invoicesTypesActions.loadEmailTemplateTypeDetailsFail({ error }))
          )
        )
      )
    )
  )

  loadEmailTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesTypesActions.loadEmailTemplates),
      exhaustMap(() =>
        this.invoicesService.getEmailTemplates().pipe(
          map(response =>
            response
              .map(
                item =>
                  <EmailTemplate>{
                    id: item.uuid,
                    party: item.party,
                    description: item.description,
                    template: item.template ?? '',
                    subject: item.subject ?? '',
                    type: item.type
                  }
              )
              .sort((a, b) => a.description.localeCompare(b.description))
          ),
          map(emailTemplates =>
            invoicesTypesActions.loadEmailTemplatesSuccess({
              emailTemplates
            })
          ),
          catchError(error =>
            of(invoicesTypesActions.loadEmailTemplateTypesFail({ error }))
          )
        )
      )
    )
  )
}
