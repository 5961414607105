import { platform_v2 } from '@navix/api-models'
import { updateAuditActions } from '../../+state/audits/update-audit/update-audit.actions'
import {
  UpdateAuditChargesV2ChargesRequest,
  UpdateAuditChargesV2Request
} from '../../domain/audits/update-audit-charges-v2.request'

export function toUpdateAuditChargesV2Request(
  payload: Parameters<typeof updateAuditActions.updateAuditChargesV2>[0],
  auditDocument: platform_v2.Navix_Services_Sdk_Audit
): UpdateAuditChargesV2Request {
  const validVendorCharges = returnValidChargeAdjustments(payload.vendorCharges)
  const processedVendorCharges = processChargeAdjustments(validVendorCharges)

  const validCustomerCharges = returnValidChargeAdjustments(
    payload.customerCharges
  )
  const processedCustomerCharges =
    processChargeAdjustments(validCustomerCharges)

  return {
    vendorExternalId: payload.vendorExternalId,
    auditDocumentETag: auditDocument.eTag!,
    vendor: {
      chargeAdjustments: processedVendorCharges
    },
    customer: {
      chargeAdjustments: processedCustomerCharges
    }
  }
}

function returnValidChargeAdjustments(
  charges:
    | Parameters<
        typeof updateAuditActions.updateAuditChargesV2
      >[0]['customerCharges']
    | Parameters<
        typeof updateAuditActions.updateAuditChargesV2
      >[0]['vendorCharges']
) {
  return charges.filter(charge => {
    const hasValidAdjustment =
      charge.adjustment !== 0 || charge.originalCharge !== 0
    const hasValidReason = (charge.reason?.length ?? 0) > 0
    const hasValidCode = (charge.code?.trim().length ?? 0) > 0
    const hasValidDescription = (charge.description?.trim().length ?? 0) > 0
    return (
      (hasValidAdjustment || hasValidReason) &&
      (hasValidCode || hasValidDescription)
    )
  })
}

function processChargeAdjustments(
  charges:
    | Parameters<
        typeof updateAuditActions.updateAuditChargesV2
      >[0]['customerCharges']
    | Parameters<
        typeof updateAuditActions.updateAuditChargesV2
      >[0]['vendorCharges']
): UpdateAuditChargesV2ChargesRequest[] {
  return charges.map(charge => ({
    type: charge.chargeTypeUuid,
    code: charge.code === '' ? null : charge.code,
    description: charge.description === '' ? null : charge.description,
    originalCharge: Number(charge.originalCharge ?? charge.adjustment),
    adjustment: charge.adjustment,
    reason: charge.reason === '' ? null : charge.reason
  }))
}
