import { platform_v2 } from '@navix/api-models'
import { updateAuditActions } from '../../+state/audits/update-audit/update-audit.actions'

export function toUpdateAuditRequest(
  payload: Parameters<typeof updateAuditActions.updateAudit>[0],
  auditDocument: platform_v2.Navix_Services_Sdk_Audit
): platform_v2.Navix_Services_Sdk_Audit {
  const hasPreviousVendor = payload.previousVendorExternalId !== undefined
  const vendor = auditDocument.vendors?.[payload.vendorExternalId]
  const previousVendor =
    auditDocument.vendors?.[payload.previousVendorExternalId!]

  let invoicesFromPreviousVendor: platform_v2.Navix_Services_Sdk_AuditInvoice[] =
    []
  let invoicesForPreviousVendor: platform_v2.Navix_Services_Sdk_AuditInvoice[] =
    []

  if (hasPreviousVendor) {
    invoicesForPreviousVendor =
      previousVendor?.invoices?.filter(
        invoice => !payload.invoicesUuids.includes(invoice.source ?? '')
      ) ?? []
    invoicesFromPreviousVendor =
      previousVendor?.invoices?.filter(invoice =>
        payload.invoicesUuids.includes(invoice.source ?? '')
      ) ?? []
  } else {
    invoicesForPreviousVendor =
      auditDocument.provisionalInvoices?.filter(
        invoice => !payload.invoicesUuids.includes(invoice.source ?? '')
      ) ?? []
    invoicesFromPreviousVendor =
      auditDocument.provisionalInvoices?.filter(invoice =>
        payload.invoicesUuids.includes(invoice.source ?? '')
      ) ?? []
  }

  const newAudit = <platform_v2.Navix_Services_Sdk_Audit>{
    ...auditDocument,
    provisionalInvoices: hasPreviousVendor
      ? auditDocument.provisionalInvoices
      : invoicesForPreviousVendor,
    vendors: {
      ...auditDocument.vendors,
      ...(hasPreviousVendor
        ? {
            [payload.previousVendorExternalId ?? '']: {
              ...previousVendor,
              invoices: invoicesForPreviousVendor
            }
          }
        : {}),
      [payload.vendorExternalId]: {
        ...vendor,
        invoices: [...(vendor?.invoices ?? []), ...invoicesFromPreviousVendor]
      }
    }
  }
  return newAudit
}
