import { Injectable, inject } from '@angular/core'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { format } from 'date-fns'
import {
  EMPTY,
  catchError,
  combineLatest,
  debounceTime,
  exhaustMap,
  filter,
  finalize,
  iif,
  map,
  of,
  race,
  switchMap,
  take,
  tap,
  timer
} from 'rxjs'
import { FromGetLineItemsResponse } from '../../adapter/orders/FromGetLineItemsResponse'
import { FromGetMatchedInvoicesResponse } from '../../adapter/orders/FromGetMatchedInvoicesResponse'
import { fromGetOrderChargesComposedResponse } from '../../adapter/orders/FromGetOrderChargesComposedResponse'
import { FromGetOrderDetailsResponse } from '../../adapter/orders/FromGetOrderDetailsResponse'
import { FromGetOrderStopsResponse } from '../../adapter/orders/FromGetOrderStopsResponse'
import { FromGetOrdersResponse } from '../../adapter/orders/FromGetOrdersResponse'
import { FromGetReferenceNumbersResponse } from '../../adapter/orders/FromGetReferenceNumbersResponse'
import { fromGetNewOrderDetailsResponse } from '../../adapter/orders/FromGetNewOrderDetailsResponse'
import { ToOrderAdapter } from '../../adapter/orders/OrderAdapter'
import { Order } from '../../domain/orders/order.model'
import { OrdersAsyncOperations } from '../../domain/orders/orders-loading.model'
import { OrdersService } from '../../infrastructure/orders.service'
import { ordersActions } from './orders.actions'
import { OrdersFacade } from './orders.facade'

//TODO: remove the load sideeffects from here
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ChargeCodesFacade } from '@navix/charge-codes/domain'
import { platform_v1, platform_v2 } from '@navix/api-models'
import { toUpdateOrderPurchaseInvoiceRequest } from '../../adapter/orders/to-update-order-purchase-invoice.request'
import { toRemoveOrderPurchaseInvoiceAssociatedUuidRequest } from '../../adapter/orders/to-remove-order-purchase-invoice-associated-uuid.request'
import { Tag } from '../../domain/orders/tag.model'
import { concatLatestFrom } from '@ngrx/operators'
import { Store } from '@ngrx/store'
import { selectDivisions } from './orders.selectors'

@Injectable()
export class OrdersEffects {
  private toOrderAdapter = new ToOrderAdapter()

  private actions$ = inject(Actions)
  private ordersService = inject(OrdersService)
  private ordersFacade = inject(OrdersFacade)
  private chargeCodesFacade = inject(ChargeCodesFacade)
  private invoicesService = inject(platform_v1.VendorInvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)
  private platformOrdersService = inject(platform_v2.OrderService)
  private store = inject(Store)

  constructor() {
    this.toOrderAdapter.set(FromGetOrdersResponse)
    this.toOrderAdapter.set(FromGetOrderDetailsResponse)
    this.toOrderAdapter.set(FromGetOrderStopsResponse)
    this.toOrderAdapter.set(FromGetReferenceNumbersResponse)
    this.toOrderAdapter.set(FromGetLineItemsResponse)
    this.toOrderAdapter.set(FromGetMatchedInvoicesResponse)
  }

  loadOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.loadOrders),
      switchMap(({ filters }) => this.ordersService.getOrders(filters)),
      switchMap(res => [
        ordersActions.setTotalCount({ count: res['@odata.count'] }),
        ordersActions.loadOrdersSuccess({
          orders: this.toOrderAdapter.convert(
            FromGetOrdersResponse,
            res
          ) as Order[]
        })
      ])
    )
  )
  resetGetAllLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.loadOrdersSuccess, ordersActions.loadOrdersFail),
      debounceTime(100),
      map(() => ordersActions.resetGetAllLoadingState())
    )
  )

  //#region download orders

  downloadInvoiceDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.downloadOrders),
      tap(({ id }) => {
        this.alertsFacade.addAlert({
          label: `Orders are downloading.`,
          alertType: 'success',
          duration: 6000000,
          id,
          canClose: false,
          displaySpinner: true
        })
      }),
      switchMap(({ filters, id }) =>
        this.ordersService
          .downloadOrder({
            ...filters,
            itemsPerPage: undefined,
            page: undefined
          })
          .pipe(
            map(response => {
              return {
                blob: <Blob>response.body,
                filename: /filename=([^;\n]*)/.exec(
                  response.headers.get('content-disposition') ?? ''
                )?.[1]
              }
            }),
            map(({ blob, filename }) =>
              ordersActions.downloadOrdersSuccess({
                filename,
                blob
              })
            ),
            catchError(httpError =>
              of(
                ordersActions.downloadOrdersFail({
                  error: httpError.error
                })
              )
            ),
            finalize(() => {
              this.alertsFacade.removeAlert({ id })
            })
          )
      )
    )
  )

  downloadOrdersSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ordersActions.downloadOrdersSuccess),
        switchMap(({ blob, filename }) => {
          if (filename) return of({ blob, filename })

          const documentFileName = `UnmatchedOrders-${format(
            new Date(),
            'yyyyMMddHHmmss'
          )}.xlsx`

          return of({
            blob,
            filename: documentFileName
          })
        }),
        tap(({ blob, filename }) => {
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = filename as string
          a.click()
          URL.revokeObjectURL(objectUrl)
        }),
        tap(() => {
          this.alertsFacade.addAlert({
            label: `Downloaded document successfully`,
            alertType: 'success'
          })
        })
      ),
    { dispatch: false }
  )

  downloadOrdersFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ordersActions.downloadOrdersFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to download the document."
          })
        })
      ),
    { dispatch: false }
  )

  //#endregion

  //#region load Order Details

  loadOrderDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.loadOrderDetails),
      tap(() =>
        this.ordersFacade.startLoading(OrdersAsyncOperations.getDetails)
      ),
      exhaustMap(({ orderId, legacyOrderId }) =>
        this.ordersService.getOrderDetails(legacyOrderId).pipe(
          map(details =>
            this.toOrderAdapter.convert(FromGetOrderDetailsResponse, details)
          ),
          map(order => ({ ...order, id: orderId, legacyId: legacyOrderId })),
          map(order => ordersActions.loadOrderDetailsSuccess({ order })),
          tap(() =>
            this.ordersFacade.endLoading(OrdersAsyncOperations.getDetails)
          )
        )
      )
    )
  )

  //#endregion

  //#region load new Order Details

  loadNewOrderDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.loadNewOrderDetails),
      exhaustMap(({ orderId, legacyOrderId }) =>
        this.ordersService.getNewOrderDetails(orderId).pipe(
          switchMap(response => {
            return this.chargeCodesFacade.allChargeTypes$.pipe(
              filter(chargeCodes =>
                chargeCodes.every(
                  chargeCode => chargeCode.tenantChargeTypes !== undefined
                )
              ),
              map(chargeCodes => ({ response, chargeCodes })),
              take(1)
            )
          }),
          map(details => fromGetNewOrderDetailsResponse(details)),
          map(order => ({ ...order, id: orderId, legacyId: legacyOrderId })),
          map(order => ordersActions.loadNewOrderDetailsSuccess({ order })),
          catchError(error =>
            of(
              ordersActions.loadNewOrderDetailsFail({
                error
              })
            )
          )
        )
      )
    )
  )

  resetGetNewOrderDetailsLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ordersActions.loadNewOrderDetailsSuccess,
        ordersActions.loadNewOrderDetailsFail
      ),
      debounceTime(100),
      map(() => ordersActions.resetGetNewOrderDetailsLoadingState())
    )
  )

  //#endregion

  //#region load Order Stops

  loadOrderStops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.loadOrderStops),
      tap(() => this.ordersFacade.startLoading(OrdersAsyncOperations.getStops)),
      exhaustMap(({ orderId, legacyOrderId }) =>
        this.ordersService.getOrderStops(legacyOrderId).pipe(
          map(response => ({
            stops: this.toOrderAdapter.convert(
              FromGetOrderStopsResponse,
              response
            ).stops,
            id: orderId
          })),
          map(order => order as Order),
          map(order =>
            ordersActions.loadOrderStopsSuccess({ orderWithStops: order })
          ),
          tap(() =>
            this.ordersFacade.endLoading(OrdersAsyncOperations.getStops)
          )
        )
      )
    )
  )

  //#endregion

  //#region load Reference Numbers

  loadReferenceNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.loadReferenceNumbers),
      tap(() =>
        this.ordersFacade.startLoading(
          OrdersAsyncOperations.getReferenceNumbers
        )
      ),
      exhaustMap(({ orderId, legacyOrderId }) =>
        this.ordersService.getReferenceNumbers(legacyOrderId).pipe(
          map(response => ({
            referenceNumbers: this.toOrderAdapter.convert(
              FromGetReferenceNumbersResponse,
              response
            ).referenceNumbers,
            id: orderId
          })),
          map(order => order as Order),
          map(order =>
            ordersActions.loadReferenceNumbersSuccess({
              orderWithReferenceNumbers: order
            })
          ),
          tap(() =>
            this.ordersFacade.endLoading(
              OrdersAsyncOperations.getReferenceNumbers
            )
          )
        )
      )
    )
  )

  //#endregion

  //#region load Order Line Items

  loadLineItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.loadLineItems),
      tap(() =>
        this.ordersFacade.startLoading(OrdersAsyncOperations.getLineItems)
      ),
      exhaustMap(({ orderId, legacyOrderId }) =>
        this.ordersService.getLineItems(legacyOrderId).pipe(
          map(response => ({
            lineItems: this.toOrderAdapter.convert(
              FromGetLineItemsResponse,
              response
            ).lineItems,
            id: orderId
          })),
          map(order => order as Order),
          map(order =>
            ordersActions.loadLineItemsSuccess({
              orderWithLineItems: order
            })
          ),
          tap(() =>
            this.ordersFacade.endLoading(OrdersAsyncOperations.getLineItems)
          )
        )
      )
    )
  )

  //#endregion

  //#region load Order Charges

  loadCharges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.loadCharges),
      tap(() =>
        this.ordersFacade.startLoading(OrdersAsyncOperations.getCharges)
      ),
      switchMap(payload =>
        race(
          timer(1).pipe(
            tap(() => this.chargeCodesFacade.loadChargeCodesWithDetails())
          ),
          this.chargeCodesFacade.allChargeTypes$
        ).pipe(
          take(1),
          map(() => payload)
        )
      ),
      exhaustMap(({ orderId, legacyOrderId }) =>
        combineLatest([
          this.ordersService.getVendorChargesNew(legacyOrderId),
          this.ordersService.getCustomerChargesNew(legacyOrderId)
        ]).pipe(
          take(1),
          switchMap(responses => {
            const responsesChargeTypeIds = responses.flatMap(response =>
              response.map(charge => charge.chargeTypeId)
            )
            return this.chargeCodesFacade.allChargeTypes$.pipe(
              filter(
                chargeCodes =>
                  chargeCodes.every(
                    chargeCode => chargeCode.tenantChargeTypes !== undefined
                  ) &&
                  responsesChargeTypeIds.every(chargeTypeId =>
                    chargeCodes.some(
                      chargeCode => chargeCode.id === chargeTypeId
                    )
                  )
              ),
              take(1),
              map(chargeCodes =>
                fromGetOrderChargesComposedResponse(
                  [...responses, chargeCodes],
                  orderId
                )
              )
            )
          }),
          map(order =>
            ordersActions.loadChargesSuccess({
              orderWithCharges: order
            })
          ),
          tap(() =>
            this.ordersFacade.endLoading(OrdersAsyncOperations.getCharges)
          )
        )
      )
    )
  )

  //#endregion

  //#region load Matched Invoices

  loadMatchedInvices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.loadMatchedInvoices),
      exhaustMap(({ orderId, legacyOrderId }) =>
        this.ordersService.getMatchedInvoices(legacyOrderId).pipe(
          map(response => ({
            invoices: this.toOrderAdapter.convert(
              FromGetMatchedInvoicesResponse,
              response
            ).invoices,
            id: orderId
          })),
          map(order =>
            ordersActions.loadMatchedInvoicesSuccess({
              orderWithMatchedInvoices: <Order>order
            })
          )
        )
      )
    )
  )

  resetGetMatchedInvoicesLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ordersActions.loadMatchedInvoicesSuccess,
        ordersActions.loadMatchedInvoicesFail
      ),
      debounceTime(100),
      map(() => ordersActions.resetGetMatchedInvoicesLoadingState())
    )
  )

  //#endregion

  //#region select Purchase Invoice

  updateOrdersPurchaseInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.updateOrdersPurchaseInvoice),
      exhaustMap(payload =>
        this.invoicesService
          .putApiV1InvoicesOwnership({
            vendorInvoiceUuid: payload.vendorInvoiceUuid,
            requestBody: {
              ownInvoice: true
            }
          })
          .pipe(
            switchMap(() =>
              this.platformOrdersService.getApiV2Orders({
                orderId: payload.orderUuid
              })
            ),
            switchMap(response =>
              this.platformOrdersService.putApiV2Orders({
                id: payload.orderUuid,
                requestBody: toUpdateOrderPurchaseInvoiceRequest(
                  payload,
                  response
                )
              })
            ),
            map(() =>
              ordersActions.updateOrdersPurchaseInvoiceSuccess({
                shouldReloadOrderDetails: payload.shouldReloadOrderDetails
              })
            ),
            catchError(error =>
              of(
                ordersActions.updateOrdersPurchaseInvoiceFail({
                  error
                })
              )
            )
          )
      )
    )
  )

  resetUpdatePurchaseInvoiceLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ordersActions.updateOrdersPurchaseInvoiceSuccess,
        ordersActions.updateOrdersPurchaseInvoiceFail
      ),
      debounceTime(100),
      map(() => ordersActions.resetUpdatePurchaseInvoiceLoadingState())
    )
  )

  //#endregion

  //#region select Purchase Invoice

  removePurchaseInvoiceAssociatedUuid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.removePurchaseInvoiceAssociatedUuid),
      exhaustMap(payload =>
        this.platformOrdersService
          .getApiV2Orders({ orderId: payload.orderUuid })
          .pipe(
            switchMap(response =>
              this.platformOrdersService
                .putApiV2Orders({
                  id: payload.orderUuid,
                  requestBody:
                    toRemoveOrderPurchaseInvoiceAssociatedUuidRequest(
                      payload,
                      response
                    )
                })
                .pipe(
                  map(() =>
                    ordersActions.removePurchaseInvoiceAssociatedUuidSuccess()
                  ),
                  catchError(error =>
                    of(
                      ordersActions.removePurchaseInvoiceAssociatedUuidFailed({
                        error
                      })
                    )
                  )
                )
            )
          )
      )
    )
  )

  resetRemovePurchaseInvoiceAssociatedUuidLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ordersActions.removePurchaseInvoiceAssociatedUuidSuccess,
        ordersActions.removePurchaseInvoiceAssociatedUuidFailed
      ),
      debounceTime(100),
      map(() => ordersActions.resetUpdatePurchaseInvoiceLoadingState())
    )
  )

  //#endregion

  //#region load Divisions

  loadOrderDivisionTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.loadDivisionTags),
      concatLatestFrom(() => this.store.select(selectDivisions)),
      switchMap(([payload, disputedReasons]) =>
        iif(
          () => disputedReasons.length === 0,
          of(payload),
          EMPTY.pipe(
            finalize(() => {
              this.store.dispatch({ type: `[CACHED]${payload.type}` })
              this.ordersFacade.endLoading(
                OrdersAsyncOperations.loadDivisionTags
              )
            })
          )
        )
      ),
      exhaustMap(() =>
        this.platformOrdersService.getApiV2Divisions().pipe(
          map(response =>
            response.map(
              division =>
                ({
                  name: division.name,
                  value: division.value,
                  uuid: division.uuid
                }) as Tag
            )
          ),
          map(divisions =>
            ordersActions.loadDivisionTagsSuccess({
              tags: divisions
            })
          )
        )
      )
    )
  )

  resetLoadDivisionTagsLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ordersActions.loadDivisionTagsSuccess,
        ordersActions.loadDivisionTagsFail
      ),
      debounceTime(100),
      map(() => ordersActions.resetLoadDivisionTagsLoadingState())
    )
  )

  //#endregion
}
