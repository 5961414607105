import { Injectable } from '@angular/core'
import { Observable, Subject, firstValueFrom, map, race } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class InvoiceApproveService {
  private preApprovalSteps: Array<{
    name: string
    isCompleted: Observable<boolean>
    isFailed: Observable<boolean>
    callback: () => Promise<
      boolean | { forceContinue: boolean; success: boolean }
    >
  }> = []
  canDeactivate = () => {
    return true // set default
  }

  cancelSubject = new Subject<void>()
  continueSubject = new Subject<void>()

  registerPreApprovalStep(
    name: string,
    isCompleted: Observable<boolean>,
    isFailed: Observable<boolean>,
    callback: () => ReturnType<(typeof this.preApprovalSteps)[0]['callback']>
  ): void {
    this.preApprovalSteps = this.preApprovalSteps.filter(
      step => step.name !== name
    )
    this.preApprovalSteps.push({
      name,
      isCompleted,
      isFailed,
      callback
    })
  }

  async executePreApprovalSteps(): Promise<void> {
    this.cancelSubject.next()
    for (const step of this.preApprovalSteps) {
      await new Promise<void>((resolve, reject) => {
        step.callback().then(performOperation => {
          if (typeof performOperation === 'boolean' && performOperation) return
          else if (
            typeof performOperation === 'object' &&
            performOperation.success &&
            performOperation.forceContinue
          ) {
            this.continueSubject.next()
            return
          }

          this.cancelSubject.next()
        })

        firstValueFrom(
          race(
            step.isCompleted.pipe(map(() => true)),
            step.isFailed.pipe(map(() => false)),
            this.cancelSubject.pipe(map(() => false)),
            this.continueSubject.pipe(map(() => true))
          )
        ).then(completed =>
          completed ? resolve() : reject('canceled by used/failed')
        )
      })
    }
  }

  registerCanDeactivate(callback: () => boolean): void {
    this.canDeactivate = callback
  }

  executeCanDeactivate(): boolean {
    return this.canDeactivate()
  }
}
