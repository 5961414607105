import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { Dialog } from '@angular/cdk/dialog'
import { invoiceCustomerDetailsActions } from './invoice-customer-details.actions'
import { EDIT_INVOICE_CUSTOMER_DIALOG } from '../invoices.constants'
import { invoiceDetailsActions } from '../invoice-details/invoice-details.actions'
import { invoiceExceptionsActions } from '../invoice-exceptions/invoice-exceptions.actions'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable()
export class InvoiceCustomerDetailsEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)
  private dialog = inject(Dialog)

  updateInvoiceCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceCustomerDetailsActions.updateInvoiceCustomer),
      exhaustMap(({ id, newCustomer }) =>
        this.invoicesService
          .updateCustomer({
            customerId: newCustomer.id,
            invoiceId: id
          })
          .pipe(
            map(() =>
              invoiceCustomerDetailsActions.updateInvoiceCustomerSuccess({
                invoiceId: id
              })
            ),
            catchError(error =>
              of(
                invoiceCustomerDetailsActions.updateInvoiceCustomerFail({
                  error
                })
              )
            )
          )
      )
    )
  )

  updateInvoiceCustomerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceCustomerDetailsActions.updateInvoiceCustomerSuccess),
      tap(() =>
        this.alertsFacade.addAlert({
          alertType: 'success',
          label: `Invoice was succesfully updated.`
        })
      ),
      tap(() =>
        this.dialog.getDialogById(EDIT_INVOICE_CUSTOMER_DIALOG)?.close()
      ),
      switchMap(({ invoiceId }) =>
        of(
          invoiceDetailsActions.loadInvoiceDetails({ invoiceId }),
          invoiceExceptionsActions.loadInvoiceExceptions({ invoiceId })
        )
      )
    )
  )

  updateInvoiceCustomerFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceCustomerDetailsActions.updateInvoiceCustomerFail),
        tap(error => console.error(error)),
        tap(httpError => {
          const {
            error: { error }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } = httpError as any as HttpErrorResponse

          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              error?.message?.length > 0
                ? error.message
                : `There's an error trying to update invoice customer.`
          })
        })
      ),
    { dispatch: false }
  )
}
