import { makeEnvironmentProviders } from '@angular/core'
import { provideEffects } from '@ngrx/effects'
import { provideState } from '@ngrx/store'
import {
  AuditsFacade,
  addAuditNoteEffects,
  loadAuditEffects,
  updateAuditEffects
} from '../+state/audits'
import {
  AUDITS_FEATURE_KEY,
  auditsReducer
} from '../+state/audits/audits.reducer'

export function provideAuditsFeature() {
  return makeEnvironmentProviders([
    provideEffects(loadAuditEffects, addAuditNoteEffects, updateAuditEffects),
    provideState(AUDITS_FEATURE_KEY, auditsReducer),
    AuditsFacade
  ])
}
