import { createReducer, on } from '@ngrx/store'
import { GetPaginatedDisputesRequest } from '../../domain/disputes/get-paginated-disputes.request'
import {
  GetPaginatedDisputeResponse,
  PaginatedDisputeItemResponse
} from '../../domain/disputes/get-paginated-disputes.response'
import { disputesActions } from './disputes.actions'

export const FEATURE_DISPUTES_KEY = 'feature-disputes'

export interface DisputeListState {
  paginatedDisputesResponse: GetPaginatedDisputeResponse | undefined
  currentDispute: PaginatedDisputeItemResponse | undefined
  currentDisputeIndex: number
  paginatedDisputeRequest: GetPaginatedDisputesRequest
  loading: boolean
  stopLoading: boolean
}

export const initialState: DisputeListState = {
  paginatedDisputesResponse: undefined,
  currentDispute: undefined,
  currentDisputeIndex: -1,
  paginatedDisputeRequest: {
    itemsPerPage: 25,
    page: 1,
    sortField: 'CreatedAt',
    sortOrder: 'desc',
    ownershipUuid: '',
    resolved: false,
    invoiceStatuses: ['audit', 'matched', 'ready', 'approved']
  },
  loading: false,
  stopLoading: false
}

export const disputeListReducer = createReducer(
  initialState,
  on(disputesActions.loadPaginatedDisputes, (state: DisputeListState) => ({
    ...state
  })),
  on(
    disputesActions.loadPaginatedDisputesSuccess,
    (state: DisputeListState, { response }) => ({
      ...state,
      paginatedDisputesResponse: response,
      totalRecords: response.totalRecords
    })
  ),
  on(disputesActions.loadPaginatedDisputesFail, (state: DisputeListState) => ({
    ...state
  })),
  on(
    disputesActions.patchPaginatedDisputesRequest,
    (state, { request }): DisputeListState => ({
      ...state,
      paginatedDisputeRequest: request
    })
  ),
  on(disputesActions.clearPaginatedDisputes, (state: DisputeListState) => ({
    ...state,
    paginatedDisputeResponse: undefined
  })),
  on(
    disputesActions.setPaginatedDisputesLoading,
    (state, { loading }): DisputeListState => ({
      ...state,
      loading: loading
    })
  ),
  on(disputesActions.setCurrentDispute, (state, { dispute, index }) => ({
    ...state,
    currentDispute: dispute,
    currentDisputeIndex: index
  }))
)
