import { Dialog } from '@angular/cdk/dialog'
import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { AlertsFacade, LegacyAlertsFacade } from '@navix/alerts/domain'
import { CurrentUserFacade } from '@navix/current-user/domain'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { format } from 'date-fns'
import {
  catchError,
  concat,
  debounceTime,
  exhaustMap,
  finalize,
  forkJoin,
  iif,
  map,
  of,
  skip,
  switchMap,
  take,
  tap,
  toArray,
  withLatestFrom
} from 'rxjs'
import { InvoicesAdapter } from '../../../adapter/invoices/InvoicesAdapter'
import { ToSendEmailNotificationRequest } from '../../../adapter/invoices/ToSendEmailNotificationRequest'
import {
  DataFilters,
  StatusFilter
} from '../../../domain/invoices/data-filters.model'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { TerminateInvoiceRequest } from '../../../domain/invoices/terminate-invoice.request'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import {
  ADD_INVOICE_EMAIL_NOTIFICATION_DIALOG,
  INVOICES_ABSOLUTE_ROUTE_PREFIX,
  MS_BEFORE_RESET_LOADING_STATE
} from '../invoices.constants'
import { InvoicesFacade } from '../invoices.facade'
import { operationsActions } from './operations.actions'
import { TerminateInvoiceOperation } from '../../../domain/invoices/terminate-invoice-operation.model'

@Injectable()
export class OperationsEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private invoicesFacade = inject(InvoicesFacade)
  private router = inject(Router)
  private legacyAlertsFacade = inject(LegacyAlertsFacade)
  private alertsFacade = inject(AlertsFacade)
  private dialog = inject(Dialog)
  private currentUserFacade = inject(CurrentUserFacade)

  private invoiceAdapter = new InvoicesAdapter()

  constructor() {
    this.invoiceAdapter.set(ToSendEmailNotificationRequest)
  }

  downloadInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.downloadInvoices),
      tap(() => {
        this.legacyAlertsFacade.addAlert({
          label: `Invoices are downloading.`,
          alertType: 'success',
          duration: 6000000,
          canClose: false,
          displaySpinner: true,
          id: -100 //ToDo(ladaj): update alerts to support string ids
        })
      }),
      concatLatestFrom(() => [this.invoicesFacade.filters$]),
      exhaustMap(([, filters]) =>
        this.invoicesService.downloadInvoices(filters).pipe(
          map(response => ({
            blob: <Blob>response.body,
            filename: `Invoices-${format(new Date(), 'yyyyMMddHHmmss')}.xlsx`
          })),
          map(response =>
            operationsActions.downloadInvoicesSuccess({
              blob: response.blob,
              filename: response.filename
            })
          ),
          catchError(error =>
            of(operationsActions.downloadInvoicesFail({ error }))
          ),
          finalize(() => {
            this.legacyAlertsFacade.removeAlert({ id: -100 })
          })
        )
      )
    )
  )

  downloadInvoicesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(operationsActions.downloadInvoicesSuccess),

        tap(({ blob, filename }) => {
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = filename
          a.click()
          URL.revokeObjectURL(objectUrl)
        }),
        tap(() => {
          this.legacyAlertsFacade.addAlert({
            label: `Download document successfully`,
            alertType: 'success'
          })
        })
      ),
    { dispatch: false }
  )

  downloadInvoicesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(operationsActions.downloadInvoicesFail),
        tap(() => {
          this.legacyAlertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to download the document."
          })
        })
      ),
    { dispatch: false }
  )

  sendEmailNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.sendEmailNotification),
      map(emailNotification => ({
        request: this.invoiceAdapter.convert(
          ToSendEmailNotificationRequest,
          emailNotification
        )
      })),
      exhaustMap(({ request }) =>
        this.invoicesService.sendEmailNotification(request).pipe(
          map(() => operationsActions.sendEmailNotificationSuccess()),
          catchError(error =>
            of(
              operationsActions.sendEmailNotificationFail({
                error
              })
            )
          )
        )
      )
    )
  )

  sendEmailNotificationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(operationsActions.sendEmailNotificationSuccess),
        tap(() =>
          this.legacyAlertsFacade.addAlert({
            alertType: 'success',
            label: `Email notification sent successfully.`
          })
        ),
        tap(() =>
          this.dialog
            .getDialogById(ADD_INVOICE_EMAIL_NOTIFICATION_DIALOG)
            ?.close()
        )
      ),
    { dispatch: false }
  )

  sendEmailNotificationFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(operationsActions.sendEmailNotificationFail),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''

          this.legacyAlertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : "There's an error trying to send email notification."
          })
        })
      ),
    { dispatch: false }
  )

  reAudit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.reAudit),
      exhaustMap(({ invoiceId }) =>
        this.invoicesService.reAudit(invoiceId).pipe(
          map(() => operationsActions.reAuditSuccess({ invoiceId })),
          catchError(() => of(operationsActions.reAuditFail()))
        )
      )
    )
  )

  resetReAuditLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.reAuditSuccess, operationsActions.reAuditFail),
      debounceTime(100),
      map(() => operationsActions.resetReAuditLoadingState())
    )
  )

  approveInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.approveInvoice),
      exhaustMap(({ invoiceId, invoiceUuid, useLegacy }) => {
        const approveObservable = useLegacy
          ? this.invoicesService.approveInvoice(invoiceId)
          : this.invoicesService.approveInvoiceV2(invoiceUuid)

        return approveObservable.pipe(
          map(() => operationsActions.approveInvoiceSuccess({ invoiceId })),
          catchError(httpError =>
            of(operationsActions.approveInvoiceFail({ error: httpError.error }))
          )
        )
      })
    )
  )

  resetApproveInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        operationsActions.approveInvoiceSuccess,
        operationsActions.approveInvoiceFail
      ),
      debounceTime(100),
      map(() => operationsActions.resetApproveInvoiceLoadingState())
    )
  )

  terminateInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.terminateInvoice),
      map(invoice => ({
        request: <TerminateInvoiceRequest>{
          id: invoice.id,
          uuid: invoice.uuid,
          vendorInvoiceStatusReasonId: invoice.terminateInvoice.reasonId,
          comment: invoice.terminateInvoice.comment,
          operation: TerminateInvoiceOperation.SingleTerminate
        },
        useLegacy: invoice.useLegacy
      })),
      exhaustMap(({ request, useLegacy }) => {
        const terminateObservable = useLegacy
          ? this.invoicesService.terminateInvoice(request)
          : this.invoicesService.terminateInvoiceV2(request)

        return terminateObservable.pipe(
          map(() =>
            operationsActions.terminateInvoiceSuccess({
              invoiceUuid: request.uuid
            })
          ),
          catchError(httpError =>
            of(
              operationsActions.terminateInvoiceFail({ error: httpError.error })
            )
          )
        )
      })
    )
  )

  resetTerminateInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        operationsActions.terminateInvoiceSuccess,
        operationsActions.terminateInvoiceFail
      ),
      debounceTime(100),
      map(() => operationsActions.resetTerminateInvoiceLoadingState())
    )
  )

  regenerateInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.regenerateInvoice),
      map(({ invoiceId }) => invoiceId),
      exhaustMap(invoiceId =>
        this.invoicesService.regenerateInvoice(invoiceId).pipe(
          map(request =>
            operationsActions.regenerateInvoiceSuccess({
              invoiceId: request.vendorInvoiceId,
              previousInvoiceId: invoiceId
            })
          ),
          catchError(httpError =>
            of(
              operationsActions.regenerateInvoiceFail({
                error: httpError.error
              })
            )
          )
        )
      )
    )
  )

  resetRegenerateInvoiceLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        operationsActions.regenerateInvoiceSuccess,
        operationsActions.regenerateInvoiceFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => operationsActions.resetRegenerateInvoiceLoadingState())
    )
  )

  regenerateInvoiceFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(operationsActions.regenerateInvoiceFail),
        tap(() => {
          this.legacyAlertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to regenerate invoice."
          })
        })
      ),
    { dispatch: false }
  )

  navigateToNextInvoice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(operationsActions.navigateToNextInvoice),
        withLatestFrom(this.invoicesFacade.filters$),
        tap(([{ requireToLoadList }, filters]) => {
          if (requireToLoadList) {
            this.invoicesFacade.loadInvoices(filters)
          }
        }),
        switchMap(([nextInvoiceState]) =>
          iif(
            () => nextInvoiceState.nextInvoice !== undefined,
            this.invoicesFacade.allInvoices$.pipe(
              map(invoices => ({ isNewPage: false, invoices }))
            ),
            this.invoicesFacade.allInvoices$.pipe(
              skip(1),
              map(invoices => ({ isNewPage: true, invoices }))
            )
          ).pipe(
            map(({ isNewPage, invoices }) => {
              const isEmptyList = !invoices.length

              return isEmptyList
                ? -1
                : isNewPage
                  ? invoices.at(0)?.id
                  : nextInvoiceState.nextInvoice?.id
            }),
            withLatestFrom(
              this.invoicesFacade.filters$.pipe(map(filters => filters.status))
            ),
            take(1)
          )
        ),
        tap(([nextInvoiceId, status]) => {
          if (nextInvoiceId === -1 || !nextInvoiceId)
            this.router.navigate([
              INVOICES_ABSOLUTE_ROUTE_PREFIX,
              'list',
              StatusFilter[status ?? StatusFilter.audit]
            ])
          else {
            this.router.navigate([
              INVOICES_ABSOLUTE_ROUTE_PREFIX,
              'details',
              nextInvoiceId
            ])
          }
        })
      ),
    { dispatch: false }
  )

  terminateMultipleInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.terminateMultipleInvoices),
      exhaustMap(({ terminateInvoice, invoices, useLegacy }) => {
        const chunksObservables = [
          ...chunks(invoices, invoices.length / 10)
        ].map(chunk =>
          chunk.map(invoice => {
            const request = {
              id: invoice.id,
              vendorInvoiceStatusReasonId:
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                terminateInvoice.reasonId!,
              comment: terminateInvoice.comment,
              uuid: invoice.uuid,
              operation: TerminateInvoiceOperation.MassTerminate
            }
            const obs = useLegacy
              ? this.invoicesService.terminateInvoice(request)
              : this.invoicesService.terminateInvoiceV2(request)

            return obs.pipe(
              map(() => ({ invoice, success: true })),
              catchError(() => of({ invoice, success: false }))
            )
          })
        )

        const concatedChunks = chunksObservables.map(chunk =>
          concat(...chunk).pipe(toArray())
        )

        return forkJoin(concatedChunks).pipe(
          map(response => response.flat()),
          map(response => {
            return operationsActions.terminateMultipleInvoicesSuccess({
              allFinished: response.every(item => item.success),
              failedInvoices: response.filter(item => !item.success).length
            })
          }),
          catchError(httpError =>
            of(
              operationsActions.terminateMultipleInvoicesFail({
                error: httpError
              })
            )
          )
        )
      })
    )
  )

  resetTerminateMultipleInvoicesLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        operationsActions.terminateMultipleInvoicesSuccess,
        operationsActions.terminateMultipleInvoicesFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => operationsActions.resetTerminateMultipleInvoicesLoadingState())
    )
  )

  approveMultipleInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.approveMultipleInvoices),
      exhaustMap(({ invoices, useLegacy }) => {
        return this.invoicesService
          .massApproveInvoices(invoices.map(invoice => invoice.uuid))
          .pipe(
            map(response => {
              const allSucceed = response.every(item => item.approved)
              const failedApprovals = response.filter(x => !x.approved)

              return allSucceed
                ? operationsActions.approveMultipleInvoicesSuccess()
                : operationsActions.approveMultipleInvoicesPartialFail({
                  failedApprovals
                })
            }),
            catchError(httpError =>
              of(
                operationsActions.approveMultipleInvoicesFail({
                  error: httpError
                })
              )
            )
          )
      })
    )
  )

  resetApproveMultipleInvoicesLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        operationsActions.approveMultipleInvoicesSuccess,
        operationsActions.approveMultipleInvoicesFail,
        operationsActions.approveMultipleInvoicesPartialFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => operationsActions.resetApproveMultipleInvoicesLoadingState())
    )
  )

  navigateToPreviousInvoice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(operationsActions.navigateToPreviousInvoice),
        concatLatestFrom(() => [
          this.invoicesFacade.selectedInvoice$,
          this.invoicesFacade.allInvoices$
        ]),
        map(([, invoice, invoices]) => {
          const currentInvoiceIndex = invoices.findIndex(
            record => record.id === invoice.id
          )
          const previousInvoice =
            currentInvoiceIndex === 0
              ? undefined
              : invoices.at(currentInvoiceIndex - 1)
          const previousInvoiceState: {
            requireToLoadList: boolean
            currentInvoiceIndex: number
            previousInvoice?: Invoice
            isFirstInvoiceOfAllInvoices?: boolean
          } = {
            requireToLoadList: false,
            currentInvoiceIndex: currentInvoiceIndex,
            previousInvoice: previousInvoice,
            isFirstInvoiceOfAllInvoices: false
          }
          if (currentInvoiceIndex === -1 || previousInvoice === undefined)
            previousInvoiceState.requireToLoadList = true

          return previousInvoiceState
        }),
        concatLatestFrom(previousInvoiceState =>
          this.invoicesFacade.filters$.pipe(
            map(filters => {
              if (
                previousInvoiceState.previousInvoice === undefined &&
                filters.page === 1
              ) {
                previousInvoiceState.isFirstInvoiceOfAllInvoices = true
              }
              if (previousInvoiceState.previousInvoice === undefined) {
                return {
                  ...filters,
                  page:
                    filters.page === 1 ? filters.page : (filters.page ?? 0) - 1
                } as Required<DataFilters>
              } else {
                return filters as Required<DataFilters>
              }
            })
          )
        ),
        tap(([previousInvoiceState, filters]) => {
          if (previousInvoiceState.requireToLoadList) {
            this.invoicesFacade.loadInvoices(filters)
          }
          this.invoicesFacade.patchListFilters(filters)
        }),
        switchMap(([previousInvoiceState, filters]) =>
          iif(
            () => previousInvoiceState.previousInvoice !== undefined,
            this.invoicesFacade.allInvoices$,
            this.invoicesFacade.allInvoices$.pipe(skip(1))
          ).pipe(
            map(invoices => {
              const previousInvoiceId =
                invoices.at(previousInvoiceState.currentInvoiceIndex) ===
                  undefined
                  ? undefined
                  : previousInvoiceState.previousInvoice?.id ??
                  invoices.at(filters.itemsPerPage - 1)?.id

              return {
                allInvoices: invoices,
                previousInvoiceId,
                isFirstInvoiceOfAllInvoices:
                  previousInvoiceState.isFirstInvoiceOfAllInvoices
              }
            }),
            take(1)
          )
        ),
        concatLatestFrom(() =>
          this.invoicesFacade.filters$.pipe(map(filters => filters.status))
        ),
        tap(([{ previousInvoiceId, isFirstInvoiceOfAllInvoices }, status]) => {
          if (previousInvoiceId === undefined || isFirstInvoiceOfAllInvoices)
            this.router.navigate([
              INVOICES_ABSOLUTE_ROUTE_PREFIX,
              'list',
              StatusFilter[status ?? StatusFilter.audit]
            ])
          else {
            this.router.navigate([
              INVOICES_ABSOLUTE_ROUTE_PREFIX,
              'details',
              previousInvoiceId
            ])
          }
        })
      ),
    { dispatch: false }
  )

  reAuditMultipleInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.reAuditMultipleInvoices),
      exhaustMap(({ invoiceIds }) => {
        const chunksObservables = [
          ...chunks(invoiceIds, invoiceIds.length / 10)
        ].map(chunk =>
          chunk.map(invoiceId =>
            this.invoicesService.reAudit(invoiceId).pipe(
              map(() => ({ invoiceId, success: true })),
              catchError(() => of({ invoiceId, success: false }))
            )
          )
        )

        const concatedChunks = chunksObservables.map(chunk =>
          concat(...chunk).pipe(toArray())
        )

        return forkJoin(concatedChunks).pipe(
          map(response => response.flat()),
          map(response => {
            return operationsActions.reAuditMultipleInvoicesSuccess({
              allFinished: response.every(item => item.success),
              failedInvoices: response.filter(item => !item.success).length
            })
          }),
          catchError(httpError =>
            of(
              operationsActions.reAuditMultipleInvoicesFail({
                error: httpError
              })
            )
          )
        )
      })
    )
  )

  resetReAuditMultipleInvoicesLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        operationsActions.reAuditMultipleInvoicesSuccess,
        operationsActions.reAuditMultipleInvoicesFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => operationsActions.resetReAuditMultipleInvoicesLoadingState())
    )
  )
}

function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n)
  }
}
