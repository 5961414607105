import { Injectable, inject } from '@angular/core'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, exhaustMap, map, of, tap } from 'rxjs'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { InvoicesFacade } from '../invoices.facade'
import { invoiceRelatedDocumentsActions } from './invoice-related-documents.actions'
import { RelatedDocumentsFormats } from '../../../domain/invoices/invoice.model'
import { RelatedDocument } from '../../../domain/invoices/related-document.model'

@Injectable()
export class InvoiceRelatedDocumentsEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private invoicesFacade = inject(InvoicesFacade)
  private alertsFacade = inject(LegacyAlertsFacade)

  loadInvoiceRatingEngineDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceRelatedDocumentsActions.loadInvoiceRelatedDocuments),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.getRelatedDocuments)
      ),
      exhaustMap(({ invoiceUuid, invoiceId }) =>
        this.invoicesService.getRelatedDocuments(invoiceUuid).pipe(
          map(response =>
            response.map(
              item =>
                ({
                  type: item.type,
                  format: item.format,
                  data:
                    item.format === RelatedDocumentsFormats.Json
                      ? JSON.stringify(this.parseJson(item.data), null, '\t')
                      : item.data,
                  createdDate: item.createdDate
                }) as RelatedDocument
            )
          ),
          map(relatedDocuments =>
            invoiceRelatedDocumentsActions.loadInvoiceRelatedDocumentsSuccess({
              relatedDocuments,
              invoiceId
            })
          ),
          tap(() =>
            this.invoicesFacade.endLoading(AsyncOperations.getRelatedDocuments)
          ),
          catchError(error =>
            of(
              invoiceRelatedDocumentsActions.loadInvoiceRelatedDocumentsFail({
                error
              })
            )
          )
        )
      )
    )
  )

  parseJson(data: string): object | string {
    try {
      const fixed = JSON.parse(data)
      if (typeof fixed === 'string') {
        return this.parseJson(fixed)
      }
      return fixed
    } catch {
      console.error('Error parsing JSON', { data })
      return data
    }
  }

  loadInvoiceRatingEngineDocumentFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceRelatedDocumentsActions.loadInvoiceRelatedDocumentsFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load invoice related documents."
          })
        })
      ),
    { dispatch: false }
  )
}
