import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { on } from '@ngrx/store'
import {
  AuditAsyncOperations,
  AuditsLoadingState
} from '../../../domain/audits/audits-loading.model'
import { AuditsState } from '../audits.reducer'
import { updateAuditActions } from './update-audit.actions'

export const updateAuditReducers = [
  on(updateAuditActions.updateAudit, (state: AuditsState) => ({
    ...state,
    loading: getUpdateAuditInProgressLoadingState(state.loading)
  })),
  on(
    updateAuditActions.updateAuditSuccess,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: getUpdateAuditCompletedLoadingState(state.loading)
    })
  ),
  on(
    updateAuditActions.updateAuditFail,
    (state: AuditsState, { error }): AuditsState => ({
      ...state,
      loading: getUpdateAuditFailedLoadingState(state.loading, {}, error)
    })
  ),

  on(updateAuditActions.updateAuditCharges, (state: AuditsState) => ({
    ...state,
    loading: getUpdateAuditInProgressLoadingState(state.loading)
  })),
  on(
    updateAuditActions.updateAuditChargesSuccess,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: getUpdateAuditCompletedLoadingState(state.loading)
    })
  ),
  on(
    updateAuditActions.updateAuditChargesFail,
    (
      state: AuditsState,
      { error, isMissingOrderAuditDocument, isMissingVendorInvoiceCharges }
    ): AuditsState => {
      return {
        ...state,
        loading: getUpdateAuditFailedLoadingState(
          state.loading,
          {
            redirectToListPage: isMissingOrderAuditDocument,
            showConfirmationModal: isMissingVendorInvoiceCharges
          },
          error
        )
      }
    }
  ),

  on(
    updateAuditActions.resetUpdateAuditLoadingState,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.updateAudit,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    updateAuditActions.addAuditChargesMappings,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.addAuditChargesMappings,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    updateAuditActions.addAuditChargesMappingsSuccess,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.addAuditChargesMappings,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(
    updateAuditActions.addAuditChargesMappingsFail,
    (state: AuditsState, { error }): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.addAuditChargesMappings,
        status: LoadingStatuses.Failed,
        message:
          String(error) ??
          'There was an error trying to add audit charges mappings.'
      })
    })
  ),
  on(
    updateAuditActions.resetAddAuditChargesMappingsLoadingState,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.addAuditChargesMappings,
        status: LoadingStatuses.NotStarted
      })
    })
  ),

  //Update Audit Charges V2
  on(updateAuditActions.updateAuditChargesV2, (state: AuditsState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AuditAsyncOperations.updateAuditChargesV2,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    updateAuditActions.updateAuditChargesV2Success,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.updateAuditChargesV2,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(
    updateAuditActions.updateAuditChargesV2Fail,
    (
      state: AuditsState,
      { error, isMissingOrderAuditDocument, isMissingVendorInvoiceCharges }
    ): AuditsState => {
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AuditAsyncOperations.updateAuditChargesV2,
          status: LoadingStatuses.Failed,
          message: error ?? 'There was an error trying to update the charges.',
          metadata: {
            redirectToListPage: isMissingOrderAuditDocument,
            showConfirmationModal: isMissingVendorInvoiceCharges
          }
        })
      }
    }
  ),
  on(
    updateAuditActions.resetupdateAuditChargesV2LoadingState,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.updateAuditChargesV2,
        status: LoadingStatuses.NotStarted
      })
    })
  ),

  //Update Audit Vendor
  on(updateAuditActions.updateAuditVendor, (state: AuditsState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AuditAsyncOperations.updateAuditVendor,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    updateAuditActions.updateAuditVendorSuccess,
    (state: AuditsState, { newVendorId }): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.updateAuditVendor,
        status: LoadingStatuses.Completed,
        metadata: { newVendorId }
      })
    })
  ),
  on(
    updateAuditActions.updateAuditVendorFail,
    (
      state: AuditsState,
      { error, isMissingOrderAuditDocument, isMissingVendorInvoiceCharges }
    ): AuditsState => {
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AuditAsyncOperations.updateAuditVendor,
          status: LoadingStatuses.Failed,
          message: error ?? 'There was an error trying to update the vendor.',
          metadata: {
            redirectToListPage: isMissingOrderAuditDocument,
            showConfirmationModal: isMissingVendorInvoiceCharges
          }
        })
      }
    }
  ),
  on(
    updateAuditActions.resetUpdateAuditVendorLoadingState,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.updateAuditVendor,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]

const getUpdateAuditInProgressLoadingState = (
  state: AuditsLoadingState
): AuditsLoadingState =>
  updateLoadingState(state, {
    operation: AuditAsyncOperations.updateAudit,
    status: LoadingStatuses.InProgress
  })

const getUpdateAuditCompletedLoadingState = (
  state: AuditsLoadingState
): AuditsLoadingState =>
  updateLoadingState(state, {
    operation: AuditAsyncOperations.updateAudit,
    status: LoadingStatuses.Completed
  })

const getUpdateAuditFailedLoadingState = (
  state: AuditsLoadingState,
  metadata: AuditsLoadingState['updateAudit']['metadata'],
  errorMessage?: string
): AuditsLoadingState =>
  updateLoadingState(state, {
    operation: AuditAsyncOperations.updateAudit,
    status: LoadingStatuses.Failed,
    message: errorMessage ?? 'There was an error trying to update the audit.',
    metadata
  })
