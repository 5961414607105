import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  ORDERS_FEATURE_KEY,
  OrdersState,
  ordersAdapter
} from './orders.reducer'

export const selectOrdersState =
  createFeatureSelector<OrdersState>(ORDERS_FEATURE_KEY)

const { selectAll, selectEntities } = ordersAdapter.getSelectors()

export const selectAllOrders = createSelector(
  selectOrdersState,
  (state: OrdersState) => selectAll(state)
)

export const selectAllOrdersAsDictionary = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.entities
)

export const selectOrdersEntities = createSelector(
  selectOrdersState,
  (state: OrdersState) => selectEntities(state)
)

export const selectSelectedId = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.selectedId
)

export const selectTotalCount = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.totalCount
)

export const selectLoading = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.loading
)

export const selectEntity = createSelector(
  selectOrdersEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
)

export const selectFilters = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.filters
)

export const selectDivisions = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.divisions
)

