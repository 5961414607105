import { IStrategy } from '@navix/utils/adapter'
import { Invoice } from '../../domain/invoices//invoice.model'
import { GetInvoiceTenantResponse } from '../../domain/invoices/get-invoice-tenant.response'

export class FromGetInvoiceTenantResponse
  implements IStrategy<GetInvoiceTenantResponse, Invoice>
{
  convert(response: GetInvoiceTenantResponse): Invoice {
    const NO_EXISTING_TENANT_ID = -1
    return <Invoice>{
      tenant: {
        id:
          response.tenantId == undefined
            ? NO_EXISTING_TENANT_ID
            : response.tenantId,
        hasAccessToInvoice: response.hasAccessToInvoice
      }
    }
  }
}
