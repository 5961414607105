import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import {
  EMPTY,
  catchError,
  exhaustMap,
  finalize,
  iif,
  map,
  of,
  switchMap,
  tap
} from 'rxjs'
import { InvoicesService } from '../../../../infrastructure/invoices.service'
import { TypeOfException } from '../../../../domain/invoices/type-of-exception.model'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { hardcodedTypesOfExceptionsData } from '../../static'
import { invoicesTypesActions } from '../types.actions'
import { selectTypesOfExceptions } from '../../invoices.selectors'
import { Store } from '@ngrx/store'
import { AsyncOperations } from '../../../../domain/invoices/invoices-loading.model'
import { InvoicesFacade } from '../../invoices.facade'

@Injectable()
export class TypesOfExceptionsEffects {
  private actions$ = inject(Actions)
  private invoicesFacade = inject(InvoicesFacade)
  private store = inject(Store)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)

  loadTypesOfExceptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesTypesActions.loadTypesOfExceptions),
      concatLatestFrom(() => this.store.select(selectTypesOfExceptions)),
      switchMap(([payload, typesOfExceptions]) =>
        iif(
          () => typesOfExceptions.length === 0,
          of(payload),
          EMPTY.pipe(
            finalize(() => {
              this.store.dispatch({ type: `[CACHED]${payload.type}` })
              this.invoicesFacade.endLoading(AsyncOperations.getDisputedReasons)
            })
          )
        )
      ),
      exhaustMap(() =>
        this.invoicesService.getTypesOfExceptions().pipe(
          map(response =>
            response
              .map(item => {
                if (
                  !(hardcodedTypesOfExceptionsData ?? {})[
                    item?.exceptionCategoryId ?? ''
                  ]
                )
                  return undefined
                return <TypeOfException>{
                  id: item.exceptionCategoryId,
                  name:
                    item.name ??
                    hardcodedTypesOfExceptionsData[item.exceptionCategoryId]
                      .description,
                  icon: hardcodedTypesOfExceptionsData[item.exceptionCategoryId]
                    .icon,
                  sortPosition:
                    hardcodedTypesOfExceptionsData[item.exceptionCategoryId]
                      .sortPosition
                }
              })
              .filter<TypeOfException>(
                (item): item is TypeOfException => item !== undefined
              )
              .sort((a, b) => a.sortPosition - b.sortPosition)
          ),
          map(typesOfExceptions =>
            invoicesTypesActions.loadTypesOfExceptionsSuccess({
              typesOfExceptions
            })
          ),
          catchError(error =>
            of(invoicesTypesActions.loadTypesOfExceptionsFail({ error }))
          )
        )
      )
    )
  )

  loadTypesOfExceptionsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoicesTypesActions.loadTypesOfExceptionsFail),
        tap(error => {
          console.error(error)
        }),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load types of exceptions."
          })
        })
      ),
    { dispatch: false }
  )
}
