import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { ManageLineItemsRequest } from '../../domain/invoices/manage-line-items.request'

export function toManageLineItemsRequest(
  request: InvoiceForm['manageLineItems']
): ManageLineItemsRequest {
  if (!Array.isArray(request.lineItems)) return []

  const result = request.lineItems.map(lineItem => ({
    orderLineItemId:
      lineItem.orderLineItemId === 'new' ? null : lineItem.orderLineItemId,
    class: lineItem.classCode?.toString() ?? undefined,
    description: lineItem.description,
    dimUnits: lineItem.messureUnitTypes,
    handlingUnit: Number(lineItem.handlingUnitTypeId),
    handlingUnitCount: lineItem.handlingUnitCount,
    height: lineItem.height,
    length: lineItem.length,
    nmfcCode: lineItem.nmfcCode === '' ? undefined : lineItem.nmfcCode,
    totalPieces: lineItem.totalPieces,
    weight: lineItem.weight,
    weightUnit: lineItem.weightUnit,
    width: lineItem.width,
    isStack: lineItem.isStack
  }))

  return result as ManageLineItemsRequest
}
