//todo(ladaj): discuss if valid to hace a separate model for single order and other one for order-centric.

import { OrderCharge } from './order-charge.model'

//TODO(ladaj): This file needs to be refactored
export type Order = {
  billType: string
  createDate: Date | null
  customerCharge: number
  customerId: number
  destinationCity: string
  destinationName: string
  destinationState: string
  destinationZip: string
  freightChargeTermsDescription: string
  mode: string
  originCity: string
  originName: string
  originState: string
  originZip: string
  serviceLevel: string
  serviceType: string
  shipmentNumber: string
  invoiceNumber: string
  stops: {
    hasMultipleStops: boolean
    firstOrigin?: OrderStop
    origins: OrderStop[]
    firstDestination?: OrderStop
    destinations: OrderStop[]
    billTo?: OrderStop
    remitTo?: OrderStop
  }
  tenantName: string
  vendorCharge: number
  weight: string
  withDetails: boolean
  invoices: {
    vendorName: string
    vendorId: number | undefined
    vendorExternalId: string | undefined
    vendorUuid: string | undefined
    invoiceId: number
    invoiceUuid: string
    invoiceTypeId?: number
    invoiceType?: string
    invoiceNumber?: string
    statusId: number
    statusDescription: string
    statusDate: Date
  }[]
  lineItems: LineItem[]
  referenceNumbers: {
    typeId: number
    typeDescription: string
    referenceNumber: string
  }[]

  // MULTIPLE VENDORS
  id: string
  legacyId: number
  metadata: string
  orderNumber: string
  shipDate: Date | null
  deliveryDate: Date | null
  vendors: {
    vendorId?: number
    externalId: string
    name: string
    chargeTerms: string
    paymentTerms?: string
    billTo: Address
    remitTo: Address
    service: {
      mode: string
      type: string
      level: string
    }
    charges: OrderCharge[]
    purchaseInvoices: {
      uuid: string
      invoiceUuid: string | undefined
      charges: OrderCharge[]
      rawCharges: {
        chargeAmount: number
        code: string
        description: string
      }[]
      status: string
    }[]
    stops: (Address & {
      typeDescription: 'Origin' | 'Destination' | 'Bill To' | 'Remit To'
      sequence: number
    })[]
  }[]
  customer?: {
    name: string
    externalId: string
  }
  vendorCharges: (OrderCharge & { vendorName?: string })[]
  customerCharges: OrderCharge[]

  lineItems2: {
    id: string
    class: number
    cube: number
    density: number
    dimensionUnitDescription: string
    handlingUnitCount: number
    handlingUnitDescription: string
    height: number
    length: number
    name: string
    nmfcCode: string
    pieces: number
    stackable: boolean
    weight: number
    weightUnitDescription: string
    width: number
  }[]
  referenceNumbers2: {
    typeDescription:
      | 'Order Number'
      | 'Shipment Number'
      | 'BOL Number'
      | 'SO Number'
      | 'General Reference Number'
      | 'PO Number'
    description: string
    value: string
  }[]
  stops2: (Address & {
    typeDescription: 'Origin' | 'Destination' | 'Bill To' | 'Remit To'
    sequence: number
  })[]
  division: string
}

export enum OrderStopTypes {
  Origin = 'Origin',
  Destination = 'Destination',
  BillTo = 'Bill To',
  RemitTo = 'Remit To'
}

export type OrderStop = {
  typeDescription: OrderStopTypes
  name: string
  address1: string
  address2: string
  city: string
  stateCode: string
  zip: string
  countryCode: string
}

type LineItem = {
  class: number
  cube: number
  density: number
  dimensionUnitDescription: string
  handlingUnitCount: number
  handlingUnitDescription: string
  height: number
  length: number
  name: string
  nmfcCode: string
  pieces: number
  stack: string
  weight: number
  weightUnitDescription: string
  width: number
}

export type Address = {
  name: string | undefined
  address1: string
  address2: string | undefined
  city: string
  stateCode: string
  countryCode: string
  zip: string
}
