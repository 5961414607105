import { OrderStatusFilter } from '../domain/orders/data-filters.model'

export const ordersFilterTabsDataObj: Readonly<{
  [key in 'customers' | 'vendors']: {
    label: string
    key: key
    skipFor: ReadonlyArray<OrderStatusFilter>
    readOnlyFor: ReadonlyArray<OrderStatusFilter>
  }
}> = {
  customers: {
    key: 'customers',
    label: 'Customers',
    skipFor: [],
    readOnlyFor: []
  },
  vendors: { key: 'vendors', label: 'Vendors', skipFor: [], readOnlyFor: [] }
} as const

export const ordersFilterTabsData = Object.values(ordersFilterTabsDataObj)
