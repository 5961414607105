import { Injectable } from '@angular/core'
import { Invoice, InvoiceStop } from '../domain/invoices/invoice.model'
import { Order } from '../domain/orders/order.model'

const REQUIRED_DOCUMENT_TYPES = [6, 4, 2, 7, 9]

enum ReferenceNumberIds {
  OrderNumber = 1,
  ShipmentNumber = 7,
  BOLNumber = 2,
  PONumber = 3
}

@Injectable({
  providedIn: 'root'
})
export class EmailTemplatesService {
  getPlaceholderValues(
    invoice: Invoice,
    order: Order | undefined,
    documentTypes: {
      typeId: number
      description: string
      documentIds: number[]
    }[],
    tenantName: string
  ): Record<string, string> {
    const netCharges = this.getNetCharge(invoice.legacyCharges.vendor)
    return {
      '[6, 4, 2, 7, 9]': `<ul>${this.getMissingDocumentTypes(documentTypes)}</ul>`,
      '[TENANTNAME]': tenantName ?? '',
      '[VENDORNAME]': invoice.vendorDescription ?? '',
      '[INVOICENUMBER]': invoice.invoiceNumber ?? '',
      '[SHIPMENTNUMBER]':
        this.getReferenceNumberFromInvoice(
          invoice.referenceNumbers,
          ReferenceNumberIds[ReferenceNumberIds.ShipmentNumber]
        ) ?? '',
      '[ORDERNUMBER]':
        this.getOrderReferenceNumberFromOrder(order?.referenceNumbers ?? []) ??
        this.getReferenceNumberFromInvoice(
          invoice.referenceNumbers,
          ReferenceNumberIds[ReferenceNumberIds.OrderNumber]
        ) ??
        '',
      '[CUSTOMERNAME]': invoice.customerDescription ?? '',
      '[BILLTONAME]': invoice.stops.billTo.name ?? '',
      '[BILLTOADDRESS1]': invoice.stops.billTo.address1 ?? '',
      '[BILLTOCITY]': invoice.stops.billTo.city ?? '',
      '[BILLTOSTATE]': invoice.stops.billTo.stateCode ?? '',
      '[BILLTOZIP]': invoice.stops.billTo.zip ?? '',
      '[BILLTOCOUNTRY]': invoice.stops.billTo.countryCode ?? '',
      '[ORDERWEIGHT]':
        this.sumWeightAndUnit(
          order?.lineItems?.map(x => ({
            weight: x.weight,
            weightUnit: x.weightUnitDescription
          })) ?? []
        ) ?? '',
      '[INVOICEWEIGHT]':
        this.sumWeightAndUnit(
          invoice.lineItems.map(x => ({
            weight: x.weight,
            weightUnit: x.weightUnit
          }))
        ) ?? '[INVOICE WEIGHT]',
      '[PONUMBER]':
        this.getReferenceNumberFromInvoice(
          invoice.referenceNumbers,
          ReferenceNumberIds[ReferenceNumberIds.PONumber]
        ) ?? '',
      '[BOLNUMBER]':
        this.getReferenceNumberFromInvoice(
          invoice.referenceNumbers,
          ReferenceNumberIds[ReferenceNumberIds.BOLNumber]
        ) ?? '',
      '[ORIGINCITY]': invoice.stops.firstOrigin.city ?? '',
      '[ORIGINSTATE]': invoice.stops.firstOrigin.stateCode ?? '',
      '[ORIGINADDRESS]': this.getFullAddress(invoice.stops.firstOrigin),
      '[DESTCITY]': invoice.stops.lastDestination.city ?? '',
      '[DESTSTATE]': invoice.stops.lastDestination.stateCode ?? '',
      '[DESTADDRESS]': this.getFullAddress(invoice.stops.lastDestination),
      '[SHIPDATE]': this.formatDate(invoice.shipDate),
      '[INVOICECLASS]':
        Array.from(
          new Set(invoice.lineItems.map(x => x.class).filter(x => x != null))
        ).join(', ') ?? '',
      '[ORDERCLASS]':
        Array.from(
          new Set(order?.lineItems.map(x => x.class).filter(x => x != null))
        ).join(', ') ?? '',
      '[ORDERNETCHARGE]': netCharges.orderAmount?.toString(),
      '[INVOICENETCHARGE]': netCharges.adjustedComparerAmount?.toString(),
      '[NETCHARGEVARIANCE]': netCharges.difference?.toString()
    }
  }

  replacePlaceholders(
    template: string,
    values: Record<string, string>
  ): string {
    let result = template

    for (const key in values) {
      const placeholder = new RegExp(this.escapeRegExp(key), 'g')
      result = result.replace(placeholder, values[key])
    }

    return result
  }

  private escapeRegExp(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  }

  private getMissingDocumentTypes = (
    documentTypes: {
      typeId: number
      description: string
      documentIds: number[]
    }[]
  ): string => {
    const missingDocumentTypeIds = REQUIRED_DOCUMENT_TYPES.filter(typeId =>
      documentTypes.some(x => x.documentIds.length === 0 && x.typeId === typeId)
    )

    return missingDocumentTypeIds
      .map(typeId => documentTypes.find(type => type.typeId === typeId))
      .map(documentType => `<li><p>${documentType?.description}</p></li>`)
      .join('')
  }

  private getOrderReferenceNumberFromOrder(
    referenceNumbers: Order['referenceNumbers']
  ): string | undefined {
    const filteredReferences = referenceNumbers.filter(
      ref => ref.typeId === ReferenceNumberIds.OrderNumber
    )
    const sortedReferences = filteredReferences.sort((a, b) =>
      a.referenceNumber.localeCompare(b.referenceNumber)
    )
    return sortedReferences[0]?.referenceNumber
  }

  private sumWeightAndUnit(
    lineItems: { weight: number | undefined; weightUnit: string }[]
  ): string {
    const unit = lineItems[0]?.weightUnit
    const sum = lineItems.reduce(
      (acc, lineItem) => acc + (lineItem.weight ?? 0),
      0
    )
    return `${Math.round(sum)} ${unit}`
  }

  private getReferenceNumberFromInvoice(
    referenceNumbers: Invoice['referenceNumbers'],
    typeId: string
  ): string {
    const filteredReferences = referenceNumbers.filter(
      ref => ref.typeId === typeId
    )
    const sortedTags = filteredReferences
      .flatMap(({ tags }) => tags.map(({ value }) => value))
      .sort((compare, comparer) => compare.localeCompare(comparer))
    return sortedTags[0]
  }

  private getFullAddress(stop: InvoiceStop) {
    const { name, address1, address2, city, stateCode, zip, countryCode } =
      stop ?? {}

    return `${name ?? ''} ${address1 ?? ''} ${address2 ?? ''} ${city ?? ''}${city ? ',' : ''} ${stateCode ?? ''} ${zip ?? ''} ${countryCode ?? ''}`
  }

  private formatDate(date: Date | undefined) {
    if (date) {
      const month = date.getMonth() + 1
      const day = date.getDate()
      const year = date.getFullYear()

      const formattedDate = `${month}/${day}/${year}`

      return formattedDate
    }

    return 'N/A'
  }

  getNetCharge(charges: Invoice['legacyCharges']['vendor']) {
    const result = Object.entries(charges).reduce(
      (acc, [key, charge]) => {
        const isAccessorialWithDetails =
          key === 'accessorials' && charge.details && charge.details.length > 0

        if (isAccessorialWithDetails) {
          charge.details.forEach(detail => {
            acc.orderAmount += detail.orderAmount || 0
            acc.adjustedComparerAmount += detail.adjustedComparerAmount || 0
            acc.difference +=
              (detail.orderAmount || 0) - (detail.adjustedComparerAmount || 0)
          })
        } else {
          acc.orderAmount += charge.orderAmount || 0
          acc.adjustedComparerAmount += charge.adjustedComparerAmount || 0
          acc.difference +=
            (charge.orderAmount || 0) - (charge.adjustedComparerAmount || 0)
        }

        return acc
      },
      { orderAmount: 0, adjustedComparerAmount: 0, difference: 0 }
    )

    return {
      orderAmount: result.orderAmount.toFixed(2),
      adjustedComparerAmount: result.adjustedComparerAmount.toFixed(2),
      difference: result.difference.toFixed(2)
    }
  }
}
