/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { platform_v2 } from '@navix/api-models'
import { updateAuditActions } from '../../+state/audits/update-audit/update-audit.actions'

type NormalizedCharge = {
  type: string | null | undefined
  code: string | null | undefined
  description: string | null | undefined
  charge: number | null | undefined
  currentCharge: number | null | undefined
}

export function toAddAuditChargesMappingsRequest(
  payload: Parameters<typeof updateAuditActions.addAuditChargesMappings>[0],
  auditDocument: platform_v2.Navix_Services_Sdk_Audit
): platform_v2.Navix_Services_Sdk_Audit {
  const mappingMatchFn = (charge: NormalizedCharge) => {
    return (mapping: (typeof payload)['customerAdjustedMappings'][0]) => {
      const isTypeMatch =
        mapping.previousChargeTypeUuid ===
        (charge.type ?? payload.unknownChargeUuid)

      const isCodeMatch =
        mapping.code === charge.code ||
        (mapping.code === 'NO CODE' &&
          (charge.code === null || charge.code === undefined))

      const isDescriptionMatch =
        mapping.description === '' ||
        mapping.description === undefined ||
        mapping.description === null
          ? true
          : mapping.description === charge.description

      const isOriginalChargeMatch = isNaN(mapping.originalCharge)
        ? true
        : mapping.originalCharge === charge.charge

      const isAdjustedChargeMatch =
        isNaN(mapping.currentCharge) ||
        charge.currentCharge == null ||
        isNaN(charge.currentCharge)
          ? true
          : mapping.currentCharge === charge.currentCharge

      const isMatch =
        isTypeMatch &&
        isCodeMatch &&
        isDescriptionMatch &&
        isOriginalChargeMatch &&
        isAdjustedChargeMatch

      return isMatch
    }
  }
  const filterAdjustedCharges = (
    adjustments: platform_v2.Navix_Services_Sdk_ChargeAdjustment[],
    mappings:
      | (typeof payload)['customerAdjustedMappings']
      | (typeof payload)['vendorAdjustedMappings'],
    negate = false
  ) => {
    const matches: string[] = []
    return adjustments.filter(charge => {
      const chargeMatchFn = mappingMatchFn({
        charge: Number(charge.originalCharge),
        currentCharge: Number(charge.adjustment),
        code: charge.code!,
        description: charge.description ?? '',
        type: charge.type!
      })
      const isMatch = mappings.some(mapping => {
        const uniqueKey = `${mapping.previousChargeTypeUuid}-${mapping.code}-${mapping.description}-${mapping.originalCharge}-${mapping.currentCharge}`
        if (matches.includes(uniqueKey)) return false
        const isMatch = chargeMatchFn(mapping)
        if (isMatch) matches.push(uniqueKey)
        return isMatch
      })
      return negate ? !isMatch : isMatch
    })
  }

  const filterOrderCharges = (
    charges: platform_v2.Navix_Services_Sdk_AuditUserTypeMappedCharge[],
    mappings:
      | (typeof payload)['vendorOrderMappings']
      | (typeof payload)['customerOrderMappings'],
    negate = false
  ) => {
    const matches: string[] = []
    return charges.filter(charge => {
      const chargeMatchFn = mappingMatchFn({
        type: charge.type,
        code: charge.code,
        description: charge.description,
        charge: charge.charge,
        currentCharge: charge.charge
      })
      const isMatch = mappings.some(mapping => {
        const uniqueKey = `${mapping.previousChargeTypeUuid}-${mapping.code}-${mapping.description}-${mapping.originalCharge}-${mapping.currentCharge}`
        if (matches.includes(uniqueKey)) return false
        const isMatch = chargeMatchFn(mapping)
        if (isMatch) matches.push(uniqueKey)
        return isMatch
      })
      return negate ? !isMatch : isMatch
    })
  }

  const customerOrderCharges =
    auditDocument.customer?.userTypeMappedCharges ?? []

  const customerOrderChargesWithoutMapping = filterOrderCharges(
    customerOrderCharges,
    payload.customerOrderMappings,
    true
  )

  const customerOrderChargesWithMapping = filterOrderCharges(
    customerOrderCharges,
    payload.customerOrderMappings
  )

  const customer = <platform_v2.Navix_Services_Sdk_AuditCustomer>{
    ...auditDocument.customer,
    userTypeMappedCharges: [
      ...customerOrderChargesWithoutMapping,
      ...customerOrderChargesWithMapping.map(charge => ({
        ...charge,
        type:
          payload.customerOrderMappings.find(
            mappingMatchFn({
              type: charge.type,
              code: charge.code,
              description: charge.description,
              charge: charge.charge,
              currentCharge: charge.charge
            })
          )?.chargeTypeUuid ?? charge.type
      }))
    ],
    chargeCodeMappings: {
      ...auditDocument.customer?.chargeCodeMappings,
      ...payload.customerOrderMappings.reduce((acc, mapping) => {
        const existingMappingUuids = Object.keys(
          auditDocument.customer?.chargeCodeMappings ?? {}
        )
        if (existingMappingUuids.includes(mapping.chargeTypeUuid)) {
          return acc
        }
        return {
          ...acc,
          [mapping.chargeTypeUuid]: {
            code: mapping.tenantChargeCode,
            description: mapping.tenantChargeDescription
          }
        }
      }, {})
    }
  }

  const invoices =
    payload.vendorExternalId !== undefined
      ? auditDocument.vendors?.[payload.vendorExternalId]?.invoices ?? []
      : auditDocument.provisionalInvoices ?? []
  const invoiceWithMappings = invoices.find(
    invoice => invoice.source === payload.invoiceUuid
  )
  const invoiceWithoutMappings = invoices.filter(
    invoice => invoice.source !== payload.invoiceUuid
  )

  const customerAdjustments =
    invoiceWithMappings?.charges?.customer?.chargeAdjustments ?? []
  const vendorAdjustments =
    invoiceWithMappings?.charges?.vendor?.chargeAdjustments ?? []

  const customerAdjustedChargesWithoutMapping = filterAdjustedCharges(
    customerAdjustments,
    payload.customerAdjustedMappings,
    true
  )
  const customerAdjustedChargesWithMapping = filterAdjustedCharges(
    customerAdjustments,
    payload.customerAdjustedMappings
  )

  const vendorAdjustedChargesWithoutMapping = filterAdjustedCharges(
    vendorAdjustments,
    payload.vendorAdjustedMappings,
    true
  )
  const vendorAdjustedChargesWithMapping = filterAdjustedCharges(
    vendorAdjustments,
    payload.vendorAdjustedMappings
  )

  const hasExternalId =
    payload.vendorExternalId !== undefined && payload.vendorExternalId !== null
  const vendorOrderChargesWithoutMapping = hasExternalId
    ? filterOrderCharges(
        auditDocument.vendors?.[payload.vendorExternalId!]
          ?.userTypeMappedCharges ?? [],
        payload.vendorOrderMappings,
        true
      )
    : []

  const vendorOrderChargesWithMapping = hasExternalId
    ? filterOrderCharges(
        auditDocument.vendors?.[payload.vendorExternalId!]
          ?.userTypeMappedCharges ?? [],
        payload.vendorOrderMappings
      )
    : []

  const invoicesWithNewMappings: platform_v2.Navix_Services_Sdk_AuditInvoice[] =
    [
      ...invoiceWithoutMappings,
      {
        ...invoiceWithMappings,

        charges: {
          vendor: {
            chargeAdjustments: [
              ...vendorAdjustedChargesWithoutMapping,
              ...vendorAdjustedChargesWithMapping.map(charge => ({
                ...charge,
                type:
                  payload.vendorAdjustedMappings.find(
                    mappingMatchFn({
                      charge: Number(charge.originalCharge),
                      currentCharge: Number(charge.adjustment),
                      code: charge.code!,
                      description: charge.description!,
                      type: charge.type!
                    })
                  )?.chargeTypeUuid ?? charge.type
              }))
            ]
          },
          customer: {
            chargeAdjustments: [
              ...customerAdjustedChargesWithoutMapping,
              ...customerAdjustedChargesWithMapping.map(charge => ({
                ...charge,
                type:
                  payload.customerAdjustedMappings.find(
                    mappingMatchFn({
                      charge: Number(charge.originalCharge),
                      currentCharge: Number(charge.adjustment),
                      code: charge.code!,
                      description: charge.description!,
                      type: charge.type!
                    })
                  )?.chargeTypeUuid ?? charge.type
              }))
            ]
          }
        }
      }
    ]

  const newAudit = <platform_v2.Navix_Services_Sdk_Audit>{
    ...auditDocument,
    customer,
    provisionalInvoices:
      payload.vendorExternalId !== undefined
        ? auditDocument.provisionalInvoices
        : invoicesWithNewMappings,
    vendors: {
      ...auditDocument.vendors,
      ...(payload.vendorExternalId !== undefined
        ? {
            [payload.vendorExternalId]: {
              chargeCodeMappings: {
                ...auditDocument.vendors?.[payload.vendorExternalId]
                  ?.chargeCodeMappings,
                ...payload.vendorAdjustedMappings
                  .concat(payload.customerAdjustedMappings)
                  .concat(payload.vendorOrderMappings)
                  .reduce((acc, mapping) => {
                    const existingMappingUuids = Object.keys(
                      auditDocument.vendors?.[payload.vendorExternalId!]
                        ?.chargeCodeMappings ?? {}
                    )
                    if (existingMappingUuids.includes(mapping.chargeTypeUuid)) {
                      return acc
                    }
                    return {
                      ...acc,
                      [mapping.chargeTypeUuid]: {
                        code: mapping.tenantChargeCode,
                        description: mapping.tenantChargeDescription
                      }
                    }
                  }, {})
              },
              ...auditDocument.vendors?.[payload.vendorExternalId],
              invoices: invoicesWithNewMappings,
              userTypeMappedCharges: [
                ...vendorOrderChargesWithoutMapping,
                ...vendorOrderChargesWithMapping.map(charge => {
                  return {
                    ...charge,
                    type:
                      payload.vendorOrderMappings.find(
                        mappingMatchFn({
                          type: charge.type,
                          code: charge.code,
                          description: charge.description,
                          charge: charge.charge,
                          currentCharge: charge.charge
                        })
                      )?.chargeTypeUuid ?? charge.type
                  }
                })
              ]
            }
          }
        : {})
    }
  }

  return newAudit
}
