import { Dialog } from '@angular/cdk/dialog'
import { Injectable, inject } from '@angular/core'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { InvoicesAdapter } from '../../../adapter/invoices/InvoicesAdapter'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { InvoicesFacade } from '../invoices.facade'

import { LoadingStatuses } from '@navix/shared/loading'
import {
  catchError,
  debounceTime,
  exhaustMap,
  map,
  of,
  switchMap,
  tap
} from 'rxjs'
import { FromGetVendorInvoiceNotesResponse } from '../../../adapter/invoices/FromGetInvoiceNotesResponse'
import { ToAddInvoiceNotesRequest } from '../../../adapter/invoices/ToAddInvoiceNotesRequest'
import { ToUpdateInvoiceNotesRequest } from '../../../adapter/invoices/ToUpdateInvoiceNotesRequest'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { invoiceDetailsActions } from '../invoice-details/invoice-details.actions'
import { invoicesActions } from '../invoices.actions'
import {
  ADD_INVOICE_NOTES_DIALOG,
  DELETE_INVOICE_NOTES_DIALOG,
  EDIT_INVOICE_NOTES_DIALOG
} from '../invoices.constants'
import { invoiceNotesActions } from './invoice-notes.actions'

@Injectable()
export class VendorInvoiceNotesEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)
  private invoicesFacade = inject(InvoicesFacade)
  private dialog = inject(Dialog)
  private invoiceAdapter = new InvoicesAdapter()

  constructor() {
    this.invoiceAdapter.set(FromGetVendorInvoiceNotesResponse)
    this.invoiceAdapter.set(ToAddInvoiceNotesRequest)
    this.invoiceAdapter.set(ToUpdateInvoiceNotesRequest)
  }

  loadInvoiceNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceNotesActions.loadInvoiceNotes),
      exhaustMap(({ invoiceId }) =>
        this.invoicesService.getInvoiceNotes(invoiceId).pipe(
          map(response =>
            this.invoiceAdapter.convert(
              FromGetVendorInvoiceNotesResponse,
              response
            )
          ),
          map(notes =>
            invoiceNotesActions.loadInvoiceNotesSuccess({
              notes,
              invoiceId
            })
          ),
          catchError(error =>
            of(invoiceNotesActions.loadInvoiceNotesFail({ error }))
          )
        )
      )
    )
  )

  loadInvoiceNotesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceNotesActions.loadInvoiceNotesFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load vendor invoice notes."
          })
        })
      ),
    { dispatch: false }
  )

  updateInvoiceNotesMarkAsComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceNotesActions.updateInvoiceNotesMarkAsComplete),
      tap(() =>
        this.invoicesFacade.startLoading(
          AsyncOperations.updateInvoiceNotesMarkAsComplete
        )
      ),
      exhaustMap(({ invoiceId, note }) =>
        this.invoicesService.updateInvoiceNotesMarkAsComplete(note.id).pipe(
          map(() =>
            invoiceNotesActions.updateInvoiceNotesMarkAsCompleteSuccess({
              invoiceId,
              isDisputed: note.isDisputed
            })
          ),
          catchError(error =>
            of(
              invoiceNotesActions.updateInvoiceNotesMarkAsCompleteFail({
                error
              })
            )
          ),
          tap(() =>
            this.invoicesFacade.endLoading(
              AsyncOperations.updateInvoiceNotesMarkAsComplete
            )
          )
        )
      )
    )
  )

  updateInvoiceNotesMarkAsCompleteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceNotesActions.updateInvoiceNotesMarkAsCompleteSuccess),
      tap(({ isDisputed }) => {
        const label = isDisputed
          ? 'Dispute has been updated successfully.'
          : 'Note has been updated successfully.'

        this.alertsFacade.addAlert({
          label,
          alertType: 'success'
        })
      }),
      switchMap(({ invoiceId }) =>
        of(
          invoiceNotesActions.loadInvoiceNotes({
            invoiceId
          }),
          invoiceDetailsActions.loadInvoiceDetails({
            invoiceId
          })
        )
      )
    )
  )

  updateInvoiceNotesMarkAsCompleteFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceNotesActions.updateInvoiceNotesMarkAsCompleteFail),
        tap(error => console.error(error)),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : `There's an error trying to update invoice notes as complete.`
          })
        })
      ),
    { dispatch: false }
  )

  addInvoiceNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceNotesActions.addInvoiceNotes),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.addInvoiceNotes)
      ),
      map(({ invoice }) => ({
        request: this.invoiceAdapter.convert(ToAddInvoiceNotesRequest, invoice),
        invoice
      })),
      exhaustMap(({ request, invoice }) =>
        this.invoicesService.addInvoiceNotes(request).pipe(
          map(() =>
            invoiceNotesActions.addInvoiceNotesSuccess({
              invoice,
              isDisputed: request.isDisputed
            })
          ),
          catchError(error =>
            of(
              invoiceNotesActions.addInvoiceNotesFail({
                error
              })
            )
          ),
          tap(() =>
            this.invoicesFacade.endLoading(AsyncOperations.addInvoiceNotes)
          )
        )
      )
    )
  )

  addInvoiceNotesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceNotesActions.addInvoiceNotesSuccess),
      tap(({ isDisputed }) => {
        const label = isDisputed
          ? `Dispute was added successfully.`
          : `Note was added successfully.`

        this.alertsFacade.addAlert({
          label,
          alertType: 'success'
        })
      }),
      tap(() => this.dialog.getDialogById(ADD_INVOICE_NOTES_DIALOG)?.close()),
      switchMap(({ invoice }) =>
        of(
          invoiceNotesActions.loadInvoiceNotes({
            invoiceId: invoice.id ?? 0
          }),
          invoiceDetailsActions.loadInvoiceDetails({
            invoiceId: invoice.id ?? 0
          })
        )
      )
    )
  )

  addInvoiceNotesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceNotesActions.addInvoiceNotesFail),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''

          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : "There's an error trying to create vendor invoice note."
          })
        })
      ),
    { dispatch: false }
  )

  updateInvoiceNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceNotesActions.updateInvoiceNotes),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.updateInvoiceNotes)
      ),
      map(({ invoice }) => ({
        request: this.invoiceAdapter.convert(
          ToUpdateInvoiceNotesRequest,
          invoice
        ),
        invoice
      })),
      exhaustMap(({ request, invoice }) =>
        this.invoicesService.updateInvoiceNotes(request).pipe(
          map(() =>
            invoiceNotesActions.updateInvoiceNotesSuccess({
              invoice,
              isDisputed: request.isDisputed
            })
          ),
          catchError(error =>
            of(
              invoiceNotesActions.updateInvoiceNotesFail({
                error
              })
            )
          ),
          tap(() =>
            this.invoicesFacade.endLoading(AsyncOperations.updateInvoiceNotes)
          )
        )
      )
    )
  )

  updateInvoiceNotesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceNotesActions.updateInvoiceNotesSuccess),
      tap(({ isDisputed }) => {
        const label = isDisputed
          ? `Dispute has been updated successfully.`
          : `Note has been updated successfully.`

        this.alertsFacade.addAlert({
          label,
          alertType: 'success'
        })
      }),
      tap(() => this.dialog.getDialogById(EDIT_INVOICE_NOTES_DIALOG)?.close()),
      switchMap(({ invoice }) =>
        of(
          invoiceNotesActions.loadInvoiceNotes({
            invoiceId: invoice.id ?? 0
          }),
          invoiceDetailsActions.loadInvoiceDetails({
            invoiceId: invoice.id ?? 0
          })
        )
      )
    )
  )

  updateInvoiceNotesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceNotesActions.updateInvoiceNotesFail),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''

          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : "There's an error trying to update invoice notes."
          })
        })
      ),
    { dispatch: false }
  )

  deleteInvoiceNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceNotesActions.deleteInvoiceNotes),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.deleteInvoiceNotes)
      ),
      exhaustMap(({ noteId, invoiceId }) =>
        this.invoicesService.deleteNotes(noteId).pipe(
          map(() =>
            invoiceNotesActions.deleteInvoiceNotesSuccess({ invoiceId })
          ),
          catchError(error =>
            of(
              invoiceNotesActions.deleteInvoiceNotesFail({
                error
              })
            )
          ),
          tap(() =>
            this.invoicesFacade.endLoading(AsyncOperations.deleteInvoiceNotes)
          )
        )
      )
    )
  )

  deleteVendorInvoiceNotesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceNotesActions.deleteInvoiceNotesSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Vendor invoice note has been deleted successfully.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.dialog.getDialogById(DELETE_INVOICE_NOTES_DIALOG)?.close()
      ),
      switchMap(({ invoiceId }) =>
        of(
          invoiceNotesActions.loadInvoiceNotes({
            invoiceId
          }),
          invoiceDetailsActions.loadInvoiceDetails({
            invoiceId
          })
        )
      )
    )
  )

  deleteInvoiceNotesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceNotesActions.deleteInvoiceNotesFail),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''

          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : "There's an error trying to delete invoice note."
          })
        })
      ),
    { dispatch: false }
  )

  addInvoiceNotesAndSendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceNotesActions.addInvoiceNotesAndSendEmail),
      map(({ invoice }) => ({
        request: this.invoiceAdapter.convert(ToAddInvoiceNotesRequest, invoice),
        invoice
      })),
      exhaustMap(({ request, invoice }) =>
        this.invoicesService.addInvoiceNotes(request).pipe(
          map(() =>
            invoiceNotesActions.addInvoiceNotesAndSendEmailSuccess({ invoice })
          ),
          catchError(error =>
            of(
              invoiceNotesActions.addInvoiceNotesAndSendEmailFail({
                error
              })
            )
          )
        )
      )
    )
  )

  resetAddInvoiceNotesAndSendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceNotesActions.addInvoiceNotesAndSendEmailSuccess,
        invoiceNotesActions.addInvoiceNotesAndSendEmailFail
      ),
      debounceTime(100),
      map(() =>
        invoicesActions.setLoading({
          operation: AsyncOperations.addInvoiceNotesAndSendEmail,
          loading: LoadingStatuses.NotStarted,
          message: ''
        })
      )
    )
  )

  addInvoiceLegacyNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceNotesActions.addInvoiceLegacyNotes),
      map(({ invoice }) => ({
        request: this.invoiceAdapter.convert(ToAddInvoiceNotesRequest, invoice),
        invoice
      })),
      exhaustMap(({ request, invoice }) =>
        this.invoicesService.addInvoiceNotes(request).pipe(
          map(() =>
            invoiceNotesActions.addInvoiceLegacyNotesSuccess({ invoice })
          ),
          catchError(error =>
            of(
              invoiceNotesActions.addInvoiceLegacyNotesFail({
                error
              })
            )
          )
        )
      )
    )
  )

  resetAddInvoiceLegacyNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceNotesActions.addInvoiceLegacyNotesSuccess,
        invoiceNotesActions.addInvoiceLegacyNotesFail
      ),
      debounceTime(100),
      map(() =>
        invoicesActions.setLoading({
          operation: AsyncOperations.addInvoiceLegacyNotes,
          loading: LoadingStatuses.NotStarted,
          message: ''
        })
      )
    )
  )
}
