import { createFeatureSelector, createSelector } from '@ngrx/store'
import { DisputeListState, FEATURE_DISPUTES_KEY } from './disputes.reducer'

export const selectDisputeListState =
  createFeatureSelector<DisputeListState>(FEATURE_DISPUTES_KEY)

export const selectPaginatedDisputesState = createSelector(
  selectDisputeListState,
  (state: DisputeListState) => state
)

export const selectPaginatedDisputeItems = createSelector(
  selectDisputeListState,
  (state: DisputeListState) => state.paginatedDisputesResponse?.items || []
)

export const selectCurrentDispute = createSelector(
  selectDisputeListState,
  (state: DisputeListState) => state.currentDispute
)

export const selectCurrentDisputeIndex = createSelector(
  selectDisputeListState,
  (state: DisputeListState) => state.currentDisputeIndex
)

export const selectPaginatedDisputeRequest = createSelector(
  selectDisputeListState,
  (state: DisputeListState) => state.paginatedDisputeRequest
)

export const selectPaginatedDisputesTotalRecords = createSelector(
  selectDisputeListState,
  (state: DisputeListState) =>
    state.paginatedDisputesResponse?.totalRecords || 0
)

export const selectPaginatedDisputesLoadingStatus = createSelector(
  selectDisputeListState,
  (state: DisputeListState) => state.loading
)
