import { makeEnvironmentProviders } from '@angular/core'
import { provideState } from '@ngrx/store'
import {
  INVOICES_FEATURE_KEY,
  invoicesReducer
} from '../+state/invoices/invoices.reducer'
import { InvoicesEffects } from '../+state/invoices/invoices.effects'
import { provideEffects } from '@ngrx/effects'
import { InvoicesFacade } from '../+state/invoices/invoices.facade'
import { VendorInvoiceAuditHistoryEffects } from '../+state/invoices/invoice-audit-history/invoice-audit-history.effects'
import { InvoiceChargesEffects } from '../+state/invoices/invoice-charges/invoice-charges.effects'
import { InvoiceCurrencyEffects } from '../+state/invoices/invoice-currency/invoice-currency.effects'
import { InvoiceCustomerDetailsEffects } from '../+state/invoices/invoice-customer-details/invoice-customer-details.effects'
import { InvoiceDetailsEffects } from '../+state/invoices/invoice-details/invoice-details.effects'
import { InvoiceDocumentsEffects } from '../+state/invoices/invoice-documents/invoice-documents.effects'
import { InvoiceDuplicatesEffects } from '../+state/invoices/invoice-duplicates/invoice-duplicates.effects'
import { InvoiceExceptionsEffects } from '../+state/invoices/invoice-exceptions/invoice-exceptions.effects'
import { InvoiceLineItemsEffects } from '../+state/invoices/invoice-line-items/invoice-line-items.effects'
import { VendorInvoiceNotesEffects } from '../+state/invoices/invoice-notes/invoice-notes.effects'
import { InvoiceReferenceNumbersEffects } from '../+state/invoices/invoice-reference-numbers/invoice-reference-numbers.effects'
import { InvoiceRelatedDocumentsEffects } from '../+state/invoices/invoice-related-documents/invoice-related-documents.effects'
import { InvoiceVendorDetailsEffects } from '../+state/invoices/invoice-vendor-details/invoice-vendor-details.effects'
import { OperationsEffects } from '../+state/invoices/operations/operations.effects'
import { DisputedReasonsEffects } from '../+state/invoices/types/effects/disputed-reasons.effects'
import { DocumentTypesEffects } from '../+state/invoices/types/effects/document-types.effects'
import { EmailTemplateTypesEffects } from '../+state/invoices/types/effects/email-template-types.effects'
import { ReferenceNumberTypesEffects } from '../+state/invoices/types/effects/reference-number-types.effects'
import { TerminateReasonsEffects } from '../+state/invoices/types/effects/terminate-reasons.effects'
import { TypesOfExceptionsEffects } from '../+state/invoices/types/effects/types-of-exceptions.effects'
import { VendorInvoiceTypesEffects } from '../+state/invoices/types/effects/vendor-invoice-types.effects'
import { InvoiceDisputesEffects } from '../+state/invoices/invoice-disputes/invoice-disputes.effects'
import { invoiceUserKeyMetaReducer } from '../+state/invoices/invoices-user-keys.meta-reducer'
import { DisputesEffects } from '../+state/disputes/disputes.effects'
import {
  disputeListReducer,
  FEATURE_DISPUTES_KEY
} from '../+state/disputes/disputes.reducer'

export function provideInvoicesFeature() {
  return makeEnvironmentProviders([
    provideEffects([
      InvoicesEffects,
      TypesOfExceptionsEffects,
      DisputedReasonsEffects,
      DisputesEffects,
      InvoiceVendorDetailsEffects,
      InvoiceExceptionsEffects,
      InvoiceReferenceNumbersEffects,
      InvoiceChargesEffects,
      InvoiceLineItemsEffects,
      VendorInvoiceNotesEffects,
      VendorInvoiceAuditHistoryEffects,
      InvoiceDocumentsEffects,
      ReferenceNumberTypesEffects,
      DocumentTypesEffects,
      OperationsEffects,
      EmailTemplateTypesEffects,
      TerminateReasonsEffects,
      VendorInvoiceTypesEffects,
      InvoiceDetailsEffects,
      InvoiceCustomerDetailsEffects,
      InvoiceDuplicatesEffects,
      InvoiceCurrencyEffects,
      InvoiceRelatedDocumentsEffects,
      InvoiceDisputesEffects
    ]),
    provideState(INVOICES_FEATURE_KEY, invoicesReducer, {
      metaReducers: invoiceUserKeyMetaReducer
    }),
    provideState(FEATURE_DISPUTES_KEY, disputeListReducer),
    InvoicesFacade
  ])
}
