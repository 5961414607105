import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  INVOICES_FEATURE_KEY,
  InvoicesState,
  invoicesAdapter,
  templatesAdapter
} from './invoices.reducer'

export const selectInvoicesState =
  createFeatureSelector<InvoicesState>(INVOICES_FEATURE_KEY)

const { selectAll, selectEntities } = invoicesAdapter.getSelectors()

export const selectAllInvoices = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => selectAll(state)
)

export const selectInvoicesEntities = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => selectEntities(state)
)

export const selectSelectedId = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.selectedId
)

export const selectTotalCount = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.totalCount
)

export const selectLoading = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.loading
)

export const selectEntity = createSelector(
  selectInvoicesEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
)

export const selecFilters = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => ({
    ...state.filters,
    dateRangeTypes: state.filters.dateRangeTypes ?? [],
    remitTo: state.filters.remitTo ?? ''
  })
)

export const selectTypesOfExceptions = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.typesOfExceptions
)

export const selectDisputedReasons = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.disputedReasons
)

export const selectInvoiceStatuses = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.statuses
)

export const selectFiltersMemory = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.filtersMemory
)

export const selectFreightTermsSelector = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.freightChargeTerms
)

export const selectLineItemClasses = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.lineItemClasses
)

export const selectAllReferenceNumberTypes = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.referenceNumberTypes
)

export const selectDocumentTypes = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.documentTypes
)

export const selectEmailTemplateTypes = createSelector(
  selectInvoicesState,
  (state: InvoicesState) =>
    templatesAdapter.getSelectors().selectAll(state.emailTemplateTypes)
)

export const selectEmailTemplateTypesAsDictionary = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.emailTemplateTypes.entities
)

export const selectEmailTemplates = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.emailTemplates
)

export const selectTerminateReasons = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.terminateReasons
)

export const selectInvoiceTypes = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.invoiceTypes
)

export const selectDuplicateActionTypes = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.duplicateActionTypes
)

export const selectRelatedDocuments = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.relatedDocuments
)

export const selectPagesCount = createSelector(
  selectInvoicesState,
  (state: InvoicesState) =>
    Math.ceil(state.totalCount / <number>state.filters.itemsPerPage)
)

export const selectStandAloneWindowOpen = createSelector(
  selectInvoicesState,
  (state: InvoicesState) => state.standAloneWindowOpen
)
