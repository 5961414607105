import { platform_v2 } from '@navix/api-models'
import { ordersActions } from '../../+state/orders/orders.actions'
import { Navix_Services_Sdk_Order } from 'libs/api-models/src/lib/platform-v2'

type PurchaseInvoiceObject = {
  Uuid: string
  Status: unknown
  Charges: {
    Charge: number
    Description: string
    Code: string
    LineItemUuid: string
    InvoiceNumber: string
    InvoiceLineNumber: string
    ReferenceNumbers: unknown[]
  }[]
  VendorExternalId: string
  AssociatedVendorInvoiceUuid: string
}

export function toRemoveOrderPurchaseInvoiceAssociatedUuidRequest(
  payload: Parameters<
    typeof ordersActions.removePurchaseInvoiceAssociatedUuid
  >[0],
  orderDocument: platform_v2.Navix_Services_Sdk_OrderDocument
): Navix_Services_Sdk_Order {
  const purchaseInvoiceFromMetadata = orderDocument.order.metadata
    ? orderDocument.order.metadata['PURCHASEINVOICES']
    : undefined

  if (purchaseInvoiceFromMetadata !== undefined) {
    const purchaseInvoiceObjects = Object.entries(
      purchaseInvoiceFromMetadata
    ).map(([key, value]) => {
      return tryParsePurchaseInvoiceFromString(value)
    })

    const newPurchaseInvoicesObjects = purchaseInvoiceObjects.map(
      purchaseInvoiceObject => {
        if (purchaseInvoiceObject?.Uuid === payload.purchaseInvoiceUuid) {
          return {
            ...purchaseInvoiceObject,
            AssociatedVendorInvoiceUuid: ''
          }
        }
        return {
          ...purchaseInvoiceObject,
          AssociatedVendorInvoiceUuid:
            purchaseInvoiceObject?.AssociatedVendorInvoiceUuid?.toLowerCase()
        }
      }
    )

    const purchaseInvoicesJSON: Record<string, string> = {}

    newPurchaseInvoicesObjects.map(pi => {
      const jsonString = JSON.stringify(pi)
      purchaseInvoicesJSON[pi?.Uuid ?? ''] = jsonString
    })

    const newOrder = <Navix_Services_Sdk_Order>{
      ...orderDocument.order,
      metadata: {
        ...orderDocument.order.metadata,
        ['PURCHASEINVOICES']: purchaseInvoicesJSON
      }
    }

    return newOrder
  }

  return orderDocument.order
}

function tryParsePurchaseInvoiceFromString(
  value: string
): PurchaseInvoiceObject | undefined {
  try {
    const result: PurchaseInvoiceObject = JSON.parse(value)
    return result
  } catch (error) {
    return undefined
  }
}
