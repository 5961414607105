import { Invoice } from '../../domain/invoices//invoice.model'
import { GetInvoicesRestResponse } from '../../domain/invoices/get-invoices-rest.response'
import { DataFilters } from '../../domain/invoices/data-filters.model'

export function fromGetInvoicesRestResponse(
  response: GetInvoicesRestResponse,
  filters?: DataFilters
): Invoice[] {
  if (!Array.isArray(response.records)) return []
  return response.records.map<Invoice>(
    invoice =>
      ({
        uuid: invoice.uuid,
        id: invoice.vendorInvoiceId,
        belongsToPage: filters?.page,
        notes:
          invoice.noteDisputeReasons?.map<Invoice['notes'][number]>(
            note =>
              <Invoice['notes'][number]>{
                reasonId: note.vendorInvoiceNoteDisputeReasonId,
                reasonDescription: note.description ?? '',
                isCompleted: note.isCompleted,
                isDisputed: note.isDisupted
              }
          ) ?? [],
        invoiceNumber: invoice.invoiceNumber,
        shipmentNumber: invoice.shipmentNumber,
        customerDescription: invoice.customerDescription,
        vendorDescription: invoice.vendorDescription,
        createdDate: isNaN(Date.parse(invoice?.createdDate ?? ''))
          ? undefined
          : new Date(invoice.createdDate as string),
        shipDate: isNaN(Date.parse(invoice?.shipDate ?? ''))
          ? undefined
          : new Date(invoice?.shipDate as string),
        billTypeDescription: invoice.vendorInvoiceTypeDescription,
        statusDescription: invoice.vendorInvoiceStatusDescription,
        exceptions:
          invoice.exceptions?.map<Invoice['exceptions'][number]>(
            exception =>
              ({
                categoryId: exception.exceptionCategoryId
              }) as Invoice['exceptions'][number]
          ) ?? [],

        orders: invoice.orderNumbers?.split(','),
        shipperDescription: invoice.shipper,
        consigneeDescription: invoice.consignee,
        ownerUserId: invoice.ownerUserUuid,
        ownerUserName: invoice.ownerUserName ?? '',
        ownerUserEmail: invoice.ownerUserEmail ?? '',
        statusDateLastModified: invoice.vendorInvoiceStatusDateLastModified,
        statusId: invoice.vendorInvoiceStatusDescriptionId,
        disputeReasons: invoice.disputeReasons ?? '',
        party: invoice.party ?? '',
        vendorId: invoice.vendorId ?? undefined,
        parentInvoiceId: invoice.parentInvoiceId ?? undefined,
        invoiceDate:
          invoice.invoiceDate !== null
            ? new Date(invoice.invoiceDate)
            : undefined,
        netCharge:
          invoice.vendorNetCharge === null
            ? undefined
            : invoice.vendorNetCharge,
        vendorInvoiceTypeId: invoice.vendorInvoiceTypeId ?? undefined,
        statusQueueDescription: invoice.statusQueue
      }) as Invoice
  )
}
