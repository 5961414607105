import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { InvoicesService } from '../../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import {
  EMPTY,
  catchError,
  exhaustMap,
  finalize,
  iif,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs'
import { TerminateReason } from '../../../../domain/invoices/terminate-reason.model'
import { invoicesTypesActions } from '../types.actions'
import { Store } from '@ngrx/store'
import { selectTerminateReasons } from '../../invoices.selectors'

@Injectable()
export class TerminateReasonsEffects {
  private store = inject(Store)
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)

  loadTerminateReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesTypesActions.loadTerminateReasons),
      withLatestFrom(this.store.select(selectTerminateReasons)),
      switchMap(([payload, terminateReasons]) =>
        iif(
          () => terminateReasons?.length > 0,
          EMPTY.pipe(
            finalize(() =>
              this.store.dispatch({ type: `[CACHED]${payload.type}` })
            )
          ),
          of(payload)
        )
      ),
      exhaustMap(() =>
        this.invoicesService.getTerminateReasons().pipe(
          map(response =>
            response
              .map(
                item =>
                  <TerminateReason>{
                    id: item.vendorInvoiceStatusReasonId,
                    description: item.description
                  }
              )
              .sort((a, b) => a.description.localeCompare(b.description))
          ),
          map(terminateReasons =>
            invoicesTypesActions.loadTerminateReasonsSuccess({
              terminateReasons
            })
          ),
          catchError(error =>
            of(invoicesTypesActions.loadTerminateReasonsFail({ error }))
          )
        )
      )
    )
  )

  loadTerminateReasonsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoicesTypesActions.loadTerminateReasonsFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load terminate reasons."
          })
        })
      ),
    { dispatch: false }
  )
}
