import { IStrategy } from '@navix/utils/adapter'
import { GetLineItemsResponse } from '../../domain/orders/get-line-items.response'
import { Order } from '../../domain/orders/order.model'

export class FromGetLineItemsResponse
  implements IStrategy<GetLineItemsResponse, Order>
{
  convert(response: GetLineItemsResponse): Order {
    return <Order>{
      lineItems: response.value.map(lineItem =>
        this.convertToLineItem(lineItem)
      ),
      lineItems2: response.value.map(lineItem =>
        this.convertToNewLineItem(lineItem)
      )
    }
  }

  convertToLineItem(responseLineItem: GetLineItemsResponse['value'][number]) {
    return <Order['lineItems'][number]>{
      class: responseLineItem.Class,
      cube: responseLineItem.Cube,
      density: responseLineItem.Density,
      dimensionUnitDescription: responseLineItem.DIMUnits,
      handlingUnitCount: responseLineItem.HandlingUnitCount,
      handlingUnitDescription: responseLineItem.HandlingUnitDescription,
      height: responseLineItem.Height,
      length: responseLineItem.Length,
      name: responseLineItem.Description,
      nmfcCode: responseLineItem.NMFCCode,
      pieces: responseLineItem.TotalPieces,
      stack: responseLineItem.Stack && (responseLineItem.Stack ? 'Y' : 'N'),
      weight: responseLineItem.Weight,
      weightUnitDescription: responseLineItem.WeightUnit,
      width: responseLineItem.Width
    }
  }

  convertToNewLineItem(item: GetLineItemsResponse['value'][number]) {
    return <Order['lineItems2'][number]>{
      class: item.Class,
      cube: item.Cube,
      density: item.Density,
      dimensionUnitDescription: item.DIMUnits,
      handlingUnitCount: item.HandlingUnitCount,
      handlingUnitDescription: item.HandlingUnitDescription,
      height: item.Height,
      length: item.Length,
      name: item.Description,
      nmfcCode: item.NMFCCode,
      pieces: item.TotalPieces,
      stackable: item.Stack,
      weight: item.Weight,
      weightUnitDescription: item.WeightUnit,
      width: item.Width
    }
  }
}
