import { KnownChageTypes } from './known-chage-types'

export const fixedCharges = [
  KnownChageTypes.BaseRate,
  KnownChageTypes.Discount,
  KnownChageTypes.Fsc,
  KnownChageTypes.Taxes,
  KnownChageTypes.Duties
] as const

export const commonChargesDefaultOrder = [
  KnownChageTypes.BaseRate,
  KnownChageTypes.Discount,
  KnownChageTypes.Fsc,
  KnownChageTypes.Accesorials,
  KnownChageTypes.UnknownAccesorials,
  KnownChageTypes.Taxes,
  KnownChageTypes.Duties
] as const
