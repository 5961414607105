import { makeEnvironmentProviders } from '@angular/core'
import { provideState } from '@ngrx/store'
import {
  ORDERS_FEATURE_KEY,
  ordersReducer
} from '../+state/orders/orders.reducer'
import { OrdersEffects } from '../+state/orders/orders.effects'
import { provideEffects } from '@ngrx/effects'
import { OrdersFacade } from '../+state/orders/orders.facade'

export function provideOrdersFeature() {
  return makeEnvironmentProviders([
    provideEffects(OrdersEffects),
    provideState(ORDERS_FEATURE_KEY, ordersReducer),
    OrdersFacade
  ])
}
