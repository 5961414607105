import { Injectable, Signal, computed, inject } from '@angular/core'
import { Store, select } from '@ngrx/store'
import {
  asapScheduler,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  startWith
} from 'rxjs'

import { ordersActions } from './orders.actions'
import * as OrdersSelectors from './orders.selectors'
import { ordersFilterTabsData } from '../../infrastructure/filters-tabs-data'
import { Order } from '../../domain/orders/order.model'
import {
  MergedCustomerVendorOrderCharge2,
  OrderCharge,
  OrderChargeGrouped
} from '../../domain/orders/order-charge.model'
import { getRouterSelectors } from '@ngrx/router-store'
import { LoadingStatuses, loadingStateEqualFn } from '@navix/shared/loading'
import {
  OrdersAsyncOperations,
  OrdersLoadingState
} from '../../domain/orders/orders-loading.model'
import {
  OrdersDataFilters,
  OrderStatusFilter
} from '../../domain/orders/data-filters.model'
import { KnownChageTypes } from './static/known-chage-types'
import { MergedCustomerVendorOrderCharge } from '../../domain/orders/order-charge.model'
import { commonChargesDefaultOrder } from './static/basic-charges-default-order'

const { selectQueryParams, selectRouteData, selectRouteParams } =
  getRouterSelectors()

@Injectable()
export class OrdersFacade {
  private readonly store: Store = inject(Store)

  //TODO: Remove on tenant state migration
  // temporalTenant$ = this.store.pipe(select(x => (x as any)?.app?.tenant))

  routeData$ = this.store.select(selectRouteData)
  routeQueryParams$ = this.store.select(selectQueryParams)
  routeParams$ = this.store.select(selectRouteParams)

  allOrders$ = this.store.pipe(select(OrdersSelectors.selectAllOrders))
  allOrders = this.store.selectSignal(OrdersSelectors.selectAllOrders)
  allOrdersAsDictionary$ = this.store.pipe(
    select(OrdersSelectors.selectAllOrdersAsDictionary)
  )
  totalCount$ = this.store.pipe(select(OrdersSelectors.selectTotalCount))

  selectedOrder$ = this.store.pipe(select(OrdersSelectors.selectEntity))
  selectedOrder = this.store.selectSignal(OrdersSelectors.selectEntity)

  selectedOrderWithDetails$ = this.store.pipe(
    select(OrdersSelectors.selectEntity),
    filter(order => order != undefined),
    filter(order => order?.withDetails === true),
    map(order => order as Order)
  )

  selectedOrderWithDetails = this.store.selectSignal(
    OrdersSelectors.selectEntity
  )

  /**
   * @deprecated use loading$ instead
   * @see loading$
   */
  simpleLoading$ = this.store.pipe(
    select(OrdersSelectors.selectLoading),
    map(loading =>
      Object.entries(loading).reduce<
        Record<keyof typeof OrdersAsyncOperations, boolean>
      >(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value.status === LoadingStatuses.InProgress
        }),
        {} as Record<keyof typeof OrdersAsyncOperations, boolean>
      )
    )
  )

  loading$ = this.store.select(OrdersSelectors.selectLoading)
  loading = this.store.selectSignal(OrdersSelectors.selectLoading)
  filters$ = this.store.pipe(select(OrdersSelectors.selectFilters))
  allDivisions$ = this.store.pipe(select(OrdersSelectors.selectDivisions))

  selectedOderRelatedInvoices$ = this.selectedOrder$.pipe(
    filter(order => !!order && !!order?.invoices),
    map(order => order?.invoices as Order['invoices'])
  )

  badgeFiltersList$ = combineLatest(
    ordersFilterTabsData.map(badgeItem =>
      this.filters$.pipe(
        distinctUntilChanged(
          (prevFilters, filters) =>
            prevFilters[badgeItem.key]?.length ===
              filters[badgeItem.key]?.length &&
            filters.status === prevFilters.status
        ),
        map(filters => filters as Required<OrdersDataFilters>),
        map(filters => ({
          ...badgeItem,
          skip:
            badgeItem.skipFor.includes(filters.status) ||
            badgeItem.readOnlyFor.includes(filters.status),
          value: filters[badgeItem.key].find(filter => filter.id === 0)
            ? 'All'
            : filters[badgeItem.key]?.map(filter => filter.label).join(', ')
        })),

        startWith({ ...badgeItem, value: '', skip: false })
      )
    )
  ).pipe(
    map(badges => badges.filter(badge => badge.value !== '' && !badge.skip))
  )

  orderDetailLabels = computed(() => {
    const order = this.selectedOrderWithDetails()
    if (!order) return []
    const customerExternalId = order.customer?.externalId
      ? `(${order.customer.externalId})`
      : ''
    const customerComposeName = `${order.customer?.name} ${customerExternalId}`
    return [
      {
        id: 'customer',
        label: 'Customer:',
        value: order.customer?.name ? customerComposeName : 'N/A'
      },
      {
        id: 'order-number',
        label: 'Order Number:',
        value: order.orderNumber
      },
      {
        id: 'ship-date',
        label: 'Ship Date:',
        value: this.formatDate(order.shipDate)
      },
      {
        id: 'delivery-date',
        label: 'Delivery Date:',
        value: this.formatDate(order.deliveryDate)
      },
      {
        id: 'weight',
        label: 'Weight:',
        value: `${Math.round(
          order.lineItems2?.reduce(
            (acc, lineItem) => acc + (lineItem.weight ?? 0),
            0
          )
        )}`
      },
      {
        id: 'created-date',
        label: 'Created Date:',
        value: this.formatDate(order.createDate)
      },
      {
        id: 'division',
        label: 'Division:',
        value: order.division ?? 'N/A'
      }
    ]
  })

  customerCharges = computed(() => this.selectedOrder()?.customerCharges ?? [])
  vendorCharges = computed(() => this.selectedOrder()?.vendorCharges ?? [])

  selectedOrderMergedOrderCharges = computed(
    (): MergedCustomerVendorOrderCharge[] => {
      const basicCharges = commonChargesDefaultOrder
        .map<MergedCustomerVendorOrderCharge>(chargeCodeId => {
          return this.mergeOrderCharges(
            this.customerCharges(),
            this.vendorCharges(),
            chargeCodeId,
            charge => charge.chargeTypeId === chargeCodeId
          )
        })
        .toSorted(
          (comapre, comparer) =>
            commonChargesDefaultOrder.indexOf(comapre.chargeTypeId) -
            commonChargesDefaultOrder.indexOf(comparer.chargeTypeId)
        )
      return basicCharges
    }
  )

  selectedOrderMergedOrderChargesForMultiVendor = computed(() => {
    const vendorCharges =
      this.selectedOrder()?.vendors?.flatMap(vendor => {
        const vendorCharges =
          vendor.charges?.map<OrderChargeGrouped>(
            charge =>
              <OrderChargeGrouped>{
                ...charge,
                groupId: vendor.externalId,
                groupName: vendor.name,
                kind: 'vendor',
                vendorExternalId: vendor.externalId
              }
          ) ?? []

        const vendorPurchaseInvoiceCharges = vendor.purchaseInvoices.map(
          (pi, i) =>
            pi.charges.map<OrderChargeGrouped>(
              charge =>
                <OrderChargeGrouped>{
                  ...charge,
                  groupId: `${pi.uuid}-${i + 1}`,
                  groupName: `Purchase Invoice ${i + 1}`,
                  vendorExternalId: vendor.externalId,
                  kind: 'purchase-invoice'
                }
            )
        )
        return [vendorCharges, ...vendorPurchaseInvoiceCharges]
      }) ?? []

    const mergedChargesSorted = commonChargesDefaultOrder.map(chargeTypeId => {
      return this.mergeOrderChargesForMultiVendor(
        vendorCharges.filter(charges => charges.length > 0),
        this.selectedOrder()?.customerCharges ?? [],
        chargeTypeId,
        x => x.chargeTypeId === chargeTypeId
      )
    })

    return mergedChargesSorted
  })

  divisions = this.store.selectSignal(OrdersSelectors.selectDivisions)

  formatDate(date: Date | undefined | null) {
    if (!date) return undefined
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      timeZone: 'UTC'
    })
    return dateFormatter.format(new Date(date))
  }

  loadOrders(filters: OrdersDataFilters) {
    this.store.dispatch(ordersActions.loadOrders({ filters }))
  }

  startLoading(operation: OrdersAsyncOperations) {
    this.store.dispatch(
      ordersActions.setLoading({
        operation,
        loading: true
      })
    )
  }
  endLoading(operation: OrdersAsyncOperations) {
    this.store.dispatch(
      ordersActions.setLoading({
        operation,
        loading: false
      })
    )
  }

  setSelectedOrderId(orderId?: Order['id']) {
    this.store.dispatch(ordersActions.setSelectedId({ orderId }))
  }

  selecOrderV1(payload: {
    orderId: Order['id']
    legacyOrderId: Order['legacyId']
  }) {
    asapScheduler.schedule(() => {
      this.store.dispatch(ordersActions.loadOrderDetails(payload))
      this.store.dispatch(ordersActions.loadOrderStops(payload))
      this.store.dispatch(ordersActions.loadReferenceNumbers(payload))
      this.store.dispatch(ordersActions.loadLineItems(payload))
      this.store.dispatch(ordersActions.loadCharges(payload))
      this.store.dispatch(ordersActions.loadMatchedInvoices(payload))
    })
  }

  selectOrderV2(payload: {
    orderId: Order['id']
    legacyOrderId: Order['legacyId']
  }) {
    asapScheduler.schedule(() => {
      this.store.dispatch(
        ordersActions.loadNewOrderDetails({
          orderId: payload.orderId,
          legacyOrderId: payload.legacyOrderId
        })
      )
      this.store.dispatch(ordersActions.loadMatchedInvoices(payload))
    })
  }

  selectOnlyOrderV2(payload: {
    orderId: Order['id']
    legacyOrderId: Order['legacyId']
  }) {
    asapScheduler.schedule(() => {
      this.store.dispatch(
        ordersActions.loadNewOrderDetails({
          orderId: payload.orderId,
          legacyOrderId: payload.legacyOrderId
        })
      )
    })
  }

  loadOrderReferenceNumbers(payload: {
    orderId: Order['id']
    legacyOrderId: Order['legacyId']
  }) {
    this.store.dispatch(ordersActions.loadReferenceNumbers(payload))
  }

  loadOrderLineItems(payload: {
    orderId: Order['id']
    legacyOrderId: Order['legacyId']
  }) {
    this.store.dispatch(ordersActions.loadLineItems(payload))
  }

  async updateListFilters(filters: OrdersDataFilters) {
    const currentFilters = await firstValueFrom(this.filters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(ordersActions.setListFilters({ filters: newFilters }))
  }

  async updateListFiltersMemory(filters: OrdersDataFilters) {
    const currentFilters = await firstValueFrom(this.filters$)

    this.store.dispatch(
      ordersActions.setListFiltersMemory({
        filters: filters,
        status: currentFilters.status as OrderStatusFilter
      })
    )
  }

  downloadOrders(filters: OrdersDataFilters) {
    const id = new Date().getTime()
    this.store.dispatch(ordersActions.downloadOrders({ filters, id }))
  }

  async loadInvoices(payload?: {
    orderId: Order['id']
    legacyOrderId: Order['legacyId']
  }) {
    let idsToUse = payload

    if (idsToUse === undefined) {
      const currentOrder = await firstValueFrom(this.selectedOrderWithDetails$)
      idsToUse = {
        orderId: currentOrder.id,
        legacyOrderId: currentOrder.legacyId
      }
    }

    this.store.dispatch(ordersActions.loadMatchedInvoices(idsToUse))
  }

  getLoadingStateSignal<K extends keyof typeof OrdersAsyncOperations>(
    operation: K
  ) {
    return computed(() => this.loading()[operation], {
      equal: loadingStateEqualFn
    }) satisfies Signal<OrdersLoadingState[K]>
  }

  getPurchaseInvoices(vendorExternalId: string | undefined) {
    return (
      this.selectedOrder()?.vendors?.find(
        x => x.externalId === vendorExternalId
      )?.purchaseInvoices ?? []
    )
  }

  getPurchaseInvoice(
    vendorExternalId: string | undefined,
    invoiceUuid: string | undefined
  ) {
    if (vendorExternalId === undefined || invoiceUuid === undefined)
      return undefined

    return this.selectedOrder()
      ?.vendors?.find(x => x.externalId === vendorExternalId)
      ?.purchaseInvoices.find(x => x.invoiceUuid === invoiceUuid)
  }

  getVendor(vendorExternalId: string | undefined) {
    if (vendorExternalId === undefined) return undefined
    return this.selectedOrder()?.vendors?.find(
      x => x.externalId === vendorExternalId
    )
  }

  getPurchaseInvoiceByUuid(purchaseInvoiceUuid: string) {
    return this.selectedOrder()
      ?.vendors?.flatMap(x => x.purchaseInvoices)
      .find(x => x.uuid === purchaseInvoiceUuid)
  }

  mergeOrderCharges(
    customerCharges: OrderCharge[],
    vendorCharges: OrderCharge[],
    chargeTypeId: number,
    findFn: (charge: OrderCharge) => boolean
  ): MergedCustomerVendorOrderCharge {
    const customerMatch = customerCharges.find(findFn)
    const vendorMatch = vendorCharges.find(findFn)

    const allDetails = (customerMatch?.details ?? [])
      .concat(vendorMatch?.details ?? [])
      .map(detail => {
        return {
          chargeTypeId: detail.chargeTypeId,
          description: detail.description,
          key: `${detail.chargeTypeId}-${detail.description}`
        }
      })

    const allDetailsWithoutDuplicates = [
      ...new Map(allDetails.map(item => [item['key'], item])).values()
    ]

    const fixedChargeTypeId =
      customerMatch?.chargeTypeId ?? vendorMatch?.chargeTypeId ?? chargeTypeId

    const zeroIfEmpty =
      customerMatch?.parentChargeTypeId === KnownChageTypes.Accesorials ||
      vendorMatch?.parentChargeTypeId === KnownChageTypes.Accesorials
    return {
      chargeTypeId: fixedChargeTypeId,
      description: customerMatch?.description ?? vendorMatch?.description ?? '',
      customerAmount:
        customerMatch?.chargeAmount ?? (zeroIfEmpty ? 0 : undefined),
      vendorAmount: vendorMatch?.chargeAmount ?? (zeroIfEmpty ? 0 : undefined),
      details: allDetailsWithoutDuplicates.map(detail => {
        return this.mergeOrderCharges(
          customerMatch?.details ?? [],
          vendorMatch?.details ?? [],
          detail.chargeTypeId,
          charge =>
            fixedChargeTypeId === KnownChageTypes.UnknownAccesorials
              ? charge.description === detail.description
              : charge.chargeTypeId === detail.chargeTypeId
        )
      })
    }
  }

  mergeOrderChargesForMultiVendor(
    vendorCharges: OrderChargeGrouped[][],
    customerCharges: OrderCharge[],
    chargeTypeId: number,
    findFn: (charge: OrderCharge) => boolean,
    zeroIfEmpty = true,
    vendorMatchesWereUsedAsDetailsCount = 0,
    customerMatchesWereUsedAsDetailsCount = 0
  ): MergedCustomerVendorOrderCharge2 {
    const vendorChargesMatch = vendorCharges.map(x => x.filter(findFn))
    const customerChargesMatch = customerCharges.filter(findFn)
    const vendorChargesMatchDetailsFlatten = vendorChargesMatch.flatMap(x =>
      x.flatMap(
        x =>
          x.details.map(y => ({
            ...y,
            groupName: x.groupName,
            groupId: x.groupId
          })) ?? []
      )
    )

    const vendorChargesMatchFlatten = vendorChargesMatch.flat()
    const hasPurchaseInvoices = vendorChargesMatchFlatten.some(
      x => x.groupName?.includes('Purchase Invoice') ?? false
    )
    const matchesAsDetailsTolerance = hasPurchaseInvoices ? 0 : 1
    const isSimpleOneToOneMatch =
      vendorChargesMatch.every(vendorCharges => vendorCharges.length === 1) &&
      customerChargesMatch.length === 1

    const allDetailsFromDetails = [
      ...customerChargesMatch.flatMap(x => x.details ?? []),
      ...vendorChargesMatchDetailsFlatten
    ]

    const allDetailsFromMatches = [
      ...(vendorMatchesWereUsedAsDetailsCount > matchesAsDetailsTolerance
        ? []
        : vendorChargesMatchFlatten),
      ...(customerMatchesWereUsedAsDetailsCount > matchesAsDetailsTolerance
        ? []
        : customerChargesMatch)
    ].filter(
      x =>
        x.chargeAmount !== 0 &&
        x.chargeAmount !== undefined &&
        x.chargeAmount !== null
    )

    const useMatchesAsDetails =
      allDetailsFromDetails.length === 0 && allDetailsFromMatches.length > 2

    const allDetails = (
      useMatchesAsDetails ? allDetailsFromMatches : allDetailsFromDetails
    )
      .map((detail, i) => {
        const { groupName, groupId } = detail as OrderChargeGrouped
        const isPurchaseInvoice =
          groupName?.includes('Purchase Invoice') ?? false
        return {
          chargeTypeId: detail.chargeTypeId,
          description: detail.description,
          amount: detail.chargeAmount,
          groupId,
          groupName,
          isPurchaseInvoice,
          id: detail.id,
          key: isSimpleOneToOneMatch
            ? hasPurchaseInvoices
              ? `${groupId}-${i}-${detail.chargeAmount}`
              : `${detail.chargeTypeId}-${detail.description}`
            : detail.id
        }
      })
      .filter(charge => (hasPurchaseInvoices ? charge.isPurchaseInvoice : true))

    const detailMatchFn = (
      detail: (typeof allDetails)[number],
      charge: OrderCharge
    ) => {
      return isSimpleOneToOneMatch
        ? hasPurchaseInvoices
          ? charge.description === detail.description &&
            charge.chargeAmount === detail.amount
          : charge.description === detail.description
        : charge.id === detail.key
    }
    const allDetailsWithoutDuplicates = [
      ...new Map(allDetails.map(item => [`${item['key']}`, item])).values()
    ]
      .toSorted((a, b) => b.amount - a.amount)
      .toSorted(
        (a, b) => a.description?.localeCompare(b.description ?? '') ?? 0
      )

    const fixedChargeTypeId = chargeTypeId

    const vendorChargesFixed = vendorCharges.map<
      MergedCustomerVendorOrderCharge2['vendors'][0]
    >(charge => {
      const matched = charge.find(findFn)
      const matched2 = !hasPurchaseInvoices
        ? charge.filter(findFn).reduce((acc, curr) => {
            return acc + (curr.chargeAmount ?? 0)
          }, 0)
        : matched?.chargeAmount ?? 0
      const defaultChargeData = charge.at(0)
      return {
        chargeAmount: matched2 === 0 ? (zeroIfEmpty ? 0 : undefined) : matched2,
        vendorExternalId:
          matched?.vendorExternalId ??
          defaultChargeData?.vendorExternalId ??
          '',
        kind: matched?.kind ?? defaultChargeData?.kind ?? 'vendor',
        groupId: matched?.groupId ?? defaultChargeData?.groupId ?? '',
        groupName: matched?.groupName ?? defaultChargeData?.groupName ?? ''
      }
    })

    const customerChargesFixed = [
      customerChargesMatch.length > 0
        ? customerChargesMatch.reduce((acc, curr) => {
            const matched = findFn(curr)
            return acc + (matched ? curr.chargeAmount ?? 0 : 0)
          }, 0)
        : 0
    ].map(fixedAmount => {
      if (fixedAmount !== 0) return fixedAmount
      return zeroIfEmpty ? 0 : undefined
    })

    const chargeDetails = allDetailsWithoutDuplicates.map((detail, i) => {
      let useVendorChargesMatchAsDetails =
        vendorMatchesWereUsedAsDetailsCount >= matchesAsDetailsTolerance
      let useCustomerChargesMatchAsDetails =
        vendorMatchesWereUsedAsDetailsCount >= matchesAsDetailsTolerance
      const vendorChargesToMatch = vendorChargesMatch.map(vendorDetails =>
        vendorDetails.flatMap((charge, i, list) => {
          const isPurchaseInvoice =
            charge.groupName?.includes('Purchase Invoice')

          const useCurrentMatchAsDetails =
            (charge.details?.length ?? 0) === 0 && list.length > 1

          useVendorChargesMatchAsDetails =
            useVendorChargesMatchAsDetails || useCurrentMatchAsDetails

          return (
            useCurrentMatchAsDetails && useVendorChargesMatchAsDetails
              ? [charge]
              : charge.details
          ).map((y, i) => {
            return <OrderChargeGrouped>{
              ...y,
              id: isPurchaseInvoice ? `${charge.groupName}-${i}` : y.id ?? '',
              kind: charge.kind,
              groupId: charge.groupId,
              groupName: charge.groupName,
              vendorExternalId: charge.vendorExternalId
            }
          })
        })
      )

      const customersDetails = customerChargesMatch.flatMap(
        x => x.details ?? []
      )
      const useCustomerMatchesAsDetails =
        customersDetails.length === 0 && customerChargesMatch.length > 1

      useCustomerChargesMatchAsDetails =
        useCustomerChargesMatchAsDetails || useCustomerMatchesAsDetails

      const customersToMatch =
        useCustomerMatchesAsDetails && useCustomerChargesMatchAsDetails
          ? customerChargesMatch
          : customersDetails

      return this.mergeOrderChargesForMultiVendor(
        vendorChargesToMatch,
        customersToMatch,
        detail.chargeTypeId,
        charge => detailMatchFn(detail, charge),
        fixedChargeTypeId === KnownChageTypes.Accesorials,
        vendorMatchesWereUsedAsDetailsCount +
          (useVendorChargesMatchAsDetails ? 1 : 0),
        customerMatchesWereUsedAsDetailsCount +
          (useCustomerChargesMatchAsDetails ? 1 : 0)
      )
    })

    const allMatchDescriptions = [
      ...customerChargesMatch.map(charge => charge.description),
      ...vendorChargesMatch.flat().map(charge => charge.description)
    ].filter(
      (description): description is string =>
        description !== null &&
        description !== undefined &&
        description.trim() !== ''
    )

    const processedCharge = {
      chargeTypeId: fixedChargeTypeId,
      description: allMatchDescriptions.at(0) ?? '',
      customerAmounts: customerChargesFixed,
      vendors: vendorChargesFixed,
      details: chargeDetails
    }

    return processedCharge
  }

  selectPurchaseInvoice(
    uuid: string,
    vendorInvoiceUuid: string,
    shouldReloadOrderDetails: boolean
  ) {
    const orderUuid = this.selectedOrder()?.id ?? ''
    this.store.dispatch(
      ordersActions.updateOrdersPurchaseInvoice({
        uuid,
        orderUuid: orderUuid,
        vendorInvoiceUuid,
        shouldReloadOrderDetails
      })
    )
  }

  removePurchaseInvoiceAssociatedInvoiceUuid(purchaseInvoiceUuid: string) {
    const orderUuid = this.selectedOrder()?.id ?? ''
    this.store.dispatch(
      ordersActions.removePurchaseInvoiceAssociatedUuid({
        purchaseInvoiceUuid,
        orderUuid
      })
    )
  }

  loadDivisionTags() {
    this.store.dispatch(ordersActions.loadDivisionTags())
  }
}
