import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { MapInvoiceVendorChargeRequest } from '../../domain/invoices/map-invoice-vendor-charge.request'

export class ToMapInvoiceChargeRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'id'> & {
        charge: InvoiceForm['chargesToMap'][number]
      },
      MapInvoiceVendorChargeRequest | undefined
    >
{
  convert(
    request: Pick<InvoiceForm, 'id'> & {
      charge: InvoiceForm['chargesToMap'][number]
    }
  ): MapInvoiceVendorChargeRequest | undefined {
    if (isNaN(Number(request.charge.chargeId))) return undefined
    return {
      charge: {
        chargeTypeId: Number(request.charge.typeId),
        vendorInvoiceChargeId: Number(request.charge.chargeId),
        tenantCode: request.charge.tenantCode ?? null,
        tenantDescription: request.charge.tenantDescription ?? null
      },
      invoiceId: request.id as number
    }
  }
}
