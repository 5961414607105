import { on } from '@ngrx/store'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { InvoicesState, invoicesAdapter } from '../invoices.reducer'
import { invoiceAuditHistoryActions } from './invoice-audit-history.actions'

export const invoiceAuditHistoryReducers = [
  on(
    invoiceAuditHistoryActions.loadInvoiceAuditHistorySuccess,
    (state: InvoicesState, { auditHistory, invoiceId }) =>
      invoicesAdapter.upsertOne(
        {
          id: invoiceId,
          auditHistory
        } as Invoice,
        state
      )
  )
]
