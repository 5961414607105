import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { UpdateInvoiceVendorStopsRequest } from '../../domain/invoices/update-invoice-vendor-stops.request'

export class ToUpdateInvoiceVendorStopsRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'stops' | 'id'>,
      UpdateInvoiceVendorStopsRequest
    >
{
  convert(
    request: Pick<InvoiceForm, 'stops' | 'id'>
  ): UpdateInvoiceVendorStopsRequest {
    return {
      invoiceId: request.id as number,
      stops: [
        ...request.stops.origins.map(stop => this.convertStop(stop)),
        ...request.stops.destinations.map(stop => this.convertStop(stop)),
        this.convertStop(request.stops.billTo),
        ...request.stops.remitTo.map(stop => this.convertStop(stop))
      ].map((stop, index) => ({ ...stop, stopSequence: index + 1 }))
    }
  }

  private convertStop(
    stop: InvoiceForm['stops']['origins' | 'destinations'][number]
  ): UpdateInvoiceVendorStopsRequest['stops'][0] {
    return <UpdateInvoiceVendorStopsRequest['stops'][number]>{
      address1: stop.address1,
      address2: stop.address2,
      addressName: stop.name,
      city: stop.city,
      stateId: stop.stateId,
      uuid: stop.uuid ?? undefined,
      vendorInvoiceStopId: stop.id ?? undefined,
      vendorInvoiceStopType: stop.typeDescription,
      zip: stop.zip
    }
  }
}
