import { Injectable, inject } from '@angular/core'
import { NonNullableFormBuilder, Validators } from '@angular/forms'
import { AuditFormControls } from '../domain/audits/audit-form.model'

@Injectable({
  providedIn: 'root'
})
export class AuditFormService {
  private readonly fb = inject(NonNullableFormBuilder)

  getAuditNoteForm(): AuditFormControls['note'] {
    return this.fb.group({
      text: this.fb.control<string>('', Validators.required)
    } as AuditFormControls['note']['controls'])
  }
}
