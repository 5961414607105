/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IStrategy } from '@navix/utils/adapter'
import { Invoice } from '../../domain/invoices/invoice.model'
import { GetCustomerOrderChargesNewResponse } from '../../domain/invoices/get-customer-order-charges-new.response'
import { GetInvoiceCustomerAdjustedChargesNewResponse } from '../../domain/invoices/get-invoice-customer-adjusted-charges-new.response'
import { GetInvoiceCustomerAuditChargesNewResponse } from '../../domain/invoices/get-invoice-customer-audit-charges-new.response'
import { GetInvoiceAuditChargesResponse } from '../../domain/invoices/get-invoice-audit-charges.response'
import { GetInvoiceChargesNewResponse } from '../../domain/invoices/get-invoice-charges-new.response'
import { GetOrderChargesResponse } from '../../domain/invoices/get-order-charges.response'

import { KnownChageTypes } from '../../+state/invoices/static'
import { InvoiceCharge } from '../../domain/models/invoice-charge.model'
//TODO(ladaj): resolve dependency
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ChargeCode } from '@navix/charge-codes/domain'

type Response = [
  ChargeCode[],
  GetInvoiceChargesNewResponse,
  GetInvoiceAuditChargesResponse,
  GetOrderChargesResponse,
  GetCustomerOrderChargesNewResponse,
  GetInvoiceCustomerAuditChargesNewResponse,
  GetInvoiceCustomerAdjustedChargesNewResponse
]

type NormalizedResponse = {
  chargeId: number
  adjustedChargeAmount: number
  varianceReasonId: number | undefined
  typeId: number
  chargeAmount: number
  currency: string
  isLock: boolean | undefined
  code: string | undefined
  description: string | undefined
  metadata: string | undefined
  tenantCode: string | undefined
  tenantDescription: string | undefined
}

type ChargesDataResponse = {
  adjustedCharges: NormalizedResponse[]
  auditCharges: NormalizedResponse[]
  orderCharges: NormalizedResponse[]
}

type ChargesResponse = {
  vendor: ChargesDataResponse
  customer: ChargesDataResponse
}

type ChargesGroupNames = keyof Invoice['legacyCharges']

export class FromGetInvoiceChargesComposed
  implements IStrategy<Response, Invoice['legacyCharges']>
{
  convert(response: Response) {
    const chargeTypes = response[0]
    const normalizedResponse = this.getNormalizedResponse(response)
    const getChargesForType = this.getCharge(chargeTypes, normalizedResponse)
    const charges: Invoice['legacyCharges'] = {
      vendor: {
        baseRate: getChargesForType(
          KnownChageTypes.BaseRate,
          'vendor',
          'customer'
        ),
        discount: getChargesForType(
          KnownChageTypes.Discount,
          'vendor',
          'customer'
        ),
        fsc: getChargesForType(KnownChageTypes.Fsc, 'vendor', 'customer'),
        accessorials: getChargesForType(
          KnownChageTypes.Accesorials,
          'vendor',
          'customer'
        ),
        unknownAccessorials: getChargesForType(
          KnownChageTypes.UnknownAccesorials,
          'vendor',
          'customer'
        ),
        taxes: getChargesForType(KnownChageTypes.Taxes, 'vendor', 'customer'),
        duties: getChargesForType(KnownChageTypes.Duties, 'vendor', 'customer'),
        customerFees: getChargesForType(
          KnownChageTypes.CustomerFees,
          'vendor',
          'customer'
        )
      },
      customer: {
        baseRate: getChargesForType(
          KnownChageTypes.BaseRate,
          'customer',
          'vendor'
        ),
        discount: getChargesForType(
          KnownChageTypes.Discount,
          'customer',
          'vendor'
        ),
        fsc: getChargesForType(KnownChageTypes.Fsc, 'customer', 'vendor'),
        accessorials: getChargesForType(
          KnownChageTypes.Accesorials,
          'customer',
          'vendor'
        ),
        unknownAccessorials: getChargesForType(
          KnownChageTypes.UnknownAccesorials,
          'customer',
          'vendor'
        ),
        taxes: getChargesForType(KnownChageTypes.Taxes, 'customer', 'vendor'),
        duties: getChargesForType(KnownChageTypes.Duties, 'customer', 'vendor'),
        customerFees: getChargesForType(
          KnownChageTypes.CustomerFees,
          'customer',
          'vendor'
        )
      }
    }

    return charges
  }
  private getCharge(
    chargeTypes: ChargeCode[],
    normalizedResponse: ChargesResponse
  ): (
    typeId: KnownChageTypes,
    source: ChargesGroupNames,
    secondarySource: ChargesGroupNames
  ) => InvoiceCharge {
    return (
      typeId: KnownChageTypes,
      source: ChargesGroupNames,
      secondarySource: ChargesGroupNames
    ) => {
      const getChargesForType = this.getCharge(chargeTypes, normalizedResponse)

      const chargeDetails = chargeTypes.find(charge => charge.id === typeId)!
      const chargeResponseData = normalizedResponse[source]
      const secondaryChargeResponseData = normalizedResponse[secondarySource]

      const orderCharges = chargeResponseData.orderCharges.filter(
        charge => charge.typeId === typeId
      )
      const auditCharges = chargeResponseData.auditCharges.filter(
        charge => charge.typeId === typeId
      )
      const adjustedCharges = chargeResponseData.adjustedCharges.filter(
        charge => charge.typeId === typeId
      )

      const secondaryOrderCharges =
        secondaryChargeResponseData.orderCharges.filter(
          charge => charge.typeId === typeId
        )
      const secondaryAuditCharges =
        secondaryChargeResponseData.auditCharges.filter(
          charge => charge.typeId === typeId
        )
      const secondaryAdjustedCharges =
        secondaryChargeResponseData.adjustedCharges.filter(
          charge => charge.typeId === typeId
        )

      const orderChargeAmount = orderCharges.reduce(
        (acc, charge) => acc + charge.chargeAmount,
        0
      )

      const auditChargeAmount = auditCharges.reduce(
        (acc, charge) => acc + charge.chargeAmount,
        0
      )

      const adjustedComparerChargeAmount =
        source === 'customer'
          ? orderChargeAmount
          : adjustedCharges.reduce(
              (acc, charge) => acc + charge.chargeAmount,
              0
            )

      const adjustedChargeAmount = adjustedCharges.reduce(
        (acc, charge) => acc + charge.adjustedChargeAmount,
        0
      )

      const childChargesTypeIds = chargeResponseData.adjustedCharges
        .concat(chargeResponseData.orderCharges)
        .concat(chargeResponseData.auditCharges)
        .concat(secondaryChargeResponseData.auditCharges)
        .concat(secondaryChargeResponseData.orderCharges)
        .concat(secondaryChargeResponseData.adjustedCharges)
        .map(charge => charge.typeId)

      const childCharges = chargeTypes
        .filter(charge => childChargesTypeIds.includes(charge.id))
        .filter(charge => {
          if (typeId === KnownChageTypes.Accesorials)
            return (
              charge.parentChargeTypeId === typeId &&
              charge.id !== KnownChageTypes.UnknownAccesorials
            )
          return charge.parentChargeTypeId === typeId
        })
        .map(charge =>
          getChargesForType(
            charge.id as KnownChageTypes,
            source,
            secondarySource
          )
        )

      const hasAggregationDetails =
        auditCharges.length > 1 ||
        orderCharges.length > 1 ||
        adjustedCharges.length > 1 ||
        secondaryAuditCharges.length > 1 ||
        secondaryOrderCharges.length > 1 ||
        secondaryAdjustedCharges.length > 1 ||
        typeId === KnownChageTypes.UnknownAccesorials

      const parentChargeTypeId =
        typeId === KnownChageTypes.UnknownAccesorials
          ? KnownChageTypes.UnknownAccesorials
          : chargeDetails.parentChargeTypeId!

      const mainAggregationDetails = this.getAggregationDetails(
        parentChargeTypeId,
        adjustedCharges,
        orderCharges,
        auditCharges,
        source
      )

      const secondaryAggregationDetails = this.getEmptyAggregationDetails(
        parentChargeTypeId,
        secondaryAdjustedCharges,
        secondaryOrderCharges,
        secondaryAuditCharges,
        secondarySource
      )

      const aggregatedChilds = hasAggregationDetails
        ? source === 'vendor'
          ? mainAggregationDetails.concat(secondaryAggregationDetails)
          : secondaryAggregationDetails.concat(mainAggregationDetails)
        : []

      const isAccessorialCharge =
        typeId === KnownChageTypes.Accesorials ||
        typeId === KnownChageTypes.UnknownAccesorials
      const useSecondarySource =
        !isAccessorialCharge &&
        !hasAggregationDetails &&
        adjustedCharges.length === 0 &&
        orderCharges.length === 0 &&
        (secondaryAdjustedCharges.length > 0 ||
          secondaryOrderCharges.length > 0)

      const orderCharge = this.getChargeData(
        orderCharges,
        secondaryOrderCharges,
        hasAggregationDetails,
        useSecondarySource
      )

      const auditCharge = this.getChargeData(
        auditCharges,
        secondaryAuditCharges,
        hasAggregationDetails,
        useSecondarySource
      )
      const adjustedCharge = this.getChargeData(
        adjustedCharges,
        secondaryAdjustedCharges,
        hasAggregationDetails,
        useSecondarySource
      )

      const orderMetadata = this.getMetadata(orderCharge?.metadata)
      const auditMetadata = this.getMetadata(auditCharge?.metadata)
      const adjustedMetadata = this.getMetadata(adjustedCharge?.metadata)

      const metadataKeys = Array.from(
        new Set([
          ...Object.keys(adjustedMetadata),
          ...Object.keys(auditMetadata),
          ...Object.keys(orderMetadata)
        ])
      )

      const metadata = metadataKeys.map<InvoiceCharge['metadata'][number]>(
        key => ({
          typeId: undefined,
          typeDescription: key.toString(),
          parentTypeId: typeId,
          orderAmount: Object.hasOwn(orderMetadata, key)
            ? orderMetadata[key]
            : undefined,
          auditAmount: Object.hasOwn(auditMetadata, key)
            ? auditMetadata[key]
            : undefined,
          adjustedAmount: Object.hasOwn(adjustedMetadata, key)
            ? adjustedMetadata[key]
            : undefined,
          adjustedComparerAmount: undefined,
          varianceReasonId: undefined,
          adjustedChargeId: undefined,
          isLock: false,
          hasAggregationDetails: false,
          isAggregationDetail: false,
          code: undefined,
          description: undefined,
          details: [],
          metadata: undefined,
          orderChargeId: undefined,
          tenantCode: undefined,
          tenantDescription: undefined
        })
      )

      return {
        guid: self.crypto.randomUUID(),
        parentTypeId: chargeDetails.parentChargeTypeId,
        typeId: chargeDetails.id,
        typeDescription: chargeDetails.description,
        orderAmount: orderChargeAmount,
        auditAmount: auditChargeAmount,
        adjustedComparerAmount: adjustedComparerChargeAmount,
        adjustedAmount: adjustedChargeAmount,
        adjustedChargeId: adjustedCharge?.chargeId,
        orderChargeId: orderCharge?.chargeId,
        varianceReasonId: hasAggregationDetails
          ? undefined
          : adjustedCharge?.varianceReasonId,
        hasAggregationDetails: hasAggregationDetails,
        isAggregationDetail: false,
        code: hasAggregationDetails
          ? undefined
          : adjustedCharge?.code ?? orderCharge?.code ?? auditCharge?.code,
        description: hasAggregationDetails
          ? undefined
          : adjustedCharge?.description ??
            orderCharge?.description ??
            auditCharge?.description,
        tenantCode: hasAggregationDetails
          ? undefined
          : adjustedCharge?.tenantCode,
        tenantDescription: hasAggregationDetails
          ? undefined
          : adjustedCharge?.tenantDescription,
        details: childCharges.concat(aggregatedChilds),
        metadata: metadata,
        chargeSource: useSecondarySource ? secondarySource : source,
        originalAdjustment: undefined,
        originalCode: undefined,
        originalDescription: undefined
      }
    }
  }

  private getChargeData(
    charges: NormalizedResponse[],
    secondaryCharges: NormalizedResponse[],
    hasAggregationDetails: boolean,
    useSecondarySource: boolean
  ) {
    return hasAggregationDetails
      ? undefined
      : charges.at(0) ??
          (useSecondarySource ? secondaryCharges.at(0) : undefined)
  }

  private getDescription(
    descriptionToUse: string | undefined,
    codeToUse: string | undefined
  ) {
    const description = `${descriptionToUse ?? ''}${
      codeToUse ? ` (${codeToUse})` : ''
    }`
    return description === '' ? 'N/A' : description
  }

  private getAggregationDetails(
    parentTypeId: KnownChageTypes,
    adjustedCharges: NormalizedResponse[],
    orderCharges: NormalizedResponse[],
    auditCharges: NormalizedResponse[],
    source: ChargesGroupNames
  ): InvoiceCharge[] {
    const aggregatedAdjustedCharges = adjustedCharges.map<InvoiceCharge>(
      charge => ({
        ...this.getAggregatedChargeCommonProperties(
          charge,
          parentTypeId,
          source
        ),
        adjustedComparerAmount: charge.chargeAmount,
        adjustedAmount: charge.adjustedChargeAmount,
        adjustedChargeId: charge.chargeId,
        varianceReasonId: charge.varianceReasonId
      })
    )
    const aggregatedOrderCharges = orderCharges.map<InvoiceCharge>(charge => ({
      ...this.getAggregatedChargeCommonProperties(charge, parentTypeId, source),
      orderAmount: charge.chargeAmount,
      orderChargeId: charge.chargeId
    }))
    const aggregatedAuditCharges = auditCharges.map<InvoiceCharge>(charge => ({
      ...this.getAggregatedChargeCommonProperties(charge, parentTypeId, source),
      auditAmount: charge.chargeAmount
    }))

    const aggregatedOrderChargesIsPairWithAdjusted = Array.from({
      length: aggregatedOrderCharges.length
    }).fill(false)
    const aggregatedAuditChargesIsPairWithAdjusted = Array.from({
      length: aggregatedAuditCharges.length
    }).fill(false)

    if (source === 'customer') {
      const adjustedChargesWithDescriptionMatches =
        aggregatedAdjustedCharges.map(charge => {
          let proccessedCharge = {
            ...charge
          }
          const orderDescriptionMatch = aggregatedOrderCharges.filter(
            orderCharge => charge.description === orderCharge.description
          )

          const auditDescriptionMatch = aggregatedAuditCharges.filter(
            auditCharge => charge.description === auditCharge.description
          )
          if (orderDescriptionMatch.length === 1) {
            const orderChargeIndex = aggregatedOrderCharges.findIndex(
              orderCharge =>
                orderCharge.orderChargeId ===
                orderDescriptionMatch[0].orderChargeId
            )
            aggregatedOrderChargesIsPairWithAdjusted[orderChargeIndex] = true
            proccessedCharge = {
              ...proccessedCharge,
              orderAmount: orderDescriptionMatch[0].orderAmount,
              orderChargeId: orderDescriptionMatch[0].orderChargeId
            }
          }

          if (auditDescriptionMatch.length === 1) {
            const auditChargeIndex = aggregatedAuditCharges.findIndex(
              auditCharge => auditCharge.guid === auditDescriptionMatch[0].guid
            )
            aggregatedAuditChargesIsPairWithAdjusted[auditChargeIndex] = true
            proccessedCharge = {
              ...proccessedCharge,
              auditAmount: auditDescriptionMatch[0].auditAmount
            }
          }

          return proccessedCharge
        })

      const orderChargesToConcat = aggregatedOrderChargesIsPairWithAdjusted
        .map((isPairWithAdjusted, index) =>
          isPairWithAdjusted ? undefined : aggregatedOrderCharges[index]
        )
        .filter(charge => charge !== undefined) as InvoiceCharge[]

      const auditChargesToConcat = aggregatedAuditChargesIsPairWithAdjusted
        .map((isPairWithAdjusted, index) =>
          isPairWithAdjusted ? undefined : aggregatedAuditCharges[index]
        )
        .filter(charge => charge !== undefined) as InvoiceCharge[]

      return adjustedChargesWithDescriptionMatches
        .concat(orderChargesToConcat)
        .concat(auditChargesToConcat)
        .filter(charge => charge !== undefined)
    }

    return aggregatedAdjustedCharges
      .concat(aggregatedOrderCharges)
      .concat(aggregatedAuditCharges)
  }

  private getEmptyAggregationDetails(
    typeId: KnownChageTypes,
    adjustedCharges: NormalizedResponse[],
    orderCharges: NormalizedResponse[],
    auditCharges: NormalizedResponse[],
    source: ChargesGroupNames
  ): InvoiceCharge[] {
    return this.getAggregationDetails(
      typeId,
      adjustedCharges,
      orderCharges,
      auditCharges,
      source
    ).map(charge => ({
      ...charge,
      adjustedAmount: undefined,
      adjustedComparerAmount: undefined,
      varianceReasonId: undefined,
      orderAmount: undefined,
      auditAmount: undefined
    }))
  }

  private getNormalizedResponse([
    ,
    vendorInvoiceCharges,
    vendorAuditCharges,
    vendorOrderCharges,
    customerOrderCharges,
    customerAuditCharges,
    customerAdjustedCharges
  ]: Response): ChargesResponse {
    return {
      vendor: {
        adjustedCharges: vendorInvoiceCharges.map<NormalizedResponse>(
          charge => ({
            chargeId: charge.vendorInvoiceChargeId,
            ...this.getNormalizedChargeWithDefaultProperties(charge),
            adjustedChargeAmount: charge.adjustedCharge,
            varianceReasonId: charge.varianceReasonId,
            tenantCode: charge.tenantCode,
            tenantDescription: charge.tenantDescription
          })
        ),

        auditCharges: vendorAuditCharges.map<NormalizedResponse>(charge => ({
          chargeId: charge.auditChargeId,
          ...this.getNormalizedChargeWithDefaultProperties(charge)
        })),
        orderCharges: vendorOrderCharges.map<NormalizedResponse>(charge => ({
          chargeId: charge.orderChargeId,
          ...this.getNormalizedChargeWithDefaultProperties(charge)
        }))
      },
      customer: {
        adjustedCharges: customerAdjustedCharges.map<NormalizedResponse>(
          charge => ({
            chargeId: charge.customerAdjustedChargeId,
            ...this.getNormalizedChargeWithDefaultProperties(charge),
            adjustedChargeAmount: charge.adjustedCharge,
            varianceReasonId: charge.varianceReasonId,
            tenantCode: charge.code,
            tenantDescription: charge.description
          })
        ),
        auditCharges: customerAuditCharges.map<NormalizedResponse>(charge => ({
          chargeId: charge.customerAuditChargeId,
          ...this.getNormalizedChargeWithDefaultProperties(charge)
        })),
        orderCharges: customerOrderCharges.map<NormalizedResponse>(charge => ({
          chargeId: charge.customerOrderChargeId,
          ...this.getNormalizedChargeWithDefaultProperties(charge)
        }))
      }
    }
  }

  private getNormalizedChargeWithDefaultProperties(
    charge:
      | GetInvoiceChargesNewResponse[number]
      | GetInvoiceAuditChargesResponse[number]
      | GetOrderChargesResponse[number]
      | GetCustomerOrderChargesNewResponse[number]
      | GetInvoiceCustomerAuditChargesNewResponse[number]
      | GetInvoiceCustomerAdjustedChargesNewResponse[number]
  ): Omit<NormalizedResponse, 'chargeId'> {
    return {
      typeId: charge.chargeTypeId,
      currency: charge.currency,
      code: charge.code,
      description: charge.description,
      metadata: charge.metadata,
      isLock: charge.isLock,
      varianceReasonId: undefined,
      chargeAmount: (charge['charge' as keyof typeof charge] as number) ?? 0,
      tenantCode: undefined,
      tenantDescription: undefined,
      adjustedChargeAmount: 0
    }
  }
  private getAggregatedChargeCommonProperties(
    charge: NormalizedResponse,
    parentTypeId: number,
    source: ChargesGroupNames
  ): InvoiceCharge {
    const descriptionToUse = charge?.tenantDescription ?? charge?.description
    const codeToUse = charge?.tenantCode ?? charge?.code
    const description = this.getDescription(descriptionToUse, codeToUse)

    return {
      guid: self.crypto.randomUUID(),
      parentTypeId: parentTypeId,
      typeId: charge.typeId,
      typeDescription: description,
      orderAmount: undefined,
      auditAmount: undefined,
      adjustedComparerAmount: undefined,
      adjustedAmount: undefined,
      adjustedChargeId: undefined,
      orderChargeId: undefined,
      varianceReasonId: undefined,
      hasAggregationDetails: false,
      isAggregationDetail: true,
      code: charge.code,
      description: charge.description,
      tenantCode: charge.tenantCode,
      tenantDescription: charge.tenantDescription,
      details: [],
      metadata: [],
      chargeSource: source,
      originalAdjustment: undefined,
      originalCode: undefined,
      originalDescription: undefined
    }
  }

  private getMetadata(metadataString?: string): {
    [key: string]: string
  } {
    try {
      return JSON.parse(metadataString ?? '{}')
    } catch (error) {
      console.warn('[NavixApp] Error parsing metadata: ', metadataString)

      return {}
    }
  }
}
