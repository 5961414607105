import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { on } from '@ngrx/store'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { InvoicesState, invoicesAdapter } from '../invoices.reducer'
import { invoiceDisputesActions } from './invoice-disputes.actions'

export const invoiceDisputesReducers = [
  on(invoiceDisputesActions.loadInvoiceDisputes, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.getDisputes,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    invoiceDisputesActions.loadInvoiceDisputesSuccess,
    (state: InvoicesState, { invoiceWithDisputes }): InvoicesState =>
      invoicesAdapter.upsertOne(invoiceWithDisputes, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.getDisputes,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    invoiceDisputesActions.loadInvoiceDisputesFail,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDisputes,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load invoice disputes."
      })
    })
  ),
  on(
    invoiceDisputesActions.resetGetDisputesLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDisputes,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    invoiceDisputesActions.loadInvoiceDisputesCommunication,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDisputesCommunication,
        status: LoadingStatuses.InProgress
      })
    })
  ),

  on(
    invoiceDisputesActions.loadInvoiceDisputesCommunicationSuccess,
    (state: InvoicesState, { invoiceWithDisputesCommunication }) =>
      invoicesAdapter.upsertOne(invoiceWithDisputesCommunication, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.getDisputesCommunication,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    invoiceDisputesActions.loadInvoiceDisputesCommunicationFail,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDisputesCommunication,
        status: LoadingStatuses.Failed,
        message:
          "There's an error trying to load invoice disputes communications."
      })
    })
  ),

  on(
    invoiceDisputesActions.resetGetDisputesCommunicationLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDisputesCommunication,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(invoiceDisputesActions.resolveDisputeReasons, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.resolveDisputeReasons,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    invoiceDisputesActions.resolveDisputeReasonsSuccess,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.resolveDisputeReasons,
        status: LoadingStatuses.Completed,
        message: 'Dispute reasons resolved successfully.'
      })
    })
  ),
  on(
    invoiceDisputesActions.resolveDisputeReasonsFail,
    (state: InvoicesState, httpError) => {
      const message = String(httpError.error.error.message)

      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.resolveDisputeReasons,
          status: LoadingStatuses.Failed,
          message:
            message ?? "There's an error trying to resolve dispute reasons."
        })
      }
    }
  ),
  on(
    invoiceDisputesActions.resetResolveDisputeReasonsLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.resolveDisputeReasons,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    invoiceDisputesActions.addInvoiceDisputes,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceDisputes,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDisputesActions.addInvoiceDisputesSuccess,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceDisputes,
        status: LoadingStatuses.Completed,
        message: 'Invoice dispute created successfully.'
      })
    })
  ),
  on(
    invoiceDisputesActions.addInvoiceDisputesFail,
    (state: InvoicesState, { error }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceDisputes,
        status: LoadingStatuses.Failed,
        message: error
      })
    })
  ),
  on(
    invoiceDisputesActions.resetAddInvoiceDisputesLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceDisputes,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    invoiceDisputesActions.sendReplyCommunication,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.sendReplyCommunication,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDisputesActions.sendReplyCommunicationSuccess,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.sendReplyCommunication,
        status: LoadingStatuses.Completed,
        message: 'Reply communication sent successfully.'
      })
    })
  ),
  on(
    invoiceDisputesActions.sendReplyCommunicationFail,
    (state: InvoicesState, { error }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.sendReplyCommunication,
        status: LoadingStatuses.Failed,
        message:
          error.status === 500
            ? 'We encountered a problem - please try again'
            : error.error.message
      })
    })
  ),
  on(
    invoiceDisputesActions.resetSendReplyCommunicationLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.sendReplyCommunication,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    invoiceDisputesActions.downloadAttachment,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.downloadAttachment,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDisputesActions.downloadAttachmentSuccess,
    (state: InvoicesState, { blob, filename }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.downloadAttachment,
        status: LoadingStatuses.Completed,
        message: 'Attachment downloaded successfully.',
        metadata: { blob, filename }
      })
    })
  ),
  on(
    invoiceDisputesActions.downloadAttachmentFail,
    (state: InvoicesState, { error }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.downloadAttachment,
        status: LoadingStatuses.Failed,
        message: error.error.message
      })
    })
  ),
  on(
    invoiceDisputesActions.resetDownloadAttachmentLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.downloadAttachment,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    invoiceDisputesActions.loadMultipleInvoicesDisputes,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getMultipleInvoicesDisputes,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDisputesActions.loadMultipleInvoicesDisputesSuccess,
    (state: InvoicesState, { hasDisputes }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getMultipleInvoicesDisputes,
        status: LoadingStatuses.Completed,
        metadata: { hasDisputes }
      })
    })
  ),
  on(
    invoiceDisputesActions.loadMultipleInvoicesDisputesFail,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getMultipleInvoicesDisputes,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load invoice disputes."
      })
    })
  ),
  on(
    invoiceDisputesActions.resetGetDisputesLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getMultipleInvoicesDisputes,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
