import { IStrategy } from '@navix/utils/adapter'
import { Invoice } from '../../domain/invoices/invoice.model'
import { GetDuplicateInvoicesResponse } from '../../domain/invoices/get-duplicate-invoices.response'

export class FromGetDuplicateInvoicesResponse
  implements IStrategy<GetDuplicateInvoicesResponse, Invoice[]>
{
  convert(response: GetDuplicateInvoicesResponse): Invoice[] {
    if (!Array.isArray(response.value)) return []

    return response.value.map<Invoice>(
      duplicateInvoice =>
        <Invoice>{
          id: duplicateInvoice.VendorInvoiceId,
          invoiceNumber: duplicateInvoice.InvoiceNumber,
          shipmentNumber: duplicateInvoice.ShipmentNumber,
          shipDate: isNaN(Date.parse(duplicateInvoice.ShipDate ?? ''))
            ? undefined
            : new Date(duplicateInvoice?.ShipDate as string),
          createdDate: isNaN(Date.parse(duplicateInvoice.CreatedDate ?? ''))
            ? undefined
            : new Date(duplicateInvoice.CreatedDate as string),
          parentInvoiceId: duplicateInvoice.ParentInvoiceId as number,
          statusDescription: duplicateInvoice.VendorInvoiceStatusDescription,
          statusId: duplicateInvoice.VendorInvoiceStatusDescriptionId,

          netCharge: duplicateInvoice.VendorNetCharge ?? 0,
          vendorInvoiceTypeId:
            duplicateInvoice.VendorInvoiceTypeId ?? undefined,
          orders: duplicateInvoice.OrderNumbers?.split(','),
          vendorDescription: duplicateInvoice.VendorDescription,
          fromDuplicates: true
        }
    )
  }
}
