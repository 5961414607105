import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { InvoiceForm } from '../../../domain/invoices/invoice-form.model'
import { HttpErrorResponse } from '@angular/common/http'

export const invoiceVendorDetailsActions = createActionGroup({
  source: 'Invoice Vendor Details',
  events: {
    'update invoice freight charge terms': props<{
      id: number
      newFreightChageTerms: { description: string }
    }>(),
    'update invoice freight charge terms success': props<{
      invoiceId: number
    }>(),
    'update invoice freight charge terms fail': props<{
      error: HttpErrorResponse
    }>(),

    'update invoice vendor dates': props<{
      id: number
      shipDate: Date
      deliveryDate?: Date
      invoiceDate: Date
    }>(),
    'update invoice vendor dates success': props<{ invoiceId: number }>(),
    'update invoice vendor dates fail': props<{ error: HttpErrorResponse }>(),

    'update invoice vendor': props<{
      id: number
      newVendor: { id: number }
      fromModal: boolean
    }>(),
    'update invoice vendor success': props<{
      invoiceId: number
      newVendorId: number
      fromModal: boolean
    }>(),
    'update invoice vendor fail': props<{ error: HttpErrorResponse }>(),
    resetUpdateInvoiceVendorLoadingState: emptyProps(),
    updateInvoiceVendorInState: props<{
      id: number
      newVendor: { id: number; uuid: string; externalId: string }
    }>(),

    'update invoice vendor stops': props<Pick<InvoiceForm, 'stops' | 'id'>>(),
    'update invoice vendor stops success': props<{ invoiceId: number }>(),
    'update invoice vendor stops fail': props<{ error: HttpErrorResponse }>()
  }
})
