import { createActionGroup, props } from '@ngrx/store'

export const invoiceCustomerDetailsActions = createActionGroup({
  source: 'Invoice Customer Details',
  events: {
    'update invoice customer': props<{
      id: number
      newCustomer: { id: number }
    }>(),
    'update invoice customer success': props<{ invoiceId: number }>(),
    'update invoice customer fail': props<{ error: unknown }>()
  }
})
