import { Injectable } from '@angular/core'
import { Invoice } from '../domain/invoices/invoice.model'

@Injectable({
  providedIn: 'root'
})
export class InvoicesOrdersViewService {
  private popup: Window | undefined | null
  private readonly DEFAULT_SCREEN_WIDTH = 1455 as const
  private readonly DEFAULT_SCREEN_HEIGHT = 800 as const
  private readonly DEFAULT_SCREEN_Y_POSITION = 100 as const
  private screenX = screen.width / 2 - this.DEFAULT_SCREEN_WIDTH / 2
  private screenY: number = this.DEFAULT_SCREEN_Y_POSITION
  private invoicesOrdersURL = '/orders'

  private updateScreenCoordinates(screenX: number, screenY: number) {
    this.screenX = screenX
    this.screenY = screenY
  }

  private buildWindowParams() {
    return `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
          width=${this.DEFAULT_SCREEN_WIDTH},height=${this.DEFAULT_SCREEN_HEIGHT},
          screenX=${this.screenX},screenY=${this.screenY},target=_self`
  }

  private openInvoicesOrdersWindow() {
    const params = this.buildWindowParams()
    this.popup = open(this.invoicesOrdersURL, 'Orders Data', params)

    if (this.popup) {
      this.popup.onunload = event => {
        const { screenX, screenY } = event.composedPath?.()[0] as Window
        this.updateScreenCoordinates(screenX, screenY)
      }
    }
  }

  openInvoicesOrdersInNewWindow(orderIds: Invoice['ordersIds']) {
    const data = btoa(JSON.stringify({ orderIds }))

    const { screenX, screenY } = this.popup || {}

    if (screenX && screenY) {
      this.updateScreenCoordinates(screenX, screenY)
    }

    this.invoicesOrdersURL = `standalone/orders/s/details/clear/${orderIds![0].id}_${orderIds![0].legacyId}?data=${data}`

    this.openInvoicesOrdersWindow()
  }
}
