import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { UpdateInvoiceCustomerChargesBatchRequest } from '../../domain/invoices/update-invoice-customer-charges-batch.request'
import { KnownChageTypes } from '../../+state/invoices/static'

export class ToUpdateInvoiceCustomerChargesBatchRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'charges' | 'id' | 'currencyId'>,
      UpdateInvoiceCustomerChargesBatchRequest
    >
{
  convert(
    request: Pick<InvoiceForm, 'charges' | 'id' | 'currencyId'>
  ): UpdateInvoiceCustomerChargesBatchRequest {
    const items = request.charges
      .filter(charge => typeof charge.typeId === 'number')
      .filter(charge => {
        const isUnknownAccesorials =
          charge.typeId === KnownChageTypes.UnknownAccesorials
        const isAdjusted = charge.kind === 'adjusted'
        const isValidAdjustedAmount =
          Number(charge.adjustedAmount ?? 0) !== 0 ||
          (charge.originalAdjustment !== null &&
            charge.originalAdjustment !== undefined)
        const hasVarianceReasonId =
          charge.varianceReasonId !== null &&
          charge.varianceReasonId !== undefined
        const hasValidAdjustedChargeId =
          charge.adjustedChargeId !== undefined &&
          charge.adjustedChargeId !== null &&
          !isNaN(Number(charge.adjustedChargeId))

        const isValidUnknownAccesorial =
          isUnknownAccesorials && hasValidAdjustedChargeId
        const isValidAdjustedCharge =
          (isAdjusted || isValidAdjustedAmount) &&
          (isValidAdjustedAmount ||
            hasVarianceReasonId ||
            hasValidAdjustedChargeId)

        const isValidCharge = isUnknownAccesorials
          ? isValidUnknownAccesorial
          : isValidAdjustedCharge

        return isValidCharge
      })
      .map(charge => {
        const chargeId = isNaN(Number(charge.adjustedChargeId))
          ? undefined
          : Number(charge.adjustedChargeId)
        return {
          chargeTypeId: charge.typeId as number,
          adjustedCharge: charge.adjustedAmount as number,
          customerAdjustedChargeId: chargeId ?? undefined,
          code: charge.tenantCode ?? undefined,
          description: charge.tenantDescription ?? undefined,
          varianceReasonId: charge.varianceReasonId as number
        }
      })

    return {
      currencyId: request.currencyId ?? 1,
      items: items,
      vendorInvoiceId: request.id as number
    }
  }
}
