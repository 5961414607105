import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { InvoiceForm } from '../../../domain/invoices/invoice-form.model'
import { HttpErrorResponse } from '@angular/common/http'

export const invoiceDetailsActions = createActionGroup({
  source: 'Invoice Details',
  events: {
    'load invoice details': props<{
      invoiceId: number
      ignoreNewSelectedId?: boolean
    }>(),
    'load invoice details success': props<{ invoice: Invoice }>(),
    'load invoice details fail': props<{ error: unknown }>(),
    resetLoadInvoiceDetailsLoadingState: emptyProps(),

    'update invoice other details': props<{
      invoice: Pick<InvoiceForm, 'otherDetails' | 'id'>
    }>(),
    'update invoice other details success': props<{
      invoiceId: number
    }>(),
    'update invoice other details fail': props<{ error: HttpErrorResponse }>(),
    'reset update invoice other details loading state': emptyProps(),

    'add tags': props<{
      invoiceId: number
      tag: Record<string, object | null>
    }>(),
    'add tags success': props<{
      invoiceId: number
    }>(),
    'add tags fail': props<{ error: HttpErrorResponse }>(),
    'add tags loading state': emptyProps(),

    'remove tags': props<{
      invoiceId: number
      tag: Record<string, object | null>
    }>(),
    'remove tags success': props<{
      invoiceId: number
    }>(),
    'remove tags fail': props<{ error: HttpErrorResponse }>(),
    'remove tags loading state': emptyProps(),

    'load invoice tenant id': props<{
      invoiceId: number
    }>(),
    'load invoice tenant id success': props<{
      invoiceId: number
      tenant: Invoice['tenant']
    }>(),
    'load invoice tenant id fail': props<{
      error: unknown
    }>()
  }
})
