export type FreightChargeTerm = {
  description: 'Prepaid & Add' | 'Prepaid' | 'Collect' | 'Third Party'
}
export const freightTermMap = {
  'Prepaid & Add': 'PrepaidAdd',
  Prepaid: 'Prepaid',
  Collect: 'Collect',
  'Third Party': 'ThirdParty'
} as const

export const reverseFreightTermMap = Object.fromEntries(
  Object.entries(freightTermMap).map(([key, value]) => [value, key])
) as Record<
  (typeof freightTermMap)[keyof typeof freightTermMap],
  keyof typeof freightTermMap
>
