import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { InvoicesFacade } from '../invoices.facade'
import { InvoicesAdapter } from '../../../adapter/invoices/InvoicesAdapter'
import { FromGetInvoiceDocumentsResponse } from '../../../adapter/invoices/FromGetInvoiceDocumentsResponse'
import {
  catchError,
  debounceTime,
  exhaustMap,
  finalize,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  throwError,
  zip
} from 'rxjs'

import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import {
  DELETE_INVOICE_DOCUMENTS_DIALOG,
  MS_BEFORE_RESET_LOADING_STATE
} from '../invoices.constants'
import { Dialog } from '@angular/cdk/dialog'

import { AddInvoiceDocumentRequest } from '../../../domain/invoices/add-invoice-document.request'
import { invoiceDocumentsActions } from './invoice-documents.actions'
import { invoiceDetailsActions } from '../invoice-details/invoice-details.actions'
import { UpdateInvoiceDocumentRequest } from '../../../domain/invoices/update-invoice-document.request'
import { platform_v1, platform_v2 } from '@navix/api-models'

@Injectable()
export class InvoiceDocumentsEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private documentsService = inject(platform_v2.DocumentsService)
  private documentsServiceV1 = inject(platform_v1.DocumentsService)
  private alertsFacade = inject(LegacyAlertsFacade)
  private invoicesFacade = inject(InvoicesFacade)
  private dialog = inject(Dialog)

  private invoiceAdapter = new InvoicesAdapter()

  constructor() {
    this.invoiceAdapter.set(FromGetInvoiceDocumentsResponse)
  }

  loadInvoiceDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.loadInvoiceDocuments),
      tap(() => this.invoicesFacade.startLoading(AsyncOperations.getDocuments)),
      mergeMap(({ invoiceId }) =>
        this.invoicesService.getInvoiceDocuments(invoiceId).pipe(
          map(response =>
            this.invoiceAdapter.convert(
              FromGetInvoiceDocumentsResponse,
              response
            )
          ),
          map(documents =>
            invoiceDocumentsActions.loadInvoiceDocumentsSuccess({
              documents,
              invoiceId
            })
          ),
          tap(() =>
            this.invoicesFacade.endLoading(AsyncOperations.getDocuments)
          ),
          catchError(error =>
            of(invoiceDocumentsActions.loadInvoiceDocumentsFail({ error }))
          )
        )
      )
    )
  )

  loadInvoiceDocumentsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceDocumentsActions.loadInvoiceDocumentsFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load invoice documents."
          })
        })
      ),
    { dispatch: false }
  )

  updateInvoiceDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.updateInvoiceDocuments),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.updateInvoiceDocuments)
      ),
      map(({ documents, id }) => ({
        requests: documents.map<UpdateInvoiceDocumentRequest>(document => ({
          documentId: Number(document.id),
          invoiceId: Number(id),
          vendorInvoiceDocumentTypeId: Number(document.typeId),
          hasSignature: document.hasSignature,
          signatureDate: document.signatureDate?.toISOString()
        })),
        invoiceId: id as number
      })),
      exhaustMap(({ requests, invoiceId }) =>
        zip(
          requests.map(request =>
            this.invoicesService.updateInvoiceDocument(request)
          )
        ).pipe(
          map(() =>
            invoiceDocumentsActions.updateInvoiceDocumentsSuccess({
              invoiceId
            })
          ),
          catchError(error =>
            of(
              invoiceDocumentsActions.updateInvoiceDocumentsFail({
                error
              })
            )
          ),
          tap(() =>
            this.invoicesFacade.endLoading(
              AsyncOperations.updateInvoiceDocuments
            )
          )
        )
      )
    )
  )

  updateInvoiceDocumentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.updateInvoiceDocumentsSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: ` invoice documents has been updateed successfully.`,
          alertType: 'success'
        })
      }),
      switchMap(({ invoiceId }) =>
        of(
          invoiceDocumentsActions.loadInvoiceDocuments({
            invoiceId: invoiceId
          }),
          invoiceDetailsActions.loadInvoiceDetails({
            invoiceId
          })
        )
      )
    )
  )

  updateInvoiceDocumentsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceDocumentsActions.updateInvoiceDocumentsFail),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''

          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : "There's an error trying to update invoice documents."
          })
        })
      ),
    { dispatch: false }
  )

  deleteInvoiceDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.deleteInvoiceDocuments),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.deleteInvoiceDocuments)
      ),
      exhaustMap(({ documentId, invoiceId }) =>
        this.invoicesService.deleteDocuments(invoiceId, documentId).pipe(
          map(() =>
            invoiceDocumentsActions.deleteInvoiceDocumentsSuccess({ invoiceId })
          ),
          catchError(error =>
            of(
              invoiceDocumentsActions.deleteInvoiceDocumentsFail({
                error
              })
            )
          ),
          finalize(() =>
            this.invoicesFacade.endLoading(
              AsyncOperations.deleteInvoiceDocuments
            )
          )
        )
      )
    )
  )

  deleteInvoiceDocumentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.deleteInvoiceDocumentsSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Document was successfully deleted.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.dialog.getDialogById(DELETE_INVOICE_DOCUMENTS_DIALOG)?.close()
      ),
      switchMap(({ invoiceId }) =>
        of(
          invoiceDocumentsActions.loadInvoiceDocuments({
            invoiceId: invoiceId
          }),
          invoiceDetailsActions.loadInvoiceDetails({
            invoiceId
          })
        )
      )
    )
  )

  deleteInvoiceDocumentsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceDocumentsActions.deleteInvoiceDocumentsFail),
        tap(error => console.error(error)),
        tap(httpError => {
          const {
            error: { error }
          } = httpError
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              error?.message?.length > 0
                ? error.message
                : `There's an error trying to delete document.`
          })
        })
      ),
    { dispatch: false }
  )

  downloadInvoiceDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.downloadInvoiceDocument),
      tap(({ documentId }) => {
        this.alertsFacade.addAlert({
          label: `Document is downloading.`,
          alertType: 'success',
          duration: 6000000,
          canClose: false,
          displaySpinner: true,
          id: documentId
        })
      }),
      switchMap(({ documentId }) =>
        this.invoicesService.downloadInvoiceDocument(documentId).pipe(
          map(response => ({
            blob: <Blob>response.body,
            filename: /(?:filename=")(.+)(?:;")/.exec(
              response.headers.get('Content-Disposition') ?? ''
            )?.[1]
          })),
          map(({ blob, filename }) =>
            invoiceDocumentsActions.downloadInvoiceDocumentSuccess({
              filename,
              documentId,
              blob
            })
          ),
          catchError(httpError =>
            of(
              invoiceDocumentsActions.downloadInvoiceDocumentFail({
                error: httpError.error
              })
            )
          ),
          finalize(() => {
            this.alertsFacade.removeAlert({ id: documentId })
          })
        )
      )
    )
  )

  downloadInvoiceDocumentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceDocumentsActions.downloadInvoiceDocumentSuccess),
        concatLatestFrom(() => this.invoicesFacade.selectedInvoice$),
        switchMap(([{ blob, filename, documentId }, invoice]) => {
          if (filename) return of({ blob, filename })

          const document = invoice?.documents?.find(
            document => document.id === documentId
          )

          return of({
            blob,
            filename: document?.name as string
          })
        }),
        tap(({ blob, filename }) => {
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = filename
          a.click()
          URL.revokeObjectURL(objectUrl)
        }),
        tap(() => {
          this.alertsFacade.addAlert({
            label: `Download document successfully`,
            alertType: 'success'
          })
        })
      ),
    { dispatch: false }
  )

  downloadInvoiceDocumentFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceDocumentsActions.downloadInvoiceDocumentFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to download the document."
          })
        })
      ),
    { dispatch: false }
  )

  addInvoiceDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.addInvoiceDocuments),
      concatLatestFrom(() => this.invoicesFacade.selectedInvoiceId$),
      map(([{ documents }, invoiceId]) => ({
        requests: documents.map<AddInvoiceDocumentRequest>(document => ({
          VendorInvoiceDocumentTypeId: Number(document.typeId),
          Document: document.file as File,
          invoiceId: invoiceId as number,
          hasSignature: document.hasSignature,
          signatureDate: document.signatureDate?.toISOString()
        })),
        invoiceId: invoiceId as number
      })),
      exhaustMap(({ requests, invoiceId }) =>
        zip(
          requests.map(request =>
            this.invoicesService.addInvoiceDocument(request)
          )
        ).pipe(
          map(() =>
            invoiceDocumentsActions.addInvoiceDocumentsSuccess({
              invoiceId
            })
          ),
          catchError(error =>
            of(
              invoiceDocumentsActions.addInvoiceDocumentsFail({
                error
              })
            )
          ),
          finalize(() => {
            this.invoicesFacade.resetLoading(
              AsyncOperations.addInvoiceDocuments
            )
          })
        )
      )
    )
  )

  addInvoiceDocumentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.addInvoiceDocumentsSuccess),
      switchMap(({ invoiceId }) =>
        of(
          invoiceDocumentsActions.loadInvoiceDocuments({
            invoiceId: invoiceId
          }),
          invoiceDetailsActions.loadInvoiceDetails({
            invoiceId
          })
        )
      )
    )
  )

  generateInvoiceDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.generateDefaultInvoiceDocument),
      concatLatestFrom(() => this.invoicesFacade.selectedInvoiceWithDetails$),
      map(([, invoice]) => ({
        invoiceId: invoice.id
      })),
      exhaustMap(({ invoiceId }) =>
        this.invoicesService.generateDefaultInvoiceDocument(invoiceId).pipe(
          map(() =>
            invoiceDocumentsActions.generateDefaultInvoiceDocumentSuccess({
              invoiceId
            })
          ),
          catchError(error =>
            of(
              invoiceDocumentsActions.generateDefaultInvoiceDocumentFail({
                error
              })
            )
          ),
          tap(() =>
            this.invoicesFacade.endLoading(
              AsyncOperations.generateInvoiceDocument
            )
          )
        )
      )
    )
  )

  generateInvoiceDocumentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.generateDefaultInvoiceDocumentSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Invoice document has been generated successfully.`,
          alertType: 'success'
        })
      }),
      switchMap(({ invoiceId }) =>
        of(
          invoiceDocumentsActions.loadInvoiceDocuments({
            invoiceId: invoiceId
          }),
          invoiceDetailsActions.loadInvoiceDetails({
            invoiceId
          })
        )
      )
    )
  )

  generateDefaultInvoiceDocumentFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceDocumentsActions.generateDefaultInvoiceDocumentFail),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''

          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : "There's an error trying to generate invoice documents"
          })
        })
      ),
    { dispatch: false }
  )

  mergeInvoiceDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.mergeInvoiceDocuments),
      exhaustMap(({ requests }) =>
        zip(
          requests.map(request =>
            this.invoicesService.mergeInvoiceDocuments(
              request.invoiceId,
              request.documentIds
            )
          )
        ).pipe(
          map(() =>
            invoiceDocumentsActions.mergeInvoiceDocumentsSuccess({
              requests
            })
          ),
          catchError(httpError =>
            of(
              invoiceDocumentsActions.mergeInvoiceDocumentsFail({
                error: httpError.error
              })
            )
          ),
          finalize(() =>
            this.invoicesFacade.endLoading(
              AsyncOperations.mergeInvoiceDocuments
            )
          )
        )
      )
    )
  )

  mergeInvoiceDocumentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.mergeInvoiceDocumentsSuccess),
      exhaustMap(({ requests }) =>
        of(
          ...requests.map(request =>
            invoiceDocumentsActions.loadInvoiceDocuments({
              invoiceId: request.invoiceId
            })
          )
        )
      )
    )
  )

  mergeInvoiceDocumentsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceDocumentsActions.mergeInvoiceDocumentsFail),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''

          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : "There's an error trying to merge invoice documents"
          })
        })
      ),
    { dispatch: false }
  )

  attachDocumentsToInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.attachDocumentsToInvoice),
      map(({ documents, uuid }) => {
        return {
          requests:
            documents.map<platform_v2.Invoice_Models_Requests_VendorInvoiceUrlDocumentRequest>(
              document => ({
                vendorInvoiceDocumentTypeId: Number(document.typeId),
                url: document.fileUrl,
                name: document.fileName,
                hasSignature: document.hasSignature,
                signatureDate: document.signatureDate?.toISOString()
              })
            ),
          uuid: uuid
        }
      }),
      exhaustMap(({ requests, uuid }) =>
        zip(
          requests.map(request =>
            this.documentsService
              .postApiV2InvoicesDocumentsAttach({
                invoiceUuid: uuid as string,
                requestBody: request
              })
              .pipe(
                map(() => ({ success: true })),
                catchError(() => of({ success: false }))
              )
          )
        ).pipe(
          map(result => {
            if (result.every(r => !r.success)) throw new Error()
            return invoiceDocumentsActions.attachDocumentsToInvoiceSuccess({
              allFinished: result.every(r => r.success) ? true : false
            })
          }),
          catchError(error =>
            of(
              invoiceDocumentsActions.attachDocumentsToInvoiceFail({
                error
              })
            )
          )
        )
      )
    )
  )

  resetUpdateInvoiceChargesLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDocumentsActions.attachDocumentsToInvoiceSuccess,
        invoiceDocumentsActions.attachDocumentsToInvoiceFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        invoiceDocumentsActions.resetAttachDocumentsToInvoiceLoadingState()
      )
    )
  )

  redactDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDocumentsActions.redactDocument),
      map(({ invoiceUuid, blob, originalDocumentUuid }) => ({
        request: {
          VendorInvoiceDocumenUuid: originalDocumentUuid,
          Document: new File([blob], 'redacted.pdf')
        },
        invoiceUuid: invoiceUuid
      })),
      exhaustMap(({ request, invoiceUuid }) =>
        this.documentsServiceV1
          .postApiV1InvoicesRedactedDocuments({
            vendorInvoiceUuid: invoiceUuid,
            formData: request
          })
          .pipe(
            map(() => invoiceDocumentsActions.redactDocumentSuccess()),
            catchError(error => {
              let errorMessage
              if (error.status === 403) {
                errorMessage = error.body.message
              }
              console.error(
                'There was an error trying to add the dispute reason groups',
                error
              )
              return of(
                invoiceDocumentsActions.redactDocumentFail({
                  error: errorMessage
                })
              )
            })
          )
      )
    )
  )

  resetRedactDocumentLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDocumentsActions.redactDocument,
        invoiceDocumentsActions.redactDocumentSuccess
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoiceDocumentsActions.resetRedactDocumentLoadingState())
    )
  )

  // addInvoiceDocumentsFail$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(invoiceDocumentsActions.addInvoiceDocumentsFail)
  //     ),
  //   { dispatch: false }
  // )
}
