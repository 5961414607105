import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of, tap } from 'rxjs'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { InvoicesFacade } from '../invoices.facade'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { invoiceExceptionsActions } from './invoice-exceptions.actions'
import { MS_BEFORE_RESET_LOADING_STATE } from '../invoices.constants'

@Injectable()
export class InvoiceExceptionsEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private invoicesFacade = inject(InvoicesFacade)
  private alertsFacade = inject(LegacyAlertsFacade)
  EXCEPTION_CATEGORY_WORK_INSTRUCTIONS_ID = 8

  loadInvoiceExceptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceExceptionsActions.loadInvoiceExceptions),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.getExceptions)
      ),
      exhaustMap(({ invoiceId }) =>
        this.invoicesService.getInvoiceExceptions(invoiceId).pipe(
          map(response =>
            response
              .map(
                item =>
                  <Invoice['exceptions'][number]>{
                    categoryId: item.exceptionCategoryId,
                    categoryExceptions: item.invoiceExceptions.map(
                      invoiceException => ({
                        id: invoiceException.invoiceExceptionId,
                        message: invoiceException.message,
                        statusId: invoiceException.exceptionStatusId,
                        statusDescription: invoiceException.exceptionStatus,
                        isApproveBlocker:
                          invoiceException.isApproveBlocker ?? false
                      })
                    )
                  }
              )
              .filter(
                x =>
                  x.categoryId !== this.EXCEPTION_CATEGORY_WORK_INSTRUCTIONS_ID
              )
          ),
          map(invoiceExceptions =>
            invoiceExceptionsActions.loadInvoiceExceptionsSuccess({
              invoiceExceptions,
              invoiceId
            })
          ),
          debounceTime(MS_BEFORE_RESET_LOADING_STATE),
          tap(() =>
            this.invoicesFacade.endLoading(AsyncOperations.getExceptions)
          ),
          catchError(error =>
            of(invoiceExceptionsActions.loadInvoiceExceptionsFail({ error }))
          )
        )
      )
    )
  )

  loadInvoiceExceptionsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceExceptionsActions.loadInvoiceExceptionsFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load invoice exceptions."
          })
        })
      ),
    { dispatch: false }
  )

  loadWorkInstructions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceExceptionsActions.loadWorkInstructions),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.getWorkInstructions)
      ),
      exhaustMap(({ invoiceUuid, invoiceId }) =>
        this.invoicesService.getWorkInstructions(invoiceUuid).pipe(
          map(response => ({
            invoice: {
              id: invoiceId,
              workInstructions: response as Invoice['workInstructions']
            } as Invoice
          })),
          map(({ invoice }) =>
            invoiceExceptionsActions.loadWorkInstructionsSuccess({
              invoiceWithWorkInstructions: invoice
            })
          ),
          catchError(error =>
            of(invoiceExceptionsActions.loadWorkInstructionsFail({ error }))
          )
        )
      )
    )
  )

  resetLoadWorkInstructionLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceExceptionsActions.loadWorkInstructionsSuccess,
        invoiceExceptionsActions.loadWorkInstructionsFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        invoiceExceptionsActions.resetLoadWorkInstructionsLoadingStatus()
      )
    )
  )

  loadWorkInstructionsPhase2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceExceptionsActions.loadWorkInstructionsPhase2),
      exhaustMap(({ invoiceUuid, invoiceId }) =>
        this.invoicesService.getWorkInstructionsPhase2(invoiceUuid).pipe(
          map(response => ({
            invoice: {
              id: invoiceId,
              workInstructionsPhase2: response.map(workInstruction => ({
                id: workInstruction.invoiceExceptionId,
                description: workInstruction.description,
                isApproveBlocker: workInstruction.isApproveBlocker,
                isAttested: workInstruction.isAttested
              }))
            } as Invoice
          })),
          map(({ invoice }) =>
            invoiceExceptionsActions.loadWorkInstructionsPhase2Success({
              invoiceWithWorkInstructions: invoice
            })
          ),
          catchError(error =>
            of(
              invoiceExceptionsActions.loadWorkInstructionsPhase2Fail({ error })
            )
          )
        )
      )
    )
  )

  resetLoadWorkInstructionPhase2LoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceExceptionsActions.loadWorkInstructionsPhase2Success,
        invoiceExceptionsActions.loadWorkInstructionsPhase2Fail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        invoiceExceptionsActions.resetLoadWorkInstructionsPhase2LoadingStatus()
      )
    )
  )

  addAttestation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceExceptionsActions.addAttestation),
      exhaustMap(
        ({ invoiceUuid, invoiceId, workInstructionsIds, areAllAttested }) =>
          this.invoicesService
            .addAttestation(invoiceUuid, workInstructionsIds)
            .pipe(
              map(() =>
                invoiceExceptionsActions.addAttestationSuccess({
                  invoiceUuid,
                  invoiceId,
                  areAllAttested
                })
              ),
              catchError(error => {
                let errorMessage
                if (error.status === 403) {
                  errorMessage = error.error.message
                }
                return of(
                  invoiceExceptionsActions.addAttestationFail({
                    error: errorMessage
                  })
                )
              })
            )
      )
    )
  )

  resetAddAttestationLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceExceptionsActions.addAttestationSuccess,
        invoiceExceptionsActions.addAttestationFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoiceExceptionsActions.resetAddAttestationLoadingStatus())
    )
  )
}
