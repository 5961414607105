export const INVOICES_ABSOLUTE_ROUTE_PREFIX = '/overview/invoice/s' as const
export const INVOICES_STANDALONE_ABSOLUTE_ROUTE_PREFIX =
  '/standalone/invoice' as const
export const EDIT_VENDOR_NAME_DIALOG = 'EDIT_VENDOR_NAME_DIALOG' as const
export const EDIT_VENDOR_DATES_DIALOG = 'EDIT_VENDOR_DATES_DIALOG' as const
export const EDIT_VENDOR_FREIGHT_CHARGE_TERM_DIALOG =
  'EDIT_VENDOR_FREIGHT_CHARGE_TERM_DIALOG' as const
export const EDIT_VENDOR_STOPS_DIALOG = 'EDIT_VENDOR_STOPS_DIALOG' as const
export const ADD_INVOICE_LINE_ITEMS_DIALOG =
  'ADD_INVOICE_LINE_ITEMS_DIALOG' as const
export const EDIT_INVOICE_LINE_ITEMS_DIALOG =
  'EDIT_INVOICE_LINE_ITEMS_DIALOG' as const
export const DELETE_INVOICE_LINE_ITEMS_DIALOG =
  'DELETE_INVOICE_LINE_ITEMS_DIALOG' as const
export const VIEW_INVOICE_NOTES_DIALOG = 'VIEW_INVOICE_NOTES_DIALOG' as const
export const ADD_INVOICE_NOTES_DIALOG = 'ADD_INVOICE_LINE_ITEMS_DIALOG' as const
export const EDIT_INVOICE_NOTES_DIALOG = 'EDIT_INVOICE_NOTES_DIALOG' as const
export const DELETE_INVOICE_NOTES_DIALOG =
  'DELETE_INVOICE_NOTES_DIALOG' as const

export const CUSTOMER_FEES_DIALOG_ID = 'CUSTOMER_FEES_DIALOG_ID' as const
export const DELETE_INVOICE_DOCUMENTS_DIALOG =
  'DELETE_INVOICE_DOCUMENTS_DIALOG' as const
export const ADD_INVOICE_DOCUMENTS_DIALOG =
  'ADD_INVOICE_DOCUMENTS_DIALOG' as const
export const EDIT_INVOICE_OTHER_DETAILS_DIALOG =
  'EDIT_INVOICE_OTHER_DETAILS_DIALOG' as const
export const EDIT_INVOICE_REFERENCE_NUMBERS_DIALOG =
  'EDIT_INVOICE_REFERENCE_NUMBERS_DIALOG' as const
export const ADD_INVOICE_REFERENCE_NUMBERS_DIALOG =
  'ADD_INVOICE_REFERENCE_NUMBERS_DIALOG' as const
export const EDIT_INVOICE_CUSTOMER_DIALOG =
  'EDIT_INVOICE_CUSTOMER_DIALOG' as const
export const ADD_INVOICE_EMAIL_NOTIFICATION_DIALOG =
  'ADD_INVOICE_EMAIL_NOTIFICATION_DIALOG' as const

export const TERMINATE_INVOICE_DIALOG = 'TERMINATE_INVOICE_DIALOG' as const
export const APPROVE_INVOICE_DIALOG = 'APPROVE_INVOICE_DIALOG' as const
export const EDIT_INVOICE_CURRENCY_DIALOG =
  'EDIT_INVOICE_CURRENCY_DIALOG' as const
export const DEFAULT_ALL_OPTIONS = { id: 0, label: 'All' }
export const FIRST_PAGE_VALUE = 1
export const INVOICE_FILTER_GROUP_ALL_OPTIONS = [
  {
    id: -10,
    selected: false,
    label: 'All Tenant Dispute Reasons',
    section: 'Tenant'
  },
  {
    id: -11,
    selected: false,
    label: 'All Vendor Dispute Reasons',
    section: 'Vendor'
  }
]
export const MS_BEFORE_RESET_LOADING_STATE = 100
export const EDIT_INVOICE_DISPUTES_DIALOG =
  'EDIT_INVOICE_DISPUTES_DIALOG' as const

export const ADD_INVOICE_DISPUTES_DIALOG =
  'ADD_INVOICE_DISPUTES_DIALOG' as const

export const DISPUTE_ALLOWED_TYPES =
  '.txt,.doc,.docx,.pdf,.xls,.xlsx,.csv,.jpg,.jpeg,.png,.gif,.bmp'

export const ALLOWED_FILE_TYPES = [
  'text/plain',
  'application/msword',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/jpeg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp'
]

export const IGNORE_ORDER_CHARGES_VENDOR_ID = -1
export const ADD_INVOICE_LEGACY_NOTES_DIALOG =
  'ADD_INVOICE_LEGACY_NOTES_DIALOG' as const
export const VIEW_INVOICE_LEGACY_NOTES_DIALOG =
  'VIEW_INVOICE_LEGACY_NOTES_DIALOG' as const
export const MANAGE_LINE_ITEMS_DIALOG = 'MANAGE_LINE_ITEMS_DIALOG' as const
export const EVANS_TENANTS = [
  'd148722a-5c2e-4f8b-b4a8-7f3077fb10b9', // Evans Prod
  'b62eb370-7735-4b0a-a01b-bbda54f2ad9e', // Evans Sandbox Prod
  '6ec083b8-5d06-48a7-b6f0-360a5924583c' // Evans Dev
]
