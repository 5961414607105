import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { UpdateInvoiceOtherDetailsRequest } from '../../domain/invoices/update-invoice-other-details.request'

export class ToUpdateInvoiceOtherDetailsRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'otherDetails' | 'id'>,
      UpdateInvoiceOtherDetailsRequest
    >
{
  convert(
    request: Pick<InvoiceForm, 'otherDetails' | 'id'>
  ): UpdateInvoiceOtherDetailsRequest {
    const {
      invoiceNumber,
      modeId,
      serviceTypeId,
      invoiceTypeId,
      serviceLevelId
    } = request.otherDetails

    return {
      invoiceNumber,
      transportationModeId: modeId ? Number(modeId) : modeId,
      transportationServiceTypeId: serviceTypeId
        ? Number(serviceTypeId)
        : serviceTypeId,
      vendorInvoiceId: Number(request.id),
      vendorInvoiceTypeId: invoiceTypeId
        ? Number(invoiceTypeId)
        : invoiceTypeId,
      transportationServiceLevelId: serviceLevelId
    }
  }
}
