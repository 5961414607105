import { on } from '@ngrx/store'
import { invoiceChargesActions } from './invoice-charges.actions'
import { InvoicesState, invoicesAdapter } from '../invoices.reducer'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'

export const invoiceChargesReducers = [
  on(
    invoiceChargesActions.loadLegacyInvoiceCharges,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getCharges,
        status: LoadingStatuses.InProgress,
        message: undefined
      })
    })
  ),
  on(
    invoiceChargesActions.loadInvoiceCharges,
    (state: InvoicesState, payload) => {
      return invoicesAdapter.upsertOne(
        <Invoice>{
          id: payload.invoiceId,
          legacyCharges: undefined as any
        },
        {
          ...state,

          loading: updateLoadingState(state.loading, {
            operation: AsyncOperations.getCharges,
            status: LoadingStatuses.InProgress,
            message: undefined
          })
        }
      )
    }
  ),

  on(
    invoiceChargesActions.loadLegacyInvoiceChargesFail,
    invoiceChargesActions.loadInvoiceChargesFail,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getCharges,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to load invoice charges.'
      })
    })
  ),
  on(
    invoiceChargesActions.loadLegacyInvoiceChargesSuccess,
    invoiceChargesActions.loadInvoiceChargesSuccess,
    (state: InvoicesState, { invoiceCharges, invoiceId }) => {
      return invoicesAdapter.upsertOne(
        <Invoice>{
          id: invoiceId,
          legacyCharges: invoiceCharges,
          withDetails: true
        },
        {
          ...state,
          loading: updateLoadingState(state.loading, {
            operation: AsyncOperations.getCharges,
            status: LoadingStatuses.Completed,
            message: undefined
          })
        }
      )
    }
  ),
  on(
    invoiceChargesActions.resetGetChargesLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getCharges,
        status: LoadingStatuses.NotStarted,
        message: undefined
      })
    })
  ),
  on(
    invoiceChargesActions.updateInvoiceVendorCharges,
    invoiceChargesActions.updateInvoiceCustomerCharges,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceCharges,
        status: LoadingStatuses.InProgress,
        message: undefined
      })
    })
  ),
  on(
    invoiceChargesActions.updateInvoiceChargesSuccess,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceCharges,
        status: LoadingStatuses.Completed,
        message: undefined
      })
    })
  ),
  on(
    invoiceChargesActions.updateInvoiceChargesFail,
    (state: InvoicesState, { error, errorMessage }) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceCharges,
        status: LoadingStatuses.Failed,
        message:
          error?.message ??
          errorMessage ??
          'There was an error trying to update invoice charges.'
      })
    })
  ),
  on(
    invoiceChargesActions.resetUpdateInvoiceChargesLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceCharges,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(invoiceChargesActions.mapInvoiceCharges, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.mapInvoiceCharges,
      status: LoadingStatuses.InProgress,
      message: undefined
    })
  })),
  on(
    invoiceChargesActions.mapInvoiceChargesSuccess,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.mapInvoiceCharges,
        status: LoadingStatuses.Completed,
        message: 'Unknown charge mapped successfully.'
      })
    })
  ),
  on(
    invoiceChargesActions.mapInvoiceChargesFail,
    (state: InvoicesState, httpError) => {
      const message: string = httpError.error.error?.message ?? ''
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.mapInvoiceCharges,
          status: LoadingStatuses.Failed,
          message
        })
      }
    }
  ),
  on(
    invoiceChargesActions.resetMapInvoiceChargesLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.mapInvoiceCharges,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
