import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { platform_v2 } from '@navix/api-models'
import {
  API_URL_V2_1_VERSION,
  API_URL_V2_VERSION,
  HEADER_API_KEY
} from '@navix/utils/tokens'
import { Observable } from 'rxjs'
import { AddAuditNoteRequest } from '../domain/audits/add-audit-note.request'
import { UpdateAuditChargesV2Request } from '../domain/audits/update-audit-charges-v2.request'
import { Audit } from '../domain/audits/audit.model'

@Injectable({
  providedIn: 'root'
})
export class AuditsService {
  private readonly _http = inject(HttpClient)
  private readonly apiV2Url = inject(API_URL_V2_VERSION)
  private readonly apiV2_1Url = inject(API_URL_V2_1_VERSION)
  private readonly apiKey = inject(HEADER_API_KEY)

  private readonly apiAuditsV2Url = `${this.apiV2Url}audits/` as const
  private readonly apiAuditsV2_1Url = `${this.apiV2_1Url}audits/` as const

  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }

  getAuditByOrderId(
    orderId: string
  ): Observable<platform_v2.Navix_Services_Sdk_Audit> {
    return this._http.get<platform_v2.Navix_Services_Sdk_Audit>(
      `${this.apiAuditsV2Url}order/${orderId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addAuditNote(
    auditId: string,
    request: AddAuditNoteRequest
  ): Observable<string> {
    return this._http.post<string>(
      `${this.apiAuditsV2Url}${auditId}/notes`,
      request,
      {
        headers: this.headersWithApiKey,
        responseType: 'text' as 'json'
      }
    )
  }

  updateAudit(
    orderUuid: string,
    request: platform_v2.Navix_Services_Sdk_Audit,
    vendorInvoiceId: number
  ): Observable<void> {
    return this._http.put<void>(
      `${this.apiAuditsV2Url}order/${orderUuid}/vendorInvoiceId/${vendorInvoiceId}`,
      request
    )
  }

  updateAuditChargesV2(
    auditUuid: string,
    invoiceUuid: string,
    request: UpdateAuditChargesV2Request
  ): Observable<void> {
    return this._http.put<void>(
      `${this.apiAuditsV2_1Url}${auditUuid}/invoice/${invoiceUuid}/charges`,
      request
    )
  }

  updateAuditVendor(
    auditUuid: string,
    invoiceUuid: string,
    request: {
      auditDocumentEtag: string
      vendorExternalId: string
    }
  ): Observable<Audit> {
    return this._http.put<Audit>(
      `${this.apiAuditsV2_1Url}${auditUuid}/invoice/${invoiceUuid}/update-vendor`,
      request
    )
  }
}
