import { platform_v2_1 } from '@navix/services-api-models'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { Invoice } from '../../domain/invoices/invoice.model'
import { freightTermMap } from '../../domain/invoices/freight-charge-term.model'

export function toAddInvoiceFromOrder({
  invoiceForm,
  lineItems
}: {
  invoiceForm: Pick<InvoiceForm, 'details' | 'stops' | 'defaultCharges'>
  lineItems: Invoice['lineItems']
}): platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest {
  const originStops: Array<platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_InvoiceStopRequest> =
    invoiceForm.stops.origins.map(stop => ({
      type: 'Origin',
      address: {
        name: stop.name,
        address1: stop.address1,
        address2: stop.address2,
        city: stop.city,
        state: stop.stateCode,
        postalCode: stop.zip,
        country:
          stop.countryCode as platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_Country
      }
    }))
  const destinations: Array<platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_InvoiceStopRequest> =
    invoiceForm.stops.destinations.map(stop => ({
      type: 'Destination',
      address: {
        name: stop.name,
        address1: stop.address1,
        address2: stop.address2,
        city: stop.city,
        state: stop.stateCode,
        postalCode: stop.zip,
        country:
          stop.countryCode as platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_Country
      }
    }))
  const billTo: platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_InvoiceStopRequest =
    {
      type: 'BillTo',
      address: {
        name: invoiceForm.stops.billTo.name,
        address1: invoiceForm.stops.billTo.address1,
        address2: invoiceForm.stops.billTo.address2,
        city: invoiceForm.stops.billTo.city,
        state: invoiceForm.stops.billTo.stateCode,
        postalCode: invoiceForm.stops.billTo.zip,
        country: invoiceForm.stops.billTo
          .countryCode as platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_Country
      }
    }

  const remitTo: Array<platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_InvoiceStopRequest> =
    invoiceForm.stops.remitTo.map(stop => ({
      type: 'RemitTo',
      address: {
        name: stop.name,
        address1: stop.address1,
        address2: stop.address2,
        city: stop.city,
        state: stop.stateCode,
        postalCode: stop.zip,
        country:
          stop.countryCode as platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_Country
      }
    }))
  return {
    invoiceFormat: 'Manual',
    tenantUuid: invoiceForm.details.tenantUuid,
    type: 'Invoice',
    invoiceNumber: invoiceForm.details.invoiceNumber,
    invoiceDate: (invoiceForm.details.invoiceDate as Date).toISOString(),
    shipDate: (invoiceForm.details.shipDate as Date).toISOString(),
    deliveryDate: invoiceForm.details.deliveryDate
      ? (invoiceForm.details.deliveryDate as Date).toISOString()
      : null,
    stops: [
      ...originStops,
      ...destinations,
      ...(billTo.address?.name ? [billTo] : []),
      ...(remitTo[0].address?.name ? remitTo : [])
    ],
    items: lineItems.map(lineItem => {
      return {
        id:
          !lineItem.id || lineItem.id === 0
            ? undefined
            : lineItem.id?.toString(),
        description: lineItem.description,
        class: lineItem.class,
        nmfcCode: lineItem.nMFCCode,
        handlingUnit:
          lineItem.handlingUnitDescription as platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_HandlingUnit,
        handlingUnitCount: lineItem.handlingUnitCount,
        totalCount: lineItem.totalPieces,
        dimensions: {
          system:
            lineItem.uomDescription == 'IN' && lineItem.weightUnit == 'LBS'
              ? 'Imperial'
              : ('Metric' as platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_MeasurementSystem),
          weight: lineItem.weight,
          height: lineItem.height,
          length: lineItem.length,
          width: lineItem.width,
          stackable: lineItem.stack,
          volume: lineItem.cube
        }
      }
    }),
    vendor: {
      externalId: invoiceForm.details.vendorId?.toString()
    },
    chargeTerms: invoiceForm.details.freightTermsDescription
      ? freightTermMap[
          invoiceForm.details
            .freightTermsDescription as keyof typeof freightTermMap
        ]
      : undefined,
    customer: {
      externalId: invoiceForm.details.customerId?.toString()
    },
    referenceNumbers: [
      {
        type: 'OrderNumber',
        value: invoiceForm.details.orderNumber
      },
      ...(invoiceForm.details.shipmentNumber
        ? [
            {
              type: 'ShipmentNumber' as platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_InvoiceReferenceNumberType,
              value: invoiceForm.details.shipmentNumber
            }
          ]
        : []),
      ...(invoiceForm.details.bolNumber
        ? [
            {
              type: 'BOLNumber' as platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_InvoiceReferenceNumberType,
              value: invoiceForm.details.bolNumber
            }
          ]
        : []),
      ...(invoiceForm.details.poNumber
        ? [
            {
              type: 'PONumber' as platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_InvoiceReferenceNumberType,
              value: invoiceForm.details.poNumber
            }
          ]
        : [])
    ],
    charges: [
      {
        charge: invoiceForm.defaultCharges.baseRate.amount,
        code: {
          type: 'Tenant',
          typeId: invoiceForm.defaultCharges.baseRate.typeId
        }
      },
      {
        charge: invoiceForm.defaultCharges.discount.amount,
        code: {
          type: 'Tenant',
          typeId: invoiceForm.defaultCharges.discount.typeId
        }
      },
      {
        charge: invoiceForm.defaultCharges.fuelSurcharge.amount,
        code: {
          type: 'Tenant',
          typeId: invoiceForm.defaultCharges.fuelSurcharge.typeId
        }
      },
      {
        charge: invoiceForm.defaultCharges.taxes.amount,
        code: {
          type: 'Tenant',
          typeId: invoiceForm.defaultCharges.taxes.typeId
        }
      },
      {
        charge: invoiceForm.defaultCharges.duties.amount,
        code: {
          type: 'Tenant',
          typeId: invoiceForm.defaultCharges.duties.typeId
        }
      },
      ...(invoiceForm.defaultCharges.unknownAccessorial.amount !== 0
        ? [
            {
              charge: invoiceForm.defaultCharges.unknownAccessorial.amount,
              code: {
                type: 'Tenant' as platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_ChargeCodeType,
                typeId: invoiceForm.defaultCharges.unknownAccessorial.typeId
              }
            }
          ]
        : []),
      ...invoiceForm.defaultCharges.accessorialsDetail.map(accessorial => ({
        charge: accessorial.amount,
        code: {
          type: 'Tenant' as platform_v2_1.Invoice_Api_DTO_InvoiceDocumentCreateRequest_ChargeCodeType,
          // value: accessorial.tenantCode,
          // description: accessorial.tenantDescription,
          typeId: accessorial.typeId
        }
      }))
    ]
  }
}
