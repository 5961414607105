import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { AddInvoiceDefaultChargesRequest } from '../../domain/invoices/add-invoice-default-charges.request'
import { KnownChageTypes } from '../../+state/invoices/static/known-chage-types'

export class ToAddInvoiceDefaultChargesRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'defaultCharges' | 'id'>,
      AddInvoiceDefaultChargesRequest
    >
{
  convert(
    request: Pick<InvoiceForm, 'defaultCharges' | 'id'>
  ): AddInvoiceDefaultChargesRequest {
    return {
      invoiceId: request.id ?? 0,
      charges: this.mapDefaultChargesToRequest(
        request.defaultCharges
      ).charges.concat(
        request.defaultCharges.accessorialsDetail.map<
          AddInvoiceDefaultChargesRequest['charges'][number]
        >(item => ({
          chargeTypeId: item.typeId!,
          charge: item.amount!,
          tenantCode: item.tenantCode ?? null,
          tenantDescription: item.tenantDescription ?? null
        }))
      )
    }
  }

  private mapDefaultChargesToRequest(
    defaultCharges: InvoiceForm['defaultCharges']
  ): Pick<AddInvoiceDefaultChargesRequest, 'charges'> {
    const chargeTypeMap = {
      baseRate: KnownChageTypes.BaseRate,
      discount: KnownChageTypes.Discount,
      fuelSurcharge: KnownChageTypes.Fsc,
      taxes: KnownChageTypes.Taxes,
      duties: KnownChageTypes.Duties
    }

    return {
      charges: Object.keys(chargeTypeMap)
        .map(key => {
          const chargeTypeId = chargeTypeMap[key as keyof typeof chargeTypeMap]
          const value = defaultCharges[key as keyof typeof chargeTypeMap]
          if (value === undefined)
            return <AddInvoiceDefaultChargesRequest['charges'][number]>{}
          return <AddInvoiceDefaultChargesRequest['charges'][number]>{
            chargeTypeId: chargeTypeId,
            charge: Number(value.amount),
            tenantCode: value.tenantCode ?? null,
            tenantDescription: value.tenantDescription ?? null
          }
        })
        .filter(item => item.chargeTypeId !== undefined)
    }
  }
}
