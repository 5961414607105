import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import {
  OrdersDataFilters,
  OrderStatusFilter
} from '../../domain/orders/data-filters.model'
import { Order } from '../../domain/orders/order.model'
import {
  OrdersAsyncOperations,
  OrdersLoadingState
} from '../../domain/orders/orders-loading.model'
import { ordersActions } from './orders.actions'
import {
  LoadingStatuses,
  getDefaultLoadingState,
  updateLoadingState
} from '@navix/shared/loading'
import { Tag } from '../../domain/orders/tag.model'

export const ORDERS_FEATURE_KEY = 'feature-orders'
export type FilterMemoryAvailableFilters = Pick<
  OrdersDataFilters,
  'customers' | 'vendors'
>
export interface OrdersState extends EntityState<Order> {
  selectedId?: Order['id']
  selectedLegacyId?: Order['legacyId']
  totalCount: number
  loading: OrdersLoadingState
  filters: OrdersDataFilters
  filtersMemory: {
    [OrderStatusFilter.unmatched]: FilterMemoryAvailableFilters
  }
  divisions: Tag[]
  remitTos: Tag[]
}

export interface OrdersPartialState {
  readonly [ORDERS_FEATURE_KEY]: OrdersState
}

export const ordersAdapter: EntityAdapter<Order> = createEntityAdapter<Order>()

export const initialOrdersState: OrdersState = ordersAdapter.getInitialState({
  totalCount: 0,
  loading: getDefaultLoadingState(OrdersAsyncOperations),
  filters: {
    search: undefined,
    page: 1,
    itemsPerPage: 50,
    sortBy: 'CreateDate',
    sortDirection: 'asc',
    status: OrderStatusFilter.unmatched,
    customers: [],
    vendors: []
  },
  filtersMemory: {
    [OrderStatusFilter.unmatched]: {}
  },
  divisions: [],
  remitTos: []
})

const reducer = createReducer(
  initialOrdersState,
  on(
    ordersActions.loadOrders,
    (state): OrdersState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.getAll,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    ordersActions.loadOrdersFail,
    (state): OrdersState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.getAll,
        status: LoadingStatuses.Failed,
        message: 'Failed to load orders'
      })
    })
  ),

  on(
    ordersActions.loadOrdersSuccess,
    (state, { orders }): OrdersState =>
      ordersAdapter.setAll(orders, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: OrdersAsyncOperations.getAll,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    ordersActions.resetGetAllLoadingState,
    (state): OrdersState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.getAll,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    ordersActions.loadOrderDetails,
    (state, { orderId }): OrdersState => ({ ...state, selectedId: orderId })
  ),
  on(
    ordersActions.setSelectedId,
    (state, { orderId }): OrdersState => ({ ...state, selectedId: orderId })
  ),
  on(
    ordersActions.loadOrderDetailsSuccess,
    (state, { order }): OrdersState => ordersAdapter.upsertOne(order, state)
  ),
  on(
    ordersActions.setTotalCount,
    (state, { count }): OrdersState => ({
      ...state,
      totalCount: count
    })
  ),
  on(
    ordersActions.loadOrderStopsSuccess,
    (state, { orderWithStops }): OrdersState =>
      ordersAdapter.upsertOne(orderWithStops, state)
  ),
  on(
    ordersActions.loadReferenceNumbersSuccess,
    (state, { orderWithReferenceNumbers }): OrdersState =>
      ordersAdapter.upsertOne(orderWithReferenceNumbers, state)
  ),
  on(
    ordersActions.loadLineItemsSuccess,
    (state, { orderWithLineItems }): OrdersState =>
      ordersAdapter.upsertOne(orderWithLineItems, state)
  ),
  on(
    ordersActions.loadChargesSuccess,
    (state, { orderWithCharges }): OrdersState =>
      ordersAdapter.upsertOne(orderWithCharges, state)
  ),
  on(
    ordersActions.setLoading,
    (state, { operation, loading }): OrdersState => ({
      ...state,
      loading: { ...state.loading, [operation]: loading }
    })
  ),
  on(
    ordersActions.setListFilters,
    (state, { filters }): OrdersState => ({
      ...state,
      filters
    })
  ),
  on(
    ordersActions.setListFiltersMemory,
    (state, { filters, status }): OrdersState => ({
      ...state,
      filtersMemory: { ...state.filtersMemory, [status]: filters }
    })
  ),
  on(
    ordersActions.loadMatchedInvoices,
    (state): OrdersState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.getMatchedInvoices,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    ordersActions.loadMatchedInvoicesSuccess,
    (state, { orderWithMatchedInvoices }): OrdersState => {
      return ordersAdapter.upsertOne(
        {
          ...orderWithMatchedInvoices,
          vendors: state.entities[orderWithMatchedInvoices.id]?.vendors ?? []
        },
        {
          ...state,
          loading: updateLoadingState(state.loading, {
            operation: OrdersAsyncOperations.getMatchedInvoices,
            status: LoadingStatuses.Completed,
            message: 'Matched invoices loaded successfully'
          })
        }
      )
    }
  ),
  on(
    ordersActions.loadMatchedInvoicesFail,
    (state): OrdersState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.getMatchedInvoices,
        status: LoadingStatuses.Failed,
        message: 'Failed to load matched invoices'
      })
    })
  ),
  on(
    ordersActions.resetGetMatchedInvoicesLoadingState,
    (state): OrdersState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.getMatchedInvoices,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    ordersActions.loadNewOrderDetails,
    (state, { orderId }): OrdersState => ({
      ...state,
      selectedId: orderId,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.getDetails,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    ordersActions.loadNewOrderDetailsSuccess,
    (state, { order }): OrdersState =>
      ordersAdapter.upsertOne(order, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: OrdersAsyncOperations.getDetails,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    ordersActions.loadNewOrderDetailsFail,
    (state: OrdersState): OrdersState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.getDetails,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load order's details."
      })
    })
  ),
  on(
    ordersActions.resetGetNewOrderDetailsLoadingState,
    (state: OrdersState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.getDetails,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(ordersActions.updateOrdersPurchaseInvoice, (state: OrdersState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: OrdersAsyncOperations.updateOrdersPurchaseInvoice,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    ordersActions.updateOrdersPurchaseInvoiceSuccess,
    (state: OrdersState, { shouldReloadOrderDetails }) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.updateOrdersPurchaseInvoice,
        status: LoadingStatuses.Completed,
        message: 'Purchase invoice updated successfully',
        metadata: {
          shouldReloadOrderDetails
        }
      })
    })
  ),
  on(ordersActions.updateOrdersPurchaseInvoiceFail, (state: OrdersState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: OrdersAsyncOperations.updateOrdersPurchaseInvoice,
      status: LoadingStatuses.Failed,
      message: 'Failed to update purchase invoice'
    })
  })),
  on(
    ordersActions.resetUpdatePurchaseInvoiceLoadingState,
    (state: OrdersState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.updateOrdersPurchaseInvoice,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    ordersActions.removePurchaseInvoiceAssociatedUuid,
    (state: OrdersState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.updateOrdersPurchaseInvoice,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    ordersActions.removePurchaseInvoiceAssociatedUuidSuccess,
    (state: OrdersState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.updateOrdersPurchaseInvoice,
        status: LoadingStatuses.Completed,
        message: 'Purchase invoice updated successfully',
        metadata: {
          shouldReloadOrderDetails: false
        }
      })
    })
  ),
  on(
    ordersActions.removePurchaseInvoiceAssociatedUuidFailed,
    (state: OrdersState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.updateOrdersPurchaseInvoice,
        status: LoadingStatuses.Failed,
        message: 'Failed to update purchase invoice'
      })
    })
  ),
  on(
    ordersActions.resetRemovePurchaseInvoiceAssociatedUuidLoadingState,
    (state: OrdersState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.updateOrdersPurchaseInvoice,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(ordersActions.loadDivisionTags, (state: OrdersState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: OrdersAsyncOperations.loadDivisionTags,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    ordersActions.loadDivisionTagsSuccess,
    (state: OrdersState, { tags }): OrdersState => ({
      ...state,
      divisions: tags
    })
  ),
  on(
    ordersActions.loadDivisionTagsFail,
    (state: OrdersState): OrdersState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.loadDivisionTags,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to load email templates.'
      })
    })
  ),
  on(
    ordersActions.resetLoadDivisionTagsLoadingState,
    (state: OrdersState): OrdersState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: OrdersAsyncOperations.loadDivisionTags,
        status: LoadingStatuses.NotStarted
      })
    })
  )

)

export function ordersReducer(state: OrdersState | undefined, action: Action) {
  return reducer(state, action)
}
