import { createActionGroup, emptyProps, props } from '@ngrx/store'

export const updateAuditActions = createActionGroup({
  source: 'Audit - Update',
  events: {
    updateAudit: props<{
      orderUuid: string
      vendorInvoiceId: number
      vendorExternalId: string
      previousVendorExternalId: string | undefined
      invoicesUuids: string[]
    }>(),
    updateAuditSuccess: emptyProps(),
    updateAuditFail: props<{ error: string }>(),

    updateAuditCharges: props<{
      orderUuid: string
      invoiceUuid: string
      vendorExternalId: string
      vendorInvoiceId: number
      vendorCharges: {
        chargeTypeUuid: string
        code: string
        description: string | undefined
        originalCharge: number
        adjustment: number
        reason: string
      }[]

      customerCharges: {
        chargeTypeUuid: string
        code: string
        description: string | undefined
        originalCharge: number
        adjustment: number
        reason: string
      }[]
    }>(),
    updateAuditChargesSuccess: emptyProps(),
    updateAuditChargesFail: props<{
      error: string
      isMissingOrderAuditDocument: boolean
      isMissingVendorInvoiceCharges?: boolean
    }>(),
    updateAuditChargesFailMissingOrder: emptyProps(),

    resetUpdateAuditLoadingState: emptyProps(),

    addAuditChargesMappings: props<{
      orderUuid: string
      vendorExternalId: string | undefined
      vendorInvoiceId: number
      invoiceUuid: string
      unknownChargeUuid: string
      customerOrderMappings: {
        chargeTypeUuid: string
        previousChargeTypeUuid: string
        originalCharge: number
        currentCharge: number
        tenantChargeCode: string
        tenantChargeDescription: string
        code: string
        description: string
      }[]
      vendorOrderMappings: {
        chargeTypeUuid: string
        previousChargeTypeUuid: string
        originalCharge: number
        currentCharge: number
        tenantChargeCode: string
        tenantChargeDescription: string
        code: string
        description: string
      }[]
      vendorAdjustedMappings: {
        chargeTypeUuid: string
        previousChargeTypeUuid: string
        originalCharge: number
        currentCharge: number
        tenantChargeCode: string
        tenantChargeDescription: string
        code: string
        description: string
      }[]
      customerAdjustedMappings: {
        chargeTypeUuid: string
        previousChargeTypeUuid: string
        originalCharge: number
        currentCharge: number
        tenantChargeCode: string
        tenantChargeDescription: string
        code: string
        description: string
      }[]
    }>(),
    addAuditChargesMappingsSuccess: emptyProps(),
    addAuditChargesMappingsFail: props<{ error: unknown }>(),
    resetAddAuditChargesMappingsLoadingState: emptyProps(),

    // Update Audit Charges V2
    updateAuditChargesV2: props<{
      orderUuid: string
      auditUuid: string
      invoiceUuid: string
      vendorExternalId?: string
      vendorCharges: {
        chargeTypeUuid: string
        code: string | undefined
        description: string | undefined
        originalCharge: number
        adjustment: number
        reason: string | undefined
      }[]

      customerCharges: {
        chargeTypeUuid: string
        code: string | undefined
        description: string | undefined
        originalCharge: number
        adjustment: number
        reason: string | undefined
      }[]
    }>(),
    updateAuditChargesV2Success: emptyProps(),
    updateAuditChargesV2Fail: props<{
      error: string
      isMissingOrderAuditDocument: boolean
      isMissingVendorInvoiceCharges?: boolean
    }>(),
    resetupdateAuditChargesV2LoadingState: emptyProps(),

    // Update Audit Vendor V2
    updateAuditVendor: props<{
      orderUuid: string
      auditUuid: string
      invoiceUuid: string
      vendorExternalId: string
      vendorId: number
    }>(),
    updateAuditVendorSuccess: props<{
      newVendorId: number
    }>(),
    updateAuditVendorFail: props<{
      error: string
      isMissingOrderAuditDocument: boolean
      isMissingVendorInvoiceCharges?: boolean
    }>(),
    resetUpdateAuditVendorLoadingState: emptyProps()
  }
})
