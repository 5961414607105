import { LoadingState } from '@navix/shared/loading'

export enum AuditAsyncOperations {
  loadAudit = 'loadAudit',
  addAuditNote = 'addAuditNote',
  updateAudit = 'updateAudit',
  addAuditChargesMappings = 'addAuditChargesMappings',
  updateAuditChargesV2 = 'updateAuditChargesV2',
  updateAuditVendor = 'updateAuditVendor'
}

type AuditAsyncOperationsMetadata = {
  [key in keyof typeof AuditAsyncOperations]: unknown
} & {
  readonly [AuditAsyncOperations.updateAudit]: {
    redirectToListPage?: boolean
    showConfirmationModal?: boolean
  }
  readonly [AuditAsyncOperations.updateAuditChargesV2]: {
    showConfirmationModal?: boolean
  }
  readonly [AuditAsyncOperations.updateAuditVendor]: {
    newVendorId: number
  }
}

export type AuditsLoadingState = LoadingState<
  typeof AuditAsyncOperations,
  AuditAsyncOperationsMetadata
>
