/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Invoice } from '../../domain/invoices/invoice.model'
import { GetInvoiceCustomerAuditChargesNewResponse } from '../../domain/invoices/get-invoice-customer-audit-charges-new.response'
import { GetInvoiceAuditChargesResponse } from '../../domain/invoices/get-invoice-audit-charges.response'
import { GetInvoiceChargesNewResponse } from '../../domain/invoices/get-invoice-charges-new.response'

import { KnownChageTypes } from '../../+state/invoices/static'
import { InvoiceCharge } from '../../domain/models/invoice-charge.model'
//TODO(ladaj): resolve dependency
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ChargeCode } from '@navix/charge-codes/domain'
import { platform_v2 } from '@navix/api-models'
import { invoiceChargesActions } from '../../+state/invoices/invoice-charges/invoice-charges.actions'
import { EVANS_TENANTS } from '../../+state/invoices/invoices.constants'

type Response = Readonly<
  [
    platform_v2.Navix_Services_Sdk_Audit,
    GetInvoiceAuditChargesResponse,
    GetInvoiceCustomerAuditChargesNewResponse,
    ChargeCode[],
    (
      | {
          chargeAmount: number
          code: string
          description: string
        }[]
      | undefined
    )
  ]
>

type NormalizedResponse = {
  chargeId: number | string
  adjustedChargeAmount: number
  varianceReasonId: number | undefined
  typeId: number
  chargeAmount: number
  currency: string
  isLock: boolean | undefined
  code: string | undefined
  description: string | undefined
  metadata: string | undefined
  tenantCode: string | undefined
  tenantDescription: string | undefined
  originalAdjustment: number | undefined
  originalCode: string | undefined
}

type ChargesDataResponse = {
  adjustedCharges: NormalizedResponse[]
  auditCharges: NormalizedResponse[]
  orderCharges: NormalizedResponse[]
}

type ChargesResponse = {
  vendor: ChargesDataResponse
  customer: ChargesDataResponse
}

type ChargesGroupNames = keyof Invoice['legacyCharges']

export function fromGetInvoiceChargesComposedNew(
  response: Response,
  payload: ReturnType<typeof invoiceChargesActions.loadInvoiceCharges>
) {
  const AUDIT_RESPONSE_INDEX = 0
  const auditDocument = response[AUDIT_RESPONSE_INDEX]
  const isEvansTenant = EVANS_TENANTS.includes(auditDocument.tenantUuid!)

  const CHARGE_CODES_RESPONSE_INDEX = 3
  const chargeTypes = response[CHARGE_CODES_RESPONSE_INDEX]
  const normalizedResponse = getNormalizedResponse(
    response,
    payload,
    isEvansTenant
  )
  const getChargesForType = getCharge(
    chargeTypes,
    normalizedResponse,
    isEvansTenant
  )
  const charges: Invoice['legacyCharges'] = {
    vendor: {
      baseRate: getChargesForType(
        KnownChageTypes.BaseRate,
        'vendor',
        'customer'
      ),
      discount: getChargesForType(
        KnownChageTypes.Discount,
        'vendor',
        'customer'
      ),
      fsc: getChargesForType(KnownChageTypes.Fsc, 'vendor', 'customer'),
      accessorials: getChargesForType(
        KnownChageTypes.Accesorials,
        'vendor',
        'customer'
      ),
      unknownAccessorials: getChargesForType(
        KnownChageTypes.UnknownAccesorials,
        'vendor',
        'customer'
      ),
      taxes: getChargesForType(KnownChageTypes.Taxes, 'vendor', 'customer'),
      duties: getChargesForType(KnownChageTypes.Duties, 'vendor', 'customer'),
      customerFees: getChargesForType(
        KnownChageTypes.CustomerFees,
        'vendor',
        'customer'
      )
    },
    customer: {
      baseRate: getChargesForType(
        KnownChageTypes.BaseRate,
        'customer',
        'vendor'
      ),
      discount: getChargesForType(
        KnownChageTypes.Discount,
        'customer',
        'vendor'
      ),
      fsc: getChargesForType(KnownChageTypes.Fsc, 'customer', 'vendor'),
      accessorials: getChargesForType(
        KnownChageTypes.Accesorials,
        'customer',
        'vendor'
      ),
      unknownAccessorials: getChargesForType(
        KnownChageTypes.UnknownAccesorials,
        'customer',
        'vendor'
      ),
      taxes: getChargesForType(KnownChageTypes.Taxes, 'customer', 'vendor'),
      duties: getChargesForType(KnownChageTypes.Duties, 'customer', 'vendor'),
      customerFees: getChargesForType(
        KnownChageTypes.CustomerFees,
        'customer',
        'vendor'
      )
    }
  }
  return charges
}

function getCharge(
  chargeTypes: ChargeCode[],
  normalizedResponse: ChargesResponse,
  isEvansTenant: boolean
): (
  typeId: KnownChageTypes,
  source: ChargesGroupNames,
  secondarySource: ChargesGroupNames
) => InvoiceCharge {
  return (
    typeId: KnownChageTypes,
    source: ChargesGroupNames,
    secondarySource: ChargesGroupNames
  ) => {
    const getChargesForType = getCharge(
      chargeTypes,
      normalizedResponse,
      isEvansTenant
    )

    const chargeDetails = chargeTypes.find(charge => charge.id === typeId)!
    const chargeResponseData = normalizedResponse[source]
    const secondaryChargeResponseData = normalizedResponse[secondarySource]

    const orderCharges = chargeResponseData.orderCharges.filter(
      charge => charge.typeId === typeId
    )
    const auditCharges = chargeResponseData.auditCharges.filter(
      charge => charge.typeId === typeId
    )
    const adjustedCharges = chargeResponseData.adjustedCharges.filter(
      charge => charge.typeId === typeId
    )

    const secondaryOrderCharges =
      secondaryChargeResponseData.orderCharges.filter(
        charge => charge.typeId === typeId
      )
    const secondaryAuditCharges =
      secondaryChargeResponseData.auditCharges.filter(
        charge => charge.typeId === typeId
      )
    const secondaryAdjustedCharges =
      secondaryChargeResponseData.adjustedCharges.filter(
        charge => charge.typeId === typeId
      )

    const orderChargeAmount = orderCharges.reduce(
      (acc, charge) => acc + charge.chargeAmount,
      0
    )

    const auditChargeAmount = auditCharges.reduce(
      (acc, charge) => acc + charge.chargeAmount,
      0
    )

    const adjustedComparerChargeAmount =
      source === 'customer'
        ? orderChargeAmount
        : adjustedCharges.reduce((acc, charge) => acc + charge.chargeAmount, 0)

    const adjustedChargeAmount = adjustedCharges.reduce(
      (acc, charge) => acc + charge.adjustedChargeAmount,
      0
    )

    const childChargesTypeIds = chargeResponseData.adjustedCharges
      .concat(chargeResponseData.orderCharges)
      .concat(chargeResponseData.auditCharges)
      .concat(secondaryChargeResponseData.auditCharges)
      .concat(secondaryChargeResponseData.orderCharges)
      .concat(secondaryChargeResponseData.adjustedCharges)
      .map(charge => charge.typeId)

    const childCharges = chargeTypes
      .filter(charge => childChargesTypeIds.includes(charge.id))
      .filter(charge => {
        if (typeId === KnownChageTypes.Accesorials)
          return (
            charge.parentChargeTypeId === typeId &&
            charge.id !== KnownChageTypes.UnknownAccesorials
          )
        return charge.parentChargeTypeId === typeId
      })
      .map(charge =>
        getChargesForType(charge.id as KnownChageTypes, source, secondarySource)
      )

    const hasAggregationDetails =
      auditCharges.length > 1 ||
      orderCharges.length > 1 ||
      adjustedCharges.length > 1 ||
      secondaryAuditCharges.length > 1 ||
      secondaryOrderCharges.length > 1 ||
      secondaryAdjustedCharges.length > 1 ||
      typeId === KnownChageTypes.UnknownAccesorials

    const parentChargeTypeId =
      typeId === KnownChageTypes.UnknownAccesorials
        ? KnownChageTypes.UnknownAccesorials
        : chargeDetails.parentChargeTypeId!

    const mainAggregationDetails = getAggregationDetails(
      parentChargeTypeId,
      adjustedCharges,
      orderCharges,
      auditCharges,
      source,
      isEvansTenant
    )

    const secondaryAggregationDetails = getEmptyAggregationDetails(
      parentChargeTypeId,
      secondaryAdjustedCharges,
      secondaryOrderCharges,
      secondaryAuditCharges,
      secondarySource,
      isEvansTenant
    )

    const aggregatedChilds = hasAggregationDetails
      ? source === 'vendor'
        ? mainAggregationDetails.concat(secondaryAggregationDetails)
        : secondaryAggregationDetails.concat(mainAggregationDetails)
      : []

    const isAccessorialCharge =
      typeId === KnownChageTypes.Accesorials ||
      typeId === KnownChageTypes.UnknownAccesorials
    const useSecondarySource =
      !isAccessorialCharge &&
      !hasAggregationDetails &&
      adjustedCharges.length === 0 &&
      orderCharges.length === 0 &&
      (secondaryAdjustedCharges.length > 0 || secondaryOrderCharges.length > 0)

    const orderCharge = getChargeData(
      orderCharges,
      secondaryOrderCharges,
      hasAggregationDetails,
      useSecondarySource
    )

    const auditCharge = getChargeData(
      auditCharges,
      secondaryAuditCharges,
      hasAggregationDetails,
      useSecondarySource
    )
    const adjustedCharge = getChargeData(
      adjustedCharges,
      secondaryAdjustedCharges,
      hasAggregationDetails,
      useSecondarySource
    )

    const orderMetadata = getMetadata(orderCharge?.metadata)
    const auditMetadata = getMetadata(auditCharge?.metadata)
    const adjustedMetadata = getMetadata(adjustedCharge?.metadata)

    const metadataKeys = Array.from(
      new Set([
        ...Object.keys(adjustedMetadata),
        ...Object.keys(auditMetadata),
        ...Object.keys(orderMetadata)
      ])
    )

    const metadata = metadataKeys.map<InvoiceCharge['metadata'][number]>(
      key => ({
        typeId: undefined,
        typeDescription: key.toString(),
        parentTypeId: typeId,
        orderAmount: Object.hasOwn(orderMetadata, key)
          ? orderMetadata[key]
          : undefined,
        auditAmount: Object.hasOwn(auditMetadata, key)
          ? auditMetadata[key]
          : undefined,
        adjustedAmount: Object.hasOwn(adjustedMetadata, key)
          ? adjustedMetadata[key]
          : undefined,
        adjustedComparerAmount: undefined,
        varianceReasonId: undefined,
        adjustedChargeId: undefined,
        isLock: false,
        hasAggregationDetails: false,
        isAggregationDetail: false,
        code: undefined,
        description: undefined,
        details: [],
        metadata: undefined,
        orderChargeId: undefined,
        tenantCode: undefined,
        tenantDescription: undefined
      })
    )

    return {
      guid: self.crypto.randomUUID(),
      parentTypeId: chargeDetails.parentChargeTypeId,
      typeId: chargeDetails.id,
      typeDescription: chargeDetails.description,
      orderAmount: orderChargeAmount,
      auditAmount: auditChargeAmount,
      adjustedComparerAmount: adjustedComparerChargeAmount,
      adjustedAmount: adjustedChargeAmount,
      adjustedChargeId: adjustedCharge?.chargeId,
      orderChargeId: orderCharge?.chargeId,
      varianceReasonId: hasAggregationDetails
        ? undefined
        : adjustedCharge?.varianceReasonId,
      hasAggregationDetails: hasAggregationDetails,
      isAggregationDetail: false,
      code: hasAggregationDetails
        ? undefined
        : adjustedCharge?.code ?? orderCharge?.code ?? auditCharge?.code,
      description: hasAggregationDetails
        ? undefined
        : adjustedCharge?.description ??
          orderCharge?.description ??
          auditCharge?.description,
      tenantCode: hasAggregationDetails
        ? undefined
        : adjustedCharge?.tenantCode,
      tenantDescription: hasAggregationDetails
        ? undefined
        : adjustedCharge?.tenantDescription,
      details: childCharges.concat(aggregatedChilds),
      metadata: metadata,
      chargeSource: useSecondarySource ? secondarySource : source,
      originalAdjustment: adjustedCharge?.originalAdjustment,
      originalCode: adjustedCharge?.originalCode,
      originalDescription: adjustedCharge?.description
    }
  }
}

function getChargeData(
  charges: NormalizedResponse[],
  secondaryCharges: NormalizedResponse[],
  hasAggregationDetails: boolean,
  useSecondarySource: boolean
) {
  return hasAggregationDetails
    ? undefined
    : charges.at(0) ?? (useSecondarySource ? secondaryCharges.at(0) : undefined)
}

function getDescription(
  descriptionToUse: string | undefined,
  codeToUse: string | undefined
) {
  const description = `${descriptionToUse ?? ''}${
    codeToUse ? ` (${codeToUse})` : ''
  }`
  return description === '' ? 'N/A' : description.trim()
}

function getAggregationDetails(
  parentTypeId: KnownChageTypes,
  adjustedCharges: NormalizedResponse[],
  orderCharges: NormalizedResponse[],
  auditCharges: NormalizedResponse[],
  source: ChargesGroupNames,
  isEvansTenant: boolean
): InvoiceCharge[] {
  const aggregatedAdjustedCharges = adjustedCharges.map<InvoiceCharge>(
    charge => ({
      ...getAggregatedChargeCommonProperties(
        charge,
        parentTypeId,
        source,
        isEvansTenant
      ),
      adjustedComparerAmount: charge.chargeAmount,
      adjustedAmount: charge.adjustedChargeAmount,
      adjustedChargeId: charge.chargeId,
      varianceReasonId: charge.varianceReasonId
    })
  )
  const aggregatedOrderCharges = orderCharges.map<InvoiceCharge>(charge => ({
    ...getAggregatedChargeCommonProperties(
      charge,
      parentTypeId,
      source,
      isEvansTenant
    ),
    orderAmount: charge.chargeAmount,
    orderChargeId: charge.chargeId
  }))

  const aggregatedAuditCharges = auditCharges.map<InvoiceCharge>(charge => ({
    ...getAggregatedChargeCommonProperties(
      charge,
      parentTypeId,
      source,
      isEvansTenant
    ),
    auditAmount: charge.chargeAmount
  }))

  const aggregatedOrderChargesIsPairWithAdjusted = Array.from({
    length: aggregatedOrderCharges.length
  }).fill(false)
  const aggregatedAuditChargesIsPairWithAdjusted = Array.from({
    length: aggregatedAuditCharges.length
  }).fill(false)

  if (source === 'customer' || isEvansTenant) {
    const adjustedChargesWithDescriptionMatches = aggregatedAdjustedCharges.map(
      charge => {
        let proccessedCharge = {
          ...charge
        }
        const orderDescriptionMatch = aggregatedOrderCharges.filter(
          orderCharge => charge.description === orderCharge.description
        )

        const auditDescriptionMatch = aggregatedAuditCharges.filter(
          auditCharge => charge.description === auditCharge.description
        )
        if (orderDescriptionMatch.length === 1) {
          const orderChargeIndex = aggregatedOrderCharges.findIndex(
            orderCharge =>
              orderCharge.orderChargeId ===
              orderDescriptionMatch[0].orderChargeId
          )
          aggregatedOrderChargesIsPairWithAdjusted[orderChargeIndex] = true
          proccessedCharge = {
            ...proccessedCharge,
            orderAmount: orderDescriptionMatch[0].orderAmount,
            orderChargeId: orderDescriptionMatch[0].orderChargeId
          }
        }
        if (auditDescriptionMatch.length === 1) {
          const auditChargeIndex = aggregatedAuditCharges.findIndex(
            auditCharge => auditCharge.guid === auditDescriptionMatch[0].guid
          )
          aggregatedAuditChargesIsPairWithAdjusted[auditChargeIndex] = true
          proccessedCharge = {
            ...proccessedCharge,
            auditAmount: auditDescriptionMatch[0].auditAmount
          }
        }
        return proccessedCharge
      }
    )

    const orderChargesToConcat = aggregatedOrderChargesIsPairWithAdjusted
      .map((isPairWithAdjusted, index) =>
        isPairWithAdjusted ? undefined : aggregatedOrderCharges[index]
      )
      .filter(charge => charge !== undefined) as InvoiceCharge[]

    const auditChargesToConcat = aggregatedAuditChargesIsPairWithAdjusted
      .map((isPairWithAdjusted, index) =>
        isPairWithAdjusted ? undefined : aggregatedAuditCharges[index]
      )
      .filter(charge => charge !== undefined) as InvoiceCharge[]

    return adjustedChargesWithDescriptionMatches
      .concat(orderChargesToConcat)
      .concat(auditChargesToConcat)
      .filter(charge => charge !== undefined)
  }

  return aggregatedAdjustedCharges
    .concat(aggregatedOrderCharges)
    .concat(aggregatedAuditCharges)
}

function getEmptyAggregationDetails(
  typeId: KnownChageTypes,
  adjustedCharges: NormalizedResponse[],
  orderCharges: NormalizedResponse[],
  auditCharges: NormalizedResponse[],
  source: ChargesGroupNames,
  isEvansTenant: boolean
): InvoiceCharge[] {
  return getAggregationDetails(
    typeId,
    adjustedCharges,
    orderCharges,
    auditCharges,
    source,
    isEvansTenant
  ).map(charge => ({
    ...charge,
    adjustedAmount: undefined,
    adjustedComparerAmount: undefined,
    varianceReasonId: undefined,
    orderAmount: undefined,
    auditAmount: undefined
  }))
}

function getNormalizedResponse(
  [
    auditDocument,
    vendorAuditChargesRaw,
    customerAuditChargesRaw,
    chargeCodes,
    purchaseInvoiceCharges
  ]: Response,
  payload: ReturnType<typeof invoiceChargesActions.loadInvoiceCharges>,
  isEvansTenant: boolean
): ChargesResponse {
  const externalId = payload.vendorExternalId
  const invoiceUuid = payload.invoiceUuid

  let vendor: platform_v2.Navix_Services_Sdk_AuditVendor | undefined
  let invoice: platform_v2.Navix_Services_Sdk_AuditInvoice | undefined
  if (externalId !== undefined) {
    vendor = auditDocument.vendors?.[externalId]
    invoice = vendor?.invoices?.find(invoice => invoice.source === invoiceUuid)
  } else {
    invoice = auditDocument.provisionalInvoices?.find(
      invoice => invoice.source === invoiceUuid
    )
  }

  const customerAdjustedChargesRaw =
    invoice?.charges?.customer?.chargeAdjustments ?? []

  const vendorAdjustedChargesRaw =
    invoice?.charges?.vendor?.chargeAdjustments ?? []

  const vendorOrderChargesRawFromAudit = vendor?.userTypeMappedCharges ?? []

  const vendorOrderChargesRaw =
    purchaseInvoiceCharges !== undefined
      ? purchaseInvoiceCharges.map<platform_v2.Navix_Services_Sdk_AuditUserTypeMappedCharge>(
          purchaseInvoice => {
            const matchedType = vendorOrderChargesRawFromAudit.find(
              charge =>
                charge.code?.toLowerCase() ===
                  purchaseInvoice.code.toLowerCase() &&
                charge.description?.toLowerCase() ===
                  purchaseInvoice.description.toLowerCase()
            )?.type

            const unknownType = chargeCodes.find(
              chargeCode => chargeCode.id === KnownChageTypes.UnknownAccesorials
            )!.uuid
            return {
              charge: purchaseInvoice.chargeAmount,
              code: purchaseInvoice.code ?? 'NO CODE',
              description: purchaseInvoice.description ?? '',
              type: matchedType ?? unknownType
            }
          }
        ) ?? []
      : vendorOrderChargesRawFromAudit

  const customerOrderChargesMapped = getCustomerOrderCharges(
    auditDocument,
    customerAdjustedChargesRaw,
    chargeCodes
  )

  const vendorAdjustedCharges = getVendorAdjustedCharges(
    vendorAdjustedChargesRaw,
    chargeCodes,
    vendorOrderChargesRaw,
    isEvansTenant
  )

  const customerAdjustedCharges = getCustomerAdjustedCharges(
    customerAdjustedChargesRaw,
    chargeCodes
  )
  const vendorOrderCharges = getVendorOrderCharges(
    vendorOrderChargesRaw,
    vendorAdjustedChargesRaw,
    chargeCodes
  )
  const vendorAuditCharges = vendorAuditChargesRaw.map<NormalizedResponse>(
    charge => ({
      chargeId: charge.auditChargeId,
      ...getNormalizedChargeWithDefaultProperties(charge)
    })
  )
  const customerAuditCharges = customerAuditChargesRaw.map<NormalizedResponse>(
    charge => ({
      chargeId: charge.customerAuditChargeId,
      ...getNormalizedChargeWithDefaultProperties(charge)
    })
  )
  return {
    vendor: {
      adjustedCharges: vendorAdjustedCharges,
      auditCharges: vendorAuditCharges,
      orderCharges: vendorOrderCharges
    },
    customer: {
      adjustedCharges: customerAdjustedCharges,
      auditCharges: customerAuditCharges,
      orderCharges: customerOrderChargesMapped
    }
  }
}

function getVendorOrderCharges(
  vendorOrderCharges: platform_v2.Navix_Services_Sdk_AuditUserTypeMappedCharge[],
  vendorAdjustedCharges: platform_v2.Navix_Services_Sdk_ChargeAdjustment[],
  chargeCodes: ChargeCode[]
) {
  return (vendorOrderCharges ?? []).map<NormalizedResponse>((charge, i) => {
    const adjustedChargePair = vendorAdjustedCharges.find(
      adjustedCharge =>
        adjustedCharge.code?.toLowerCase() === charge.code?.toLowerCase() &&
        adjustedCharge.originalCharge === charge.charge &&
        adjustedCharge.description?.toLowerCase() ===
          charge.description?.toLowerCase() &&
        adjustedCharge.type === charge.type
    )
    const chargeCodeFromAdjustedPair = chargeCodes.find(
      chargeCode => chargeCode.uuid === adjustedChargePair?.type
    )
    const chargeCodeFromCharge = chargeCodes.find(chargeCode => {
      return (
        chargeCode.uuid === charge?.type ||
        chargeCode.tenantChargeTypes.find(
          tenantChargeType => tenantChargeType.id === charge?.type
        ) !== undefined
      )
    })

    const chargeCodeId =
      (chargeCodeFromAdjustedPair?.id === KnownChageTypes.UnknownAccesorials
        ? undefined
        : chargeCodeFromAdjustedPair?.id) ??
      chargeCodeFromCharge?.id ??
      KnownChageTypes.UnknownAccesorials

    return <NormalizedResponse>{
      chargeId: `vendorOrder_t:${chargeCodeId}-c:${charge.code}-d:${charge.description}-o:${adjustedChargePair?.originalCharge}-i:${i}`,
      typeId: chargeCodeId,
      currency: '',
      code: charge.code ?? '',
      description: charge.description ?? '',
      metadata: undefined,
      isLock: false,
      varianceReasonId: undefined,
      chargeAmount: charge.charge ?? 0,
      tenantCode: undefined,
      tenantDescription: undefined,
      adjustedChargeAmount: 0
    }
  })
}

function getCustomerAdjustedCharges(
  customerAdjustedCharges: platform_v2.Navix_Services_Sdk_ChargeAdjustment[],
  chargeCodes: ChargeCode[]
) {
  const customerAdjustedChargesMapped =
    customerAdjustedCharges.map<NormalizedResponse>((charge, i) => {
      const knownMapChargeCodeUuid = charge.type

      const chargeCode = chargeCodes.find(
        chargeCode =>
          chargeCode.uuid ===
          (knownMapChargeCodeUuid !== undefined
            ? knownMapChargeCodeUuid
            : charge.type)
      )
      const varianceReasonId = chargeCode?.varianceReasons?.find(
        reason => reason.description === charge.reason
      )?.id

      const chargeCodeId = chargeCode?.id ?? KnownChageTypes.UnknownAccesorials
      return <NormalizedResponse>{
        chargeId: `customerAdjusted_t:${chargeCodeId}-c:${charge.code}-d:${charge.description}-o:${charge?.originalCharge}-i:${i}`,
        typeId: chargeCodeId,
        currency: '',
        code: charge.code,
        description: charge.description ?? '',
        metadata: undefined,
        isLock: false,
        varianceReasonId: varianceReasonId,
        chargeAmount: charge.originalCharge ?? charge.adjustment ?? 0,
        tenantCode: undefined,
        tenantDescription: undefined,
        adjustedChargeAmount: charge.adjustment,
        originalAdjustment: charge.originalCharge,
        originalCode: charge.code
      }
    })
  const customerAdjustedChargesMappedUnique = Object.values(
    Object.groupBy(customerAdjustedChargesMapped, charge => charge.chargeId)
  )
    .map<NormalizedResponse>(charge => charge![0])
    .filter(charge => charge !== undefined)
  return customerAdjustedChargesMappedUnique
}

function getVendorAdjustedCharges(
  vendorAdjustedCharges: platform_v2.Navix_Services_Sdk_ChargeAdjustment[],
  chargeCodes: ChargeCode[],
  vendorOrderCharges: platform_v2.Navix_Services_Sdk_AuditUserTypeMappedCharge[],
  isEvansTenant: boolean
) {
  const vendorAdjustedChargesMapped =
    vendorAdjustedCharges.map<NormalizedResponse>((charge, i) => {
      const knownMapChargeCodeUuid = charge.type

      const chargeCode = chargeCodes.find(
        chargeCode =>
          chargeCode.uuid ===
          (knownMapChargeCodeUuid !== undefined
            ? knownMapChargeCodeUuid
            : charge.type)
      )

      const varianceReasonId = chargeCode?.varianceReasons?.find(
        reason => reason.description === charge.reason
      )?.id

      const chargeCodeId = chargeCode?.id ?? KnownChageTypes.UnknownAccesorials
      return <NormalizedResponse>{
        chargeId: `vendorAdjusted_t:${chargeCodeId}-c:${charge.code}-d:${charge.description}-o:${charge.originalCharge}-i:${i}`,
        typeId: chargeCodeId,
        currency: '',
        code: charge.code,
        description: charge.description ?? '',
        metadata: undefined,
        isLock: false,
        varianceReasonId: varianceReasonId,
        chargeAmount: charge.originalCharge ?? charge.adjustment ?? 0,
        tenantCode: undefined,
        tenantDescription: undefined,
        adjustedChargeAmount: charge.adjustment,
        originalAdjustment: charge.originalCharge,
        originalCode: charge.code
      }
    })

  const unmatchedVendorOrderCharges = vendorOrderCharges.filter(
    orderCharge =>
      !vendorAdjustedCharges.some(
        adjustedCharge =>
          orderCharge.code === adjustedCharge.code &&
          orderCharge.description === adjustedCharge.description &&
          (orderCharge.type === adjustedCharge.type || orderCharge.type == null)
      )
  )

  const newAdjustedChargesBasedOnOrderCharges = (
    isEvansTenant ? unmatchedVendorOrderCharges : []
  ).map<NormalizedResponse>((charge, i) => {
    const knownMapChargeCodeUuid = charge.type

    const chargeCode = chargeCodes.find(
      chargeCode =>
        chargeCode.uuid ===
        (knownMapChargeCodeUuid !== undefined
          ? knownMapChargeCodeUuid
          : charge.type)
    )

    const chargeCodeId = chargeCode?.id ?? KnownChageTypes.UnknownAccesorials
    return <NormalizedResponse>{
      chargeId: `vendorAdjusted_t:${chargeCodeId}-c:${charge.code}-d:${charge.description}-o:${charge.charge}-i:${i}`,
      typeId: chargeCodeId,
      currency: '',
      code: charge.code,
      description: charge.description ?? '',
      metadata: undefined,
      isLock: false,
      varianceReasonId: undefined,
      chargeAmount: 0,
      tenantCode: undefined,
      tenantDescription: undefined,
      adjustedChargeAmount: 0,
      originalAdjustment: 0,
      originalCode: charge.code
    }
  })

  const vendorAdjustedChargesMappedUnique = Object.values(
    Object.groupBy(
      [
        ...vendorAdjustedChargesMapped,
        ...newAdjustedChargesBasedOnOrderCharges
      ],
      charge => charge.chargeId
    )
  )
    .map<NormalizedResponse>(charge => charge![0])
    .filter(charge => charge !== undefined)

  return vendorAdjustedChargesMappedUnique
}

function getCustomerOrderCharges(
  auditDocument: platform_v2.Navix_Services_Sdk_Audit,
  customerAdjustedCharges: platform_v2.Navix_Services_Sdk_ChargeAdjustment[],
  chargeCodes: ChargeCode[]
) {
  const customerOrderCharges =
    auditDocument.customer?.userTypeMappedCharges ?? []
  const customerOrderChargesMapped =
    customerOrderCharges.map<NormalizedResponse>((charge, i) => {
      const adjustedChargePair = customerAdjustedCharges.find(
        adjustedCharge =>
          adjustedCharge.code === charge.code &&
          adjustedCharge.originalCharge === charge.charge &&
          adjustedCharge.description === charge.description
      )
      const chargeCodeFromAdjustedPair = chargeCodes.find(
        chargeCode => chargeCode.uuid === adjustedChargePair?.type
      )

      const chargeCodeFromCharge = chargeCodes.find(chargeCode => {
        return (
          chargeCode.uuid === charge?.type ||
          chargeCode.tenantChargeTypes.find(
            tenantChargeType => tenantChargeType.id === charge?.type
          ) !== undefined
        )
      })

      const chargeCodeId =
        (chargeCodeFromAdjustedPair?.id === KnownChageTypes.UnknownAccesorials
          ? undefined
          : chargeCodeFromAdjustedPair?.id) ??
        chargeCodeFromCharge?.id ??
        KnownChageTypes.UnknownAccesorials

      return <NormalizedResponse>{
        chargeId: `customerOrder_t:${chargeCodeId}-c:${charge.code}-d:${charge.description}-o:${adjustedChargePair?.originalCharge}:i:${i}`,
        typeId: chargeCodeId,
        currency: '',
        code: charge.code ?? '',
        description: charge.description ?? '',
        metadata: undefined,
        isLock: false,
        varianceReasonId: undefined,
        chargeAmount: charge.charge ?? 0,
        tenantCode: undefined,
        tenantDescription: undefined,
        adjustedChargeAmount: 0
      }
    })
  return customerOrderChargesMapped
}

function getNormalizedChargeWithDefaultProperties(
  charge:
    | GetInvoiceChargesNewResponse[number]
    | GetInvoiceAuditChargesResponse[number]
    | GetInvoiceCustomerAuditChargesNewResponse[number]
): Omit<NormalizedResponse, 'chargeId'> {
  return {
    typeId: charge.chargeTypeId,
    currency: charge.currency,
    code: charge.code,
    description: charge.description,
    metadata: charge.metadata,
    isLock: charge.isLock,
    varianceReasonId: undefined,
    chargeAmount: (charge['charge' as keyof typeof charge] as number) ?? 0,
    tenantCode: undefined,
    tenantDescription: undefined,
    adjustedChargeAmount: 0,
    originalAdjustment: undefined,
    originalCode: undefined
  }
}
function getAggregatedChargeCommonProperties(
  charge: NormalizedResponse,
  parentTypeId: number,
  source: ChargesGroupNames,
  isEvansTenant: boolean
): InvoiceCharge {
  const descriptionToUse = charge?.tenantDescription ?? charge?.description
  const codeToUse = charge?.tenantCode ?? charge?.code
  const description = getDescription(descriptionToUse, codeToUse)

  return {
    guid: self.crypto.randomUUID(),
    parentTypeId: parentTypeId,
    typeId: charge.typeId,
    typeDescription: description,
    orderAmount: isEvansTenant ? 0 : undefined,
    auditAmount: isEvansTenant ? 0 : undefined,
    adjustedComparerAmount: isEvansTenant ? 0 : undefined,
    adjustedAmount: isEvansTenant ? 0 : undefined,
    adjustedChargeId: undefined,
    orderChargeId: undefined,
    varianceReasonId: undefined,
    hasAggregationDetails: false,
    isAggregationDetail: true,
    code: charge.code,
    description: charge.description,
    tenantCode: charge.tenantCode,
    tenantDescription: charge.tenantDescription,
    details: [],
    metadata: [],
    chargeSource: source,
    originalCode: charge.code,
    originalAdjustment: charge.originalAdjustment,
    originalDescription: charge.description
  }
}

function getMetadata(metadataString?: string): {
  [key: string]: string
} {
  try {
    return JSON.parse(metadataString ?? '{}')
  } catch (error) {
    console.warn('[NavixApp] Error parsing metadata: ', metadataString)

    return {}
  }
}
