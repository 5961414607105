import { createActionGroup, emptyProps, props } from '@ngrx/store'
import {
  OrdersDataFilters,
  OrderStatusFilter
} from '../../domain/orders/data-filters.model'
import { Order } from '../../domain/orders/order.model'
import { OrdersAsyncOperations } from '../../domain/orders/orders-loading.model'
import { Tag } from '../../domain/orders/tag.model'

export const ordersActions = createActionGroup({
  source: 'Orders',
  events: {
    'load orders': props<{ filters: OrdersDataFilters }>(),
    'load orders success': props<{ orders: Order[] }>(),
    'load orders fail': props<{ error: unknown }>(),
    resetGetAllLoadingState: emptyProps(),

    'set list filters': props<{ filters: OrdersDataFilters }>(),
    'set selected id': props<{ orderId?: Order['id'] }>(),

    'download orders': props<{ filters: OrdersDataFilters; id: number }>(),
    'download orders success': props<{
      filename?: string
      blob: Blob
    }>(),
    'download orders fail': props<{ error: unknown }>(),
    'set total count': props<{ count: number }>(),

    'load order details': props<{
      legacyOrderId: Order['legacyId']
      orderId: Order['id']
    }>(),
    'load order details success': props<{ order: Order }>(),

    'load order stops': props<{
      orderId: Order['id']
      legacyOrderId: Order['legacyId']
    }>(),
    'load order stops success': props<{ orderWithStops: Order }>(),

    'load reference numbers': props<{
      orderId: Order['id']
      legacyOrderId: Order['legacyId']
    }>(),
    'load reference numbers success': props<{
      orderWithReferenceNumbers: Order
    }>(),

    'load line items': props<{
      orderId: Order['id']
      legacyOrderId: Order['legacyId']
    }>(),
    'load line items success': props<{ orderWithLineItems: Order }>(),

    'load charges': props<{
      orderId: Order['id']
      legacyOrderId: Order['legacyId']
    }>(),
    'load charges success': props<{ orderWithCharges: Order }>(),

    'set list filters memory': props<{
      filters: OrdersDataFilters
      status: OrderStatusFilter
    }>(),

    'set loading': props<{
      operation: OrdersAsyncOperations
      loading: boolean
    }>(),

    'load matched invoices': props<{
      orderId: Order['id']
      legacyOrderId: Order['legacyId']
    }>(),
    'load matched invoices success': props<{
      orderWithMatchedInvoices: Order
    }>(),
    'load matched invoices fail': emptyProps(),
    resetGetMatchedInvoicesLoadingState: emptyProps(),

    'load new order details': props<{
      orderId: Order['id']
      legacyOrderId: Order['legacyId']
    }>(),
    'load new order details success': props<{ order: Order }>(),
    resetGetNewOrderDetailsLoadingState: emptyProps(),
    'load new order details fail': props<{ error: unknown }>(),
    updateOrdersPurchaseInvoice: props<{
      uuid: string
      orderUuid: string
      vendorInvoiceUuid: string
      shouldReloadOrderDetails: boolean
    }>(),
    updateOrdersPurchaseInvoiceSuccess: props<{
      shouldReloadOrderDetails: boolean
    }>(),
    updateOrdersPurchaseInvoiceFail: props<{ error: unknown }>(),
    resetUpdatePurchaseInvoiceLoadingState: emptyProps(),
    removePurchaseInvoiceAssociatedUuid: props<{
      purchaseInvoiceUuid: string
      orderUuid: string
    }>(),
    removePurchaseInvoiceAssociatedUuidSuccess: emptyProps(),
    removePurchaseInvoiceAssociatedUuidFailed: props<{
      error: unknown
    }>(),
    resetRemovePurchaseInvoiceAssociatedUuidLoadingState: emptyProps(),
    loadDivisionTags: emptyProps(),
    loadDivisionTagsSuccess: props<{
      tags: Tag[]
    }>(),
    loadDivisionTagsFail: props<{ error: unknown }>(),
    resetLoadDivisionTagsLoadingState: emptyProps()
  }
})
