import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { InvoiceForm } from '../../../domain/invoices/invoice-form.model'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { HttpErrorResponse } from '@angular/common/http'

export const invoiceDocumentsActions = createActionGroup({
  source: 'Invoice Documents',
  events: {
    'load invoice documents': props<{ invoiceId: number }>(),
    'load invoice documents success': props<{
      documents: Invoice['documents']
      invoiceId: number
    }>(),
    'load invoice documents fail': props<{ error: unknown }>(),

    'update invoice documents': props<Pick<InvoiceForm, 'documents' | 'id'>>(),
    'update invoice documents success': props<{
      invoiceId: number
    }>(),
    'update invoice documents fail': props<{ error: HttpErrorResponse }>(),

    'delete invoice documents': props<{
      invoiceId: number
      documentId: number
    }>(),
    'delete invoice documents success': props<{ invoiceId: number }>(),
    'delete invoice documents fail': props<{ error: HttpErrorResponse }>(),

    'download invoice document': props<{ documentId: number }>(),
    'download invoice document success': props<{
      filename?: string
      documentId: number
      blob: Blob
    }>(),
    'download invoice document fail': props<{ error: unknown }>(),

    'add invoice documents': props<Pick<InvoiceForm, 'documents'>>(),
    'add invoice documents success': props<{
      invoiceId: number
    }>(),
    'add invoice documents fail': props<{ error: HttpErrorResponse }>(),
    'generate default invoice document': emptyProps(),
    'generate default invoice document success': props<{
      invoiceId: number
    }>(),
    'generate default invoice document fail': props<{
      error: HttpErrorResponse
    }>(),
    'merge invoice documents': props<{
      requests: { invoiceId: number; documentIds: number[] }[]
    }>(),
    'merge invoice documents success': props<{
      requests: { invoiceId: number; documentIds: number[] }[]
    }>(),
    'merge invoice documents fail': props<{
      error: HttpErrorResponse
    }>(),
    'attach documents to invoice':
      props<Pick<InvoiceForm, 'documents' | 'uuid'>>(),
    'attach documents to invoice success': props<{
      allFinished: boolean
    }>(),
    'attach documents to invoice fail': props<{ error: HttpErrorResponse }>(),
    resetAttachDocumentsToInvoiceLoadingState: emptyProps(),
    'redact document': props<{
      invoiceUuid: string
      blob: Blob
      originalDocumentUuid: string
    }>(),
    'redact document success': emptyProps(),
    'redact document fail': props<{ error?: string }>(),
    resetRedactDocumentLoadingState: emptyProps()
  }
})
