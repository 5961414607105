// eslint-disable-next-line @nx/enforce-module-boundaries
import { ChargeCode } from '@navix/charge-codes/domain'
import { MeasurementUnits } from '../../+state/orders/static/unit-of-measure'

import { Order } from '../../domain/orders/order.model'
import {
  OrderCharge,
  OrderChargeDraft
} from '../../domain/orders/order-charge.model'
import { platform_v2 } from '@navix/api-models'
import { KnownChageTypes } from '../../+state/invoices/static'
import { getOrderChargesFromDraftOrderCharges } from '../../infrastructure/order-charges-mapping-functions'
import { createMatchPattern } from '@navix/utils/helpers'

type PurchaseInvoiceObject = {
  Uuid: string
  Status: string
  Charges: {
    Charge: number
    Description: string
    Code: string
    LineItemUuid: string
    InvoiceNumber: string
    InvoiceLineNumber: string
    ReferenceNumbers: unknown[]
  }[]
  VendorExternalId: string
  AssociatedVendorInvoiceUuid: string
}

const stopTypesDescriptions: Record<
  platform_v2.Navix_Services_Sdk_StopType,
  Order['stops2'][number]['typeDescription']
> = {
  Origin: 'Origin',
  Destination: 'Destination',
  BillTo: 'Bill To',
  RemitTo: 'Remit To'
}
const referenceNumberTypesDescriptions: Record<
  platform_v2.Navix_Services_Sdk_ReferenceNumberType,
  Order['referenceNumbers2'][number]['typeDescription']
> = {
  OrderNumber: 'Order Number',
  ShipmentNumber: 'Shipment Number',
  BOLNumber: 'BOL Number',
  SONumber: 'SO Number',
  GeneralReferenceNumber: 'General Reference Number',
  PONumber: 'PO Number'
}

export function fromGetNewOrderDetailsResponse({
  response,
  chargeCodes
}: {
  response: platform_v2.Navix_Services_Sdk_OrderDocument
  chargeCodes: ChargeCode[]
}): Order {
  const referenceNumbers =
    response.order.referenceNumbers?.map(convertReferenceNumber) ?? []
  const lineItems = response.order.items?.map(convertLineItem) ?? []
  const stops = response.order.stops?.map(convertStop) ?? []
  const vendorsRaw = response.order.vendors ?? []
  const vendorsMap = Map.groupBy(vendorsRaw, vendor => vendor.externalId)
  const vendorsMergedDuplicates = Array.from(vendorsMap.values()).map(
    vendor => {
      return {
        ...vendor[0],
        charges: vendor.flatMap(vendor => (vendor ? vendor.charges : []))
      }
    }
  )
  const vendors =
    vendorsMergedDuplicates.map(
      createVendorConverter(
        chargeCodes,
        response.order.metadata ?? {},
        response.order.stops ?? []
      )
    ) ?? []

  const { customerCharges, vendorCharges } = convertCharges(
    chargeCodes,
    response
  )

  return <Order>{
    orderNumber: response.orderNumber,
    shipDate: toDate(response.shipDate),
    deliveryDate: toDate(response.order.deliveryDate),
    metadata: JSON.stringify(response.order.metadata ?? ''),
    withDetails: true,
    referenceNumbers2: referenceNumbers.toSorted((compare, comparer) =>
      compare.description.localeCompare(comparer.description)
    ),
    lineItems2: lineItems,
    stops2: stops,
    customer: {
      name: response.customerName,
      externalId: response.order.customer.externalId
    },
    customerCharges,
    vendorCharges,
    vendors,
    createDate: toDate(response.createdAt),
    division: response.division
  }
}

function convertCharges(
  chargeCodes: ChargeCode[],
  response: platform_v2.Navix_Services_Sdk_OrderDocument
) {
  const unknownChargeCode = chargeCodes
    .filter(chargeCode => chargeCode.id === KnownChageTypes.UnknownAccesorials)
    .map(chargeCode => ({
      chargeTypeId: chargeCode.id,
      chargeTypeDescription: chargeCode.description,
      parentChargeTypeId: undefined
    }))[0]

  const draftVendorCharges =
    response.order.vendors?.flatMap(vendor =>
      vendor.charges?.map<OrderChargeDraft>(charge => ({
        ...(getChargeCodeDetailsIdFromResponseCharge(charge, chargeCodes) ??
          unknownChargeCode),
        chargeAmount: Number(charge.charge),
        tenantCode: charge.code?.value ?? '',
        tenantDescription: charge.code?.description ?? '',
        details: undefined,
        description: undefined
      }))
    ) ?? []

  const draftCustomerCharges =
    response.order.customer.charges?.map<OrderChargeDraft>(charge => ({
      ...(getChargeCodeDetailsIdFromResponseCharge(charge, chargeCodes) ??
        unknownChargeCode),
      chargeAmount: Number(charge.charge),
      tenantCode: charge.code?.value ?? '',
      tenantDescription: charge.code?.description ?? '',
      details: undefined,
      description: undefined
    })) ?? []
  const customerCharges = getOrderChargesFromDraftOrderCharges(
    draftCustomerCharges,
    chargeCodes
  )
  const vendorCharges = getOrderChargesFromDraftOrderCharges(
    draftVendorCharges,
    chargeCodes
  )

  return { customerCharges, vendorCharges }
}

function toDate(date: string | null | undefined) {
  return date ? new Date(date) : undefined
}

function convertLineItem(
  item: platform_v2.Navix_Services_Sdk_Item
): Order['lineItems2'][number] {
  return {
    class: Number(item.class),
    cube: calculateCube(item),
    density: calculateDensity(item),
    dimensionUnitDescription:
      MeasurementUnits[item.dimensions?.system ?? ''].dimensionUnit,
    handlingUnitCount: Number(item.handlingUnitCount),
    handlingUnitDescription: item.handlingUnit,
    height: Number(item.dimensions?.height),
    length: Number(item.dimensions?.length),
    name: item.description ?? '',
    nmfcCode: item.nmfcCode ?? '',
    pieces: Number(item.totalCount),
    stackable: item.dimensions?.stackable ?? false,
    weight: Number(item.dimensions?.weight),
    weightUnitDescription:
      MeasurementUnits[item.dimensions?.system ?? ''].weightUnit,
    width: Number(item.dimensions?.width),
    id: item.id
  }
}

function convertStop(
  stop: platform_v2.Navix_Services_Sdk_Stop
): Order['stops2'][number] {
  return {
    typeDescription: stopTypesDescriptions[stop.type],
    sequence: Number(stop.sequence),
    name: stop.address?.name ?? undefined,
    address1: stop.address?.address1 ?? '',
    address2: stop.address?.address2 ?? '',
    city: stop.address?.city ?? '',
    stateCode: stop.address?.state ?? '',
    zip: stop.address?.postalCode ?? '',
    countryCode: stop.address?.country ?? ''
  }
}

function createVendorConverter(
  chargeCodes: ChargeCode[],
  metadata: Record<string, Record<string, string>>,
  stops: platform_v2.Navix_Services_Sdk_Stop[]
): (vendor: platform_v2.Navix_Services_Sdk_Vendor) => Order['vendors'][number] {
  const unknownChargeCode = chargeCodes
    .filter(chargeCode => chargeCode.id === KnownChageTypes.UnknownAccesorials)
    .map(chargeCode => ({
      chargeTypeId: chargeCode.id,
      chargeTypeDescription: chargeCode.description,
      parentChargeTypeId: undefined
    }))[0]

  return vendor => {
    const draftVendorCharges2 = vendor.charges?.map<OrderChargeDraft>(
      charge => ({
        ...(getChargeCodeDetailsIdFromResponseCharge(charge, chargeCodes) ??
          unknownChargeCode),
        chargeAmount: Number(charge.charge),
        tenantCode: charge.code?.value ?? '',
        tenantDescription: charge.code?.description ?? '',
        details: undefined,
        description: undefined
      })
    )

    const charges = getOrderChargesFromDraftOrderCharges(
      draftVendorCharges2,
      chargeCodes
    )

    const purchaseInvoices = getPurchaseInvoicesFromMetadata(
      metadata,
      vendor.externalId,
      chargeCodes
    )

    return {
      externalId: vendor.externalId,
      name: vendor.name,
      chargeTerms: vendor.chargeTerms ?? '',
      paymentTerms: vendor.paymentTerms ?? '',
      billTo: {
        name: vendor.billToAddress?.name ?? '',
        address1: vendor.billToAddress?.address1 ?? '',
        address2: vendor.billToAddress?.address2 ?? '',
        city: vendor.billToAddress?.city ?? '',
        stateCode: vendor.billToAddress?.state ?? '',
        countryCode: vendor.billToAddress?.country ?? '',
        zip: vendor.billToAddress?.postalCode ?? ''
      },
      remitTo: {
        name: vendor.remitToAddress?.name ?? '',
        address1: vendor.remitToAddress?.address1 ?? '',
        address2: vendor.remitToAddress?.address2 ?? '',
        city: vendor.remitToAddress?.city ?? '',
        stateCode: vendor.remitToAddress?.state ?? '',
        countryCode: vendor.remitToAddress?.country ?? '',
        zip: vendor.remitToAddress?.postalCode ?? ''
      },
      service: {
        mode: vendor.service?.mode ?? '',
        type: vendor.service?.type ?? '',
        level: vendor.service?.level ?? ''
      },
      charges,
      purchaseInvoices,
      stops:
        stops
          .filter(x => x.vendors?.includes(vendor.externalId))
          .map(convertStop)
          .toSorted((a, b) => a.sequence - b.sequence) ?? []
    }
  }
}

function convertReferenceNumber(
  referenceNumber: platform_v2.Navix_Services_Sdk_ReferenceNumber
): Order['referenceNumbers2'][number] {
  return {
    value: referenceNumber.value ?? '',
    description: referenceNumber.description ?? '',
    typeDescription:
      referenceNumberTypesDescriptions[referenceNumber.type ?? '']
  }
}

function getChargeCodeDetailsIdFromResponseCharge(
  charge: platform_v2.Navix_Services_Sdk_OrderCharge,
  chargeCodes: ChargeCode[]
):
  | Pick<
      OrderCharge,
      'chargeTypeId' | 'chargeTypeDescription' | 'parentChargeTypeId'
    >
  | undefined {
  const matches = chargeCodes.filter(chargeCode =>
    chargeCode.tenantChargeTypes.some(tenantChargeType => {
      const codePattern = createMatchPattern(charge.code!.value)
      const codeMatch = codePattern.test(tenantChargeType.code)

      if (charge.code?.description === undefined) return codeMatch

      const descriptionPattern = createMatchPattern(charge.code.description)
      const descriptionMatch = descriptionPattern.test(
        tenantChargeType.description === null
          ? ''
          : tenantChargeType.description.toLowerCase()
      )

      return codeMatch && descriptionMatch
    })
  )

  if (matches.length === 1)
    return {
      chargeTypeId: matches[0].id,
      chargeTypeDescription: matches[0].description,
      parentChargeTypeId: matches[0].parentChargeTypeId
    }

  return undefined
}

function calculateCube(item: platform_v2.Navix_Services_Sdk_Item): number {
  if (
    item.dimensions?.length === 0 ||
    item.dimensions?.width === 0 ||
    item.dimensions?.height === 0
  ) {
    return 0
  }

  if (
    MeasurementUnits[item.dimensions?.system ?? ''].dimensionUnit === 'IN' &&
    MeasurementUnits[item.dimensions?.system ?? ''].weightUnit === 'LBS'
  ) {
    return Number(
      (Math.round(Number(item.dimensions?.length)) *
        Math.round(Number(item.dimensions?.width)) *
        Math.round(Number(item.dimensions?.height)) *
        Number(item.handlingUnitCount)) /
        1728
    )
  } else if (
    MeasurementUnits[item.dimensions?.system ?? ''].dimensionUnit === 'CM' &&
    MeasurementUnits[item.dimensions?.system ?? ''].weightUnit === 'KG'
  ) {
    return Number(
      (Math.round(Number(item.dimensions?.length)) *
        Math.round(Number(item.dimensions?.width)) *
        Math.round(Number(item.dimensions?.height)) *
        Number(item.handlingUnitCount)) /
        1000000
    )
  } else {
    return 0
  }
}

function calculateDensity(item: platform_v2.Navix_Services_Sdk_Item): number {
  const denominator =
    Math.round(Number(item.dimensions?.length)) *
    Math.round(Number(item.dimensions?.width)) *
    Math.round(Number(item.dimensions?.height)) *
    Number(item.handlingUnitCount)

  if (!denominator || denominator === 0) {
    return 0
  }

  if (
    MeasurementUnits[item.dimensions?.system ?? ''].dimensionUnit === 'IN' &&
    MeasurementUnits[item.dimensions?.system ?? ''].weightUnit === 'LBS'
  ) {
    return Number(
      (Math.round(Number(item.dimensions?.weight)) * 1728) / denominator
    )
  } else if (
    MeasurementUnits[item.dimensions?.system ?? ''].dimensionUnit === 'CM' &&
    MeasurementUnits[item.dimensions?.system ?? ''].weightUnit === 'KG'
  ) {
    return Number(
      (Math.round(Number(item.dimensions?.weight)) * 1000000) / denominator
    )
  } else {
    return 0
  }
}

function getPurchaseInvoicesFromMetadata(
  metadata: Record<string, Record<string, string>>,
  vendorExternalId: string,
  chargeCodes: ChargeCode[]
): Order['vendors'][number]['purchaseInvoices'] {
  const PURCHASE_INVOICES_KEY = 'purchaseInvoices'
  const purchaseInvoicesRaw =
    metadata[PURCHASE_INVOICES_KEY.toUpperCase()] ??
    metadata[PURCHASE_INVOICES_KEY.toLowerCase()] ??
    metadata[PURCHASE_INVOICES_KEY]

  if (purchaseInvoicesRaw === undefined) return []

  const purchaseInvoices = Object.entries(purchaseInvoicesRaw).map(
    ([key, value]) => {
      return {
        uuid: key,
        data: tryParsePurchaseInvoiceFromString(value)
      }
    }
  )

  const unknownChargeCode = chargeCodes
    .filter(chargeCode => chargeCode.id === KnownChageTypes.UnknownAccesorials)
    .map(chargeCode => ({
      chargeTypeId: chargeCode.id,
      chargeTypeDescription: chargeCode.description,
      parentChargeTypeId: undefined
    }))[0]

  return purchaseInvoices
    .filter(
      purchaseInvoice =>
        purchaseInvoice.data !== undefined &&
        purchaseInvoice.data.VendorExternalId === vendorExternalId
    )
    .map<Order['vendors'][number]['purchaseInvoices'][0]>(item => {
      const draftCharges =
        item.data?.Charges?.map<OrderChargeDraft>(charge => ({
          ...(getChargeCodeDetailsIdFromResponseCharge(
            {
              charge: charge.Charge,
              code: {
                value: charge.Code,
                description: charge.Description
              }
            },
            chargeCodes
          ) ?? unknownChargeCode),
          chargeAmount: Number(charge.Charge),
          tenantCode: charge.Code ?? '',
          tenantDescription: charge.Description ?? '',
          details: undefined,
          description: undefined
        })) ?? []
      const charges = getOrderChargesFromDraftOrderCharges(
        draftCharges,
        chargeCodes
      )

      return {
        invoiceUuid:
          item.data?.AssociatedVendorInvoiceUuid?.toLocaleLowerCase() ??
          undefined,
        uuid: item.uuid,
        status: item.data?.Status ?? '',
        charges,
        rawCharges: (item.data?.Charges ?? []).map(charge => ({
          chargeAmount: charge.Charge,
          code: charge.Code,
          description: charge.Description
        }))
      }
    })
}

function tryParsePurchaseInvoiceFromString(
  value: string
): PurchaseInvoiceObject | undefined {
  try {
    const result: PurchaseInvoiceObject = JSON.parse(value)
    return result
  } catch (error) {
    return undefined
  }
}
