import { createActionGroup, props } from '@ngrx/store'
import { RelatedDocument } from '../../../domain/invoices/related-document.model'

export const invoiceRelatedDocumentsActions = createActionGroup({
  source: 'Invoice Related Documents',
  events: {
    'load invoice related documents': props<{
      invoiceUuid: string
      invoiceId: number
    }>(),
    'load invoice related documents success': props<{
      relatedDocuments: RelatedDocument[]
      invoiceId: number
    }>(),
    'load invoice related documents fail': props<{ error: unknown }>()
  }
})
