import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { UpdateInvoiceNotesRequest } from '../../domain/invoices/update-invoice-notes.request'

export class ToUpdateInvoiceNotesRequest
  implements
    IStrategy<Pick<InvoiceForm, 'notes' | 'id'>, UpdateInvoiceNotesRequest>
{
  convert(
    request: Pick<InvoiceForm, 'notes' | 'id'>
  ): UpdateInvoiceNotesRequest {
    return {
      vendorInvoiceNoteId: Number(request.notes.id),
      vendorInvoiceId: Number(request.id),
      isCompleted: request.notes.isCompleted,
      message: request.notes.message,
      subject: request.notes.subject,
      visibility: this.updateVisibilityValue(request.notes.visibility),
      isDisputed: request.notes.dispute.isDisputed,
      reason:
        request.notes.dispute.isDisputed === true
          ? request.notes.dispute.reasonId?.toString()
          : undefined,
      vendorInvoiceNoteDisputeReasonId:
        request.notes.dispute.isDisputed === true
          ? request.notes.dispute.reasonId?.toString()
          : undefined
    }
  }

  private updateVisibilityValue(
    visibilityObject: InvoiceForm['notes']['visibility']
  ): string {
    const selectedValues = []
    if (visibilityObject.internal) selectedValues.push('Internal')
    if (visibilityObject.tenant) selectedValues.push('Tenant')
    if (visibilityObject.customer) selectedValues.push('Customer')
    if (visibilityObject.vendor) selectedValues.push('Vendor')
    const concatenatedString = selectedValues.join(',')
    return concatenatedString
  }
}
