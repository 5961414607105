import { LoadingState, getDefaultLoadingState } from '@navix/shared/loading'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Action, createReducer } from '@ngrx/store'
import { Audit } from '../../domain/audits/audit.model'
import {
  AuditAsyncOperations,
  AuditsLoadingState
} from '../../domain/audits/audits-loading.model'
import { loadAuditReducers } from './load-audit/load-audit.reducer'
import { addAuditNoteReducers } from './add-audit-note/add-audit-note.reducer'
import { updateAuditReducers } from './update-audit/update-audit.reducer'

export const auditsAdapter: EntityAdapter<Audit> = createEntityAdapter<Audit>()

export const AUDITS_FEATURE_KEY = 'feature-audits'

export interface AuditsState extends EntityState<Audit> {
  selectedId?: string
  loading: AuditsLoadingState
}

export const initialAuditsState: AuditsState = auditsAdapter.getInitialState({
  loading: getDefaultLoadingState(AuditAsyncOperations),
  originalDocument: undefined
})

const reducer = createReducer(
  initialAuditsState,
  ...loadAuditReducers,
  ...addAuditNoteReducers,
  ...updateAuditReducers
)

export function auditsReducer(state: AuditsState, action: Action) {
  return reducer(state, action)
}
