import { IStrategy } from '@navix/utils/adapter'
import { GetOrderStopsResponse } from '../../domain/orders/get-order-stops.response'
import {
  Order,
  OrderStop,
  OrderStopTypes
} from '../../domain/orders/order.model'

export class FromGetOrderStopsResponse
  implements IStrategy<GetOrderStopsResponse, Order>
{
  convert(response: GetOrderStopsResponse): Order {
    return <Order>{
      stops: {
        hasMultipleStops: this.validateMultipleOriginAndDestinationStops(
          response.value
        ),
        firstOrigin: this.getFirstStopFromResponse(
          response.value,
          OrderStopTypes.Origin
        ),
        origins: this.getStopsFromResponse(
          response.value,
          OrderStopTypes.Origin
        ),
        firstDestination: this.getFirstStopFromResponse(
          response.value,
          OrderStopTypes.Destination
        ),
        destinations: this.getStopsFromResponse(
          response.value,
          OrderStopTypes.Destination
        ),
        billTo: this.getFirstStopFromResponse(
          response.value,
          OrderStopTypes.BillTo
        ),
        remitTo: this.getFirstStopFromResponse(
          response.value,
          OrderStopTypes.RemitTo
        )
      },
      stops2: response.value.map(stop => ({
        typeDescription: stop.OrderStopType as OrderStopTypes,
        sequence: stop.StopSequence,
        name: stop.AddressName ?? '',
        address1: stop.Address1,
        address2: stop.Address2 ?? '',
        city: stop.City,
        stateCode: stop.State,
        zip: stop.Zip,
        countryCode: stop.Country
      }))
    }
  }

  getStopsFromResponse(
    stops: GetOrderStopsResponse['value'],
    type: OrderStopTypes
  ) {
    const initialTypeStops = stops
      .filter(stop => stop.OrderStopType === type)
      .sort((a, b) => a.StopSequence - b.StopSequence)
      .map(x => this.convertToStop(x, type))

    return initialTypeStops
  }

  getFirstStopFromResponse(
    stops: GetOrderStopsResponse['value'],
    type: OrderStopTypes
  ) {
    return this.getStopsFromResponse(stops, type)[0]
  }

  convertToStop(
    responseStop: GetOrderStopsResponse['value'][number],
    type: OrderStopTypes
  ) {
    return <OrderStop>{
      typeDescription: type,
      name: responseStop.AddressName ?? '',
      address1: responseStop.Address1,
      address2: responseStop.Address2 ?? '',
      city: responseStop.City,
      stateCode: responseStop.State,
      zip: responseStop.Zip,
      countryCode: responseStop.Country
    }
  }

  validateMultipleOriginAndDestinationStops(
    stops: GetOrderStopsResponse['value']
  ) {
    return (
      stops.filter(stop => stop.OrderStopType === OrderStopTypes.Origin)
        .length > 1 ||
      stops.filter(stop => stop.OrderStopType === OrderStopTypes.Destination)
        .length > 1
    )
  }
}
