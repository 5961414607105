import { KnownInvoicesSortBy } from '../../+state/invoices/static'

export type FilterOption = {
  id: number | string
  label: string
}

export type DataFilters = {
  search?: string
  itemsPerPage: number
  page: number
  status: StatusFilter
  sortBy: KnownInvoicesSortBy
  sortDirection: 'asc' | 'desc'
  customers: FilterOption[]
  vendors: FilterOption[]
  users: FilterOption[]
  typesOfExceptions: FilterOption[]
  disputedReasons: FilterOption[]
  disputeReasonsV2: FilterOption[]
  parties: FilterOption[]
  statuses: FilterOption[]
  sources: FilterOption[]
  divisions: FilterOption[]
  trueFlags: string[]
  falseFlags: string[]
  useUnownedUsers: boolean
  hasNoExceptions: boolean
  invoiceStatus:
    | 'matched'
    | 'unmatched'
    | 'all'
    | 'disputeList'
    | 'ownedDisputeList'
    | undefined
  parenteInvoiceId?: number
  returnVendorNetCharge?: boolean
  from: string | undefined
  to: string | undefined
  dateRangeTypes: FilterOption[]
  remitTo: string | undefined
}

export enum StatusFilter {
  audit,
  auditMatched,
  duplicates,
  disputes,
  approved,
  all,
  unmatched,
  terminated,
  disputesMatched,
  disputesUnmatched,
  readyToApprove,
  disputeList,
  ownedDisputeList
}
