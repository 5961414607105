import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  catchError,
  debounceTime,
  exhaustMap,
  map,
  of,
  switchMap,
  tap
} from 'rxjs'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { InvoicesAdapter } from '../../../adapter/invoices/InvoicesAdapter'
import { FromGetLineItemsResponse } from '../../../adapter/invoices/FromGetLineItemsResponse'
import { ToAddInvoiceLineItemsRequest } from '../../../adapter/invoices/ToAddInvoiceLineItemsRequest'
import { InvoicesFacade } from '../invoices.facade'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { Dialog } from '@angular/cdk/dialog'
import {
  ADD_INVOICE_LINE_ITEMS_DIALOG,
  DELETE_INVOICE_LINE_ITEMS_DIALOG,
  EDIT_INVOICE_LINE_ITEMS_DIALOG
} from '../invoices.constants'
import { ToUpdateInvoiceLineItemsRequest } from '../../../adapter/invoices/ToUpdateInvoiceeLineItemsRequest'
import { invoiceLineItemsActions } from './invoice-line-items.actions'
import { toManageLineItemsRequest } from '../../../adapter/invoices/ToManageLineItemsRequest'

@Injectable()
export class InvoiceLineItemsEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)
  private invoicesFacade = inject(InvoicesFacade)
  private dialog = inject(Dialog)

  private invoiceAdapter = new InvoicesAdapter()

  constructor() {
    this.invoiceAdapter.set(FromGetLineItemsResponse)
    this.invoiceAdapter.set(ToAddInvoiceLineItemsRequest)
    this.invoiceAdapter.set(ToUpdateInvoiceLineItemsRequest)
  }

  loadInvoiceLineItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceLineItemsActions.loadInvoiceLineItems),
      tap(() => this.invoicesFacade.startLoading(AsyncOperations.getLineItems)),
      switchMap(({ invoiceId }) =>
        this.invoicesService.getLineItems(invoiceId).pipe(
          map(response =>
            this.invoiceAdapter.convert(FromGetLineItemsResponse, response)
          ),
          map(lineItems =>
            invoiceLineItemsActions.loadInvoiceLineItemsSuccess({
              lineItems,
              invoiceId
            })
          ),
          tap(() =>
            this.invoicesFacade.endLoading(AsyncOperations.getLineItems)
          ),
          catchError(error =>
            of(invoiceLineItemsActions.loadInvoiceLineItemsFail({ error }))
          )
        )
      )
    )
  )

  loadInvoiceLineItemsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceLineItemsActions.loadInvoiceLineItemsFail),
        tap(error => console.error(error)),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load invoice line items."
          })
        })
      ),
    { dispatch: false }
  )

  addInvoiceLineItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceLineItemsActions.addInvoiceLineItems),
      tap(() => this.invoicesFacade.startLoading(AsyncOperations.addLineItems)),
      map(({ invoice }) => ({
        request: this.invoiceAdapter.convert(
          ToAddInvoiceLineItemsRequest,
          invoice
        ),
        invoice
      })),
      exhaustMap(({ request, invoice }) =>
        this.invoicesService.addLineItems(request).pipe(
          map(() =>
            invoiceLineItemsActions.addInvoiceLineItemsSuccess({ invoice })
          ),
          catchError(error =>
            of(
              invoiceLineItemsActions.addInvoiceLineItemsFail({
                error
              })
            )
          ),
          tap(() =>
            this.invoicesFacade.endLoading(AsyncOperations.addLineItems)
          )
        )
      )
    )
  )

  addInvoiceLineItemsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceLineItemsActions.addInvoiceLineItemsSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Line item was successfully created.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.dialog.getDialogById(ADD_INVOICE_LINE_ITEMS_DIALOG)?.close()
      ),
      switchMap(({ invoice }) =>
        of(
          invoiceLineItemsActions.loadInvoiceLineItems({
            invoiceId: invoice.id ?? 0
          })
        )
      )
    )
  )

  addInvoiceLineItemsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceLineItemsActions.addInvoiceLineItemsFail),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''

          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : "There's an error trying to create invoice line items."
          })
        })
      ),
    { dispatch: false }
  )

  updateInvoiceLineItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceLineItemsActions.updateInvoiceLineItems),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.updateLineItems)
      ),
      map(({ invoice }) => ({
        request: this.invoiceAdapter.convert(
          ToUpdateInvoiceLineItemsRequest,
          invoice
        ),
        invoice
      })),
      exhaustMap(({ request, invoice }) =>
        this.invoicesService.updateLineItems(request).pipe(
          map(() =>
            invoiceLineItemsActions.updateInvoiceLineItemsSuccess({ invoice })
          ),
          catchError(error =>
            of(
              invoiceLineItemsActions.updateInvoiceLineItemsFail({
                error
              })
            )
          ),
          tap(() =>
            this.invoicesFacade.endLoading(AsyncOperations.updateLineItems)
          )
        )
      )
    )
  )

  updateInvoiceLineItemsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceLineItemsActions.updateInvoiceLineItemsSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Line item was successfully updated.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.dialog.getDialogById(EDIT_INVOICE_LINE_ITEMS_DIALOG)?.close()
      ),
      switchMap(({ invoice }) =>
        of(
          invoiceLineItemsActions.loadInvoiceLineItems({
            invoiceId: invoice.id ?? 0
          })
        )
      )
    )
  )

  updateInvoiceLineItemsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceLineItemsActions.updateInvoiceLineItemsFail),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : "There's an error trying to update invoice line items."
          })
        })
      ),
    { dispatch: false }
  )

  deleteInvoiceLineItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceLineItemsActions.deleteInvoiceLineItems),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.deleteLineItems)
      ),
      exhaustMap(({ lineItemId, invoiceId }) =>
        this.invoicesService.deleteLineItems(invoiceId, lineItemId).pipe(
          map(() =>
            invoiceLineItemsActions.deleteInvoiceLineItemsSuccess({
              invoiceId,
              lineItemId
            })
          ),
          catchError(error =>
            of(invoiceLineItemsActions.deleteInvoiceLineItemsFail({ error }))
          ),
          tap(() =>
            this.invoicesFacade.endLoading(AsyncOperations.deleteLineItems)
          )
        )
      )
    )
  )

  deleteInvoiceLineItemsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceLineItemsActions.deleteInvoiceLineItemsSuccess),
        tap(() => {
          this.alertsFacade.addAlert({
            label: `Line item was successfully deleted.`,
            alertType: 'success'
          })
        }),
        tap(() =>
          this.dialog.getDialogById(DELETE_INVOICE_LINE_ITEMS_DIALOG)?.close()
        )
        // switchMap(({ invoiceId }) =>
        //   of(
        //     invoiceLineItemsActions.loadInvoiceLineItems({
        //       invoiceId
        //     })
        //   )
        // )
      ),
    {
      dispatch: false
    }
  )

  deleteInvoiceLineItemsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceLineItemsActions.deleteInvoiceLineItemsFail),
        tap(error => console.error(error)),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''

          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : `There's an error trying to delete invoice line items.`
          })
        })
      ),
    { dispatch: false }
  )

  manageLiteItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceLineItemsActions.manageLineItems),
      map(({ invoiceId, lineItems }) => ({
        invoiceId,
        lineItems: toManageLineItemsRequest(lineItems)
      })),
      exhaustMap(({ invoiceId, lineItems }) =>
        this.invoicesService.manageLineItems(invoiceId, lineItems).pipe(
          map(() =>
            invoiceLineItemsActions.manageLineItemsSuccess({
              invoiceId
            })
          ),
          catchError(httpError =>
            of(
              invoiceLineItemsActions.manageLineItemsFail({
                error: httpError.error
              })
            )
          )
        )
      )
    )
  )

  resetManageLineItemLoadingState = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceLineItemsActions.manageLineItemsSuccess,
        invoiceLineItemsActions.manageLineItemsFail
      ),
      debounceTime(100),
      map(() => invoiceLineItemsActions.resetManageLineItems())
    )
  )
}
