import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  AUDITS_FEATURE_KEY,
  AuditsState,
  auditsAdapter
} from './audits.reducer'

export const selectAuditsState =
  createFeatureSelector<AuditsState>(AUDITS_FEATURE_KEY)

const { selectEntities } = auditsAdapter.getSelectors()

export const selectLoading = createSelector(
  selectAuditsState,
  (state: AuditsState) => state.loading
)

export const selectOrdersEntities = createSelector(
  selectAuditsState,
  (state: AuditsState) => selectEntities(state)
)

export const selectSelectedId = createSelector(
  selectAuditsState,
  (state: AuditsState) => state.selectedId
)

export const selectEntity = createSelector(
  selectOrdersEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
)
