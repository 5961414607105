import { Injectable, inject } from '@angular/core'
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms'
import { KnownChageTypes } from '../+state/invoices/static'
import { DuplicateActions } from '../domain/invoices/duplicate-action.model'
import {
  InvoiceForm,
  InvoiceFormControls,
  LineItemFormControls,
  StopFormControls
} from '../domain/invoices/invoice-form.model'
import {
  InvoiceStop,
  InvoiceStopTypes,
  LineItem
} from '../domain/invoices/invoice.model'

@Injectable({
  providedIn: 'root'
})
export class InvoiceFormService {
  private readonly fb = inject(NonNullableFormBuilder)

  getStopsForm(): InvoiceFormControls['stops'] {
    return this.fb.group({
      origins: this.fb.array<FormGroup>([]),
      destinations: this.fb.array<FormGroup>([]),
      billTo: this.getStopForm(InvoiceStopTypes.BillTo),
      remitTo: this.fb.array<FormGroup>([])
    })
  }
  getStopForm(
    stopType: InvoiceStopTypes,
    stop?: InvoiceStop
  ): FormGroup<StopFormControls> {
    return this.fb.group({
      typeDescription: this.fb.control<InvoiceStopTypes>(
        stop?.typeDescription ?? stopType
      ),
      name: this.fb.control<string>(stop?.name ?? '', Validators.required),
      address1: this.fb.control<string>(
        stop?.address1 ?? '',
        Validators.required
      ),
      address2: this.fb.control<string | undefined>(
        stop?.address2 ?? undefined
      ),
      city: this.fb.control<string>(stop?.city ?? '', Validators.required),
      countryId: this.fb.control<number | undefined>(
        stop?.countryId ?? (stop !== undefined ? undefined : 1),
        Validators.required
      ),
      countryCode: this.fb.control<string | undefined>(stop?.countryCode),
      zip: this.fb.control<string>(stop?.zip ?? '', [
        Validators.required,
        Validators.minLength(5)
      ]),
      uuid: this.fb.control<string | undefined>(stop?.uuid),
      stateId: this.fb.control<number | undefined>(
        stop?.stateId,
        Validators.required
      ),
      stateCode: this.fb.control<string | undefined>(stop?.stateCode),
      id: this.fb.control<number | undefined>(stop?.id)
    })
  }

  getLineItemsForm(): InvoiceFormControls['lineItems'] {
    return this.fb.group({
      classCode: this.fb.control<number | undefined>(undefined),
      description: this.fb.control<string>('', Validators.required),
      messureUnitTypes: this.fb.control<string>('IN'),
      handlingUnitTypeId: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      ),
      handlingUnitCount: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      ),
      height: this.fb.control<number | undefined>(undefined),
      length: this.fb.control<number | undefined>(undefined),
      nmfcCode: this.fb.control<string | undefined>(undefined),
      isStack: this.fb.control<boolean | undefined>(undefined),
      totalPieces: this.fb.control<number | undefined>(undefined),
      id: this.fb.control<number | undefined>(undefined),
      weight: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      ),
      weightUnit: this.fb.control<string>('LBS'),
      width: this.fb.control<number | undefined>(undefined)
    })
  }

  getChargesForm(): InvoiceFormControls['charges'] {
    return this.fb.array<InvoiceFormControls['charges']['controls'][number]>([])
  }

  getChargeForm(
    charge?: InvoiceForm['charges'][number]
  ): InvoiceFormControls['charges']['controls'][number] {
    return this.fb.group({
      adjustedChargeId: this.fb.control<number | string | undefined>(
        charge?.adjustedChargeId
      ),
      typeId: this.fb.control<number | undefined>(charge?.typeId),
      typeDescription: this.fb.control<string>(charge?.typeDescription ?? ''),
      parentTypeId: this.fb.control<number | undefined>(charge?.parentTypeId),
      varianceReasonId: this.fb.control<number | undefined>(
        charge?.varianceReasonId ?? undefined,
        Validators.required
      ),
      adjustedAmount: this.fb.control<number | undefined>(
        charge?.adjustedAmount ?? 0,
        Validators.required
      ),
      isLock: this.fb.control<boolean | undefined>(false),
      tenantCode: this.fb.control<string | undefined>(charge?.tenantCode),
      tenantDescription: this.fb.control<string | undefined>(
        charge?.tenantDescription
      ),
      kind: this.fb.control<'adjusted' | 'order' | 'audit'>(
        charge?.kind ?? 'adjusted'
      ),
      originalCode: this.fb.control<string | undefined>(charge?.originalCode),
      originalDescription: this.fb.control<string | undefined>(
        charge?.originalDescription
      ),
      originalAdjustment: this.fb.control<number | undefined>(
        charge?.originalAdjustment
      )
    })
  }

  getChargeToMapForm(
    charge?: InvoiceForm['chargesToMap'][number]
  ): InvoiceFormControls['chargesToMap']['controls'][number] {
    return this.fb.group({
      chargeId: this.fb.control<number | string | undefined>(
        charge?.chargeId,
        Validators.required
      ),
      typeId: this.fb.control<number | undefined>(
        charge?.typeId,
        Validators.required
      ),
      tenantCode: this.fb.control<string | undefined>(
        charge?.tenantCode,
        Validators.required
      ),
      tenantDescription: this.fb.control<string | undefined>(
        charge?.tenantDescription
      ),
      amount: this.fb.control<number | undefined>(charge?.amount),
      code: this.fb.control<string | undefined>(charge?.code),
      description: this.fb.control<string | undefined>(charge?.description),
      isDefault: this.fb.control<boolean>(false),
      tenantChargeCodeId: this.fb.control<string>(
        charge?.tenantChargeCodeId ?? ''
      )
    })
  }

  getNotesForm(): InvoiceFormControls['notes'] {
    return this.fb.group({
      id: this.fb.control<number | undefined>(undefined),
      subject: this.fb.control<string>('', Validators.required),
      message: this.fb.control<string>('', Validators.required),
      isCompleted: this.fb.control<boolean>(false),
      dispute: this.fb.group({
        isDisputed: this.fb.control<boolean>(true),
        reasonId: this.fb.control<number | undefined>(
          undefined,
          Validators.required
        )
      }),
      visibility: this.fb.group({
        internal: this.fb.control<boolean>(true),
        tenant: this.fb.control<boolean>(false),
        customer: this.fb.control<boolean>(false),
        vendor: this.fb.control<boolean>(false)
      })
    })
  }

  getReferenceNumbersForm(): FormGroup<
    Pick<InvoiceFormControls, 'referenceNumbers'>
  > {
    return this.fb.group({
      referenceNumbers: this.fb.array<
        InvoiceFormControls['referenceNumbers']['controls'][number]
      >([])
    })
  }

  createReferenceNumbersForm(
    data?: InvoiceForm['referenceNumbers'][number]
  ): InvoiceFormControls['referenceNumbers']['controls'][number] {
    return this.fb.group({
      typeId: this.fb.control<string | undefined>(
        data?.typeId,
        Validators.required
      ),
      tags: this.fb.control<{ id: number | undefined; value: string }[]>(
        data?.tags ?? [],
        Validators.required
      )
    })
  }

  getDocumentsForm(): FormGroup<Pick<InvoiceFormControls, 'documents'>> {
    return this.fb.group({
      documents: this.fb.array<
        InvoiceFormControls['documents']['controls'][number]
      >([])
    })
  }

  getDocumentForm(
    document?: InvoiceForm['documents'][number]
  ): InvoiceFormControls['documents']['controls'][number] {
    return this.fb.group({
      id: this.fb.control<number | undefined>(document?.id),
      typeId: this.fb.control<number | undefined>(
        document?.typeId,
        Validators.required
      ),
      hasSignature: this.fb.control<boolean | undefined>(
        document?.hasSignature
      ),
      signatureDate: this.fb.control<Date | undefined>(document?.signatureDate),
      file: this.fb.control<File | undefined>(document?.file),
      fileUrl: this.fb.control<string | undefined>(document?.fileUrl),
      fileName: this.fb.control<string | undefined>(document?.fileName)
    })
  }

  getOtherDetailsForm(): InvoiceFormControls['otherDetails'] {
    return this.fb.group({
      invoiceNumber: this.fb.control<string>(''),
      invoiceTypeId: this.fb.control<number | undefined>(undefined),
      modeId: this.fb.control<number | undefined>(undefined),
      serviceLevelId: this.fb.control<number | undefined>(undefined),
      serviceTypeId: this.fb.control<number | undefined>(undefined)
    })
  }

  getEmailNotificationForm(): InvoiceFormControls['emailNotification'] {
    return this.fb.group({
      templateId: this.fb.control<number | undefined>(undefined),
      to: this.fb.control<string[]>([], Validators.required),
      cc: this.fb.control<string[]>([]),
      subject: this.fb.control<string>('', Validators.required),
      body: this.fb.control<string>('', [
        Validators.required,
        Validators.maxLength(2000)
      ]),
      attachedDocumentIds: this.fb.control<number[]>([]),
      from: this.fb.control<string>('', Validators.required)
    })
  }

  getTerminateInvoiceForm(): InvoiceFormControls['terminateInvoice'] {
    return this.fb.group({
      reasonId: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      ),
      comment: this.fb.control<string>('')
    })
  }

  getDuplicatesForm(): FormGroup<Pick<InvoiceFormControls, 'duplicates'>> {
    return this.fb.group({
      duplicates: this.fb.array<
        InvoiceFormControls['duplicates']['controls'][number]
      >([])
    })
  }

  getDuplicateForm(
    duplicate: InvoiceForm['duplicates'][number]
  ): InvoiceFormControls['duplicates']['controls'][number] {
    return this.fb.group({
      actionId: this.fb.control<DuplicateActions | undefined>(
        duplicate.actionId,
        Validators.required
      ),
      billTypeId: this.fb.control<number | undefined>(
        duplicate.billTypeId,
        Validators.required
      ),
      invoiceId: this.fb.control<number>(duplicate.invoiceId),
      invoiceUuid: this.fb.control<string>(duplicate.invoiceUuid)
    })
  }

  getShipmentDetailsForm(): InvoiceFormControls['details'] {
    return this.fb.group({
      tenantUuid: this.fb.control<string | undefined>(undefined),
      tenantId: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      ),
      customerId: this.fb.control<number | string | undefined>(
        undefined,
        Validators.required
      ),
      vendorId: this.fb.control<number | string | undefined>(
        undefined,
        Validators.required
      ),
      invoiceNumber: this.fb.control<string>('', Validators.required),
      shipmentNumber: this.fb.control<string>(''),
      orderNumber: this.fb.control<string | undefined>(undefined),
      shipDate: this.fb.control<string | Date>('', Validators.required),
      deliveryDate: this.fb.control<string | Date | undefined>(undefined),
      invoiceDate: this.fb.control<string | Date>('', Validators.required),
      freightTermsDescription: this.fb.control<string | undefined>(
        undefined,
        Validators.required
      ),
      bolNumber: this.fb.control<string | undefined>(undefined),
      poNumber: this.fb.control<string | undefined>(undefined)
    })
  }

  getDefaultChargesForm(): InvoiceFormControls['defaultCharges'] {
    return this.fb.group({
      baseRate: this.getDefaultChargeForm(KnownChageTypes.BaseRate, true),
      accessorial: this.getDefaultChargeForm(KnownChageTypes.Accesorials),
      unknownAccessorial: this.getDefaultChargeForm(
        KnownChageTypes.UnknownAccesorials
      ),
      currencyId:
        this.fb.control<InvoiceForm['defaultCharges']['currencyId']>(1),
      discount: this.getDefaultChargeForm(KnownChageTypes.Discount),
      duties: this.getDefaultChargeForm(KnownChageTypes.Duties),
      fuelSurcharge: this.getDefaultChargeForm(KnownChageTypes.Fsc),
      accessorialsDetail: this.fb.control<
        InvoiceForm['defaultCharges']['accessorialsDetail']
      >([]),
      taxes: this.getDefaultChargeForm(KnownChageTypes.Taxes)
    })
  }

  getDefaultChargeForm<
    T extends keyof Omit<
      InvoiceForm['defaultCharges'],
      'currencyId' | 'accessorialsDetail'
    >
  >(
    typeId: KnownChageTypes,
    amountRequired?: boolean
  ): InvoiceFormControls['defaultCharges']['controls'][T] {
    return this.fb.group({
      amount: this.fb.control<InvoiceForm['defaultCharges'][T]['amount']>(
        undefined,
        amountRequired ? Validators.required : null
      ),
      tenantCode:
        this.fb.control<InvoiceForm['defaultCharges'][T]['tenantCode']>(
          undefined
        ),
      tenantDescription:
        this.fb.control<InvoiceForm['defaultCharges'][T]['tenantDescription']>(
          undefined
        ),
      typeId: this.fb.control<InvoiceForm['defaultCharges'][T]['typeId']>(
        typeId,
        Validators.required
      )
    })
  }

  getDisputesForm(): InvoiceFormControls['disputes'] {
    return this.fb.group({
      id: this.fb.control<number | undefined>(undefined),
      party: this.fb.control<string>('Tenant', Validators.required),
      subject: this.fb.control<string>('', Validators.required),
      reasons: this.fb.control<string[]>([], Validators.required),
      attachedDocumentIds: this.fb.control<number[]>([]),
      to: this.fb.control<string[]>([], Validators.required),
      cc: this.fb.control<string[]>([]),
      message: this.fb.control<string>('', [
        Validators.required,
        Validators.maxLength(2000)
      ]),
      templateId: this.fb.control<number | undefined>(undefined),
      attachmentUrls: this.fb.control<string[] | undefined>(undefined),
      attachmentFiles: this.fb.array<
        InvoiceFormControls['disputes']['controls']['attachmentFiles']['controls'][number]
      >([])
    } as InvoiceFormControls['disputes']['controls'])
  }

  getDisputeAttachmentForm(
    attachmentFile: InvoiceForm['disputes']['attachmentFiles'][number]
  ): InvoiceFormControls['disputes']['controls']['attachmentFiles']['controls'][number] {
    return this.fb.group({
      file: this.fb.control<File | undefined>(attachmentFile?.file)
    })
  }

  getCommunicationForm(): InvoiceFormControls['communication'] {
    return this.fb.group({
      to: this.fb.control<string[]>([], Validators.required),
      cc: this.fb.control<string[]>([]),
      templateId: this.fb.control<string | undefined>(undefined),
      subject: this.fb.control<string>('', Validators.required),
      message: this.fb.control<string>('', [Validators.required]),
      attachmentUrls: this.fb.control<string[] | undefined>(undefined),
      attachmentFiles: this.fb.array<
        InvoiceFormControls['disputes']['controls']['attachmentFiles']['controls'][number]
      >([])
    })
  }

  getManageLineItemsForm(): InvoiceFormControls['manageLineItems'] {
    return this.fb.group({
      lineItems: this.fb.array<FormGroup>([])
    })
  }

  getManageLineItemForm(
    lineItem?: LineItem,
    isAssociatedOrderLineItems?: boolean
  ): FormGroup<LineItemFormControls> {
    return this.fb.group({
      id: this.fb.control<number | undefined>(lineItem?.id),
      orderLineItemId: this.fb.control<string | undefined>(
        lineItem?.orderLineItemId ??
          (isAssociatedOrderLineItems ? 'new' : undefined),
        Validators.required
      ),
      classCode: this.fb.control<number | undefined>(
        lineItem?.class ?? undefined
      ),
      description: this.fb.control<string>(
        lineItem?.description ?? '',
        Validators.required
      ),
      messureUnitTypes: this.fb.control<string>(
        lineItem?.uomDescription ?? 'IN'
      ),
      handlingUnitTypeId: this.fb.control<number | undefined>(
        lineItem?.handlingUnitTypeId,
        Validators.required
      ),
      handlingUnitCount: this.fb.control<number | undefined>(
        lineItem?.handlingUnitCount,
        Validators.required
      ),
      height: this.fb.control<number | undefined>(lineItem?.height),
      length: this.fb.control<number | undefined>(lineItem?.length),
      nmfcCode: this.fb.control<string | undefined>(
        lineItem?.nMFCCode ?? undefined
      ),
      totalPieces: this.fb.control<number | undefined>(lineItem?.totalPieces),
      weight: this.fb.control<number | undefined>(
        lineItem?.weight ?? undefined,
        Validators.required
      ),
      weightUnit: this.fb.control<string>(lineItem?.weightUnit ?? 'LBS'),
      width: this.fb.control<number | undefined>(lineItem?.width),
      isStack: this.fb.control<boolean | undefined>(undefined)
    })
  }
}
