import { IStrategy } from '@navix/utils/adapter'
import { Invoice } from '../../domain/invoices//invoice.model'
import { GetInvoiceDocumentsResponse } from '../../domain/invoices/get-invoice-documents.response'

export class FromGetInvoiceDocumentsResponse
  implements IStrategy<GetInvoiceDocumentsResponse, Invoice['documents']>
{
  convert(response: GetInvoiceDocumentsResponse): Invoice['documents'] {
    if (!Array.isArray(response.value)) return []

    return response.value.map<Invoice['documents'][number]>(document => ({
      id: document.VendorInvoiceDocumentId,
      uuid: document.Uuid,
      name: document.DocumentName,
      submittedName: document.DocumentSubmittedName ?? undefined,
      typeId: document.VendorInvoiceDocumentTypeId,
      typeDescription: document.DocumentTypeDescription,
      createdDate: document.CreatedDate,
      dateLastModified: document.DateLastModified,
      userNameLastModified: document.UserNameLastModified,
      url: document.DocumentUrl,
      hasSignature: document.HasSignature,
      signatureDate: parseDateForDocumentSignatureDate(document.SignatureDate),
      isRedacted: document.IsRedacted,
      redactedDocumentUuid: document.RedactedDocumentUuid ?? undefined
    }))
  }
}

function parseDateForDocumentSignatureDate(
  stringDate: string | undefined | null
): Date | undefined {
  if (stringDate === undefined || stringDate === null) return undefined

  const date = new Date(stringDate)

  if (isNaN(date.getTime())) {
    return undefined
  }

  return date
}
