import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { AddInvoiceRequest } from '../../domain/invoices/add-invoice.request'

export class ToAddInvoiceRequest
  implements IStrategy<Pick<InvoiceForm, 'details'>, AddInvoiceRequest>
{
  convert(request: Pick<InvoiceForm, 'details'>): AddInvoiceRequest {
    return {
      tenantId: Number(request.details.tenantId),
      customerId: Number(request.details.customerId),
      vendorId: Number(request.details.vendorId),
      invoiceNumber: request.details.invoiceNumber,
      shipmentNumber: request.details.shipmentNumber,
      shipDate: (request.details.shipDate as Date).toISOString(),
      invoiceDate: (request.details.invoiceDate as Date).toISOString(),
      deliveryDate:
        (request.details.deliveryDate as Date)?.toISOString() ?? undefined,
      freightChargeTerms: request.details.freightTermsDescription as string,
      orderNumber: request.details.orderNumber ?? undefined,
      bolNumber: request.details.bolNumber ?? undefined,
      poNumber: request.details.poNumber ?? undefined,
      vendorInvoiceFormatId: 1
    }
  }
}
