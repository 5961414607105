import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, filter, firstValueFrom, map, switchMap } from 'rxjs'
import { InvoicesFacade } from '../+state/invoices/invoices.facade'
import {
  DataFilters,
  StatusFilter
} from '../domain/invoices/data-filters.model'
import { ABSOLUTE_FEATURE_URL } from '@navix/utils/tokens'
import { Invoice } from '../domain/invoices/invoice.model'
import { LoadingStatuses } from '@navix/shared/loading'
import { CurrentUserFacade } from '@navix/current-user/domain'

/**
 * @use To prevent issues injecting the ABSOLUTE_FEATURE_URL provide the service into the component that will inject providers.
 */
@Injectable()
export class InvoicesNavigationService {
  private readonly router = inject(Router)
  private readonly invoicesFacade = inject(InvoicesFacade)
  private readonly currentUserFacade = inject(CurrentUserFacade)
  private readonly absoluteFeatureUrl = inject(ABSOLUTE_FEATURE_URL)

  async nextInvoice() {
    const navigationFilter: InvoiceNavigationFilter = {
      navigation: 'next',
      ignoredIds: []
    }
    await this.navigateInvoice(navigationFilter)
  }

  async nextDuplicateInvoice(ignoredIds: number[]) {
    const navigationFilter: InvoiceNavigationFilter = {
      navigation: 'next',
      ignoredIds: ignoredIds
    }
    await this.navigateInvoice(navigationFilter, 'duplicates')
  }

  async previousInvoice() {
    const navigationFilter: InvoiceNavigationFilter = {
      navigation: 'previous',
      ignoredIds: []
    }
    await this.navigateInvoice(navigationFilter)
  }

  async navigateInvoice(
    navigationFilter: InvoiceNavigationFilter,
    feature: 'details' | 'duplicates' = 'details'
  ) {
    const filters = await firstValueFrom(this.invoicesFacade.filters$)
    const currentInvoice = await firstValueFrom(
      this.invoicesFacade.selectedInvoice$
    )
    let nextInvoice = await this.getInvoice(
      navigationFilter,
      this.invoicesFacade.allInvoices$,
      currentInvoice.id
    )

    const isOrderVersion2 = this.currentUserFacade.isCurrentTenantOrderV2()
    const isComingFromDispute =
      filters.status === StatusFilter.disputesMatched ||
      filters.status === StatusFilter.disputesUnmatched

    if (nextInvoice === null)
      nextInvoice = await this.getNextInvoiceFromNewPage(
        navigationFilter,
        currentInvoice,
        filters
      )

    isComingFromDispute && isOrderVersion2
      ? this.navigateToInvoiceDisputes(nextInvoice, filters.status)
      : this.navigatoToInvoice(nextInvoice, filters.status, feature)
  }

  private async getNextInvoiceFromNewPage(
    navigationFilter: InvoiceNavigationFilter,
    currentInvoice: Invoice,
    filters: DataFilters
  ) {
    const basePage = currentInvoice.belongsToPage ?? filters.page
    const newPage = this.getNewPage(navigationFilter.navigation, basePage)

    this.invoicesFacade.patchListFilters({
      ...filters,
      page: newPage
    })
    this.invoicesFacade.upsertInvoices({
      ...filters,
      page: newPage
    })

    return await this.getInvoice(
      navigationFilter,
      this.invoicesFacade.loading$.pipe(
        map(loadingState => loadingState.getAll),
        filter(
          loadingState => loadingState.status === LoadingStatuses.Completed
        ),
        switchMap(() => this.invoicesFacade.allInvoices$)
      ),
      currentInvoice.id
    )
  }

  private getNewPage(direction: string, basePage: number) {
    switch (direction) {
      case 'next':
        return basePage + 1
      case 'previous':
        return basePage > 1 ? basePage - 1 : 1
      default:
        return 1
    }
  }

  getUnownedIndex = (
    index: number,
    indexes: number[],
    navigator: number
  ): number => {
    const isIncluded = indexes.includes(index)
    return isIncluded
      ? this.getUnownedIndex(index + navigator, indexes, navigator)
      : index
  }

  private async getInvoice(
    navigationFilter: InvoiceNavigationFilter,
    invoices: Observable<Invoice[]>,
    invoiceId: number
  ): Promise<Invoice | null> {
    const currentUserOwnerEmail = this.currentUserFacade.userProfile()?.email
    const navigator = navigationFilter.navigation === 'next' ? 1 : -1
    const rawInvoicesArray = await firstValueFrom(invoices)
    const invoiceIndex = rawInvoicesArray.findIndex(
      ({ id }) => id === invoiceId
    )
    const calculatedIndex = this.calculateIndex(
      invoiceIndex,
      navigator,
      rawInvoicesArray,
      navigationFilter.ignoredIds
    )

    const filteredInvoicesArray = rawInvoicesArray.filter(
      invoice => !navigationFilter.ignoredIds.includes(invoice.id)
    )
    const filteredCaculatedIndex = filteredInvoicesArray.findIndex(invoice => {
      return invoice.id === rawInvoicesArray[calculatedIndex]?.id
    })
    const ownedInvoicesIndexes = filteredInvoicesArray
      .map((invoice, index) =>
        invoice.ownerUserId && invoice.ownerUserEmail !== currentUserOwnerEmail
          ? index
          : -1
      )
      .filter(x => x >= 0)

    const unownedIndex = this.getUnownedIndex(
      filteredCaculatedIndex,
      ownedInvoicesIndexes,
      navigator
    )
    return filteredInvoicesArray[unownedIndex] ?? null
  }

  private calculateIndex(
    currentIndex: number,
    navigator: number,
    invoicesArray: Invoice[],
    ignoredIds: number[]
  ): number {
    const nextIndex = currentIndex + navigator
    if (ignoredIds.includes(invoicesArray[nextIndex]?.id)) {
      return this.calculateIndex(
        nextIndex,
        navigator,
        invoicesArray,
        ignoredIds
      )
    }
    return nextIndex
  }

  private navigatoToInvoice(
    nextInvoice: Invoice | null,
    state: DataFilters['status'],
    feature?: 'details' | 'duplicates'
  ) {
    const redirectionRoute =
      nextInvoice === null
        ? [this.absoluteFeatureUrl, 'list', this.convertToValidRoute(state)]
        : [this.absoluteFeatureUrl, feature, nextInvoice.id]
    this.router.navigate(redirectionRoute)
  }

  private navigateToInvoiceDisputes(
    nextInvoice: Invoice | null,
    state: DataFilters['status']
  ) {
    const redirectionRoute =
      nextInvoice === null
        ? [this.absoluteFeatureUrl, 'list', this.convertToValidRoute(state)]
        : [this.absoluteFeatureUrl, 'details', nextInvoice.id, 'disputes']
    this.router.navigate(redirectionRoute)
  }

  async navigateOrderCentricDetailIfMatched(invoice: Invoice) {
    this.invoicesFacade.loadInvoiceDetails(invoice.id)
    const url = await firstValueFrom(
      this.invoicesFacade.allInvoices$.pipe(
        map(invoices => invoices.find(i => i.id === invoice.id)),
        filter(invoice => invoice?.ordersIds !== undefined),
        map(invoice => ({
          orderIds: invoice!.ordersIds ?? [],
          isMatched: invoice!.ordersIds?.length === 1
        })),
        map(({ isMatched, orderIds }) => {
          const vendorId =
            invoice.vendorId === null || invoice.vendorId === undefined
              ? 0
              : invoice.vendorId
          const invoiceDetailsRoute = [
            this.absoluteFeatureUrl,
            'details',
            invoice.id
          ]
          const orderCentricDetailsRoute = [
            this.absoluteFeatureUrl,
            'order-centric',
            `${orderIds[0]?.id}_${orderIds[0]?.legacyId}`,
            {
              outlets: {
                audit: ['vendor', vendorId, 'invoice', invoice.id]
              }
            }
          ]
          return isMatched ? orderCentricDetailsRoute : invoiceDetailsRoute
        })
      )
    )
    return url
  }
  async navigateOrderCentricDisputesIfMatched(invoice: Invoice) {
    this.invoicesFacade.loadInvoiceDetails(invoice.id)
    const url = await firstValueFrom(
      this.invoicesFacade.allInvoices$.pipe(
        map(invoices => invoices.find(i => i.id === invoice.id)),
        filter(invoice => invoice?.ordersIds !== undefined),
        map(invoice => ({
          orderIds: invoice!.ordersIds ?? [],
          isMatched: invoice!.ordersIds?.length === 1
        })),
        map(({ isMatched, orderIds }) => {
          const vendorId =
            invoice.vendorId === null || invoice.vendorId === undefined
              ? 0
              : invoice.vendorId
          const invoiceDetailsRoute = [
            this.absoluteFeatureUrl,
            'details',
            invoice.id
          ]
          const orderCentricDisputesRoute = [
            this.absoluteFeatureUrl,
            'order-centric',
            `${orderIds[0]?.id}_${orderIds[0]?.legacyId}`,
            {
              outlets: {
                audit: ['vendor', vendorId, 'invoice', invoice.id, 'disputes']
              }
            }
          ]
          return isMatched ? orderCentricDisputesRoute : invoiceDetailsRoute
        })
      )
    )
    return url
  }

  async redirectToListWithLastStatusFilter() {
    const status = this.invoicesFacade.filters().status
    const newRoute = [
      this.absoluteFeatureUrl,
      'list',
      this.convertToValidRoute(status ?? StatusFilter.audit)
    ]
    this.router.navigate(newRoute)
  }

  convertToValidRoute(status: StatusFilter) {
    switch (status) {
      case StatusFilter.auditMatched:
        return 'audit-matched'
      case StatusFilter.disputesMatched:
        return 'disputes-matched'
      case StatusFilter.disputesUnmatched:
        return 'disputes-unmatched'
      case StatusFilter.readyToApprove:
        return 'ready-to-approve'
      case StatusFilter.disputeList:
        return 'dispute-list'
      case StatusFilter.ownedDisputeList:
        return 'owned-dispute-list'
      default:
        return StatusFilter[status]
    }
  }

  async redirectToDuplicateOrPreviousFilterList() {
    const status = await firstValueFrom(
      this.invoicesFacade.filters$.pipe(map(filters => filters.status))
    )
    if (status === StatusFilter.duplicates) {
      this.invoicesFacade.loadDuplicateInvoicesOnNavigation(
        this.invoicesFacade.parentInvoiceId()!
      )
    } else {
      this.redirectToListWithLastStatusFilter()
    }
  }

  async redirectToDuplicate() {
    const selectedInvoiceId = await firstValueFrom(
      this.invoicesFacade.selectedInvoiceId$
    )
    this.router.navigate([
      `${this.absoluteFeatureUrl}/duplicates/${selectedInvoiceId}`
    ])
  }
}
export type InvoiceNavigationFilter = {
  navigation: 'next' | 'previous'
  ignoredIds: number[]
}
