import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { UpdateInvoiceReferenceNumbersRequest } from '../../domain/invoices/update-invoice-reference-numbers.request'

export class ToUpdateInvoiceReferenceNumbersRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'referenceNumbers' | 'id' | 'dialogId'>,
      UpdateInvoiceReferenceNumbersRequest
    >
{
  convert(
    request: Pick<InvoiceForm, 'referenceNumbers' | 'id' | 'dialogId'>
  ): UpdateInvoiceReferenceNumbersRequest {
    return {
      dialogId: request.dialogId,
      invoiceId: Number(request.id),
      vendorInvoiceReferenceNumbers: request.referenceNumbers
        .flatMap(x => x.tags.map(y => ({ ...y, typeId: x.typeId })))
        .map<
          UpdateInvoiceReferenceNumbersRequest['vendorInvoiceReferenceNumbers'][number]
        >(x => ({
          vendorInvoiceId: request.id as number,
          value: x.value,
          vendorInvoiceReferenceNumberId: x.id as number,
          referenceNumberTypeId: x.typeId as string
        }))
    }
  }
}
