import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, exhaustMap, map, of, tap } from 'rxjs'
import { InvoicesService } from '../../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { ReferenceNumberType } from '../../../../domain/invoices/reference-number-type.model'
import { invoicesTypesActions } from '../types.actions'

@Injectable()
export class ReferenceNumberTypesEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)

  loadReferenceNumbersTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesTypesActions.loadReferenceNumbersTypes),
      exhaustMap(() =>
        this.invoicesService.getReferenceNumberTypes().pipe(
          map(response =>
            response.map<ReferenceNumberType>(item => ({
              id: item.referenceNumberTypeId,
              description: item.description
            }))
          ),
          map(referenceNumberTypes =>
            invoicesTypesActions.loadReferenceNumbersTypesSuccess({
              referenceNumberTypes
            })
          ),
          catchError(error =>
            of(invoicesTypesActions.loadReferenceNumbersTypesFail({ error }))
          )
        )
      )
    )
  )

  loadReferenceNumbersTypesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoicesTypesActions.loadReferenceNumbersTypesFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load reference nuber types."
          })
        })
      ),
    { dispatch: false }
  )
}
