import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { InvoicesAdapter } from '../../../adapter/invoices/InvoicesAdapter'
import { catchError, exhaustMap, map, of, tap } from 'rxjs'

import { FromGetInvoiceAuditHistoryResponse } from '../../../adapter/invoices/FromGetInvoiceAuditHistoryResponse'
import { invoiceAuditHistoryActions } from './invoice-audit-history.actions'

@Injectable()
export class VendorInvoiceAuditHistoryEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)
  private invoiceAdapter = new InvoicesAdapter()

  constructor() {
    this.invoiceAdapter.set(FromGetInvoiceAuditHistoryResponse)
  }

  loadInvoiceAuditHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceAuditHistoryActions.loadInvoiceAuditHistory),
      exhaustMap(({ invoiceId }) =>
        this.invoicesService.getVendorInvoiceAuditHistory(invoiceId).pipe(
          map(response =>
            this.invoiceAdapter.convert(
              FromGetInvoiceAuditHistoryResponse,
              response
            )
          ),
          map(auditHistory =>
            invoiceAuditHistoryActions.loadInvoiceAuditHistorySuccess({
              auditHistory,
              invoiceId
            })
          ),
          catchError(error =>
            of(
              invoiceAuditHistoryActions.loadInvoiceAuditHistoryFail({ error })
            )
          )
        )
      )
    )
  )

  loadInvoiceAuditHistoryFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceAuditHistoryActions.loadInvoiceAuditHistoryFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              "There's an error trying to load vendor invoice audit history."
          })
        })
      ),
    { dispatch: false }
  )
}
