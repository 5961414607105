import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { GetPaginatedDisputesRequest } from '../../domain/disputes/get-paginated-disputes.request'
import {
  GetPaginatedDisputeResponse,
  PaginatedDisputeItemResponse
} from '../../domain/disputes/get-paginated-disputes.response'

export const disputesActions = createActionGroup({
  source: 'Disputes',
  events: {
    // Actions for loading paginated disputes
    loadPaginatedDisputes: props<{
      request: GetPaginatedDisputesRequest
    }>(),
    loadPaginatedDisputesSuccess: props<{
      response: GetPaginatedDisputeResponse
    }>(),
    loadPaginatedDisputesFail: props<{
      error: unknown
    }>(),
    patchPaginatedDisputesRequest: props<{
      request: GetPaginatedDisputesRequest
    }>(),
    clearPaginatedDisputes: emptyProps(),
    setPaginatedDisputesLoading: props<{ loading: boolean }>(),
    setCurrentDispute: props<{
      dispute: PaginatedDisputeItemResponse | undefined
      index: number
    }>()
  }
})
