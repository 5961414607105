import { on } from '@ngrx/store'
import { invoiceLineItemsActions } from './invoice-line-items.actions'
import { InvoicesState, invoicesAdapter } from '../invoices.reducer'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'

export const invoiceLineItemsReducers = [
  on(
    invoiceLineItemsActions.loadInvoiceLineItemsSuccess,
    (state: InvoicesState, { lineItems, invoiceId }) =>
      invoicesAdapter.upsertOne(
        {
          id: invoiceId,
          lineItems
        } as Invoice,
        state
      )
  ),
  on(
    invoiceLineItemsActions.deleteInvoiceLineItemsSuccess,
    (state: InvoicesState, { invoiceId, lineItemId }) =>
      invoicesAdapter.mapOne(
        {
          id: invoiceId,
          map: invoice => ({
            ...invoice,
            lineItems: invoice.lineItems.filter(
              lineItem => lineItem.id !== lineItemId
            )
          })
        },
        state
      )
  ),
  on(invoiceLineItemsActions.manageLineItems, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.manageInvoiceLineItems,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    invoiceLineItemsActions.manageLineItemsSuccess,
    (state: InvoicesState, { invoiceId }) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.manageInvoiceLineItems,
        status: LoadingStatuses.Completed,
        message: 'Invoice line items have been updated successfully.',
        metadata: { invoiceId }
      })
    })
  ),
  on(
    invoiceLineItemsActions.manageLineItemsFail,
    (state: InvoicesState, httpError) => {
      const message = String(httpError.error.message)
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.manageInvoiceLineItems,
          status: LoadingStatuses.Failed,
          message:
            message ?? "There's an error trying to update invoice line items."
        })
      }
    }
  ),
  on(invoiceLineItemsActions.resetManageLineItems, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.manageInvoiceLineItems,
      status: LoadingStatuses.NotStarted
    })
  }))
]
