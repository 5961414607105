import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { InvoicesFacade } from '../invoices.facade'
import { InvoicesAdapter } from '../../../adapter/invoices/InvoicesAdapter'

import {
  catchError,
  debounceTime,
  exhaustMap,
  finalize,
  map,
  mergeMap,
  of,
  switchMap,
  tap
} from 'rxjs'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { invoiceDetailsActions } from './invoice-details.actions'
import { FromGetInvoiceDetailsResponse } from '../../../adapter/invoices/FromGetInvoiceDetailsResponse'
import { ToUpdateInvoiceOtherDetailsRequest } from '../../../adapter/invoices/ToUpdateInvoiceOtherDetailsRequest'
import { FromGetInvoiceTenantResponse } from '../../../adapter/invoices/FromGetInvoiceTenantResponse'
import { MS_BEFORE_RESET_LOADING_STATE } from '../invoices.constants'

@Injectable()
export class InvoiceDetailsEffects {
  private readonly actions$ = inject(Actions)
  private readonly invoicesFacade = inject(InvoicesFacade)
  private readonly invoicesService = inject(InvoicesService)
  private readonly alertsFacade = inject(LegacyAlertsFacade)

  private invoiceAdapter = new InvoicesAdapter()

  constructor() {
    this.invoiceAdapter.set(FromGetInvoiceDetailsResponse)
    this.invoiceAdapter.set(ToUpdateInvoiceOtherDetailsRequest)
    this.invoiceAdapter.set(FromGetInvoiceTenantResponse)
  }

  loadInvoiceDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDetailsActions.loadInvoiceDetails),
      mergeMap(({ invoiceId }) =>
        this.invoicesService.getInvoiceDetails(invoiceId).pipe(
          map(invoice =>
            this.invoiceAdapter.convert(FromGetInvoiceDetailsResponse, invoice)
          ),
          map(invoice =>
            invoiceDetailsActions.loadInvoiceDetailsSuccess({ invoice })
          ),
          catchError(error =>
            of(invoiceDetailsActions.loadInvoiceDetailsFail({ error }))
          )
        )
      )
    )
  )

  loadInvoiceDetailsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceDetailsActions.loadInvoiceDetailsFail),
        tap(error => console.error(error)),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load invoice details."
          })
        })
      ),
    { dispatch: false }
  )

  resetLoadInvoiceDetailsLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDetailsActions.loadInvoiceDetailsSuccess,
        invoiceDetailsActions.loadInvoiceDetailsFail
      ),
      debounceTime(100),
      map(() => invoiceDetailsActions.resetLoadInvoiceDetailsLoadingState())
    )
  )

  updateInvoiceOtherDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDetailsActions.updateInvoiceOtherDetails),
      map(({ invoice }) => ({
        request: this.invoiceAdapter.convert(
          ToUpdateInvoiceOtherDetailsRequest,
          invoice
        ),
        invoice
      })),
      exhaustMap(({ request, invoice }) =>
        this.invoicesService.updateOtherDetails(request).pipe(
          map(() =>
            invoiceDetailsActions.updateInvoiceOtherDetailsSuccess({
              invoiceId: Number(invoice.id)
            })
          ),
          catchError(error =>
            of(
              invoiceDetailsActions.updateInvoiceOtherDetailsFail({
                error
              })
            )
          )
        )
      )
    )
  )

  resetUpdateInvoiceVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDetailsActions.updateInvoiceOtherDetailsSuccess,
        invoiceDetailsActions.updateInvoiceOtherDetailsFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        invoiceDetailsActions.resetUpdateInvoiceOtherDetailsLoadingState()
      )
    )
  )

  loadTenantIdFromInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDetailsActions.loadInvoiceTenantId),
      switchMap(({ invoiceId }) =>
        this.invoicesService.getInvoiceTenantInfo(invoiceId).pipe(
          map(response => {
            return this.invoiceAdapter.convert(
              FromGetInvoiceTenantResponse,
              response
            )
          }),
          map(invoice =>
            invoiceDetailsActions.loadInvoiceTenantIdSuccess({
              invoiceId,
              tenant: {
                id: invoice.tenant?.id,
                hasAccessToInvoice: invoice.tenant?.hasAccessToInvoice
              }
            })
          ),
          catchError(error => {
            const NO_INVOICE_TENANT_ID = -1
            const action =
              error.status === 404
                ? invoiceDetailsActions.loadInvoiceTenantIdSuccess({
                    invoiceId,
                    tenant: {
                      id: NO_INVOICE_TENANT_ID,
                      hasAccessToInvoice: true
                    }
                  })
                : invoiceDetailsActions.loadInvoiceTenantIdFail({ error })

            return of(action)
          }),
          finalize(() =>
            this.invoicesFacade.endLoading(AsyncOperations.getTenantId)
          )
        )
      )
    )
  )

  addTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDetailsActions.addTags),
      map(({ invoiceId, tag }) => ({
        request: {
          tag: tag,
          invoiceId: invoiceId
        }
      })),
      exhaustMap(({ request }) =>
        this.invoicesService
          .addTags({ tag: request.tag }, request.invoiceId)
          .pipe(
            map(() =>
              invoiceDetailsActions.addTagsSuccess({
                invoiceId: Number(request.invoiceId)
              })
            ),
            catchError(error =>
              of(
                invoiceDetailsActions.addTagsFail({
                  error
                })
              )
            )
          )
      )
    )
  )

  removeTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDetailsActions.removeTags),
      map(({ invoiceId, tag }) => ({
        request: {
          tag: tag,
          invoiceId: invoiceId
        }
      })),
      exhaustMap(({ request }) =>
        this.invoicesService
          .removeTags({ tag: request.tag }, request.invoiceId)
          .pipe(
            map(() =>
              invoiceDetailsActions.removeTagsSuccess({
                invoiceId: Number(request.invoiceId)
              })
            ),
            catchError(error =>
              of(
                invoiceDetailsActions.removeTagsFail({
                  error
                })
              )
            )
          )
      )
    )
  )
}
