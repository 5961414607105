import { LoadingState } from '@navix/shared/loading'

export enum AsyncOperations {
  addInvoice = 'addInvoice',
  addInvoiceVendorAdjustedCharge = 'addInvoiceVendorAdjustedCharge',
  addInvoiceDefaultCharges = 'addInvoiceDefaultCharges',
  addInvoiceDocuments = 'addInvoiceDocuments',
  addInvoiceNotes = 'addInvoiceNotes',
  addInvoiceNotesAndSendEmail = 'addInvoiceNotesAndSendEmail',
  addLineItems = 'addLineItems',
  deleteInvoiceDocuments = 'deleteInvoiceDocuments',
  deleteInvoiceNotes = 'deleteInvoiceNotes',
  deleteLineItems = 'deleteLineItems',
  getAll = 'getAll',
  getCharges = 'getCharges',
  getDetails = 'getDetails',
  getDocuments = 'getDocuments',
  getDuplicates = 'getDuplicates',
  getExceptions = 'getExceptions',
  getInvoiceReferenceNumbers = 'getInvoiceReferenceNumbers',
  getLineItems = 'getLineItems',
  getMatchedStatus = 'getMatchedStatus',
  getStops = 'getStops',
  getDisputedReasons = 'getDisputedReasons',

  mapInvoiceCharges = 'mapInvoiceCharges',
  reAudit = 'reAudit',
  updateInvoiceDocuments = 'updateInvoiceDocuments',
  updateInvoiceCharges = 'updateInvoiceCharges',
  updateInvoiceDuplicates = 'updateInvoiceDuplicates',
  updateInvoiceNotes = 'updateInvoiceNotes',
  updateInvoiceNotesMarkAsComplete = 'updateInvoiceNotesMarkAsComplete',
  updateInvoiceOtherDetails = 'updateInvoiceOtherDetails',
  addTags = 'addTags',
  removeTags = 'removeTags',
  updateInvoiceReferenceNumbers = 'updateInvoiceReferenceNumbers',
  updateInvoiceVendorStops = 'updateInvoiceVendorStops',
  updateLineItems = 'updateLineItems',
  getRelatedDocuments = 'getRelatedDocuments',
  updateInvoiceOnwership = 'updateInvoiceOnwership',
  terminateMultipleInvoices = 'terminateMultipleInvoices',
  approveMultipleInvoices = 'approveMultipleInvoices',
  generateInvoiceDocument = 'generateInvoiceDocument',
  getTenantId = 'getTenantId',
  reAuditMultipleInvoices = 'reAuditMultipleInvoices',
  mergeInvoiceDocuments = 'mergeInvoiceDocuments',
  approveInvoice = 'approveInvoice',
  terminateInvoice = 'terminateInvoice',
  updateInvoiceVendorName = 'updateInvoiceVendorName',
  getDisputes = 'getDisputes',
  getDisputesCommunication = 'getDisputesCommunication',
  resolveDisputeReasons = 'resolveDisputeReasons',
  addInvoiceDisputes = 'addInvoiceDisputes',
  sendReplyCommunication = 'sendReplyCommunication',
  downloadAttachment = 'downloadAttachment',
  attachDocumentToInvoice = 'attachDocumentToInvoice',
  redactDocument = 'redactDocument',
  addInvoiceLegacyNotes = 'addInvoiceLegacyNotes',
  manageInvoiceLineItems = 'manageInvoiceLineItems',
  regenerateInvoice = 'regenerateInvoice',
  getWorkInstructions = 'getWorkInstructions',
  getWorkInstructionsPhase2 = 'getWorkInstructionsPhase2',
  addAttestation = 'addAttestation',
  getDuplicatesOnNavigation = 'getDuplicatesOnNavigation',
  getMultipleInvoicesDisputes = 'getMultipleInvoicesDisputes',
  addInvoiceFromOrder = 'addInvoiceFromOrder'
}

type AsyncOperationsMetadata = {
  [key in keyof typeof AsyncOperations]: unknown
} & {
  readonly [AsyncOperations.updateInvoiceVendorName]: {
    newVendorId: number
    fromModal: boolean
  }
  readonly [AsyncOperations.downloadAttachment]: {
    blob: Blob
    filename: string
  }
  readonly [AsyncOperations.terminateInvoice]: {
    invoiceUuid: string
  }
  readonly [AsyncOperations.approveMultipleInvoices]: {
    failedInvoiceApprovals: { invoiceUuid: string; reason: string }[]
  }
  readonly [AsyncOperations.regenerateInvoice]: {
    invoiceId: number
    previousInvoiceId: number
  }
  readonly [AsyncOperations.addAttestation]: {
    invoiceId: number
    invoiceUuid: string
    areAllAttested: boolean
  }
  readonly [AsyncOperations.getDuplicatesOnNavigation]: {
    shouldBeRedirectedToDuplicatesList: boolean
  }
  readonly [AsyncOperations.getMultipleInvoicesDisputes]: {
    hasDisputes: boolean
  }
}

export type InvoicesLoadingState = LoadingState<
  typeof AsyncOperations,
  AsyncOperationsMetadata
>
