import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import {
  EMPTY,
  catchError,
  exhaustMap,
  finalize,
  iif,
  map,
  of,
  switchMap,
  tap
} from 'rxjs'
import { InvoicesService } from '../../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import {
  DisputeReason,
  DisputedReason
} from '../../../../domain/invoices/disputed-reason.model'
import { invoicesTypesActions } from '../types.actions'
import { InvoicesFacade } from '../../invoices.facade'
import { AsyncOperations } from '../../../../domain/invoices/invoices-loading.model'
import { Store } from '@ngrx/store'
import { selectDisputedReasons } from '../../invoices.selectors'

@Injectable()
export class DisputedReasonsEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private store = inject(Store)
  private invoicesFacade = inject(InvoicesFacade)
  private alertsFacade = inject(LegacyAlertsFacade)

  loadDisputedReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesTypesActions.loadDisputedReasons),
      concatLatestFrom(() => this.store.select(selectDisputedReasons)),
      switchMap(([payload, disputedReasons]) =>
        iif(
          () => disputedReasons.length === 0,
          of(payload),
          EMPTY.pipe(
            finalize(() => {
              this.store.dispatch({ type: `[CACHED]${payload.type}` })
              this.invoicesFacade.endLoading(AsyncOperations.getDisputedReasons)
            })
          )
        )
      ),
      exhaustMap(() =>
        this.invoicesService.getDisputedReasons().pipe(
          map(response =>
            response.map(
              item =>
                <DisputedReason>{
                  id: item.vendorInvoiceNoteDisputeReasonId,
                  description: item.description
                }
            )
          ),
          map(disputedReasons =>
            invoicesTypesActions.loadDisputedReasonsSuccess({
              disputedReasons
            })
          ),
          catchError(error =>
            of(invoicesTypesActions.loadDisputedReasonsFail({ error }))
          ),
          finalize(() =>
            this.invoicesFacade.endLoading(AsyncOperations.getDisputedReasons)
          )
        )
      )
    )
  )

  loadDisputedReasonsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoicesTypesActions.loadDisputedReasonsFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load disputed reasons."
          })
        })
      ),
    { dispatch: false }
  )
}
