import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { on } from '@ngrx/store'
import { AuditAsyncOperations } from '../../../domain/audits/audits-loading.model'
import { AuditsState } from '../audits.reducer'
import { addAuditNoteActions } from './add-audit-note.actions'

export const addAuditNoteReducers = [
  on(addAuditNoteActions.addAuditNote, (state: AuditsState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AuditAsyncOperations.addAuditNote,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    addAuditNoteActions.addAuditNoteSuccess,
    (state: AuditsState, { orderId }): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.addAuditNote,
        status: LoadingStatuses.Completed,
        message: 'Note added successfully.',
        metadata: orderId
      })
    })
  ),
  on(
    addAuditNoteActions.addAuditNoteFail,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.addAuditNote,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to add the note.'
      })
    })
  ),
  on(
    addAuditNoteActions.resetAddAuditNoteLoadingState,
    (state: AuditsState): AuditsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AuditAsyncOperations.addAuditNote,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
