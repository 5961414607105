import { on } from '@ngrx/store'
import { InvoicesState, templatesAdapter } from '../invoices.reducer'
import { invoicesTypesActions } from './types.actions'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'

export const invoicesTypesReducers = [
  on(
    invoicesTypesActions.loadDocumentTypesSuccess,
    (state: InvoicesState, { documentTypes }): InvoicesState => ({
      ...state,
      documentTypes
    })
  ),

  on(
    invoicesTypesActions.loadEmailTemplateTypesSuccess,
    (state: InvoicesState, { emailTemplateTypes }): InvoicesState => ({
      ...state,
      emailTemplateTypes: templatesAdapter.setAll(
        emailTemplateTypes,
        state.emailTemplateTypes
      )
    })
  ),

  on(
    invoicesTypesActions.loadEmailTemplateTypeDetailsSuccess,
    (state: InvoicesState, { emailTemplateType }): InvoicesState => ({
      ...state,
      emailTemplateTypes: templatesAdapter.upsertOne(
        emailTemplateType,
        state.emailTemplateTypes
      )
    })
  ),

  on(
    invoicesTypesActions.loadReferenceNumbersTypesSuccess,
    (state: InvoicesState, { referenceNumberTypes }) => ({
      ...state,
      referenceNumberTypes
    })
  ),

  on(
    invoicesTypesActions.loadInvoiceTypesSuccess,
    (state: InvoicesState, { invoiceTypes }): InvoicesState => ({
      ...state,
      invoiceTypes
    })
  ),
  on(
    invoicesTypesActions.loadDisputedReasons,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDisputedReasons,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoicesTypesActions.loadDisputedReasonsSuccess,
    (state: InvoicesState, { disputedReasons }): InvoicesState => ({
      ...state,
      disputedReasons,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDisputedReasons,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(
    invoicesTypesActions.loadDisputedReasonsFail,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDisputedReasons,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load disputed reasons."
      })
    })
  ),

  on(
    invoicesTypesActions.loadTerminateReasonsSuccess,
    (state: InvoicesState, { terminateReasons }): InvoicesState => ({
      ...state,
      terminateReasons
    })
  ),

  on(
    invoicesTypesActions.loadTypesOfExceptionsSuccess,
    (state: InvoicesState, { typesOfExceptions }): InvoicesState => ({
      ...state,
      typesOfExceptions
    })
  ),

  on(
    invoicesTypesActions.loadEmailTemplatesSuccess,
    (state: InvoicesState, { emailTemplates }): InvoicesState => ({
      ...state,
      emailTemplates: emailTemplates
    })
  )
]
