// eslint-disable-next-line @nx/enforce-module-boundaries
import { ChargeCode } from '@navix/charge-codes/domain'
import { GetCustomerOrderChargesNewResponse } from '../../domain/orders/get-customer-order-charges-new.response'
import { GetVendorChargesNewResponse } from '../../domain/orders/get-vendor-charges-new.response'
import { Order } from '../../domain/orders/order.model'
import { OrderChargeDraft } from '../../domain/orders/order-charge.model'
import { getOrderChargesFromDraftOrderCharges } from '../../infrastructure/order-charges-mapping-functions'

export function fromGetOrderChargesComposedResponse(
  [vendorCharges, customerCharges, chargeCodes]: [
    GetVendorChargesNewResponse,
    GetCustomerOrderChargesNewResponse,
    ChargeCode[]
  ],
  orderId: Order['id']
): Order {
  const draftCustomerCharges = customerCharges.map<OrderChargeDraft>(charge => {
    const chargeCode = chargeCodes.find(
      chargeCode => chargeCode.id === charge.chargeTypeId
    )!

    return {
      chargeAmount: Number(charge.charge),
      chargeTypeId: charge.chargeTypeId,
      chargeTypeDescription: chargeCode.description,
      parentChargeTypeId: chargeCode.parentChargeTypeId,
      tenantCode: charge.code ?? '',
      tenantDescription: charge.description,
      details: undefined,
      description: undefined
    }
  })

  const draftVendorCharges = vendorCharges.map<OrderChargeDraft>(charge => {
    const chargeCode = chargeCodes.find(
      chargeCode => chargeCode.id === charge.chargeTypeId
    )!
    if (chargeCode === undefined)
      console.warn('chargeCode is undefined', charge, chargeCodes)
    return {
      chargeAmount: Number(charge.charge),
      chargeTypeId: charge.chargeTypeId,
      chargeTypeDescription: chargeCode.description,
      parentChargeTypeId: chargeCode.parentChargeTypeId,
      tenantCode: charge.code ?? '',
      tenantDescription: charge.description,
      details: undefined,
      description: undefined
    }
  })

  const customerOrderCharges = getOrderChargesFromDraftOrderCharges(
    draftCustomerCharges,
    chargeCodes
  )
  const vendorOrderCharges = getOrderChargesFromDraftOrderCharges(
    draftVendorCharges,
    chargeCodes
  )

  return <Order>{
    id: orderId,
    customerCharges: customerOrderCharges,
    vendorCharges: vendorOrderCharges
  }
}
