import { platform_v2 } from '@navix/api-models'
import { updateAuditActions } from '../../+state/audits/update-audit/update-audit.actions'

export function toUpdateAuditChargesRequest(
  payload: Parameters<typeof updateAuditActions.updateAuditCharges>[0],
  auditDocument: platform_v2.Navix_Services_Sdk_Audit
): platform_v2.Navix_Services_Sdk_Audit | false {
  const externalId = payload.vendorExternalId
  const invoiceUuid = payload.invoiceUuid
  const hasExternalId = externalId !== undefined
  const vendor = auditDocument.vendors?.[externalId]
  const invoicesWithoutInvoiceToUpdate = hasExternalId
    ? vendor?.invoices?.filter(invoice => invoice.source !== invoiceUuid) ?? []
    : auditDocument.provisionalInvoices?.filter(
        invoice => invoice.source !== invoiceUuid
      ) ?? []
  const invoice =
    externalId !== undefined
      ? vendor?.invoices?.find(invoice => invoice.source === invoiceUuid) ?? {
          source: invoiceUuid
        }
      : auditDocument.provisionalInvoices?.find(
          invoice => invoice.source === invoiceUuid
        )

  const newInvoiceWithUpdatedAdjustedCharges = {
    ...invoice,
    charges: {
      vendor: {
        ...invoice?.charges?.vendor,
        chargeAdjustments: processChargeAdjustments(payload.vendorCharges)
      },
      customer: {
        ...invoice?.charges?.customer,
        chargeAdjustments: processChargeAdjustments(payload.customerCharges)
      }
    }
  } satisfies platform_v2.Navix_Services_Sdk_AuditInvoice

  const newInvoices: platform_v2.Navix_Services_Sdk_AuditInvoice[] = [
    ...invoicesWithoutInvoiceToUpdate,
    newInvoiceWithUpdatedAdjustedCharges
  ]

  const newAudit = <platform_v2.Navix_Services_Sdk_Audit>{
    ...auditDocument,
    provisionalInvoices: hasExternalId
      ? auditDocument.provisionalInvoices
      : newInvoices,
    vendors: {
      ...auditDocument.vendors,
      ...(hasExternalId
        ? {
            [externalId]: {
              ...vendor,
              invoices: newInvoices
            }
          }
        : {})
    }
  }
  if (
    newInvoiceWithUpdatedAdjustedCharges.charges.vendor.chargeAdjustments
      .length === 0
  ) {
    console.error(
      'empty vendor charges on update audit document',
      newInvoiceWithUpdatedAdjustedCharges
    )
    console.error('Additional data - Payload:', JSON.stringify(payload))
    console.error(
      'Additional data - Current Document:',
      JSON.stringify(auditDocument)
    )

    return false
  }

  return newAudit
}

function processChargeAdjustments(
  charges:
    | Parameters<
        typeof updateAuditActions.updateAuditCharges
      >[0]['customerCharges']
    | Parameters<
        typeof updateAuditActions.updateAuditCharges
      >[0]['vendorCharges']
): platform_v2.Navix_Services_Sdk_ChargeAdjustment[] {
  return charges
    .filter(charge => {
      const hasValidAdjustment =
        charge.adjustment !== 0 || charge.originalCharge !== 0
      const hasValidReason = (charge.reason?.length ?? 0) > 0
      const hasValidCode = (charge.code?.trim().length ?? 0) > 0
      const hasValidDescription = (charge.description?.trim().length ?? 0) > 0
      return (
        (hasValidAdjustment || hasValidReason) &&
        (hasValidCode || hasValidDescription)
      )
    })
    .map(charge => ({
      type: charge.chargeTypeUuid,
      code: charge.code,
      description: charge.description,
      originalCharge: Number(charge.originalCharge ?? charge.adjustment),
      adjustment: charge.adjustment,
      reason: charge.reason === '' ? null : charge.reason
    }))
}
