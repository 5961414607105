import { LoadingState } from '@navix/shared/loading'

export enum OrdersAsyncOperations {
  getAll = 'getAll',
  getDetails = 'getDetails',
  getStops = 'getStops',
  getReferenceNumbers = 'getReferenceNumbers',
  getLineItems = 'getLineItems',
  getCharges = 'getCharges',
  getMatchedInvoices = 'getMatchedInvoices',
  updateOrdersPurchaseInvoice = 'updateOrdersPurchaseInvoice',
  loadDivisionTags = 'loadDivisionTags'
}

export type AsyncOperationsMetadata = {
  [key in keyof typeof OrdersAsyncOperations]: unknown
} & {
  readonly [OrdersAsyncOperations.updateOrdersPurchaseInvoice]: {
    shouldReloadOrderDetails: boolean
  }
}

export type OrdersLoadingState = LoadingState<
  typeof OrdersAsyncOperations,
  AsyncOperationsMetadata
>
