import { provideIcons } from '@ng-icons/core'
import {
  featherFileText,
  featherUserCheck,
  featherTruck,
  featherMapPin,
  featherCopy
} from '@ng-icons/feather-icons'
import {
  heroCurrencyDollar,
  heroDocumentMagnifyingGlass
} from '@ng-icons/heroicons/outline'

export const hardcodedTypesOfExceptionsData: Record<
  number,
  { icon: string; sortPosition: number; description: string }
> = {
  2: { icon: 'featherFileText', sortPosition: 1, description: 'Import' },
  7: {
    icon: 'heroDocumentMagnifyingGlass',
    sortPosition: 2,
    description: 'Unmatched'
  },
  5: { icon: 'featherUserCheck', sortPosition: 3, description: 'Customer' },
  4: { icon: 'featherTruck', sortPosition: 4, description: 'Vendor' },
  6: {
    icon: 'featherMapPin',
    sortPosition: 5,
    description: 'Address/Location'
  },
  3: { icon: 'featherCopy', sortPosition: 6, description: 'Duplicate' },
  1: { icon: 'heroCurrencyDollar', sortPosition: 7, description: 'Rate/Audit' }
}

export const provideTypesOfExceptionIcons = () =>
  provideIcons({
    featherFileText,
    heroDocumentMagnifyingGlass,
    featherUserCheck,
    featherTruck,
    featherMapPin,
    featherCopy,
    heroCurrencyDollar
  })
