import { IStrategy } from '@navix/utils/adapter'
import { GetLineItemsResponse } from '../../domain/invoices/get-line-items.response'
import { Invoice } from '../../domain/invoices//invoice.model'

export class FromGetLineItemsResponse
  implements IStrategy<GetLineItemsResponse, Invoice['lineItems']>
{
  convert(response: GetLineItemsResponse): Invoice['lineItems'] {
    if (!Array.isArray(response.value)) return []

    return response.value.map<Invoice['lineItems'][0]>(lineItem => ({
      id: lineItem.VendorInvoiceLineItemId,
      description: lineItem?.Description ?? '',
      totalPieces: lineItem?.TotalPieces as number,
      handlingUnitTypeId: lineItem?.HandlingUnit,
      handlingUnitDescription: lineItem?.HandlingUnitDescription,
      handlingUnitCount: lineItem?.HandlingUnitCount,
      length: lineItem?.Length,
      width: lineItem?.Width,
      height: lineItem?.Height,
      uomDescription: lineItem?.DIMUnits ?? '',
      weight: lineItem?.Weight,
      weightUnit: lineItem?.WeightUnit,
      nMFCCode: lineItem?.NMFCCode,
      class: lineItem?.Class,
      cube: lineItem?.Cube,
      density: lineItem?.Density,
      stack: lineItem?.Stack,
      orderLineItemId:
        lineItem.OrderLineItemId?.trim() === '' ||
        lineItem.OrderLineItemId === null
          ? undefined
          : lineItem.OrderLineItemId
    }))
  }
}
