import { IStrategy } from '@navix/utils/adapter'
import { AddInvoiceDisputesRequest } from '../../domain/invoices/add-invoice-disputes.request'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'

export class ToAddInvoiceDisputesRequest
  implements IStrategy<InvoiceForm['disputes'], AddInvoiceDisputesRequest>
{
  convert(request: InvoiceForm['disputes']): AddInvoiceDisputesRequest {
    return {
      party: request.party,
      subject: request.subject,
      reasons: request.reasons,
      to: request.to,
      cc: request.cc,
      text: request.message,
      attachmentUrls: request.attachmentUrls,
      attachmentFiles: request.attachmentFiles.map(file => file.file as File)
    }
  }
}
