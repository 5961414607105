import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { AddReplyCommunicationRequest } from '../../domain/invoices/add-reply-communication.request'

export class ToAddInvoiceCommunicationRequest
  implements
    IStrategy<InvoiceForm['communication'], AddReplyCommunicationRequest>
{
  convert(request: InvoiceForm['communication']): AddReplyCommunicationRequest {
    return {
      to: request.to,
      cc: request.cc,
      subject: request.subject,
      text: request.message,
      attachmentUrls: request.attachmentUrls,
      attachmentFiles: request.attachmentFiles.map(file => file.file as File)
    }
  }
}
