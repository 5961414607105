// eslint-disable-next-line @nx/enforce-module-boundaries
import { ChargeCode } from '@navix/charge-codes/domain'
import {
  fixedCharges,
  commonChargesDefaultOrder
} from '../+state/orders/static/basic-charges-default-order'
import { KnownChageTypes } from '../+state/orders/static/known-chage-types'
import {
  OrderCharge,
  OrderChargeDraft
} from '../domain/orders/order-charge.model'

export function getOrderChargesFromDraftOrderCharges(
  draftCharges: OrderChargeDraft[],
  chargeCodes: ChargeCode[]
) {
  const accessorialCharge = getAccessorialChargesFromDraftOrderCharges(
    draftCharges,
    chargeCodes
  )

  const basicCharges = getBasicOrderChargesFromDraftOrderCharges(
    draftCharges,
    chargeCodes
  )

  const unknownAccessorialCharge =
    getUnknownAccessorialOrderChargesFromDraftOrderCharges(
      draftCharges,
      chargeCodes
    )
  return [...basicCharges, accessorialCharge, unknownAccessorialCharge]
}

export function getAccessorialChargesFromDraftOrderCharges(
  draftCharges: OrderChargeDraft[],
  chargeCodes: ChargeCode[]
): OrderCharge {
  const accessorialChargeCodeIds = Array.from(
    new Set(
      draftCharges
        .filter(
          charge => !commonChargesDefaultOrder.includes(charge.chargeTypeId)
        )
        .map(charge => charge.chargeTypeId)
    )
  )

  const accessorialCharges = accessorialChargeCodeIds.map(chargeCodeId => {
    const chargeCode = chargeCodes.find(
      chargeCode => chargeCode.id === chargeCodeId
    )!
    return createOrderChargeFromDraftOrderCharge(draftCharges, chargeCode)
  })
  const accessorialChargeCode = chargeCodes.find(
    chargeCode => chargeCode.id === KnownChageTypes.Accesorials
  )!
  const accessorialCharge = {
    chargeTypeId: accessorialChargeCode.id,
    chargeTypeDescription: accessorialChargeCode.description,
    parentChargeTypeId: accessorialChargeCode.parentChargeTypeId,
    tenantCode: undefined,
    tenantDescription: undefined,
    description: accessorialChargeCode.description,
    chargeAmount: accessorialCharges.reduce(
      (acc, curr) => acc + curr.chargeAmount,
      0
    ),
    details: accessorialCharges,
    id: `local-autogenerated-id-${crypto.randomUUID()}`
  }
  return accessorialCharge
}

export function getBasicOrderChargesFromDraftOrderCharges(
  draftCharges: OrderChargeDraft[],
  chargeCodes: ChargeCode[]
) {
  return fixedCharges.map(chargeCodeId => {
    return createOrderChargeFromDraftOrderCharge(
      draftCharges,
      chargeCodes.find(chargeCode => chargeCode.id === chargeCodeId)!
    )
  })
}

export function getUnknownAccessorialOrderChargesFromDraftOrderCharges(
  draftCharges: OrderChargeDraft[],
  chargeCodes: ChargeCode[]
) {
  const unknownAccesorialCharge = chargeCodes.find(
    chargeCode => chargeCode.id === KnownChageTypes.UnknownAccesorials
  )!

  return createOrderChargeFromDraftOrderCharge(
    draftCharges,
    unknownAccesorialCharge,
    true
  )
}

export function createOrderChargeFromDraftOrderCharge(
  charges: OrderChargeDraft[],
  chargeCode: ChargeCode,
  forceDetailRows = false
): OrderCharge {
  const sameChargeCodeIdMatches = charges.filter(
    charge => charge.chargeTypeId === chargeCode.id
  )
  let charge!: OrderCharge
  if (sameChargeCodeIdMatches.length === 1 && !forceDetailRows) {
    charge = {
      ...sameChargeCodeIdMatches[0],
      id: `local-autogenerated-id-${crypto.randomUUID()}`,
      details: [],
      description: chargeCode.description
    }
  } else if (sameChargeCodeIdMatches.length > 1 || forceDetailRows) {
    const details = sameChargeCodeIdMatches.map<OrderCharge>(charge => {
      return {
        ...charge,
        id: `local-autogenerated-id-${crypto.randomUUID()}`,
        parentChargeTypeId: chargeCode.id,
        details: [],
        description: `${charge.tenantDescription} (${charge.tenantCode})`
      }
    })

    charge = {
      chargeTypeId: chargeCode.id,
      chargeTypeDescription: chargeCode.description,
      parentChargeTypeId: chargeCode.parentChargeTypeId,
      tenantCode: undefined,
      tenantDescription: undefined,
      description: chargeCode.description,
      chargeAmount: sameChargeCodeIdMatches.reduce(
        (acc, curr) => acc + curr.chargeAmount,
        0
      ),
      details: details,
      id: `local-autogenerated-id-${crypto.randomUUID()}`
    }
  } else {
    charge = {
      chargeTypeId: chargeCode.id,
      chargeTypeDescription: chargeCode.description,
      parentChargeTypeId: chargeCode.parentChargeTypeId,
      tenantCode: undefined,
      tenantDescription: undefined,
      description: chargeCode.description,
      chargeAmount: 0,
      details: [],
      id: `local-autogenerated-id-${crypto.randomUUID()}`
    }
  }
  return charge
}
