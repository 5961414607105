import { invoiceVendorDetailsActions } from '../../+state/invoices/invoice-vendor-details/invoice-vendor-details.actions'
import { UpdateInvoiceVendorDatesRequest } from '../../domain/invoices/update-invocie-vendor-dates.request'

export function toUpdateInvoiceVendorDates(
  payload: ReturnType<
    typeof invoiceVendorDetailsActions.updateInvoiceVendorDates
  >
): UpdateInvoiceVendorDatesRequest {
  const request = {
    invoiceDate: payload.invoiceDate.toISOString(),
    shipDate: payload.shipDate.toISOString(),
    vendorInvoiceId: payload.id,
    deliveryDate:
      payload.deliveryDate !== undefined
        ? payload.deliveryDate.toISOString()
        : undefined
  }
  return request
}
