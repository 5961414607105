import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'

import { UpdateInvoiceCustomerAdjustedChargeRequest } from '../../domain/invoices/update-invoice-customer-adjusted-charges.request'

export class ToUpdateCustomerAdjustedChargeRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'id'> & {
        charge: InvoiceForm['chargesToMap'][number]
      },
      UpdateInvoiceCustomerAdjustedChargeRequest | undefined
    >
{
  convert(
    request: Pick<InvoiceForm, 'id'> & {
      charge: InvoiceForm['chargesToMap'][number]
    }
  ): UpdateInvoiceCustomerAdjustedChargeRequest | undefined {
    if (isNaN(Number(request.charge.chargeId))) return undefined
    return {
      chargeTypeId: Number(request.charge.typeId),
      customerAdjustedChargeId: Number(request.charge.chargeId),
      vendorInvoiceId: Number(request.id),
      code: request.charge.tenantCode,
      codedescription: request.charge.tenantDescription
    }
  }
}
