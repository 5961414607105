import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { InvoicesService } from '../../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { catchError, exhaustMap, map, of, tap } from 'rxjs'
import { DocumentType } from '../../../../domain/invoices/document-type.model'
import { invoicesTypesActions } from '../types.actions'

@Injectable()
export class DocumentTypesEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)

  loadInvoiceDocumentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesTypesActions.loadDocumentTypes),
      exhaustMap(() =>
        this.invoicesService.getInvoiceDocumentTypes().pipe(
          map(response =>
            response.value.map(
              item =>
                <DocumentType>{
                  id: item.VendorInvoiceDocumentTypeId,
                  name: item.DocumentTypeName,
                  sortOrder: item.SortOrder
                }
            )
          ),
          map(documentTypes =>
            invoicesTypesActions.loadDocumentTypesSuccess({
              documentTypes
            })
          ),
          catchError(error =>
            of(
              invoicesTypesActions.loadDocumentTypesFail({
                error
              })
            )
          )
        )
      )
    )
  )

  loadInvoiceDocumentTypesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoicesTypesActions.loadDocumentTypesFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load invoice document types."
          })
        })
      ),
    { dispatch: false }
  )
}
