import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import {
  catchError,
  debounceTime,
  exhaustMap,
  finalize,
  map,
  of,
  tap,
  firstValueFrom
} from 'rxjs'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import {
  EDIT_VENDOR_DATES_DIALOG,
  EDIT_VENDOR_FREIGHT_CHARGE_TERM_DIALOG,
  MS_BEFORE_RESET_LOADING_STATE
} from '../invoices.constants'
import { Dialog } from '@angular/cdk/dialog'
import { InvoicesAdapter } from '../../../adapter/invoices/InvoicesAdapter'
import { ToUpdateInvoiceVendorStopsRequest } from '../../../adapter/invoices/ToUpdateInvoiceVendorStopsRequest'
import { InvoicesFacade } from '../invoices.facade'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { invoiceVendorDetailsActions } from './invoice-vendor-details.actions'
import { invoiceDetailsActions } from '../invoice-details/invoice-details.actions'
import { toUpdateInvoiceVendorDates } from '../../../adapter/invoices/toUpdateInvoiceVendorDatesRequest'

@Injectable()
export class InvoiceVendorDetailsEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private legacyAlertsFacade = inject(LegacyAlertsFacade)
  private dialog = inject(Dialog)
  private invoicesFacade = inject(InvoicesFacade)

  private readonly adapter = new InvoicesAdapter()

  constructor() {
    this.adapter.set(ToUpdateInvoiceVendorStopsRequest)
  }

  updateInvoiceVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceVendorDetailsActions.updateInvoiceVendor),
      exhaustMap(({ id, newVendor, fromModal }) =>
        this.invoicesService
          .updateVendor({
            vendorId: newVendor.id,
            vendorInvoiceId: id
          })
          .pipe(
            map(() =>
              invoiceVendorDetailsActions.updateInvoiceVendorSuccess({
                invoiceId: id,
                newVendorId: newVendor.id,
                fromModal
              })
            ),
            catchError(httpError =>
              of(
                invoiceVendorDetailsActions.updateInvoiceVendorFail({
                  error: httpError
                })
              )
            )
          )
      )
    )
  )

  resetUpdateInvoiceVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceVendorDetailsActions.updateInvoiceVendorSuccess,
        invoiceVendorDetailsActions.updateInvoiceVendorFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        invoiceVendorDetailsActions.resetUpdateInvoiceVendorLoadingState()
      )
    )
  )

  updateInvoiceVendorDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceVendorDetailsActions.updateInvoiceVendorDates),
      map(payload => toUpdateInvoiceVendorDates(payload)),
      exhaustMap(request =>
        this.invoicesService.updateInvoiceVendorDates(request).pipe(
          map(() =>
            invoiceVendorDetailsActions.updateInvoiceVendorDatesSuccess({
              invoiceId: request.vendorInvoiceId
            })
          ),
          catchError(error =>
            of(
              invoiceVendorDetailsActions.updateInvoiceVendorDatesFail({
                error
              })
            )
          ),
          finalize(async () => {
            const invoice = await firstValueFrom(
              this.invoicesFacade.selectedInvoice$
            )

            this.invoicesFacade.loadWorkInstructions(invoice.uuid, invoice.id)
            this.invoicesFacade.loadInvoiceExceptions(invoice.id)
          })
        )
      )
    )
  )

  updateInvoiceVendorDatesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceVendorDetailsActions.updateInvoiceVendorDatesSuccess),
      tap(() =>
        this.legacyAlertsFacade.addAlert({
          alertType: 'success',
          label: `Invoice vendor dates updated successfully.`
        })
      ),
      tap(() => this.dialog.getDialogById(EDIT_VENDOR_DATES_DIALOG)?.close()),
      map(({ invoiceId }) =>
        invoiceDetailsActions.loadInvoiceDetails({
          invoiceId
        })
      )
    )
  )

  updateInvoiceVendorDatesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceVendorDetailsActions.updateInvoiceVendorDatesFail),
        tap(error => console.error(error)),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''
          this.legacyAlertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : `There's an error trying to update invoice vendor dates.`
          })
        })
      ),
    { dispatch: false }
  )

  updateInvoiceVendorStops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceVendorDetailsActions.updateInvoiceVendorStops),
      map(payload =>
        this.adapter.convert(ToUpdateInvoiceVendorStopsRequest, payload)
      ),
      exhaustMap(request =>
        this.invoicesService.updateInvoiceVendorStops(request).pipe(
          map(() =>
            invoiceVendorDetailsActions.updateInvoiceVendorStopsSuccess({
              invoiceId: request.invoiceId
            })
          ),
          catchError(error =>
            of(
              invoiceVendorDetailsActions.updateInvoiceVendorStopsFail({
                error
              })
            )
          ),
          finalize(() =>
            this.invoicesFacade.endLoading(
              AsyncOperations.updateInvoiceVendorStops
            )
          )
        )
      )
    )
  )

  updateInvoiceFreightChargeTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceVendorDetailsActions.updateInvoiceFreightChargeTerms),
      exhaustMap(({ id, newFreightChageTerms }) =>
        this.invoicesService
          .updateFreightChargeTerms({
            freightChargeTerms: newFreightChageTerms.description,
            vendorInvoiceId: id
          })
          .pipe(
            map(() =>
              invoiceVendorDetailsActions.updateInvoiceFreightChargeTermsSuccess(
                { invoiceId: id }
              )
            ),
            catchError(error =>
              of(
                invoiceVendorDetailsActions.updateInvoiceFreightChargeTermsFail(
                  { error }
                )
              )
            )
          )
      )
    )
  )

  updateInvoiceFreightChargeTermsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceVendorDetailsActions.updateInvoiceFreightChargeTermsSuccess
      ),
      tap(() =>
        this.legacyAlertsFacade.addAlert({
          alertType: 'success',
          label: `Freight was successfully updated.`
        })
      ),
      tap(() =>
        this.dialog
          .getDialogById(EDIT_VENDOR_FREIGHT_CHARGE_TERM_DIALOG)
          ?.close()
      ),
      map(({ invoiceId }) =>
        invoiceDetailsActions.loadInvoiceDetails({
          invoiceId
        })
      )
    )
  )

  updateInvoiceFreightChargeTermsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceVendorDetailsActions.updateInvoiceFreightChargeTermsFail),
        tap(error => console.error(error)),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''

          this.legacyAlertsFacade.addAlert({
            alertType: 'danger',
            label:
              message.length > 0
                ? message
                : `There's an error trying to update invoice freight charge terms.`
          })
        })
      ),
    { dispatch: false }
  )
}
