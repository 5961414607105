import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Invoice } from '../../../domain/invoices/invoice.model'
import { InvoiceForm } from '../../../domain/invoices/invoice-form.model'
import { HttpErrorResponse } from '@angular/common/http'

export const invoiceDisputesActions = createActionGroup({
  source: 'Invoice Disputes',
  events: {
    loadInvoiceDisputes: props<{
      invoiceUuid: string
      invoiceId: number
    }>(),
    loadInvoiceDisputesSuccess: props<{
      invoiceWithDisputes: Invoice
    }>(),
    loadInvoiceDisputesFail: props<{
      error: unknown
    }>(),
    resetGetDisputesLoadingState: emptyProps(),
    loadInvoiceDisputesCommunication: props<{
      invoiceUuid: string
      invoiceId: number
      disputeId: string
    }>(),
    loadInvoiceDisputesCommunicationSuccess: props<{
      invoiceWithDisputesCommunication: Invoice
    }>(),
    loadInvoiceDisputesCommunicationFail: props<{
      error: unknown
    }>(),
    resetGetDisputesCommunicationLoadingState: emptyProps(),
    resolveDisputeReasons: props<{
      invoiceUuid: string
      disputeId: string
      reasons: { description: string }[]
    }>(),
    resolveDisputeReasonsSuccess: emptyProps(),
    resolveDisputeReasonsFail: props<{ error: HttpErrorResponse }>(),
    resetResolveDisputeReasonsLoadingState: emptyProps(),
    addInvoiceDisputes: props<{
      disputes: InvoiceForm['disputes']
      invoiceUuid: string
    }>(),
    addInvoiceDisputesSuccess: emptyProps(),
    addInvoiceDisputesFail: props<{ error: string }>(),
    resetAddInvoiceDisputesLoadingState: emptyProps(),
    sendReplyCommunication: props<{
      invoiceUuid: string
      disputeId: string
      communication: InvoiceForm['communication']
    }>(),
    sendReplyCommunicationSuccess: emptyProps(),
    sendReplyCommunicationFail: props<{ error: HttpErrorResponse }>(),
    resetSendReplyCommunicationLoadingState: emptyProps(),
    downloadAttachment: props<{ attachmentUrl: string; fileName: string }>(),
    downloadAttachmentSuccess: props<{
      filename: string
      blob: Blob
    }>(),
    downloadAttachmentFail: props<{ error: HttpErrorResponse }>(),
    resetDownloadAttachmentLoadingState: emptyProps(),
    loadMultipleInvoicesDisputes: props<{
      invoicesUuids: string[]
    }>(),
    loadMultipleInvoicesDisputesSuccess: props<{
      hasDisputes: boolean
    }>(),
    loadMultipleInvoicesDisputesFail: props<{
      error: unknown
    }>(),
    resetGetMultipleInvoicesDisputesLoadingState: emptyProps()
  }
})
