import { IStrategy } from '@navix/utils/adapter'
import { differenceInDays } from 'date-fns'
import { GetVendorInvoiceNotesResponse } from '../../domain/invoices/get-invoice-notes.response'
import { Invoice } from '../../domain/invoices//invoice.model'

export class FromGetVendorInvoiceNotesResponse
  implements IStrategy<GetVendorInvoiceNotesResponse, Invoice['notes']>
{
  convert(response: GetVendorInvoiceNotesResponse): Invoice['notes'] {
    const NO_BREAK_SPACE = '\u00A0'
    if (!Array.isArray(response.value)) return []

    return response.value
      .map(
        note =>
          <Invoice['notes'][number]>{
            id: note.VendorInvoiceNoteId,
            subject: note.Subject,
            dateLastModified: note.DateLastModified,
            isCompleted: note.IsCompleted,
            reasonDescription: note.VendorInvoiceNoteDisputeReasonDescription,
            reasonId: note.VendorInvoiceNoteDisputeReasonId,
            userNameLastModified:
              note.UserNameLastModified ?? note.UserNameCreatedBy,
            visibility: note.Visibility,
            message: note.Message,
            isDisputed: note.IsDisputed,
            createDate: note.CreateDate,
            userEmailCreatedBy: note.UserNameCreatedBy,
            userEmailLastModifiedBy:
              note.UserNameLastModified ?? note.UserNameCreatedBy,
            disputeAge: note.IsDisputed
              ? note.IsCompleted
                ? differenceInDays(
                    new Date(note.DateLastModified),
                    new Date(note.CreateDate)
                  )
                : differenceInDays(new Date(), new Date(note.CreateDate))
              : NO_BREAK_SPACE,
            disputeAgeLastFollowUp: note.IsDisputed
              ? note.IsCompleted
                ? 0
                : differenceInDays(new Date(), new Date(note.DateLastModified))
              : NO_BREAK_SPACE,
            userNameCreatedBy: note.UserNameCreatedBy
          }
      )
      .toSorted((compare, comparer) =>
        comparer.dateLastModified?.localeCompare(compare.dateLastModified)
      )
  }
}
