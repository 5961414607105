import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  finalize,
  forkJoin,
  map,
  of,
  race,
  switchMap,
  take,
  tap,
  timer,
  zip
} from 'rxjs'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { InvoicesAdapter } from '../../../adapter/invoices/InvoicesAdapter'

import { ToUpdateInvoiceVendorChargesBatchRequest } from '../../../adapter/invoices/ToUpdateInvoiceVendorChargesBatchRequest'
import { ToUpdateInvoiceCustomerChargesBatchRequest } from '../../../adapter/invoices/ToUpdateInvoiceCustomerChargesBatchRequest'
import { InvoicesFacade } from '../invoices.facade'
import { ToAddInvoiceDefaultChargesRequest } from '../../../adapter/invoices/ToAddInvoiceDefaultChargesRequest'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { invoiceChargesActions } from './invoice-charges.actions'
import { ToMapOrderChargeRequest } from '../../../adapter/invoices/ToMapOrderChargeRequest'
import { ToUpdateCustomerAdjustedChargeRequest } from '../../../adapter/invoices/ToUpdateCustomerAdjustedChargeRequest'
import { ToAddVendorAdjustedChargeRequest } from '../../../adapter/invoices/ToAddVendorAdjustedChargeRequest'
import { FromGetInvoiceChargesComposed } from '../../../adapter/invoices/FromGetInvoiceChargesComposedResponse'
import { ToUpdateCustomerOrderChargeRequest } from '../../../adapter/invoices/ToUpdateCustomerOrderChargeRequest'
import { ToMapInvoiceChargeRequest } from '../../../adapter/invoices/ToMapInvoiceChargeRequest'
import { IStrategy } from '@navix/utils/adapter'
//TODO(ladaj): resolve dependency
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ChargeCodesFacade } from '@navix/charge-codes/domain'
import { KnownChageTypes } from '../static'
import {
  IGNORE_ORDER_CHARGES_VENDOR_ID,
  MS_BEFORE_RESET_LOADING_STATE
} from '../invoices.constants'
import { platform_v2 } from '@navix/api-models'
import { fromGetInvoiceChargesComposedNew } from '../../../adapter/invoices/from-get-invoice-charges-composed-new'
import { OrdersFacade } from '../../orders/orders.facade'

@Injectable()
export class InvoiceChargesEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private ordersFacade = inject(OrdersFacade)
  private auditService = inject(platform_v2.AuditService)
  private invoicesFacade = inject(InvoicesFacade)
  private chargeCodesFacade = inject(ChargeCodesFacade)
  private alertsFacade = inject(LegacyAlertsFacade)

  private readonly invoicesAdapter = new InvoicesAdapter()

  constructor() {
    this.invoicesAdapter.set(ToUpdateInvoiceCustomerChargesBatchRequest)
    this.invoicesAdapter.set(ToUpdateInvoiceVendorChargesBatchRequest)
    this.invoicesAdapter.set(ToAddInvoiceDefaultChargesRequest)
    this.invoicesAdapter.set(ToMapInvoiceChargeRequest)
    this.invoicesAdapter.set(ToMapOrderChargeRequest)
    this.invoicesAdapter.set(ToUpdateCustomerAdjustedChargeRequest)
    this.invoicesAdapter.set(ToUpdateCustomerOrderChargeRequest)
    this.invoicesAdapter.set(ToAddVendorAdjustedChargeRequest)
    this.invoicesAdapter.set(FromGetInvoiceChargesComposed)
  }

  loadInvoiceCharges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceChargesActions.loadInvoiceCharges),
      debounceTime(300), // Workaround to prevent expensive double call
      switchMap(payload =>
        race(
          timer(1).pipe(
            tap(() => this.chargeCodesFacade.loadChargeCodesWithDetails())
          ),
          this.chargeCodesFacade.allChargeTypes$
        ).pipe(
          take(1),
          map(() => payload)
        )
      ),
      exhaustMap(payload =>
        forkJoin([
          this.auditService.getApiVAuditsOrder({
            orderUuid: payload.orderUuid,
            version: '2'
          }),
          this.invoicesService.getInvoiceAuditCharges(payload.invoiceId),
          this.invoicesService.getInvoiceCustomerAuditChargesNew(
            payload.invoiceId
          )
        ]).pipe(
          switchMap(response => {
            return this.chargeCodesFacade.allChargeTypes$.pipe(
              filter(chargeCodes =>
                chargeCodes.every(
                  chargeCode => chargeCode.tenantChargeTypes !== undefined
                )
              ),
              map(chargeCodes => {
                const purchaseInvoiceCharges =
                  payload.purchaseInvoiceUuid !== undefined
                    ? this.ordersFacade.getPurchaseInvoiceByUuid(
                        payload.purchaseInvoiceUuid
                      )?.rawCharges
                    : undefined

                const ignoreOrderCharges =
                  payload.vendorExternalId === undefined ||
                  (this.ordersFacade.getPurchaseInvoices(
                    payload.vendorExternalId
                  ).length > 0 &&
                    payload.purchaseInvoiceUuid === undefined)
                return [
                  ...response,
                  chargeCodes,
                  ignoreOrderCharges ? [] : purchaseInvoiceCharges
                ] as const
              }),
              take(1)
            )
          }),
          map(responsesList =>
            fromGetInvoiceChargesComposedNew(responsesList, payload)
          ),
          map(charges =>
            invoiceChargesActions.loadInvoiceChargesSuccess({
              invoiceCharges: charges,
              invoiceId: payload.invoiceId
            })
          ),
          catchError(error => {
            console.error(error)
            return of(invoiceChargesActions.loadInvoiceChargesFail({ error }))
          })
        )
      )
    )
  )

  loadLegacyInvoiceCharges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceChargesActions.loadLegacyInvoiceCharges),
      debounceTime(300), // Workaround to prevent expensive double call
      switchMap(payload =>
        race(
          timer(1).pipe(
            tap(() => this.chargeCodesFacade.loadChargeCodesWithDetails())
          ),
          this.chargeCodesFacade.allChargeTypes$
        ).pipe(
          take(1),
          map(() => [payload])
        )
      ),
      exhaustMap(([{ invoiceId, orderId, vendorId }]) =>
        forkJoin([
          this.invoicesService.getInvoiceChargesNew(invoiceId),
          this.invoicesService.getInvoiceAuditCharges(invoiceId),
          vendorId === IGNORE_ORDER_CHARGES_VENDOR_ID
            ? of([])
            : this.invoicesService.getOrderCharges(orderId, vendorId),
          this.invoicesService.getCustomerOrderChargesNew(orderId),
          this.invoicesService.getInvoiceCustomerAuditChargesNew(invoiceId),
          this.invoicesService.getInvoiceCustomerAdjustedChargesNew(invoiceId)
        ]).pipe(
          switchMap(response => {
            const usedChargeTypeIds = Object.values(response)
              .flatMap(x => x.flatMap(y => y.chargeTypeId))
              .concat([
                KnownChageTypes.BaseRate,
                KnownChageTypes.Discount,
                KnownChageTypes.Fsc,
                KnownChageTypes.Accesorials,
                KnownChageTypes.UnknownAccesorials,
                KnownChageTypes.Taxes,
                KnownChageTypes.Duties,
                KnownChageTypes.CustomerFees
              ])

            const fixedChargeTypeIds = [...new Set(usedChargeTypeIds)]

            return this.chargeCodesFacade.allChargeTypes$.pipe(
              filter(chargeCodes =>
                fixedChargeTypeIds.every(
                  chargeTypeId =>
                    chargeCodes.find(
                      chargeCode => chargeCode.id === chargeTypeId
                    ) !== undefined
                )
              ),
              map(chargeCodes => [chargeCodes, ...response]),
              take(1)
            )
          }),
          map(responsesList =>
            this.invoicesAdapter.convert(
              FromGetInvoiceChargesComposed,
              responsesList
            )
          ),
          map(charges =>
            invoiceChargesActions.loadLegacyInvoiceChargesSuccess({
              invoiceCharges: charges,
              invoiceId
            })
          ),
          catchError(error =>
            of(invoiceChargesActions.loadLegacyInvoiceChargesFail({ error }))
          )
        )
      )
    )
  )
  loadLegacyInvoiceChargesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceChargesActions.loadLegacyInvoiceChargesFail),
        tap(error => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load invoice charges."
          })
          throw error.error
        })
      ),
    { dispatch: false }
  )

  resetGetChargesLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceChargesActions.loadLegacyInvoiceChargesSuccess,
        invoiceChargesActions.loadLegacyInvoiceChargesFail,
        invoiceChargesActions.loadInvoiceChargesSuccess,
        invoiceChargesActions.loadInvoiceChargesFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoiceChargesActions.resetGetChargesLoadingState())
    )
  )

  updateInvoiceVendorCharges$ = createEffect(() =>
    zip(
      this.actions$.pipe(
        ofType(invoiceChargesActions.updateInvoiceVendorCharges)
      ),
      this.actions$.pipe(
        ofType(invoiceChargesActions.updateInvoiceCustomerCharges)
      )
    ).pipe(
      map(([vendorCharges, customerCharges]) => ({
        vendorRequest: this.invoicesAdapter.convert(
          ToUpdateInvoiceVendorChargesBatchRequest,
          vendorCharges
        ),
        customerRequest: this.invoicesAdapter.convert(
          ToUpdateInvoiceCustomerChargesBatchRequest,
          customerCharges
        )
      })),

      exhaustMap(requests => {
        if (requests.vendorRequest.charges.length === 0) {
          return of(
            invoiceChargesActions.updateInvoiceChargesFail({
              errorMessage:
                'We have detected an issue in the vendor charges.  Please refresh the browser and try again.'
            })
          )
        }
        const regularFlow = forkJoin([
          this.invoicesService.updateInvoiceVendorChargesBatch(
            requests.vendorRequest
          ),
          this.invoicesService.updateInvoiceCustomerChargesBatch(
            requests.customerRequest
          )
        ]).pipe(
          map(() => invoiceChargesActions.updateInvoiceChargesSuccess()),
          catchError(error =>
            of(
              invoiceChargesActions.updateInvoiceChargesFail({
                error: error.error
              })
            )
          )
        )
        return regularFlow
      })
    )
  )

  resetUpdateInvoiceChargesLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceChargesActions.updateInvoiceChargesSuccess,
        invoiceChargesActions.updateInvoiceChargesFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoiceChargesActions.resetUpdateInvoiceChargesLoadingState())
    )
  )

  addInvoiceDefaultCharges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceChargesActions.addInvoiceDefaultCharges),
      tap(() =>
        this.invoicesFacade.startLoading(
          AsyncOperations.addInvoiceDefaultCharges
        )
      ),
      map(invoice => ({
        request: this.invoicesAdapter.convert(
          ToAddInvoiceDefaultChargesRequest,
          invoice
        )
      })),
      exhaustMap(({ request }) => {
        return this.invoicesService.addInvoiceVendorChargesBatch(request).pipe(
          map(() => invoiceChargesActions.addInvoiceDefaultChargesSuccess()),
          tap(() =>
            this.invoicesFacade.endLoading(
              AsyncOperations.addInvoiceDefaultCharges
            )
          ),

          catchError(httpError =>
            of(
              invoiceChargesActions.addInvoiceDefaultChargesFail({
                error: httpError.error
              })
            )
          )
        )
      })
    )
  )

  mapInvoiceCharges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceChargesActions.mapInvoiceCharges),
      map(request => {
        const createChargeRequest = <I, O>(
          chargeType: new () => IStrategy<I, O>,
          charge: (typeof request)[keyof Omit<typeof request, 'type'>]
        ) =>
          charge !== undefined
            ? this.invoicesAdapter.convert<O, I>(chargeType, {
                charge,
                id: charge.id
              } as I)
            : undefined

        return {
          vendorAdjustedChargeRequest: createChargeRequest(
            ToMapInvoiceChargeRequest,
            request.vendorAdjustedCharge
          ),
          vendorOrderChargeRequest: createChargeRequest(
            ToMapOrderChargeRequest,
            request.vendorOrderCharge
          ),
          customerAdjustedChargeRequest: createChargeRequest(
            ToUpdateCustomerAdjustedChargeRequest,
            request.customerAdjustedCharge
          ),
          customerOrderChargeRequest: createChargeRequest(
            ToUpdateCustomerOrderChargeRequest,
            request.customerOrderCharge
          )
        }
      }),

      exhaustMap(request => {
        return forkJoin([
          request.vendorAdjustedChargeRequest !== undefined
            ? this.invoicesService.mapInvoiceVendorCharge(
                request.vendorAdjustedChargeRequest
              )
            : of(false),
          request.vendorOrderChargeRequest !== undefined
            ? this.invoicesService.mapOrderCharge(
                request.vendorOrderChargeRequest
              )
            : of(false),
          request.customerAdjustedChargeRequest !== undefined
            ? this.invoicesService.updateInvoiceCustomerAdjustedCharge(
                request.customerAdjustedChargeRequest
              )
            : of(false),
          request.customerOrderChargeRequest !== undefined
            ? this.invoicesService.updateInvoiceCustomerOrderCharge(
                request.customerOrderChargeRequest
              )
            : of(false)
        ]).pipe(
          map(() => invoiceChargesActions.mapInvoiceChargesSuccess()),
          catchError(error =>
            of(invoiceChargesActions.mapInvoiceChargesFail({ error }))
          )
        )
      })
    )
  )
  resetMapInvoiceChargesLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceChargesActions.mapInvoiceChargesSuccess,
        invoiceChargesActions.mapInvoiceChargesFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoiceChargesActions.resetMapInvoiceChargesLoadingState())
    )
  )

  addVendorAdjustedCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceChargesActions.addVendorAdjustedCharge),
      map(invoice =>
        this.invoicesAdapter.convert(ToAddVendorAdjustedChargeRequest, invoice)
      ),
      exhaustMap(request => {
        return this.invoicesService.addVendorAdjustedCharge(request).pipe(
          map(() => invoiceChargesActions.addVendorAdjustedChargeSuccess()),
          catchError(httpError =>
            of(
              invoiceChargesActions.addVendorAdjustedChargeFail({
                error: httpError.error
              })
            )
          ),
          finalize(() =>
            this.invoicesFacade.endLoading(
              AsyncOperations.addInvoiceVendorAdjustedCharge
            )
          )
        )
      })
    )
  )
}
