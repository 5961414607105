import { CommonCharges, InvoiceCharge } from '../models/invoice-charge.model'

export type Invoice = {
  id: number
  uuid: string
  invoiceNumber: string
  shipmentNumber: string
  customerId: number | null
  customerDescription: string
  vendorId: number | undefined
  vendorUuid: string | undefined
  vendorExternalId: string | undefined
  vendorDescription: string
  netCharge: number
  auditSavings: number
  currencyId: number
  currencyDescription: string
  modeDescription?: string
  serviceTypeDescription?: string
  modeId?: number
  serviceTypeId?: number
  serviceLevelId?: number
  vendorInvoiceTypeId?: number
  parentInvoiceId?: number
  ingestionMetadata?: Record<string, string>
  belongsToPage?: number
  isMatched?: boolean
  tagsObject: Record<string, object | null>

  createdDate: Date
  billTypeDescription: string
  statusId: number
  statusDescription: string
  statusReasonDescription: string
  statusDateLastModified: string
  typeDescription: string

  vendorNetCharge: number
  customerNetCharge: number
  exceptions: {
    categoryId?: number
    categoryExceptions: {
      id: number
      message?: string
      statusId: number
      statusDescription: string
      isApproveBlocker: boolean
    }[]
  }[]
  freightChargeTermsDescription: string

  shipDate?: Date
  deliveryDate?: Date
  invoiceDate?: Date

  withDetails: boolean
  wasInspectedByRules: boolean

  stops: {
    firstOrigin: InvoiceStop
    origins: InvoiceStop[]
    lastDestination: InvoiceStop
    destinations: InvoiceStop[]
    billTo: InvoiceStop
    remitTo: InvoiceStop | undefined
  }

  selectedOrderId: number
  selectedOrderUuid: string | undefined

  ordersIds?: { legacyId: number; id: string }[]
  orders?: string[]
  shipperDescription?: string
  consigneeDescription?: string

  referenceNumbers: {
    typeId: string
    description: string
    tags: {
      id: number
      value: string
    }[]
  }[]

  lineItems: LineItem[]

  notes: {
    id: number
    subject: string
    reasonId: number
    reasonDescription?: string
    isCompleted: boolean
    visibility: string | undefined
    userNameLastModified?: string
    dateLastModified: string
    message: string
    isDisputed: boolean
    createDate: string
    userEmailCreatedBy: string
    userEmailLastModifiedBy: string
    disputeAge: number | null
    disputeAgeLastFollowUp: number | null
    userNameCreatedBy: string
  }[]

  auditHistory: {
    id: number
    eventCategoryDescription: string
    eventNotes: string
    userEmailLastModified: string
    userClassLastModified: number
    dateLastModified: string
  }[]

  documents: {
    id: number
    uuid: string
    name: string
    submittedName: string | undefined
    typeId: number
    typeDescription: string
    createdDate: string
    dateLastModified: string
    userNameLastModified: string
    url: string
    hasSignature: boolean
    signatureDate: Date | undefined
    isRedacted: boolean
    redactedDocumentUuid: string | undefined
  }[]

  legacyCharges: {
    vendor: CommonCharges<InvoiceCharge>
    customer: CommonCharges<InvoiceCharge>
  }

  vendorInvoiceFormatId?: number
  vendorInvoiceFormatDescription?: string
  ratingEngineInfo: string
  ownerUserId?: string
  ownerUserName?: string
  ownerUserEmail?: string
  duplicateReasonDescription?: string
  tenant?: {
    id?: number
    hasAccessToInvoice?: boolean
  }
  fromDuplicates: boolean

  disputes: {
    id: string
    eTag: string
    party: string
    reasons: {
      description: string
      createdDate: string
      resolvedDate: string
    }[]
    createdDate: string
    recentCommunicationDate: string
    numberOfTouches: number
    daysSinceLastTouch: number
    age: number
    emailAddresses: {
      to: string[]
      cc: string[]
    }
    isCompleted: boolean
  }[]

  communication: {
    disputeId: string
    communications: {
      createdDate: string
      email: {
        to: string[]
        from: string
        cc: string[]
        subject: string
      }
      text: string
      attachments: {
        name: string
        url: string
        size: number
        type: string
      }[]
    }[]
  }
  disputeReasons: string
  party: string
  associatedOrderLineItems: boolean
  workInstructions?: string[]
  workInstructionsPhase2?: {
    id: number
    description: string
    isApproveBlocker: boolean
    isAttested: boolean
  }[]
  statusQueueDescription: string
  receivedDate?: Date
}

export type InvoiceStop = {
  hasManyStops: boolean
  name: string
  address1: string
  address2?: string
  city?: string
  stateId?: number
  stateCode?: string
  countryId?: number
  countryCode?: string
  zip?: string
  typeId: number
  typeDescription: InvoiceStopTypes
  uuid: string
  id: number
}

export type LineItem = {
  id: number
  orderLineItemId?: string
  description: string
  totalPieces: number
  handlingUnitTypeId: number
  handlingUnitDescription?: string
  handlingUnitCount?: number
  length?: number
  width?: number
  height?: number
  uomDescription: string
  weight?: number
  weightUnit: string
  nMFCCode?: string
  class?: number
  cube?: number
  density?: number
  stack?: boolean
}

export enum InvoiceStopTypes {
  Origin = 'Origin',
  Destination = 'Destination',
  BillTo = 'Bill To',
  RemitTo = 'Remit To'
}

export enum RelatedDocumentsFormats {
  Json = 'JSON',
  Xml = 'XML',
  Text = 'TEXT'
}

export enum RelatedDocumentTypes {
  RatingEngine = 'RATING_ENGINE',
  RatingEngineRequiredValues = 'RATING_ENGINE_REQUIRED_VALUES',
  Orderful = 'ORDERFUL'
}
