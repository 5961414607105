import { HttpClient, HttpParams } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { API_URL_V2_VERSION, HEADER_API_KEY } from '@navix/utils/tokens'
import { GetPaginatedDisputeResponse } from '../domain/disputes/get-paginated-disputes.response'
import { GetPaginatedDisputesRequest } from '../domain/disputes/get-paginated-disputes.request'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DisputeService {
  private readonly apiV2Url = inject(API_URL_V2_VERSION)
  private readonly apiKey = inject(HEADER_API_KEY)
  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }
  private apiUrl = `${this.apiV2Url}disputes/paginated`

  constructor(private http: HttpClient) {}

  getPaginatedDisputes(
    request: GetPaginatedDisputesRequest
  ): Observable<GetPaginatedDisputeResponse> {
    const url = this.apiUrl

    // Change the sort order if the sort field is LastApiCommunication
    // disputeLastApiCommunicationAt is displayed as "Get days since the date value", so the
    // latest date will display the smallest number of days, that's why we need to change the sort order
    let sortOrder = undefined
    if (request.sortField === 'LastAuditorCommunicationAt') {
      if (request.sortOrder === 'asc') {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }
    } else {
      sortOrder = request.sortOrder
    }
    const queryFilters: Record<string, string | number | boolean | string[]> = {
      page: request.page,
      itemsPerPage: request.itemsPerPage,
      ownershipUuid: request.ownershipUuid,
      sortField: request.sortField,
      sortOrder: sortOrder,
      resolved: false,
      invoiceStatuses: ['audit', 'matched', 'ready', 'approved']
    }

    const params = new HttpParams({
      fromObject: queryFilters
    })

    return this.http.get<GetPaginatedDisputeResponse>(url, {
      params,
      headers: this.headersWithApiKey
    })
  }
}
