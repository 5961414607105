import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { DisputeService } from '../../infrastructure/dispute.service'
import { disputesActions } from './disputes.actions'
import { catchError, exhaustMap, finalize, map, of, tap } from 'rxjs'
import { InvoicesFacade } from '../invoices/invoices.facade'

@Injectable()
export class DisputesEffects {
  private readonly actions$ = inject(Actions)
  private disputeService = inject(DisputeService)
  private invoicesFacade = inject(InvoicesFacade)

  loadPaginatedDisputes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disputesActions.loadPaginatedDisputes),
      tap(() => this.invoicesFacade.startPaginatedDisputeLoading()),
      exhaustMap(({ request }) =>
        this.disputeService.getPaginatedDisputes(request).pipe(
          map(response => ({ response: response })),
          map(({ response }) =>
            disputesActions.loadPaginatedDisputesSuccess({ response })
          ),
          catchError(error =>
            of(disputesActions.loadPaginatedDisputesFail({ error }))
          ),
          finalize(() => this.invoicesFacade.endPaginatedDisputeLoading())
        )
      )
    )
  )
}
