import { StatusFilter } from '../../../domain/invoices/data-filters.model'

export const filterTabsDataObj: Readonly<{
  [key in
  | 'customers'
  | 'vendors'
  | 'statuses'
  | 'typesOfExceptions'
  | 'disputedReasons'
  | 'disputeReasonsV2'
  | 'users'
  | 'sources'
  | 'divisions'
  | 'dateRangeTypes'
  | 'parties'
  | 'remitTo']: {
    label: string
    key: key
    skipFor: ReadonlyArray<StatusFilter>
    readOnlyFor: ReadonlyArray<StatusFilter>
  }
}> = {
  customers: {
    key: 'customers',
    label: 'Customers',
    skipFor: [],
    readOnlyFor: []
  },
  vendors: { key: 'vendors', label: 'Vendors', skipFor: [], readOnlyFor: [] },
  statuses: {
    key: 'statuses',
    label: 'Queue',
    skipFor: [
      StatusFilter.duplicates,
      StatusFilter.disputes,
      StatusFilter.approved,
      StatusFilter.terminated,
      StatusFilter.unmatched,
      StatusFilter.disputesMatched,
      StatusFilter.disputesUnmatched,
      StatusFilter.auditMatched,
      StatusFilter.readyToApprove
    ],
    readOnlyFor: [StatusFilter.audit, StatusFilter.auditMatched]
  },
  typesOfExceptions: {
    key: 'typesOfExceptions',
    label: 'Types of Exception',
    skipFor: [StatusFilter.duplicates],
    readOnlyFor: [StatusFilter.readyToApprove]
  },
  disputedReasons: {
    key: 'disputedReasons',
    label: 'Dispute Reason',
    readOnlyFor: [StatusFilter.audit, StatusFilter.auditMatched],
    skipFor: [
      StatusFilter.duplicates,
      StatusFilter.unmatched,
      StatusFilter.auditMatched,
      StatusFilter.approved,
      StatusFilter.terminated,
      StatusFilter.all,
      StatusFilter.readyToApprove
    ]
  },
  users: {
    key: 'users',
    label: 'Users',
    readOnlyFor: [],
    skipFor: []
  },
  sources: {
    key: 'sources',
    label: 'Sources',
    readOnlyFor: [],
    skipFor: []
  },
  divisions: {
    key: 'divisions',
    label: 'Divisions',
    readOnlyFor: [],
    skipFor: [
      StatusFilter.duplicates,
      StatusFilter.disputes,
      StatusFilter.approved,
      StatusFilter.terminated,
      StatusFilter.unmatched,
      StatusFilter.disputesUnmatched
    ]
  },
  dateRangeTypes: {
    key: 'dateRangeTypes',
    label: 'Dates',
    readOnlyFor: [],
    skipFor: []
  },
  disputeReasonsV2: {
    key: 'disputeReasonsV2',
    label: 'Dispute Reason',
    readOnlyFor: [],
    skipFor: [
      StatusFilter.duplicates,
      StatusFilter.unmatched,
      StatusFilter.auditMatched,
      StatusFilter.approved,
      StatusFilter.terminated,
      StatusFilter.all,
      StatusFilter.readyToApprove
    ]
  },
  parties: {
    key: 'parties',
    label: 'Parties',
    readOnlyFor: [],
    skipFor: [
      StatusFilter.audit,
      StatusFilter.disputes,
      StatusFilter.disputesMatched,
      StatusFilter.disputesUnmatched,
      StatusFilter.duplicates,
      StatusFilter.unmatched,
      StatusFilter.auditMatched,
      StatusFilter.approved,
      StatusFilter.terminated,
      StatusFilter.all,
      StatusFilter.readyToApprove
    ]
  },
  remitTo: {
    key: 'remitTo',
    label: 'Remit to',
    readOnlyFor: [],
    skipFor: [StatusFilter.duplicates, StatusFilter.unmatched]
  }
} as const

export const filterTabsData = Object.values(filterTabsDataObj)
