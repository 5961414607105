import { Dialog } from '@angular/cdk/dialog'
import { Injectable, inject } from '@angular/core'
import { AlertsFacade } from '@navix/alerts/domain'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { invoiceDetailsActions } from '../invoice-details/invoice-details.actions'
import { EDIT_INVOICE_CURRENCY_DIALOG } from '../invoices.constants'
import { invoiceCurrencyActions } from './invoice-currency.actions'

@Injectable()
export class InvoiceCurrencyEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(AlertsFacade)
  private dialog = inject(Dialog)

  updateInvoiceCurrency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceCurrencyActions.updateInvoiceCurrency),
      exhaustMap(({ id, newCurrency }) =>
        this.invoicesService
          .updateCurrency({
            invoiceId: id,
            currencyId: newCurrency.id
          })
          .pipe(
            map(() =>
              invoiceCurrencyActions.updateInvoiceCurrencySuccess({
                invoiceId: id
              })
            ),
            catchError(error =>
              of(
                invoiceCurrencyActions.updateInvoiceCurrencyFail({
                  error
                })
              )
            )
          )
      )
    )
  )

  updateInvoiceCurrencySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceCurrencyActions.updateInvoiceCurrencySuccess),
      tap(() =>
        this.alertsFacade.addSuccess({
          message: `Currency has been updated successfully.`
        })
      ),
      tap(() =>
        this.dialog.getDialogById(EDIT_INVOICE_CURRENCY_DIALOG)?.close()
      ),
      switchMap(({ invoiceId }) =>
        of(invoiceDetailsActions.loadInvoiceDetails({ invoiceId }))
      )
    )
  )

  updateInvoiceCurrencyFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceCurrencyActions.updateInvoiceCurrencyFail),
        tap(error => console.error(error)),
        tap(httpError => {
          const message: string = httpError.error.error?.message ?? ''
          this.alertsFacade.addError({
            message:
              message.length > 0
                ? message
                : `There's an error trying to update invoice currency.`
          })
        })
      ),
    { dispatch: false }
  )
}
