import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { OdataParser, QUERY_PARSER } from '@navix/utils/query-parser'
import {
  API_URL,
  API_URL_V1_1_VERSION,
  API_URL_V2_VERSION,
  HEADER_API_KEY
} from '@navix/utils/tokens'
import { Observable } from 'rxjs'
import { OrdersDataFilters } from '../domain/orders/data-filters.model'
import { GetCustomerChargesResponse } from '../domain/orders/get-customer-charges.response'
import { GetLineItemsResponse } from '../domain/orders/get-line-items.response'
import { GetMatchedInvoicesResponse } from '../domain/orders/get-matched-invoices.response'
import { GetOrderDetailsResponse } from '../domain/orders/get-order-details.response'
import { GetOrderStopsResponse } from '../domain/orders/get-order-stops.response'
import { GetOrdersResponse } from '../domain/orders/get-orders.response'
import { GetReferenceNumbersResponse } from '../domain/orders/get-reference-numbers.response'
import { GetVendorChargesNewResponse } from '../domain/orders/get-vendor-charges-new.response'
import { GetVendorChargesResponse } from '../domain/orders/get-vendor-charges.response'
import { GetCustomerOrderChargesNewResponse } from '../domain/orders/get-customer-order-charges-new.response'
import { platform_v2 } from '@navix/api-models'

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private readonly _http = inject(HttpClient)
  private readonly apiUrl = inject(API_URL)
  private readonly apiV1_1Url = inject(API_URL_V1_1_VERSION)
  private readonly apiV2Url = inject(API_URL_V2_VERSION)
  private readonly apiKey = inject(HEADER_API_KEY)
  private readonly parser = inject<OdataParser>(QUERY_PARSER)

  private readonly apiOrdersUrl = `${this.apiUrl}orders/` as const
  private readonly apiOrdersNewUrl = `${this.apiV1_1Url}orders/` as const
  private readonly apiOrdersV2Url = `${this.apiV2Url}orders/` as const
  private readonly apiUnmatchedOrdersUrl =
    `${this.apiUrl}new-unmatched-orders/` as const

  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }

  private filterColumns = ['OrderNumber']

  private getQueryFilters = (filters: OrdersDataFilters, count = true) => {
    const query = this.parser
      .prepareWithoutEntity()
      .setCount(count)
      .setContainFilterColumns(this.filterColumns)

    if (filters.search) query.setContainFilter(filters.search)
    if (filters.itemsPerPage) query.setTop(filters.itemsPerPage)
    if (filters.itemsPerPage && filters.page)
      query.setSkip(filters.itemsPerPage * (filters.page - 1))
    if (filters.sortBy && filters.sortDirection)
      query.setOrderBy({
        column: filters.sortBy,
        direction: filters.sortDirection
      })
    if (
      filters.customers &&
      filters.customers.length > 0 &&
      filters.customers.find(x => x.id === 0) === undefined
    )
      query.setInPropertyFilterColumns(
        'CustomerId',
        filters.customers.map(x => x.id)
      )
    if (
      filters.vendors &&
      filters.vendors.length > 0 &&
      filters.vendors.find(x => x.id === 0) === undefined
    )
      query.setAnyAndEqualityFilter(
        'VendorIds',
        filters.vendors.map(x => x.id)
      )
    return query
  }

  getOrders = (filters: OrdersDataFilters): Observable<GetOrdersResponse> => {
    const query = this.getQueryFilters(filters)

    return this._http.get<GetOrdersResponse>(`${this.apiUnmatchedOrdersUrl}`, {
      params: query.getQueryParamsAsHttpParams(),
      headers: this.headersWithApiKey
    })
  }

  getOrderDetails(orderId: number): Observable<GetOrderDetailsResponse> {
    return this._http.get<GetOrderDetailsResponse>(
      `${this.apiOrdersNewUrl}${orderId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getNewOrderDetails(
    orderId: string
  ): Observable<platform_v2.Navix_Services_Sdk_OrderDocument> {
    return this._http.get<platform_v2.Navix_Services_Sdk_OrderDocument>(
      `${this.apiOrdersV2Url}${orderId}`
    )
  }

  getOrderStops(orderId: number): Observable<GetOrderStopsResponse> {
    return this._http.get<GetOrderStopsResponse>(
      `${this.apiOrdersUrl}${orderId}/stops`,
      {
        headers: this.headersWithApiKey
      }
    )
  }
  getReferenceNumbers(
    orderId: number
  ): Observable<GetReferenceNumbersResponse> {
    return this._http.get<GetReferenceNumbersResponse>(
      `${this.apiOrdersUrl}${orderId}/reference-numbers`,
      {
        headers: this.headersWithApiKey
      }
    )
  }
  getLineItems(orderId: number): Observable<GetLineItemsResponse> {
    return this._http.get<GetLineItemsResponse>(
      `${this.apiOrdersUrl}${orderId}/line-items`,
      {
        headers: this.headersWithApiKey
      }
    )
  }
  /**
   *
   * @deprecated use getVendorChargesNew instead
   */
  getVendorCharges(orderId: number): Observable<GetVendorChargesResponse> {
    return this._http.get<GetVendorChargesResponse>(
      `${this.apiOrdersUrl}${orderId}/charges`,
      {
        headers: this.headersWithApiKey
      }
    )
  }
  /**
   *
   * @deprecated use getCustomerChargesNew instead
   */
  getCustomerCharges(orderId: number): Observable<GetCustomerChargesResponse> {
    return this._http.get<GetCustomerChargesResponse>(
      `${this.apiOrdersUrl}customer-order-charges?orderId=${orderId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getVendorChargesNew(
    orderId: number
  ): Observable<GetVendorChargesNewResponse> {
    return this._http.get<GetVendorChargesNewResponse>(
      `${this.apiOrdersUrl}${orderId}/charges/new`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getCustomerChargesNew(
    orderId: number
  ): Observable<GetCustomerOrderChargesNewResponse> {
    return this._http.get<GetCustomerOrderChargesNewResponse>(
      `${this.apiOrdersUrl}customer-order-charges/${orderId}/new`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  downloadOrder(filters: OrdersDataFilters): Observable<HttpResponse<Blob>> {
    const query = this.getQueryFilters(filters, false)

    return this._http.get<Blob>(`${this.apiUnmatchedOrdersUrl}download`, {
      params: query.getQueryParamsAsHttpParams(),
      headers: this.headersWithApiKey,
      responseType: 'blob' as 'json',
      observe: 'response'
    })
  }

  getMatchedInvoices(orderId: number): Observable<GetMatchedInvoicesResponse> {
    return this._http.get<GetMatchedInvoicesResponse>(
      `${this.apiOrdersUrl}${orderId}/invoices`,
      {
        headers: this.headersWithApiKey
      }
    )
  }
}
