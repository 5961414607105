import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { InvoicesService } from '../../../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { catchError, exhaustMap, map, of, tap } from 'rxjs'
import { VendorInvoiceType } from '../../../../domain/invoices/vendor-invoice-type.model'
import { invoicesTypesActions } from '../types.actions'

@Injectable()
export class VendorInvoiceTypesEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)

  loadInvoiceTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesTypesActions.loadInvoiceTypes),
      exhaustMap(() =>
        this.invoicesService.getVendorInvoiceTypes().pipe(
          map(response =>
            response.map(
              item =>
                <VendorInvoiceType>{
                  id: item.vendorInvoiceTypeId,
                  description: item.description
                }
            )
          ),
          map(invoiceTypes =>
            invoicesTypesActions.loadInvoiceTypesSuccess({
              invoiceTypes
            })
          ),
          catchError(error =>
            of(invoicesTypesActions.loadInvoiceTypesFail({ error }))
          )
        )
      )
    )
  )

  loadInvoiceTypesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoicesTypesActions.loadInvoiceTypesFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load terminate reasons."
          })
        })
      ),
    { dispatch: false }
  )
}
