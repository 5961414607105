import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { AddAuditNoteRequest } from '../../../domain/audits/add-audit-note.request'
import { AuditsService } from '../../../infrastructure/audits.service'
import { addAuditNoteActions } from './add-audit-note.actions'
import { MS_BEFORE_RESET_LOADING_STATE } from '../audits.constants'

export const addAuditNote = createEffect(
  (actions$ = inject(Actions), service = inject(AuditsService)) => {
    return actions$.pipe(
      ofType(addAuditNoteActions.addAuditNote),
      exhaustMap(({ audit }) =>
        service
          .addAuditNote(audit.id, {
            text: audit.note.text
          } as AddAuditNoteRequest)
          .pipe(
            map(() =>
              addAuditNoteActions.addAuditNoteSuccess({
                orderId: audit.orderId
              })
            ),
            catchError(error => {
              console.error('Error adding audit note', error)
              return of(addAuditNoteActions.addAuditNoteFail({ error }))
            })
          )
      )
    )
  },
  {
    functional: true
  }
)

export const resetAddAuditNote = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        addAuditNoteActions.addAuditNoteSuccess,
        addAuditNoteActions.addAuditNoteFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => addAuditNoteActions.resetAddAuditNoteLoadingState())
    )
  },
  {
    functional: true
  }
)
