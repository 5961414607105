import { Action, ActionReducer, MetaReducer } from '@ngrx/store'
import { invoicesActions } from './invoices.actions'
import { InvoicesState } from './invoices.reducer'

const persitedActionsPropDictionary: Record<
  string,
  (currentFiltersKey: InvoicesState['currentFiltersKey']) => {
    persistanceKey:
      | `${keyof InvoicesState}_${string}_${string}`
      | keyof InvoicesState
    stateKey: keyof InvoicesState
  }
> = {
  [invoicesActions.setListFiltersMemory.type]: currentFiltersKey => ({
    persistanceKey: `filtersMemory_${currentFiltersKey.userUuid}_${currentFiltersKey.tenantUuid}`,
    stateKey: 'filtersMemory'
  }),
  [invoicesActions.patchListFilters.type]: currentFiltersKey => ({
    persistanceKey: `filters_${currentFiltersKey.userUuid}_${currentFiltersKey.tenantUuid}`,
    stateKey: 'filters'
  }),
  [invoicesActions.setListFilters.type]: currentFiltersKey => ({
    persistanceKey: `filters_${currentFiltersKey.userUuid}_${currentFiltersKey.tenantUuid}`,
    stateKey: 'filters'
  }),
  [invoicesActions.setStandaloneWindowOpen.type]: () => ({
    persistanceKey: `standAloneWindowOpen`,
    stateKey: 'standAloneWindowOpen'
  })
}
const reHydatateActionsPropDictionary: Record<
  string,
  (currentFiltersKey: InvoicesState['currentFiltersKey']) => {
    persistanceKey:
      | `${keyof InvoicesState}_${string}_${string}`
      | keyof InvoicesState
    stateKey: keyof InvoicesState
  }
> = {
  [invoicesActions.initListFiltersMemory.type]: currentFiltersKey => ({
    persistanceKey: `filtersMemory_${currentFiltersKey.userUuid}_${currentFiltersKey.tenantUuid}`,
    stateKey: 'filtersMemory'
  }),
  [invoicesActions.initListFilters.type]: currentFiltersKey => ({
    persistanceKey: `filters_${currentFiltersKey.userUuid}_${currentFiltersKey.tenantUuid}`,
    stateKey: 'filters'
  }),
  [invoicesActions.initStandaloneWindowOpen.type]: () => ({
    persistanceKey: `standAloneWindowOpen`,
    stateKey: 'standAloneWindowOpen'
  })
}

const clearActionsPropDictionary: Record<
  string,
  (
    currentFiltersKey: InvoicesState['currentFiltersKey']
  ) => Array<keyof InvoicesState | `${keyof InvoicesState}_${string}_${string}`>
> = {
  [invoicesActions.clearListFilters.type]: currentFiltersKey => [
    `filtersMemory_${currentFiltersKey.userUuid}_${currentFiltersKey.tenantUuid}`,
    `filters_${currentFiltersKey.userUuid}_${currentFiltersKey.tenantUuid}`,
    'standAloneWindowOpen'
  ]
}

//TODO(ladaj): if we have more cases, use providers
function persistToSessionStorage(
  reducer: ActionReducer<InvoicesState, Action>
): ActionReducer<InvoicesState, Action> {
  return function (state, action) {
    const newState = reducer(state, action)

    const requireManualHandling =
      persitedActionsPropDictionary[action.type] !== undefined
    if (!requireManualHandling) return newState

    const persistenceFiltersKeys = persitedActionsPropDictionary[action.type](
      state?.currentFiltersKey ?? {
        userUuid: 'default',
        tenantUuid: 'default'
      }
    )

    localStorage.setItem(
      persistenceFiltersKeys.persistanceKey,
      JSON.stringify(newState[persistenceFiltersKeys.stateKey])
    )

    return newState
  }
}

function reHydratateFromSessionStorage(
  reducer: ActionReducer<InvoicesState, Action>
): ActionReducer<InvoicesState, Action> {
  return function (state, action) {
    const newState = reducer(state, action)

    const requireManualHandling =
      reHydatateActionsPropDictionary[action.type] !== undefined

    if (!requireManualHandling) return newState
    const persistenceFiltersKeys = reHydatateActionsPropDictionary[action.type](
      state?.currentFiltersKey ?? {
        userUuid: 'default',
        tenantUuid: 'default'
      }
    )
    const hydratedProp = localStorage.getItem(
      persistenceFiltersKeys.persistanceKey
    )
    if (hydratedProp)
      return {
        ...newState,
        [persistenceFiltersKeys.stateKey]: JSON.parse(hydratedProp)
      }
    return newState
  }
}

function clearFiltersFromSesssionStorage(
  reducer: ActionReducer<InvoicesState, Action>
): ActionReducer<InvoicesState, Action> {
  return function (state, action) {
    const newState = reducer(state, action)

    const requireManualHandling =
      clearActionsPropDictionary[action.type] !== undefined

    if (!requireManualHandling) return newState

    const persistenceFiltersKeys = clearActionsPropDictionary[action.type](
      state?.currentFiltersKey ?? {
        userUuid: 'default',
        tenantUuid: 'default'
      }
    )

    persistenceFiltersKeys.forEach(key => {
      localStorage.removeItem(key)
    })

    return newState
  }
}

export const invoiceUserKeyMetaReducer: MetaReducer<InvoicesState>[] = [
  persistToSessionStorage,
  reHydratateFromSessionStorage,
  clearFiltersFromSesssionStorage
]
