import { Injectable, Signal, computed, inject } from '@angular/core'
import { Store } from '@ngrx/store'
import { AuditForm } from '../../domain/audits/audit-form.model'
import { addAuditNoteActions } from './add-audit-note/add-audit-note.actions'
import * as AuditsSelectors from './audits.selectors'
import { loadAuditActions } from './load-audit/load-audit.actions'
import {
  AuditAsyncOperations,
  AuditsLoadingState
} from '../../domain/audits/audits-loading.model'
import { loadingStateEqualFn } from '@navix/shared/loading'
import { updateAuditActions } from './update-audit/update-audit.actions'
import { invoiceChargesActions } from '../invoices/invoice-charges/invoice-charges.actions'
import { invoicesActions } from '../invoices/invoices.actions'

@Injectable()
export class AuditsFacade {
  private readonly store: Store = inject(Store)

  loading$ = this.store.select(AuditsSelectors.selectLoading)
  loading = this.store.selectSignal(AuditsSelectors.selectLoading)
  selectedAudit = this.store.selectSignal(AuditsSelectors.selectEntity)

  selectedAuditNotes = computed(() => {
    const audit = this.selectedAudit()
    if (!audit) return []
    return audit.notes
  })

  getLoadingStateSignal<K extends keyof typeof AuditAsyncOperations>(
    operation: K
  ) {
    return computed(() => this.loading()[operation], {
      equal: loadingStateEqualFn
    }) satisfies Signal<AuditsLoadingState[K]>
  }

  selectAudit(orderId: string) {
    this.store.dispatch(loadAuditActions.loadAudit({ orderId }))
  }

  addAuditNote(note: AuditForm['note']) {
    const audit = this.selectedAudit()
    this.store.dispatch(
      addAuditNoteActions.addAuditNote({
        audit: { note, id: audit!.id, orderId: audit!.orderId }
      })
    )
  }
  addAuditChargesMappings(
    reference: {
      invoiceUuid: string
      orderUuid: string
      vendorExternalId: string | undefined
      vendorInvoiceId: number
      unknownChargeUuid: string
    },
    mappings: {
      chargeTypeUuid: string
      previousChargeTypeUuid: string
      originalCharge: number
      currentCharge: number
      code: string
      description: string
      tenantChargeCode: string
      tenantChargeDescription: string
      for: 'customer' | 'vendor'
      kind: 'order' | 'adjusted'
    }[]
  ) {
    this.store.dispatch(
      updateAuditActions.addAuditChargesMappings({
        invoiceUuid: reference.invoiceUuid,
        orderUuid: reference.orderUuid,
        vendorExternalId: reference.vendorExternalId,
        vendorInvoiceId: reference.vendorInvoiceId,
        unknownChargeUuid: reference.unknownChargeUuid,
        customerOrderMappings: mappings.filter(
          m => m.for === 'customer' && m.kind === 'order'
        ),
        customerAdjustedMappings: mappings.filter(
          m => m.for === 'customer' && m.kind === 'adjusted'
        ),
        vendorOrderMappings: mappings.filter(
          m => m.for === 'vendor' && m.kind === 'order'
        ),
        vendorAdjustedMappings: mappings.filter(
          m => m.for === 'vendor' && m.kind === 'adjusted'
        )
      })
    )
  }

  updateAudit(payload: Parameters<typeof updateAuditActions.updateAudit>[0]) {
    this.store.dispatch(updateAuditActions.updateAudit(payload))
  }

  updateAuditVendor(
    payload: Parameters<typeof updateAuditActions.updateAuditVendor>[0]
  ) {
    this.store.dispatch(updateAuditActions.updateAuditVendor(payload))
  }

  async updateInvoiceChargesWithAuditChargesUpdate(
    payload: Parameters<typeof updateAuditActions.updateAuditCharges>[0]
  ) {
    this.store.dispatch(updateAuditActions.updateAuditCharges(payload))
  }

  async updateInvoiceChargesWithAuditChargesUpdateV2(
    payload: Parameters<typeof updateAuditActions.updateAuditChargesV2>[0]
  ) {
    this.store.dispatch(updateAuditActions.updateAuditChargesV2(payload))
  }
}
