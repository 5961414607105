import { IStrategy } from '@navix/utils/adapter'
import { Invoice } from '../../domain/invoices//invoice.model'
import { GetInvoiceAuditHistoryResponse } from '../../domain/invoices/get-invoice-audit-history.response'

export class FromGetInvoiceAuditHistoryResponse
  implements IStrategy<GetInvoiceAuditHistoryResponse, Invoice['auditHistory']>
{
  convert(response: GetInvoiceAuditHistoryResponse): Invoice['auditHistory'] {
    if (!Array.isArray(response.value)) return []

    return response.value
      .map(
        auditHistory =>
          <Invoice['auditHistory'][number]>{
            id: auditHistory.VendorInvoiceChangeLogId,
            dateLastModified: auditHistory.DateLastModified,
            eventCategoryDescription:
              auditHistory.VendorInvoiceChangeLogCategoryDescription,
            eventNotes: auditHistory.Description,
            userEmailLastModified: auditHistory.UserEmailLastModified,
            userClassLastModified: auditHistory.UserClassLastModified
          }
      )
      .sort(
        (a, b) =>
          new Date(b.dateLastModified).getTime() -
          new Date(a.dateLastModified).getTime()
      )
  }
}
