import { IStrategy } from '@navix/utils/adapter'
import {
  Invoice,
  InvoiceStop,
  InvoiceStopTypes
} from '../../domain/invoices//invoice.model'
import { GetInvoiceDetailsResponse } from '../../domain/invoices/get-invoice-details.response'

export class FromGetInvoiceDetailsResponse
  implements IStrategy<GetInvoiceDetailsResponse, Invoice>
{
  convert(response: GetInvoiceDetailsResponse): Invoice {
    return <Invoice>{
      id: response.VendorInvoiceId,
      uuid: response.Uuid,
      vendorDescription: response.VendorDescription,
      freightChargeTermsDescription: response.FreightChargeTerms ?? '',
      selectedOrderId:
        response.OrdersIds?.length === 1 ? response.OrdersIds[0] : 0,
      selectedOrderUuid:
        response.OrdersIds?.length === 1 ? response.OrderUuids?.[0] : undefined,
      stops: {
        firstOrigin: this.getStopFromResponse(
          response,
          InvoiceStopTypes.Origin
        ),
        lastDestination: this.getStopFromResponse(
          response,
          InvoiceStopTypes.Destination,
          'desc'
        ),
        billTo: this.getStopFromResponse(response, InvoiceStopTypes.BillTo),

        remitTo: response.stops
          .filter(
            stop => stop.VendorInvoiceStopType === InvoiceStopTypes.RemitTo
          )
          .map(stop => this.convertToStop(stop, InvoiceStopTypes.RemitTo))
          .at(0),

        origins: response.stops
          .filter(
            stop => stop.VendorInvoiceStopType === InvoiceStopTypes.Origin
          )
          .map(stop => this.convertToStop(stop, InvoiceStopTypes.Origin)),

        destinations: response.stops
          .filter(
            stop => stop.VendorInvoiceStopType === InvoiceStopTypes.Destination
          )
          .map(stop => this.convertToStop(stop, InvoiceStopTypes.Destination))
      },
      shipDate: isNaN(Date.parse(response.ShipDate))
        ? undefined
        : new Date(response.ShipDate),
      deliveryDate: isNaN(Date.parse(response.DeliveryDate ?? ''))
        ? undefined
        : new Date(response.DeliveryDate ?? ''),
      invoiceDate: isNaN(Date.parse(response.InvoiceDate))
        ? new Date(response.CreatedDate ?? '')
        : new Date(response.InvoiceDate),

      currencyId: response.CurrencyId,
      currencyDescription: response.Currency,
      customerId: response.CustomerId as number,
      vendorId: response.VendorId ?? undefined,
      vendorUuid: response.VendorUuid ?? undefined,
      vendorExternalId: response.VendorExternalId ?? undefined,
      shipmentNumber: response.ShipmentNumber as string,
      invoiceNumber: response.InvoiceNumber as string,
      ingestionMetadata: this.getMetadata(response.IngestionMetadata),
      withDetails: true,
      ordersIds:
        response.OrderUuids?.map((id, index) => ({
          id,
          legacyId: response.OrdersIds![index]
        })) ?? [],
      customerDescription: response.CustomerDescription as string,
      modeDescription: response.TransportationModeDescription,
      serviceTypeDescription: response.TransportationServiceTypeDescription,
      typeDescription: response.VendorInvoiceTypeDescription as string,
      statusReasonDescription: response.VendorInvoiceStatusReason as string,
      modeId: response.TransportationModeId,
      serviceTypeId: response.TransportationServiceTypeId,
      serviceLevelId: response.TransportationServiceLevelId,
      vendorInvoiceTypeId: response.VendorInvoiceTypeId,
      parentInvoiceId: response.ParentInvoiceId,
      statusId: response.VendorInvoiceStatusDescriptionId as number,
      statusDescription: response.VendorInvoiceStatusDescription as string,
      wasInspectedByRules: response.WasInspectedByRules,
      vendorInvoiceFormatId: response.VendorInvoiceFormatId,
      vendorInvoiceFormatDescription: response.VendorInvoiceFormatDescription,
      ratingEngineInfo: response.RatingEngineInfo,
      tagsObject: response.TagsObject,
      ownerUserName:
        response.OwnerUserName != undefined ? response.OwnerUserName : '',
      ownerUserEmail:
        response.OwnerUserEmail != undefined ? response.OwnerUserEmail : '',
      associatedOrderLineItems: response.AssociatedOrderLineItems ?? false,
      receivedDate: isNaN(Date.parse(response.ReceivedDate ?? ''))
        ? undefined
        : new Date(response.ReceivedDate ?? '')
    }
  }

  getMetadata(metadataString?: string): {
    [key: string]: string
  } {
    try {
      if (metadataString?.trim().length === 0) {
        metadataString = '{}'
      }
      return JSON.parse(metadataString ?? '{}')
    } catch (error) {
      console.warn('[NavixApp] Error parsing metadata: ', metadataString)

      return {}
    }
  }

  getStopFromResponse(
    response: GetInvoiceDetailsResponse,
    type: InvoiceStopTypes,
    mainStopSortDirection: 'asc' | 'desc' = 'asc'
  ): InvoiceStop {
    const initialTypeStop = response.stops
      .filter(stop => stop.VendorInvoiceStopType === type)
      .sort(
        (a, b) =>
          (a.StopSequence > b.StopSequence ? 1 : -1) *
          (mainStopSortDirection === 'asc' ? 1 : -1)
      )[0]

    const hasManyStops =
      response.stops.filter(stop => stop.VendorInvoiceStopType === type)
        .length > 1

    return {
      ...this.convertToStop(initialTypeStop, type),
      hasManyStops
    }
  }

  convertToStop(
    responseStop: GetInvoiceDetailsResponse['stops'][number],
    type: InvoiceStopTypes
  ) {
    return {
      typeDescription: type,
      name: responseStop?.AddressName ?? '',
      typeId: responseStop?.VendorInvoiceStopId ?? 0,
      address1: responseStop?.Address1 ?? '',
      address2: responseStop?.Address2,
      city: responseStop?.City,
      stateCode: responseStop?.State,
      countryCode: responseStop?.Country,
      zip: responseStop?.Zip,
      uuid: responseStop?.Uuid,
      id: responseStop?.VendorInvoiceStopId,
      stateId: responseStop?.StateId,
      countryId: responseStop?.CountryId
    } as InvoiceStop
  }
}
