export enum KnownInvoiceStatusesQueues {
  Duplicates = 1,
  Unmatched = 2,
  Audit = 3,
  ReadyToApprove = 4,
  DisputesUnmatched = 5,
  DisputesMatched = 6,
  Terminated = 7,
  Approved = 8,
  Draft = 15,
  Ready = 16
}

export const DRAFT_STATUS_QUEUE = {
  id: KnownInvoiceStatusesQueues.Draft,
  value: 'Draft',
  label: 'Draft'
}

export const READY_STATUS_QUEUE = {
  id: KnownInvoiceStatusesQueues.Ready,
  value: 'Ready',
  label: 'Ready'
}

export const DUPLICATES_STATUS_QUEUE = {
  id: KnownInvoiceStatusesQueues.Duplicates,
  value: 'Duplicates',
  label: 'Duplicates'
}

export const UNMATCHED_STATUS_QUEUE = {
  id: KnownInvoiceStatusesQueues.Unmatched,
  value: 'Unmatched',
  label: 'Unmatched'
}

export const AUDIT_STATUS_QUEUE = {
  id: KnownInvoiceStatusesQueues.Audit,
  value: 'Audit',
  label: 'Audit'
}

export const READY_TO_APPROVE_STATUS_QUEUE = {
  id: KnownInvoiceStatusesQueues.ReadyToApprove,
  value: 'Ready to Approve',
  label: 'Ready to Approve'
}

export const DISPUTES_UNMATCHED_STATUS_QUEUE = {
  id: KnownInvoiceStatusesQueues.DisputesUnmatched,
  value: 'Disputes Unmatched',
  label: 'Disputes Unmatched'
}

export const DISPUTES_MATCHED_STATUS_QUEUE = {
  id: KnownInvoiceStatusesQueues.DisputesMatched,
  value: 'Disputes Matched',
  label: 'Disputes Matched'
}

export const TERMINATED_STATUS_QUEUE = {
  id: KnownInvoiceStatusesQueues.Terminated,
  value: 'Terminated',
  label: 'Terminated'
}

export const APPROVED_STATUS_QUEUE = {
  id: KnownInvoiceStatusesQueues.Approved,
  value: 'Approved',
  label: 'Approved'
}
