export enum KnownInvoiceStatuses {
  Draft = 1,
  Ready = 2,
  Unmatched = 3,
  Matched = 4,
  DuplicateUnprocessed = 5,
  Audit = 6,
  Terminated = 7,
  Approved = 8,
  Exported = 9,
  Paid = 10,
  DuplicateProcessed = 11
}

export const DRAFT_STATUS = {
  id: KnownInvoiceStatuses.Draft,
  value: 'Draft',
  label: 'Draft'
}

export const READY_STATUS = {
  id: KnownInvoiceStatuses.Ready,
  value: 'Ready',
  label: 'Ready'
}

export const UNMATCHED_STATUS = {
  id: KnownInvoiceStatuses.Unmatched,
  value: 'Unmatched',
  label: 'Unmatched'
}

export const MATCHED_STATUS = {
  id: KnownInvoiceStatuses.Matched,
  value: 'Matched',
  label: 'Matched'
}

export const AUDIT_STATUS = {
  id: KnownInvoiceStatuses.Audit,
  value: 'Audit',
  label: 'Audit'
}

export const TERMINATED_STATUS = {
  id: KnownInvoiceStatuses.Terminated,
  value: 'Terminated',
  label: 'Terminated'
}

export const APPROVED_STATUS = {
  id: KnownInvoiceStatuses.Approved,
  value: 'Approved',
  label: 'Approved'
}

export const EXPORTED_STATUS = {
  id: KnownInvoiceStatuses.Exported,
  value: 'Exported',
  label: 'Exported'
}

export const PAID_STATUS = {
  id: KnownInvoiceStatuses.Paid,
  value: 'Paid',
  label: 'Paid'
}

export const DUPLICATE_UNPROCESSED_STATUS = {
  id: KnownInvoiceStatuses.DuplicateUnprocessed,
  value: 'Duplicate-Unprocessed',
  label: 'Duplicate-Unprocessed'
}

export const DUPLICATE_PROCESSED_STATUS = {
  id: KnownInvoiceStatuses.DuplicateProcessed,
  value: 'Duplicate-Processed',
  label: 'Duplicate-Processed'
}

export const DISPUTED_STATUS = {
  id: KnownInvoiceStatuses.DuplicateProcessed,
  value: 'Disputed',
  label: 'Disputed'
}
