import { IStrategy } from '@navix/utils/adapter'
import { GetReferenceNumbersResponse } from '../../domain/invoices/get-reference-numbers.response'
import { Invoice } from '../../domain/invoices//invoice.model'

export class FromGetReferenceNumbersResponse
  implements
    IStrategy<GetReferenceNumbersResponse, Invoice['referenceNumbers']>
{
  convert(response: GetReferenceNumbersResponse): Invoice['referenceNumbers'] {
    if (!Array.isArray(response.value)) return []
    const sorted = [...response.value].sort(
      (a, b) =>
        <number>a.ReferenceNumberType?.SortOrder -
        <number>b.ReferenceNumberType?.SortOrder
    )

    return this.convertResponseToReferenceNumber(sorted)
  }

  convertResponseToReferenceNumber(
    referenceNumbersResponse: GetReferenceNumbersResponse['value']
  ): Invoice['referenceNumbers'] {
    const groupedRespondeByTypeId = referenceNumbersResponse.reduce(
      (acc, referenceNumber) => {
        const typeId = <string>(
          referenceNumber.ReferenceNumberType?.ReferenceNumberTypeId
        )
        acc[typeId] = acc[typeId] ?? []
        acc[typeId].push(referenceNumber)
        return acc
      },
      {} as { [typeId: string]: GetReferenceNumbersResponse['value'] }
    )

    const referencesNumbers = Object.entries(groupedRespondeByTypeId).map<
      Invoice['referenceNumbers'][number]
    >(([typeId, referenceNumbers]) => {
      return {
        typeId: typeId,
        description: referenceNumbers[0].ReferenceNumberType?.Description ?? '',
        tags: referenceNumbers.map(referenceNumber => ({
          id: referenceNumber.VendorInvoiceReferenceNumberId,
          value: referenceNumber.Value
        }))
      }
    })

    return referencesNumbers.toSorted((compare, comparer) =>
      compare.description.localeCompare(comparer.description)
    )
  }
}
