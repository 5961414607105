import { createActionGroup, props, emptyProps } from '@ngrx/store'
import { InvoiceForm } from '../../../domain/invoices/invoice-form.model'
import { Invoice } from '../../../domain/invoices/invoice.model'

export const invoiceDuplicatesActions = createActionGroup({
  source: 'Invoice Duplicates',
  events: {
    'load invoice duplicates': props<{
      parentInvoiceId: number
    }>(),
    'load invoice duplicates success': props<{
      duplicateInvoices: Invoice[]
    }>(),
    'load invoice duplicates fail': props<{ error: unknown }>(),

    'update invoice duplicates': props<{
      duplicates: InvoiceForm['duplicates']
      useLegacy: boolean
    }>(),
    'update invoice duplicates success': emptyProps(),
    'update invoice duplicates fail': props<{ error: string }>(),
    resetUpdateInvoiceDuplicatesLoadingState: emptyProps(),
    loadInvoiceDuplicatesOnNavigation: props<{
      parentInvoiceId: number
    }>(),
    loadInvoiceDuplicatesOnNavigationSuccess: props<{
      duplicateInvoices: Invoice[]
    }>(),
    loadInvoiceDuplicatesOnNavigationFail: props<{
      error: unknown
    }>(),
    resetLoadInvoiceDuplicatesOnNavigationLoadingState: emptyProps(),
    loadInvoiceDuplicatesForInvoiceCentric: props<{
      parentInvoiceId: number
    }>(),
    loadInvoiceDuplicatesForInvoiceCentricSuccess: props<{
      duplicateInvoices: Invoice[]
    }>(),
    loadInvoiceDuplicatesForInvoiceCentricFail: props<{
      error: unknown
    }>(),
    resetLoadInvoiceDuplicatesForInvoiceCentricLoadingState: emptyProps()
  }
})
