import { createActionGroup, props } from '@ngrx/store'
import { Invoice } from '../../../domain/invoices/invoice.model'

export const invoiceAuditHistoryActions = createActionGroup({
  source: 'Invoice Audit History',
  events: {
    'load invoice audit history': props<{ invoiceId: number }>(),
    'load invoice audit history success': props<{
      auditHistory: Invoice['auditHistory']
      invoiceId: number
    }>(),
    'load invoice audit history fail': props<{ error: unknown }>()
  }
})
