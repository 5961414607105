import { SelectionModel } from '@angular/cdk/collections'
import { Injectable, signal } from '@angular/core'
import { KnownChageTypes } from '../+state/invoices/static'

type SelectionType = number
@Injectable()
export class ChargesExpandedRowsService {
  private readonly _expandedRows = new SelectionModel<SelectionType>(true, [
    KnownChageTypes.Accesorials,
    KnownChageTypes.UnknownAccesorials
  ])
  private readonly _expandedRowsSignal = signal<SelectionType[]>(
    this._expandedRows.selected
  )
  private _toggleableIds: number[] = []

  setAllToggleableIds(ids: number[]) {
    this._toggleableIds = ids
  }

  toggleId(chargeTypeId: number) {
    this._expandedRows.toggle(chargeTypeId)
    this._expandedRowsSignal.set(this._expandedRows.selected)
  }
  selectIds(chargeTypeIds: number[]) {
    this._expandedRows.select(...chargeTypeIds)
    this._expandedRowsSignal.set(this._expandedRows.selected)
  }

  toggleAll() {
    if (this._expandedRows.selected.length > 0) {
      this._expandedRows.clear()
    } else {
      this._expandedRows.clear()
      this._expandedRows.select(...this._toggleableIds)
    }
    this._expandedRowsSignal.set(this._expandedRows.selected)
  }

  get selected() {
    return this._expandedRowsSignal
  }
}
