import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  catchError,
  debounceTime,
  exhaustMap,
  forkJoin,
  map,
  of,
  tap
} from 'rxjs'
import { fromGetInvoiceDisputesResponse } from '../../../adapter/invoices/FromGetInvoiceDisputesResponse'
import { InvoicesAdapter } from '../../../adapter/invoices/InvoicesAdapter'
import { ToAddInvoiceDisputesRequest } from '../../../adapter/invoices/ToAddInvoiceDisputesRequest'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { MS_BEFORE_RESET_LOADING_STATE } from '../invoices.constants'
import { fromGetInvoiceDisputesCommunicationsResponse } from '../../../adapter/invoices/FromGetInvoiceDisputesCommunicationsResponse'
import { invoiceDisputesActions } from './invoice-disputes.actions'
import { ToAddInvoiceCommunicationRequest } from '../../../adapter/invoices/ToAddInvoiceCommunicationRequest'
import { platform_v2 } from '@navix/api-models'

@Injectable()
export class InvoiceDisputesEffects {
  private readonly actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private service = inject(platform_v2.DisputeService)

  private invoiceAdapter = new InvoicesAdapter()

  constructor() {
    this.invoiceAdapter.set(ToAddInvoiceDisputesRequest)
    this.invoiceAdapter.set(ToAddInvoiceCommunicationRequest)
  }

  loadInvoiceDisputes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDisputesActions.loadInvoiceDisputes),
      exhaustMap(({ invoiceId, invoiceUuid }) =>
        this.invoicesService.getDisputes(invoiceUuid).pipe(
          map(response => ({
            invoice: fromGetInvoiceDisputesResponse(response, invoiceId)
          })),
          map(({ invoice }) =>
            invoiceDisputesActions.loadInvoiceDisputesSuccess({
              invoiceWithDisputes: invoice
            })
          ),
          catchError(error =>
            of(invoiceDisputesActions.loadInvoiceDisputesFail({ error }))
          )
        )
      )
    )
  )

  resetGetDisputesLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDisputesActions.loadInvoiceDisputesSuccess,
        invoiceDisputesActions.loadInvoiceDisputesFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoiceDisputesActions.resetGetDisputesLoadingState())
    )
  )

  loadInvoiceDisputesCommunications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDisputesActions.loadInvoiceDisputesCommunication),
      exhaustMap(({ invoiceUuid, invoiceId, disputeId }) =>
        this.service
          .getApiV2InvoicesDisputesCommunications({
            invoiceUuid,
            disputeUuid: disputeId
          })
          .pipe(
            map(response => ({
              invoice: fromGetInvoiceDisputesCommunicationsResponse(
                response,
                disputeId,
                invoiceId
              )
            })),
            map(({ invoice }) =>
              invoiceDisputesActions.loadInvoiceDisputesCommunicationSuccess({
                invoiceWithDisputesCommunication: invoice
              })
            ),
            catchError(error =>
              of(
                invoiceDisputesActions.loadInvoiceDisputesCommunicationFail({
                  error
                })
              )
            )
          )
      )
    )
  )

  resetGetDisputesCommunicationLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDisputesActions.loadInvoiceDisputesCommunicationSuccess,
        invoiceDisputesActions.loadInvoiceDisputesCommunicationFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        invoiceDisputesActions.resetGetDisputesCommunicationLoadingState()
      )
    )
  )

  resolveDisputeReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDisputesActions.resolveDisputeReasons),
      map(({ disputeId, reasons, invoiceUuid }) => ({
        invoiceUuid,
        disputeId,
        request: reasons.map(reason => reason.description)
      })),
      exhaustMap(({ invoiceUuid, disputeId, request }) =>
        this.invoicesService
          .resolveDisputeReasons(invoiceUuid, disputeId, request)
          .pipe(
            map(() => invoiceDisputesActions.resolveDisputeReasonsSuccess()),
            catchError(error =>
              of(invoiceDisputesActions.resolveDisputeReasonsFail({ error }))
            )
          )
      )
    )
  )

  addInvoiceDisputes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDisputesActions.addInvoiceDisputes),
      map(({ disputes, invoiceUuid }) => ({
        request: this.invoiceAdapter.convert(
          ToAddInvoiceDisputesRequest,
          disputes
        ),
        invoiceUuid: invoiceUuid
      })),
      exhaustMap(({ request, invoiceUuid }) =>
        this.invoicesService.addInvoiceDisputes(invoiceUuid, request).pipe(
          map(() => invoiceDisputesActions.addInvoiceDisputesSuccess()),
          catchError(error => {
            const errorMessages: { [key: number]: string } = {
              500: 'We encountered a problem - please try again',
              400:
                typeof error.error === 'string'
                  ? error.error
                  : 'There was an error processing your request'
            }
            const errorMessage =
              errorMessages[error.status] ||
              'There was an error processing your request'
            return of(
              invoiceDisputesActions.addInvoiceDisputesFail({
                error: errorMessage
              })
            )
          })
        )
      )
    )
  )

  resetResolveDisputeReasonsLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDisputesActions.resolveDisputeReasonsSuccess,
        invoiceDisputesActions.resolveDisputeReasonsFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoiceDisputesActions.resetResolveDisputeReasonsLoadingState())
    )
  )

  resetAddInvoiceDisputes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDisputesActions.addInvoiceDisputesSuccess,
        invoiceDisputesActions.addInvoiceDisputesFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoiceDisputesActions.resetAddInvoiceDisputesLoadingState())
    )
  )

  sendReplyCommunication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDisputesActions.sendReplyCommunication),
      map(({ invoiceUuid, disputeId, communication }) => ({
        invoiceUuid,
        disputeId,
        request: this.invoiceAdapter.convert(
          ToAddInvoiceCommunicationRequest,
          communication
        )
      })),
      exhaustMap(({ invoiceUuid, disputeId, request }) =>
        this.invoicesService
          .sendReplyCommunication(invoiceUuid, disputeId, request)
          .pipe(
            map(() => invoiceDisputesActions.sendReplyCommunicationSuccess()),
            catchError(error =>
              of(invoiceDisputesActions.sendReplyCommunicationFail({ error }))
            )
          )
      )
    )
  )

  resetSendReplyCommunicationLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDisputesActions.sendReplyCommunicationSuccess,
        invoiceDisputesActions.sendReplyCommunicationFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        invoiceDisputesActions.resetSendReplyCommunicationLoadingState()
      )
    )
  )

  downloadAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDisputesActions.downloadAttachment),
      exhaustMap(({ attachmentUrl, fileName }) =>
        this.invoicesService.downloadAttachment(attachmentUrl).pipe(
          map(response => ({
            blob: response.body!,
            filename: fileName
          })),
          map(({ blob, filename }) =>
            invoiceDisputesActions.downloadAttachmentSuccess({
              blob,
              filename
            })
          ),
          catchError(error =>
            of(invoiceDisputesActions.downloadAttachmentFail({ error }))
          )
        )
      )
    )
  )

  resetDownloadAttachmentLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDisputesActions.downloadAttachmentSuccess,
        invoiceDisputesActions.downloadAttachmentFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoiceDisputesActions.resetDownloadAttachmentLoadingState())
    )
  )

  loadMultipleInvoicesDisputes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDisputesActions.loadMultipleInvoicesDisputes),
      exhaustMap(({ invoicesUuids }) => {
        const requests = invoicesUuids.map(invoiceUuid =>
          this.invoicesService.getDisputes(invoiceUuid)
        )

        return forkJoin(requests).pipe(
          map(response => response.flat()),
          map(response => {
            return invoiceDisputesActions.loadMultipleInvoicesDisputesSuccess({
              hasDisputes:
                response.length > 0 &&
                response.some(dispute => !dispute.isCompleted)
            })
          }),
          catchError(error =>
            of(
              invoiceDisputesActions.loadMultipleInvoicesDisputesFail({ error })
            )
          )
        )
      })
    )
  )

  resetGetMultipleInvoicesDisputesLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDisputesActions.loadMultipleInvoicesDisputesSuccess,
        invoiceDisputesActions.loadMultipleInvoicesDisputesFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoiceDisputesActions.resetGetDisputesLoadingState())
    )
  )
}
