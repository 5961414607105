import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class RelatedDocumentsViewService {
  private popupBaseUrl = '/standalone/invoices/s/related-documents'
  private popup: Window | undefined | null
  private readonly DEFAULT_SCREEN_WIDTH = 992 as const
  private readonly DEFAULT_SCREEN_HEIGHT = 480 as const
  private readonly DEFAULT_SCREEN_Y_POSITION = 100 as const
  private screenX = screen.width / 2 - this.DEFAULT_SCREEN_WIDTH / 2
  private screenY: number = this.DEFAULT_SCREEN_Y_POSITION

  private updateScreenCoordinates(screenX: number, screenY: number) {
    this.screenX = screenX
    this.screenY = screenY
  }

  private buildWindowParams() {
    return `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
          width=${this.DEFAULT_SCREEN_WIDTH},height=${this.DEFAULT_SCREEN_HEIGHT},
          screenX=${this.screenX},screenY=${this.screenY},target=_self`
  }

  openRelatedDocumentsWindow(invoiceId: number, type: string) {
    const params = this.buildWindowParams()
    this.popup = open(
      `${this.popupBaseUrl}/${invoiceId}/${type}`,
      'Related Documents',
      params
    )

    if (this.popup) {
      const { screenX, screenY } = this.popup || {}

      if (screenX && screenY) {
        this.updateScreenCoordinates(screenX, screenY)
      }

      this.popup.onunload = event => {
        const { screenX, screenY } = event.composedPath?.()[0] as Window
        this.updateScreenCoordinates(screenX, screenY)
      }
    }
  }
}
