import { HttpErrorResponse } from '@angular/common/http'
import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { InvoiceForm } from '../../../domain/invoices/invoice-form.model'
import { Invoice } from '../../../domain/invoices/invoice.model'

export const operationsActions = createActionGroup({
  source: 'Invoices Operations',
  events: {
    'send email notification':
      props<Pick<InvoiceForm, 'emailNotification' | 'id'>>(),
    'send email notification success': emptyProps(),
    'send email notification fail': props<{ error: HttpErrorResponse }>(),

    're audit': props<{ invoiceId: number }>(),
    're audit success': props<{
      invoiceId: number
    }>(),
    're audit fail': emptyProps(),
    resetReAuditLoadingState: emptyProps(),

    're audit multiple invoices': props<{ invoiceIds: number[] }>(),
    're audit multiple invoices success': props<{
      allFinished: boolean
      failedInvoices: number
    }>(),
    're audit multiple invoices fail': props<{ error: string }>(),
    resetReAuditMultipleInvoicesLoadingState: emptyProps(),

    'approve invoice': props<{
      invoiceId: number
      invoiceUuid: string
      useLegacy: boolean
    }>(),
    'approve invoice success': props<{
      invoiceId: number
    }>(),
    'approve invoice fail': props<{ error: string }>(),
    resetApproveInvoiceLoadingState: emptyProps(),

    'terminate invoice': props<
      Pick<InvoiceForm, 'terminateInvoice' | 'id' | 'uuid'> & {
        useLegacy: boolean
      }
    >(),
    'terminate invoice success': props<{
      invoiceUuid: string
    }>(),
    'terminate invoice fail': props<{ error: string }>(),
    resetTerminateInvoiceLoadingState: emptyProps(),

    'terminate multiple invoices': props<
      Pick<InvoiceForm, 'terminateInvoice'> & {
        invoices: { id: number; uuid: string }[]
        useLegacy: boolean
      }
    >(),
    'terminate multiple invoices success': props<{
      allFinished: boolean
      failedInvoices: number
    }>(),
    'terminate multiple invoices fail': props<{ error: string }>(),
    resetTerminateMultipleInvoicesLoadingState: emptyProps(),

    approveMultipleInvoices: props<{
      invoices: { id: number; uuid: string }[]
      useLegacy: boolean
    }>(),
    approveMultipleInvoicesSuccess: emptyProps(),
    approveMultipleInvoicesPartialFail: props<{
      failedApprovals: { invoiceUuid: string; reason: string }[]
    }>(),
    approveMultipleInvoicesFail: props<{
      error: unknown
    }>(),
    resetApproveMultipleInvoicesLoadingState: emptyProps(),

    'download invoices': emptyProps(),
    'download invoices success': props<{
      filename: string
      blob: Blob
    }>(),
    'download invoices fail': props<{ error: unknown }>(),

    'regenerate invoice': props<{
      invoiceId: number
    }>(),
    'regenerate invoice success': props<{
      invoiceId: number
      previousInvoiceId: number
    }>(),
    'regenerate invoice fail': props<{ error: string }>(),
    resetRegenerateInvoiceLoadingState: emptyProps(),

    'navigate to next invoice': props<{
      requireToLoadList: boolean
      currentInvoiceIndex: number
      nextInvoice?: Invoice
    }>(),
    'navigate to previous invoice': emptyProps()
  }
})
