import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { SendEmailNotificationRequest } from '../../domain/invoices/send-email-notification.request'

export class ToSendEmailNotificationRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'emailNotification' | 'id'>,
      SendEmailNotificationRequest
    >
{
  convert(
    request: Pick<InvoiceForm, 'emailNotification' | 'id'>
  ): SendEmailNotificationRequest {
    return {
      vendorInvoiceId: Number(request.id),
      dynamicBody: request.emailNotification.body,
      from: request.emailNotification.from,
      receivers: request.emailNotification.to.map(receiver => ({
        email: receiver
      })),
      subject: request.emailNotification.subject,
      ccEmails: request.emailNotification.cc,
      vendorInvoiceDocuments: request.emailNotification.attachedDocumentIds
    }
  }
}
