import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, filter, firstValueFrom, map, switchMap } from 'rxjs'
import { InvoicesFacade } from '../+state/invoices/invoices.facade'
import {
  DataFilters,
  StatusFilter
} from '../domain/invoices/data-filters.model'
import { ABSOLUTE_FEATURE_URL } from '@navix/utils/tokens'
import { Invoice } from '../domain/invoices/invoice.model'
import { LoadingStatuses } from '@navix/shared/loading'
import { CurrentUserFacade } from '@navix/current-user/domain'

/**
 * @use To prevent issues injecting the ABSOLUTE_FEATURE_URL provide the service into the component that will inject providers.
 */
@Injectable()
export class OrdersNavigationService {
  private readonly router = inject(Router)
  private readonly invoicesFacade = inject(InvoicesFacade)
  private readonly currentUserFacade = inject(CurrentUserFacade)
  private readonly absoluteFeatureUrl = inject(ABSOLUTE_FEATURE_URL)

  async nextOrderCentricInvoice() {
    await this.navigateOrderCentricInvoice('next')
  }

  async nextOrderCentricInvoiceAfterApproveOrTerminate() {
    await this.navigateOrderCentricInvoice('next', true)
  }

  async previousOrderCentricInvoice() {
    await this.navigateOrderCentricInvoice('previous')
  }

  async navigateOrderCentricInvoice(
    direction: 'next' | 'previous',
    isApproveOrTerminate = false
  ) {
    const filters = await firstValueFrom(this.invoicesFacade.filters$)
    const currentInvoice = await firstValueFrom(
      this.invoicesFacade.selectedInvoice$
    )

    let nextInvoice = await this.getInvoice(
      direction,
      this.invoicesFacade.allInvoices$,
      currentInvoice.id,
      filters
    )

    if (nextInvoice === null)
      nextInvoice = await this.getNextInvoiceFromNewPage(
        direction,
        currentInvoice,
        filters,
        isApproveOrTerminate
      )

    this.navigatoToOrderCentricInvoice(nextInvoice, filters.status)
  }

  private navigatoToOrderCentricInvoice(
    nextInvoice: Invoice | null,
    currentStatus: StatusFilter
  ) {
    const isComingFromDispute =
      currentStatus === StatusFilter.disputesUnmatched ||
      currentStatus === StatusFilter.disputesMatched

    if (nextInvoice !== null) {
      isComingFromDispute
        ? this.navigateOrderCentricDisputesIfMatched(nextInvoice)
        : this.navigateOrderCentricDetailIfMatched(nextInvoice)
    } else {
      this.router.navigate([
        this.absoluteFeatureUrl,
        'list',
        this.convertToValidRoute(currentStatus)
      ])
    }
  }

  private async getNextInvoiceFromNewPage(
    direction: 'next' | 'previous',
    currentInvoice: Invoice,
    filters: DataFilters,
    isApproveOrTerminate: boolean
  ) {
    const invoices = await firstValueFrom(this.invoicesFacade.allInvoices$)
    const basePage = filters.page
    const shouldLoadNextPage = invoices.length >= filters.itemsPerPage
    const newPage = shouldLoadNextPage
      ? this.getNewPage(direction, basePage)
      : basePage

    this.invoicesFacade.patchListFilters({
      ...filters,
      page: newPage
    })
    this.invoicesFacade.upsertInvoicesWithNavigation({
      ...filters,
      page: newPage
    })

    return await this.getInvoice(
      direction,
      this.invoicesFacade.loading$.pipe(
        map(loadingState => loadingState.getAll),
        filter(
          loadingState => loadingState.status === LoadingStatuses.Completed
        ),
        switchMap(() => this.invoicesFacade.allInvoices$)
      ),
      currentInvoice.id,
      filters,
      true,
      isApproveOrTerminate
    )
  }

  private getNewPage(direction: string, basePage: number) {
    switch (direction) {
      case 'next':
        return basePage + 1
      case 'previous':
        return basePage > 1 ? basePage - 1 : 1
      default:
        return 1
    }
  }

  getUnownedIndex = (
    index: number,
    indexes: number[],
    navigator: number
  ): number => {
    const isIncluded = [...indexes].includes(index)
    return isIncluded
      ? this.getUnownedIndex(index + navigator, indexes, navigator)
      : index
  }

  private async getInvoice(
    navigation: 'next' | 'previous',
    invoices: Observable<Invoice[]>,
    invoiceId: number,
    filters: DataFilters,
    isLoadingNewPage = false,
    isApproveOrTerminate = false
  ): Promise<Invoice | null> {
    const currentUserOwnerEmail = this.currentUserFacade.userProfile()?.email
    const navigator = navigation === 'next' ? 1 : -1
    const invoicesArray = await firstValueFrom(invoices)
    const newListOfInvoices = [...invoicesArray].slice(
      0,
      isApproveOrTerminate ? filters.itemsPerPage : filters.itemsPerPage
    )

    const invoicesArrayNeedsTobeSorted =
      isLoadingNewPage && navigation === 'next'

    const newInvoicesArray = invoicesArrayNeedsTobeSorted
      ? [...invoicesArray]
          .slice(
            isApproveOrTerminate ? filters.itemsPerPage : filters.itemsPerPage
          )
          .concat(newListOfInvoices)
      : invoicesArray

    if (invoicesArrayNeedsTobeSorted)
      this.invoicesFacade.reOrderInvoicesList(newInvoicesArray)

    const ownedInvoicesIndexes = [...newInvoicesArray]
      .map((x, i) =>
        x.ownerUserId && x.ownerUserEmail !== currentUserOwnerEmail ? i : -1
      )
      .filter(x => x >= 0)
    const invoiceIndex = newInvoicesArray.findIndex(
      ({ id }) => id === invoiceId
    )
    const calculatedIndex = invoiceIndex + navigator

    const unownedIndex = this.getUnownedIndex(
      calculatedIndex,
      ownedInvoicesIndexes,
      navigator
    )
    return newInvoicesArray[unownedIndex] ?? null
  }

  navigateOrderCentricDetailIfMatched(invoice: Invoice) {
    this.invoicesFacade.loadInvoiceDetails(invoice.id)
    firstValueFrom(
      this.invoicesFacade.allInvoices$.pipe(
        map(invoices => invoices.find(i => i.id === invoice.id)),
        filter(invoice => invoice?.ordersIds !== undefined),
        map(invoice => ({
          orderIds: invoice!.ordersIds ?? [],
          isMatched: invoice!.ordersIds?.length === 1
        })),
        map(({ isMatched, orderIds }) => {
          const invoiceDetailsRoute = [
            this.absoluteFeatureUrl,
            'details',
            invoice.id
          ]
          const orderCentricDetailsRoute = [
            this.absoluteFeatureUrl,
            'order-centric',
            `${orderIds[0]?.id}_${orderIds[0]?.legacyId}`,
            {
              outlets: {
                audit: [
                  'vendor',
                  invoice.vendorId ?? 0,
                  'invoice',
                  invoice.id,
                  'details'
                ]
              }
            }
          ]
          return isMatched ? orderCentricDetailsRoute : invoiceDetailsRoute
        })
      )
    ).then(redirectionCommands => {
      this.router.navigate(redirectionCommands)
    })
  }

  navigateOrderCentricDisputesIfMatched(invoice: Invoice) {
    this.invoicesFacade.loadInvoiceDetails(invoice.id)
    firstValueFrom(
      this.invoicesFacade.allInvoices$.pipe(
        map(invoices => invoices.find(i => i.id === invoice.id)),
        filter(invoice => invoice?.ordersIds !== undefined),
        map(invoice => ({
          orderIds: invoice!.ordersIds ?? [],
          isMatched: invoice!.ordersIds?.length === 1
        })),
        map(({ isMatched, orderIds }) => {
          const invoiceDisputesRoute = [
            this.absoluteFeatureUrl,
            'details',
            invoice.id,
            'disputes'
          ]
          const orderCentricDisputesRoute = [
            this.absoluteFeatureUrl,
            'order-centric',
            `${orderIds[0]?.id}_${orderIds[0]?.legacyId}`,
            {
              outlets: {
                audit: [
                  'vendor',
                  invoice.vendorId ?? 0,
                  'invoice',
                  invoice.id,
                  'disputes'
                ]
              }
            }
          ]
          return isMatched ? orderCentricDisputesRoute : invoiceDisputesRoute
        })
      )
    ).then(redirectionCommands => {
      this.router.navigate(redirectionCommands)
    })
  }

  convertToValidRoute(status: StatusFilter) {
    switch (status) {
      case StatusFilter.auditMatched:
        return 'audit-matched'
      case StatusFilter.disputesMatched:
        return 'disputes-matched'
      case StatusFilter.disputesUnmatched:
        return 'disputes-unmatched'
      case StatusFilter.readyToApprove:
        return 'ready-to-approve'
      default:
        return StatusFilter[status]
    }
  }
}
