import {
  APPROVED_STATUS_QUEUE,
  AUDIT_STATUS_QUEUE,
  DISPUTES_MATCHED_STATUS_QUEUE,
  DISPUTES_UNMATCHED_STATUS_QUEUE,
  DRAFT_STATUS_QUEUE,
  DUPLICATES_STATUS_QUEUE,
  READY_TO_APPROVE_STATUS_QUEUE,
  TERMINATED_STATUS_QUEUE,
  UNMATCHED_STATUS_QUEUE
} from '../+state/invoices/static/known-invoice-status-queue'
import {
  DUPLICATE_UNPROCESSED_STATUS,
  DUPLICATE_PROCESSED_STATUS,
  DISPUTED_STATUS
} from '../+state/invoices/static/known-invoice-statuses'

export const getStaticInvoiceStatusesQueue = () => [
  DUPLICATES_STATUS_QUEUE,
  UNMATCHED_STATUS_QUEUE,
  AUDIT_STATUS_QUEUE,
  READY_TO_APPROVE_STATUS_QUEUE,
  DISPUTES_UNMATCHED_STATUS_QUEUE,
  DISPUTES_MATCHED_STATUS_QUEUE,
  APPROVED_STATUS_QUEUE,
  TERMINATED_STATUS_QUEUE,
  DRAFT_STATUS_QUEUE
]

export const getAllStaticInvoiceStatuses = () => [
  ...getStaticInvoiceStatusesQueue(),
  DUPLICATE_UNPROCESSED_STATUS,
  DUPLICATE_PROCESSED_STATUS,
  DISPUTED_STATUS
]
