import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Audit } from '../../../domain/audits/audit.model'
import { platform_v2 } from '@navix/api-models'

export const loadAuditActions = createActionGroup({
  source: 'Audit - Load',
  events: {
    loadAudit: props<{ orderId: Audit['orderId'] }>(),
    loadAuditSuccess: props<{
      audit: Audit
      originalDocument: platform_v2.Navix_Services_Sdk_Audit
    }>(),
    loadAuditFail: props<{ error: unknown }>(),
    resetGetAuditLoadingState: emptyProps()
  }
})
