import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable, Self, inject } from '@angular/core'
import { Observable } from 'rxjs'

import { OdataParser, QUERY_PARSER } from '@navix/utils/query-parser'
import {
  API_URL,
  API_URL_V1_1_VERSION,
  API_URL_V1_2_VERSION,
  API_URL_V2_VERSION,
  HEADER_API_KEY
} from '@navix/utils/tokens'
import { AddInvoiceDefaultChargesRequest } from '../domain/invoices/add-invoice-default-charges.request'
import { AddInvoiceDocumentRequest } from '../domain/invoices/add-invoice-document.request'
import { AddInvoiceLineItemsRequest } from '../domain/invoices/add-invoice-line-items.request'
import { AddInvoiceNotesRequest } from '../domain/invoices/add-invoice-notes.request'
import { AddInvoiceRequest } from '../domain/invoices/add-invoice.request'
import { AddInvoiceResponse } from '../domain/invoices/add-invoice.response'
import {
  DataFilters,
  StatusFilter
} from '../domain/invoices/data-filters.model'
import { GetCustomerAdjustedChargesResponse } from '../domain/invoices/get-customer-adjusted-charges.response'
import { GetCustomerOrderChargesNewResponse } from '../domain/invoices/get-customer-order-charges-new.response'
import {
  GetDisputeReasonsResponse,
  GetDisputedReasonsResponse
} from '../domain/invoices/get-disputed-reasons.response'
import { GetEmailTemplateResponse } from '../domain/invoices/get-email-template.response'
import {
  GetEmailTemplateTypesResponse,
  GetEmailTemplatesResponse
} from '../domain/invoices/get-email-template-types.response'
import { GetInvoiceAuditChargesResponse } from '../domain/invoices/get-invoice-audit-charges.response'
import { GetInvoiceAuditHistoryResponse } from '../domain/invoices/get-invoice-audit-history.response'
import { GetInvoiceChargesNewResponse } from '../domain/invoices/get-invoice-charges-new.response'
import { GetInvoiceChargesResponse } from '../domain/invoices/get-invoice-charges.response'
import { GetInvoiceCustomerAdjustedChargesNewResponse } from '../domain/invoices/get-invoice-customer-adjusted-charges-new.response'
import { GetInvoiceCustomerAdjustedChargesResponse } from '../domain/invoices/get-invoice-customer-adjusted-charges.response'
import { GetInvoiceCustomerAuditChargesNewResponse } from '../domain/invoices/get-invoice-customer-audit-charges-new.response'
import { GetInvoiceCustomerAuditChargesResponse } from '../domain/invoices/get-invoice-customer-audit-charges.response'
import { GetInvoiceCustomerOrderChargesResponse } from '../domain/invoices/get-invoice-customer-order-charges.response'
import { GetInvoiceDetailsResponse } from '../domain/invoices/get-invoice-details.response'
import { GetInvoiceDocumentTypesResponse } from '../domain/invoices/get-invoice-document-types.response'
import { GetInvoiceDocumentsResponse } from '../domain/invoices/get-invoice-documents.response'
import { GetInvoiceExceptionsResponse } from '../domain/invoices/get-invoice-exceptions.response'
import { GetVendorInvoiceNotesResponse } from '../domain/invoices/get-invoice-notes.response'

import { GetLineItemsResponse } from '../domain/invoices/get-line-items.response'
import { GetOrderChargesResponse } from '../domain/invoices/get-order-charges.response'
import { GetReferenceNumberTypesResponse } from '../domain/invoices/get-reference-number-types.response'
import { GetReferenceNumbersResponse } from '../domain/invoices/get-reference-numbers.response'
import { GetRelatedDocumentsResponse } from '../domain/invoices/get-related-documents.response'
import { GetTerminateReasonsResponse } from '../domain/invoices/get-terminate-reasons.response'
import { GetTypesOfExceptionsResponse } from '../domain/invoices/get-types-of-exceptions.response'
import { GetVarianceReasonsResponse } from '../domain/invoices/get-variance-reasons.response'
import { GetVendorInvoiceTypesResponse } from '../domain/invoices/get-vendor-invoice-types.response'
import { SendEmailNotificationRequest } from '../domain/invoices/send-email-notification.request'
import { TerminateInvoiceRequest } from '../domain/invoices/terminate-invoice.request'
import { UpdateCurrencyRequest } from '../domain/invoices/update-currency.request'
import { UpdateInvoiceCustomerRequest } from '../domain/invoices/update-customer.request'
import { UpdateFreightChargeTermsRequest } from '../domain/invoices/update-freight-charge-terms.request'
import { UpdateInvoiceVendorDatesRequest } from '../domain/invoices/update-invocie-vendor-dates.request'
import { UpdateInvoiceCustomerChargesBatchRequest } from '../domain/invoices/update-invoice-customer-charges-batch.request'
import { UpdateInvoiceDocumentsTypeRequest } from '../domain/invoices/update-invoice-documents-type.request'
import { UpdateInvoiceLineItemsRequest } from '../domain/invoices/update-invoice-line-items.request'
import { UpdateInvoiceNotesRequest } from '../domain/invoices/update-invoice-notes.request'
import { UpdateInvoiceOtherDetailsRequest } from '../domain/invoices/update-invoice-other-details.request'
import { UpdateInvoiceReferenceNumbersRequest } from '../domain/invoices/update-invoice-reference-numbers.request'
import { UpdateInvoiceToDuplicateStatusRequest } from '../domain/invoices/update-invoice-to-duplicate-status.request'
import { UpdateInvoiceToTerminateStatusRequest } from '../domain/invoices/update-invoice-to-terminate-status.request'
import { UpdateInvoiceVendorChargesBatchRequest } from '../domain/invoices/update-invoice-vendor-charges-batch.request'
import { UpdateInvoiceVendorStopsRequest } from '../domain/invoices/update-invoice-vendor-stops.request'
import { UpdateVendorRequest } from '../domain/invoices/update-vendor.request'
import { MapInvoiceVendorChargeRequest } from '../domain/invoices/map-invoice-vendor-charge.request'
import { MapOrderChargeRequest } from '../domain/invoices/map-order-charge.request'
import { UpdateInvoiceCustomerAdjustedChargeRequest } from '../domain/invoices/update-invoice-customer-adjusted-charges.request'
import { AddVendorAdjustedChargeRequest } from '../domain/invoices/add-vendor-adjusted-charge.request'
import { UpdateInvoiceOwnershipRequest } from '../domain/invoices/update-invoice-ownership.request'

import { UpdateInvoiceCustomerOrderChargeRequest } from '../domain/invoices/update-invoice-customer-order-charges.request'
import { GetInvoiceTenantResponse } from '../domain/invoices/get-invoice-tenant.response'
import { GetInvoicesRestResponse } from '../domain/invoices/get-invoices-rest.response'
import {
  HAS_NO_EXCEPTIONS_FILTER_OPTION,
  KnownInvoicesSortBy,
  NONE_DATE_FILTER_OPTION
} from '../+state/invoices/static'
import { GetInvoiceDisputesResponse } from '../domain/invoices/get-invoice-disputes.response'
import { AddDisputeReasonRequest } from '../domain/invoices/add-dispute-reasons.request'
import { AddInvoiceDisputesRequest } from '../domain/invoices/add-invoice-disputes.request'
import { AddReplyCommunicationRequest } from '../domain/invoices/add-reply-communication.request'
import { UpdateInvoiceDocumentRequest } from '../domain/invoices/update-invoice-document.request'
import { ManageLineItemsRequest } from '../domain/invoices/manage-line-items.request'
import { GetWorkInstructionsPhase2Response } from '../domain/invoices/get-work-instructions-phase-2.response'
import { KnownInvoiceStatusesQueues } from '../+state/invoices/static/known-invoice-status-queue'
import { UpdateTagsRequest } from '../domain/invoices/update-tags-request'
import { UpdateTagsResponse } from '../domain/invoices/update-tags-response'

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  private readonly _http = inject(HttpClient)
  private readonly apiUrl = inject(API_URL)
  private readonly apiV1_1Url = inject(API_URL_V1_1_VERSION)
  private readonly apiV1_2Url = inject(API_URL_V1_2_VERSION)
  private readonly apiV2Url = inject(API_URL_V2_VERSION)
  private readonly apiKey = inject(HEADER_API_KEY)
  @Self() private readonly parser = inject<OdataParser>(QUERY_PARSER)

  private readonly apiInvoicesUrl = `${this.apiUrl}invoices/` as const
  private readonly apiInvoicesNewUrl = `${this.apiV1_1Url}invoices/` as const
  private readonly apiInvoices1_2 = `${this.apiV1_2Url}invoices/` as const
  private readonly apiInvoice2Url = `${this.apiV2Url}invoices/` as const
  private readonly apiOrdersUrl = `${this.apiUrl}orders/` as const
  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }

  getInvoicesRest(filters: DataFilters): Observable<GetInvoicesRestResponse> {
    return this._http.get<GetInvoicesRestResponse>(
      `${this.apiInvoices1_2}dashboard-overview`,
      {
        params: this.getDashboardFilterParams(filters),
        headers: this.headersWithApiKey
      }
    )
  }

  getCountInvoices(filters: DataFilters): Observable<number> {
    return this._http.get<number>(`${this.apiInvoicesUrl}count`, {
      params: this.getDashboardFilterParams(filters),
      headers: this.headersWithApiKey
    })
  }

  private getDashboardFilterParams(filters: DataFilters) {
    const invoiceStatus = this.getInvoiceStatus(filters)
    const queryFilters: Record<
      string,
      string | number | boolean | ReadonlyArray<string | number | boolean>
    > = {
      // non nullable filters
      SortBy:
        KnownInvoicesSortBy[
          filters.sortBy === KnownInvoicesSortBy.VendorInvoiceStatusDescription
            ? KnownInvoicesSortBy.CreatedDate
            : filters.sortBy!
        ],
      SortOrder: filters.sortDirection!,
      IncludeAllReferenceNumbers:
        invoiceStatus === StatusFilter.unmatched ? true : false,
      PageNumber: filters.page!,
      PageSize: filters.itemsPerPage!,
      // collections filters
      CustomerIds: filters.customers?.map(x => x.id) ?? [],
      VendorIds: filters.vendors?.map(x => x.id) ?? [],
      ExceptionCategoryIds:
        filters.typesOfExceptions
          ?.map(x => x.id)
          .filter(x => !isNaN(Number(x))) ?? [],
      DisputeReasonIds: filters.disputedReasons?.map(x => x.id) ?? [],
      DisputeReasons:
        filters.disputeReasonsV2
          ?.map(x => {
            const regex = /^.*?\s*-\s*(.+)$/
            const match = x.label.match(regex)
            return match ? match[1].trim() : x.label
          })
          .filter((value, index, self) => self.indexOf(value) === index) ?? [],
      Parties: filters.parties?.map(x => x.id) ?? [],
      InvoiceFormatIds: filters.sources?.map(x => x.id) ?? [],
      OwnerUserUuids: filters.users?.map(x => x.id) ?? [],
      IsUnowned: filters.useUnownedUsers ?? false,
      hasNoExceptions:
        (filters.hasNoExceptions ?? false) ||
        filters.typesOfExceptions?.find(
          x => x.id === HAS_NO_EXCEPTIONS_FILTER_OPTION.id
        ) !== undefined,
      //Search filters
      SearchBy: filters.search?.trim() ?? '',
      ReturnVendorNetCharge: filters.returnVendorNetCharge ?? false,
      Divisions: filters.divisions?.map(x => x.label) ?? [],
      VendorInvoiceStatusDescriptionIds:
        this.getVendorInvoiceStatusDescriptionIds(invoiceStatus, filters),
      StatusQueues: this.getStatusQueues(invoiceStatus, filters),
      RemitTo: filters.remitTo ?? ''
    }

    const dateFilter = filters.dateRangeTypes
      ?.filter(filter => filter.id !== NONE_DATE_FILTER_OPTION.id)
      .at(0)?.id

    if (dateFilter) {
      queryFilters['DateFilterBy'] = dateFilter
      if (filters.from !== undefined)
        queryFilters['DateFilterFrom'] = filters.from
      if (filters.to !== undefined) queryFilters['DateFilterTo'] = filters.to
    }

    if (filters.parenteInvoiceId !== undefined)
      queryFilters['ParentInvoiceId'] = filters.parenteInvoiceId

    const params = new HttpParams({
      fromObject: queryFilters
    })
    return params
  }

  private getVendorInvoiceStatusDescriptionIds(
    invoiceStatus: number,
    filters: DataFilters
  ) {
    return invoiceStatus === StatusFilter.all
      ? filters.statuses
          ?.filter(
            status =>
              status.id === KnownInvoiceStatusesQueues.Draft ||
              status.id === KnownInvoiceStatusesQueues.Ready
          )
          .map(x => {
            if (x.id === KnownInvoiceStatusesQueues.Draft) return 1
            return 2
          }) ?? []
      : []
  }

  private getStatusQueues(invoiceStatus: number, filters: DataFilters) {
    return invoiceStatus === StatusFilter.all
      ? filters.statuses
          ?.filter(
            status =>
              status.id !== KnownInvoiceStatusesQueues.Draft &&
              status.id !== KnownInvoiceStatusesQueues.Ready
          )
          .map(x => KnownInvoiceStatusesQueues[x.id as number]) ?? []
      : StatusFilter[
          filters.status === StatusFilter.auditMatched
            ? StatusFilter.audit
            : filters.status
        ]
  }

  private getInvoiceStatus(filters: DataFilters) {
    switch (filters.status) {
      case StatusFilter.disputesMatched:
        return StatusFilter.disputes
      case StatusFilter.disputesUnmatched:
        return StatusFilter.disputes
      case StatusFilter.unmatched:
        return StatusFilter.unmatched
      case StatusFilter.readyToApprove:
      case StatusFilter.auditMatched:
        return StatusFilter.audit
      default:
        return filters.status
    }
  }

  getTypesOfExceptions(): Observable<GetTypesOfExceptionsResponse> {
    return this._http.get<GetTypesOfExceptionsResponse>(
      `${this.apiInvoicesUrl}exceptions/categories`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getDisputedReasons(): Observable<GetDisputedReasonsResponse> {
    return this._http.get<GetDisputedReasonsResponse>(
      `${this.apiInvoicesUrl}vendor-invoice-statuses/disputed-reasons`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getCustomerAdjustedCharges(
    invoiceId: number
  ): Observable<GetCustomerAdjustedChargesResponse> {
    return this._http.get<GetCustomerAdjustedChargesResponse>(
      `${this.apiInvoicesUrl}customer-adjusted-charges?invoiceId=${invoiceId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getVarianceReasons(): Observable<GetVarianceReasonsResponse> {
    return this._http.get<GetVarianceReasonsResponse>(
      `${this.apiInvoicesUrl}vendor-invoice-charges/variance-reasons`,
      {
        headers: this.headersWithApiKey
      }
    )
  }
  getLineItems(invoiceId: number): Observable<GetLineItemsResponse> {
    return this._http.get<GetLineItemsResponse>(
      `${this.apiInvoicesUrl}${invoiceId}/line-items?odataLineItems=$orderby=Class,Weight`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getReferenceNumbers(
    invoiceId: number
  ): Observable<GetReferenceNumbersResponse> {
    return this._http.get<GetReferenceNumbersResponse>(
      `${this.apiInvoicesUrl}${invoiceId}/reference-numbers`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getReferenceNumberTypes(): Observable<GetReferenceNumberTypesResponse> {
    return this._http.get<GetReferenceNumberTypesResponse>(
      `${this.apiInvoicesUrl}reference-number-types`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateVendor(request: UpdateVendorRequest): Observable<void> {
    return this._http.post<void>(
      `${this.apiInvoicesNewUrl}update-vendor`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getInvoiceDetails(invoiceId: number): Observable<GetInvoiceDetailsResponse> {
    return this._http.get<GetInvoiceDetailsResponse>(
      `${this.apiInvoices1_2}${invoiceId}/detail`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addTags(
    request: UpdateTagsRequest,
    invoiceId: number
  ): Observable<UpdateTagsResponse> {
    return this._http.patch<UpdateTagsResponse>(
      `${this.apiInvoices1_2}${invoiceId}/tags`,
      request.tag,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  removeTags(
    request: UpdateTagsRequest,
    invoiceId: number
  ): Observable<UpdateTagsResponse> {
    return this._http.delete<UpdateTagsResponse>(
      `${this.apiInvoices1_2}${invoiceId}/tags`,
      {
        headers: this.headersWithApiKey,
        body: request.tag
      }
    )
  }

  updateFreightChargeTerms(
    request: UpdateFreightChargeTermsRequest
  ): Observable<void> {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}update-freight-charge-terms`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceVendorDates(
    request: UpdateInvoiceVendorDatesRequest
  ): Observable<void> {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}${request.vendorInvoiceId}/dates`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getInvoiceExceptions(
    invoiceId: number
  ): Observable<GetInvoiceExceptionsResponse> {
    return this._http.get<GetInvoiceExceptionsResponse>(
      `${this.apiInvoicesUrl}exceptions/${invoiceId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceVendorStops(
    request: UpdateInvoiceVendorStopsRequest
  ): Observable<void> {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}vendor-invoice-stops/${request.invoiceId}/batch`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  /**
   * @deprecated use  instead getInvoiceChargesNew, getInvoiceAuditCharges, getOrderCharges
   */
  getInvoiceCharges(
    invoiceId: number,
    orderId: number
  ): Observable<GetInvoiceChargesResponse> {
    return this._http.get<GetInvoiceChargesResponse>(
      `${this.apiInvoicesUrl}${invoiceId}/charges`,
      {
        params: {
          orderId
        },
        headers: this.headersWithApiKey
      }
    )
  }

  getInvoiceChargesNew(
    invoiceId: number
  ): Observable<GetInvoiceChargesNewResponse> {
    return this._http.get<GetInvoiceChargesNewResponse>(
      `${this.apiInvoicesUrl}vendor-invoice-charges`,
      {
        params: {
          vendorInvoiceId: invoiceId
        },
        headers: this.headersWithApiKey
      }
    )
  }

  getInvoiceAuditCharges(
    invoiceId: number
  ): Observable<GetInvoiceAuditChargesResponse> {
    return this._http.get<GetInvoiceAuditChargesResponse>(
      `${this.apiInvoicesUrl}audit-charges`,
      {
        params: {
          vendorInvoiceId: invoiceId
        },
        headers: this.headersWithApiKey
      }
    )
  }

  getOrderCharges(
    orderId: number,
    vendorId: number | undefined
  ): Observable<GetOrderChargesResponse> {
    const params:
      | { orderId: number }
      | {
          orderId: number
          vendorId: number
        } = vendorId === undefined ? { orderId } : { orderId, vendorId }
    return this._http.get<GetOrderChargesResponse>(
      `${this.apiInvoicesUrl}order-charges`,
      {
        params,
        headers: this.headersWithApiKey
      }
    )
  }

  //todo: should live under orders domain.
  /**
   * @deprecated use getInvoiceCustomerOrderChargesNew instead
   */
  getInvoiceCustomerOrderCharges(
    orderId: number
  ): Observable<GetInvoiceCustomerOrderChargesResponse> {
    return this._http.get<GetInvoiceCustomerOrderChargesResponse>(
      `${this.apiUrl}orders/customer-order-charges`,
      {
        params: {
          orderId
        },
        headers: this.headersWithApiKey
      }
    )
  }

  /**
   * @deprecated use getInvoiceCustomerAuditChargesNew instead
   */
  getInvoiceCustomerAuditCharges(
    invoiceId: number
  ): Observable<GetInvoiceCustomerAuditChargesResponse> {
    return this._http.get<GetInvoiceCustomerAuditChargesResponse>(
      `${this.apiInvoicesUrl}customer-audit-charges`,
      {
        params: {
          vendorInvoiceId: invoiceId
        },
        headers: this.headersWithApiKey
      }
    )
  }
  /**
   * @deprecated use getInvoiceCustomerAdjustedChargesNew instead
   */
  getInvoiceCustomerAdjustedCharges(
    invoiceId: number
  ): Observable<GetInvoiceCustomerAdjustedChargesResponse> {
    return this._http.get<GetInvoiceCustomerAdjustedChargesResponse>(
      `${this.apiInvoicesUrl}customer-adjusted-charges`,
      {
        params: {
          vendorInvoiceId: invoiceId
        },
        headers: this.headersWithApiKey
      }
    )
  }

  getCustomerOrderChargesNew(
    orderId: number
  ): Observable<GetCustomerOrderChargesNewResponse> {
    return this._http.get<GetCustomerOrderChargesNewResponse>(
      `${this.apiUrl}orders/customer-order-charges/${orderId}/new`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getInvoiceCustomerAuditChargesNew(
    invoiceId: number
  ): Observable<GetInvoiceCustomerAuditChargesNewResponse> {
    return this._http.get<GetInvoiceCustomerAuditChargesNewResponse>(
      `${this.apiInvoicesUrl}customer-audit-charges/${invoiceId}/new`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getInvoiceCustomerAdjustedChargesNew(
    invoiceId: number
  ): Observable<GetInvoiceCustomerAdjustedChargesNewResponse> {
    return this._http.get<GetInvoiceCustomerAdjustedChargesNewResponse>(
      `${this.apiInvoicesUrl}customer-adjusted-charges/${invoiceId}/new`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addLineItems(request: AddInvoiceLineItemsRequest) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${request.vendorInvoiceId}/line-items`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateLineItems(request: UpdateInvoiceLineItemsRequest) {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}${request.vendorInvoiceId}/line-items`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceVendorChargesBatch(
    request: UpdateInvoiceVendorChargesBatchRequest
  ): Observable<void> {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}${request.invoiceId}/vendor-invoice-charges/batch/new`,
      request.charges,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  mapInvoiceVendorCharge(
    request: MapInvoiceVendorChargeRequest
  ): Observable<void> {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}${request.invoiceId}/vendor-invoice-charges`,
      request.charge,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  mapOrderCharge(request: MapOrderChargeRequest): Observable<void> {
    return this._http.put<void>(
      `${this.apiOrdersUrl}${request.orderId}/charges`,
      request.charge,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceCustomerAdjustedCharge(
    request: UpdateInvoiceCustomerAdjustedChargeRequest
  ): Observable<void> {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}customer-adjusted-charges`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }
  updateInvoiceCustomerChargesBatch(
    request: UpdateInvoiceCustomerChargesBatchRequest
  ): Observable<void> {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}customer-adjusted-charges/batch`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  deleteLineItems(invoiceId: number, lineItemId: number) {
    return this._http.delete<void>(
      `${this.apiInvoicesUrl}${invoiceId}/line-items/${lineItemId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getInvoiceNotes(
    invoiceId: number,
    filters?: DataFilters
  ): Observable<GetVendorInvoiceNotesResponse> {
    const query = this.parser.prepare('odataVendorInvoiceNotes')

    if (filters?.trueFlags)
      filters.falseFlags?.forEach(flag => query.setEqualityFilter(flag, true))

    if (filters?.falseFlags)
      filters.falseFlags?.forEach(flag => query.setEqualityFilter(flag, false))

    return this._http.get<GetVendorInvoiceNotesResponse>(
      `${this.apiInvoicesUrl}${invoiceId}/vendor-invoice-notes?odataVendorInvoiceNotes=$orderby=CreateDate%20desc`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceNotesMarkAsComplete(invoiceId: number): Observable<void> {
    return this._http.patch<void>(
      `${this.apiInvoicesUrl}${invoiceId}/notes-mark-as-complete`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addInvoiceNotes(request: AddInvoiceNotesRequest) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${request.vendorInvoiceId}/note`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceNotes(request: UpdateInvoiceNotesRequest) {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}${request.vendorInvoiceId}/note`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  deleteNotes(noteId: number) {
    return this._http.delete<void>(`${this.apiInvoicesUrl}${noteId}`, {
      headers: this.headersWithApiKey
    })
  }

  getVendorInvoiceAuditHistory(
    invoiceId: number
  ): Observable<GetInvoiceAuditHistoryResponse> {
    return this._http.get<GetInvoiceAuditHistoryResponse>(
      `${this.apiInvoicesUrl}vendor-invoice-change-logs/${invoiceId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getInvoiceDocuments(
    invoiceId: number
  ): Observable<GetInvoiceDocumentsResponse> {
    return this._http.get<GetInvoiceDocumentsResponse>(
      `${this.apiInvoicesUrl}${invoiceId}/documents`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceReferenceNumbers(
    request: UpdateInvoiceReferenceNumbersRequest
  ): Observable<void> {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}${request.invoiceId}/reference-numbers/batch`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getInvoiceDocumentTypes(): Observable<GetInvoiceDocumentTypesResponse> {
    return this._http.get<GetInvoiceDocumentTypesResponse>(
      `${this.apiInvoicesUrl}document-types`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceDocumentsType(
    request: UpdateInvoiceDocumentsTypeRequest
  ): Observable<void> {
    return this._http.patch<void>(
      `${this.apiInvoicesUrl}${request.invoiceId}/documents/${request.id}/document-type/${request.typeId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceDocument(
    request: UpdateInvoiceDocumentRequest
  ): Observable<void> {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}${request.invoiceId}/documents`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addInvoiceDocument(request: AddInvoiceDocumentRequest): Observable<void> {
    const formData: FormData = new FormData()
    formData.append('Document', request.Document)
    formData.append(
      'VendorInvoiceDocumentTypeId',
      String(request.VendorInvoiceDocumentTypeId)
    )
    if (request.hasSignature != undefined) {
      formData.append('HasSignature', String(request.hasSignature))
    }
    if (request.signatureDate != undefined) {
      formData.append('SignatureDate', String(request.signatureDate))
    }
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${request.invoiceId}/documents`,
      formData,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  deleteDocuments(invoiceId: number, documentId: number) {
    return this._http.delete<void>(
      `${this.apiInvoicesUrl}${invoiceId}/documents/${documentId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  downloadInvoiceDocument(documentId: number) {
    return this._http.get<Blob>(
      `${this.apiInvoicesUrl}documents/${documentId}/download`,
      {
        headers: this.headersWithApiKey,
        observe: 'response',
        responseType: 'blob' as 'json'
      }
    )
  }

  downloadInvoices(filters: DataFilters) {
    const invoiceStatus = this.getInvoiceStatus(filters)
    const queryFilters: Record<
      string,
      string | number | boolean | ReadonlyArray<string | number | boolean>
    > = {
      // non nullable filters
      SortBy: KnownInvoicesSortBy[filters.sortBy!],
      SortOrder: filters.sortDirection!,
      IncludeAllReferenceNumbers:
        invoiceStatus === StatusFilter.unmatched ? true : false,
      PageNumber: filters.page!,
      PageSize: filters.itemsPerPage!,
      // collections filters
      CustomerIds: filters.customers?.map(x => x.id) ?? [],
      VendorIds: filters.vendors?.map(x => x.id) ?? [],
      ExceptionCategoryIds:
        filters.typesOfExceptions
          ?.map(x => x.id)
          .filter(x => !isNaN(Number(x))) ?? [],
      DisputeReasonIds: filters.disputedReasons?.map(x => x.id) ?? [],
      InvoiceFormatIds: filters.sources?.map(x => x.id) ?? [],
      OwnerUserUuids: filters.users?.map(x => x.id) ?? [],
      IsUnowned: filters.useUnownedUsers ?? false,
      hasNoExceptions:
        (filters.hasNoExceptions ?? false) ||
        filters.typesOfExceptions?.find(
          x => x.id === HAS_NO_EXCEPTIONS_FILTER_OPTION.id
        ) !== undefined,
      //Search filters
      SearchBy: filters.search?.trim() ?? '',
      Divisions: filters.divisions?.map(x => x.label) ?? [],
      DisputeReasons:
        filters.disputeReasonsV2
          ?.map(x => x.label.toString().split(' - ')[1])
          .filter((value, index, self) => self.indexOf(value) === index) ?? [],
      Parties: filters.parties?.map(x => x.id) ?? [],
      StatusQueue: this.getStatusQueues(invoiceStatus, filters),
      RemitTo: filters.remitTo ?? ''
      //These searchs are AND operators. If we want to use OR, we need to use the searchBy property
      // ShipmentNumber: filters.search ?? '',
      // InvoiceNumber: filters.search ?? '',
      // OrderNumbers: filters.search ?? ''
    }

    const dateFilter = filters.dateRangeTypes
      ?.filter(filter => filter.id !== NONE_DATE_FILTER_OPTION.id)
      .at(0)?.id

    if (dateFilter) {
      queryFilters['DateFilterBy'] = dateFilter
      if (filters.from !== undefined)
        queryFilters['DateFilterFrom'] = filters.from
      if (filters.to !== undefined) queryFilters['DateFilterTo'] = filters.to
    }

    const params = new HttpParams({
      fromObject: queryFilters
    })

    return this._http.get<Blob>(`${this.apiInvoices1_2}download`, {
      params,
      headers: this.headersWithApiKey,
      observe: 'response',
      responseType: 'blob' as 'json'
    })
  }

  updateCustomer(request: UpdateInvoiceCustomerRequest): Observable<void> {
    return this._http.patch<void>(
      `${this.apiInvoicesNewUrl}${request.invoiceId}/customer`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateOtherDetails(request: UpdateInvoiceOtherDetailsRequest) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}update-other-details`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getEmailTemplateTypes(): Observable<GetEmailTemplateTypesResponse> {
    return this._http.get<GetEmailTemplateTypesResponse>(
      `${this.apiInvoicesUrl}notifications/email-templates`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getEmailTemplate(templateId: number): Observable<GetEmailTemplateResponse> {
    return this._http.get<GetEmailTemplateResponse>(
      `${this.apiInvoicesUrl}notifications/email-templates/${templateId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  sendEmailNotification(request: SendEmailNotificationRequest) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}notifications`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  reAudit(invoiceId: number): Observable<void> {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${invoiceId}/re-audit`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  approveInvoice(invoiceId: number) {
    return this._http.post(
      `${this.apiInvoicesUrl}${invoiceId}/set-status-to-approved`,
      null,
      { headers: this.headersWithApiKey }
    )
  }

  massApproveInvoices(invoiceUuids: string[]) {
    return this._http.post<
      {
        invoiceUuid: string
        approved: boolean
        reason: string
      }[]
    >(`${this.apiInvoice2Url}mass-approve`, invoiceUuids, {
      headers: this.headersWithApiKey
    })
  }

  approveInvoiceV2(invoiceId: string) {
    return this._http.post(
      `${this.apiInvoice2Url}${invoiceId}/statuses/approve`,
      null,
      { headers: this.headersWithApiKey }
    )
  }

  terminateInvoice(request: TerminateInvoiceRequest) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${request.id}/set-status-to-terminate`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  terminateInvoiceV2(request: TerminateInvoiceRequest) {
    return this._http.post<void>(
      `${this.apiInvoice2Url}${request.uuid}/statuses/terminate`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getTerminateReasons(): Observable<GetTerminateReasonsResponse> {
    return this._http.get<GetTerminateReasonsResponse>(
      `${this.apiInvoicesUrl}vendor-invoice-statuses/terminate-reasons`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getVendorInvoiceTypes(): Observable<GetVendorInvoiceTypesResponse> {
    return this._http.get<GetVendorInvoiceTypesResponse>(
      `${this.apiInvoicesUrl}vendor-invoice-types`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceToDuplicateStatus(
    request: UpdateInvoiceToDuplicateStatusRequest
  ) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${request.id}/set-status-to-duplicate-processed`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceToTerminateStatus(
    request: UpdateInvoiceToTerminateStatusRequest
  ) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${request.id}/set-status-to-terminate`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addInvoice(request: AddInvoiceRequest): Observable<AddInvoiceResponse> {
    return this._http.post<AddInvoiceResponse>(
      `${this.apiInvoicesNewUrl}invoices?TenantId=${request.tenantId}`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addInvoiceVendorChargesBatch(request: AddInvoiceDefaultChargesRequest) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${request.invoiceId}/vendor-invoice-charges/batch/new`,
      request.charges,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  setInvoiceStatusToReady(invoiceId: number) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${invoiceId}/set-status-to-ready`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateCurrency(request: UpdateCurrencyRequest): Observable<void> {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}${request.invoiceId}/currency`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  regenerateInvoice(invoiceId: number): Observable<AddInvoiceResponse> {
    return this._http.post<AddInvoiceResponse>(
      `${this.apiInvoicesUrl}${invoiceId}/regenerate`,
      {},
      { headers: this.headersWithApiKey }
    )
  }

  getRelatedDocuments(
    invoiceUuid: string
  ): Observable<GetRelatedDocumentsResponse> {
    return this._http.get<GetRelatedDocumentsResponse>(
      `${this.apiUrl}miscellaneous/related-documents?parentUuid=${invoiceUuid}`,
      { headers: this.headersWithApiKey }
    )
  }

  addVendorAdjustedCharge(request: AddVendorAdjustedChargeRequest) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${request.invoiceId}/vendor-invoice-charges`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceOnwership(request: UpdateInvoiceOwnershipRequest) {
    return this._http.put<void>(
      `${this.apiInvoicesUrl}${request.invoiceUuid}/ownership`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateInvoiceCustomerOrderCharge(
    request: UpdateInvoiceCustomerOrderChargeRequest
  ): Observable<void> {
    return this._http.put<void>(
      `${this.apiUrl}customer-order-charges/${request.customerOrderChargeId}`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  generateDefaultInvoiceDocument(invoiceId: number): Observable<void> {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${invoiceId}/generate`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getInvoiceTenantInfo(
    invoiceId: number
  ): Observable<GetInvoiceTenantResponse> {
    return this._http.get<GetInvoiceTenantResponse>(
      `${this.apiInvoicesUrl}${invoiceId}/tenant`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  mergeInvoiceDocuments(invoiceId: number, documentIds: number[]) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}documents/copy`,
      { vendorInvoiceId: invoiceId, documentIds },
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getDisputes(invoiceUuid: string) {
    return this._http.get<GetInvoiceDisputesResponse>(
      `${this.apiInvoice2Url}${invoiceUuid}/disputes`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getDispute(invoiceUuid: string, disputeId: string) {
    return this._http.get<GetInvoiceDisputesResponse>(
      `${this.apiInvoice2Url}${invoiceUuid}/disputes/${disputeId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  resolveDisputeReasons(
    invoiceUuid: string,
    disputeId: string,
    request: AddDisputeReasonRequest
  ) {
    return this._http.post<void>(
      `${this.apiInvoice2Url}${invoiceUuid}/disputes/${disputeId}/reasons`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addInvoiceDisputes(invoiceUuid: string, request: AddInvoiceDisputesRequest) {
    const formData: FormData = new FormData()
    formData.append('Party', request.party)
    formData.append('Text', request.text)
    formData.append('Subject', request.subject)

    if (request.reasons?.length > 0) {
      for (const reason of request.reasons) {
        formData.append('Reasons', reason)
      }
    }

    if (request.to?.length > 0) {
      for (const to of request.to) {
        formData.append('To', to)
      }
    }

    if (request.cc?.length > 0) {
      for (const cc of request.cc) {
        formData.append('Cc', cc)
      }
    }

    if (request.attachmentUrls?.length) {
      for (const url of request.attachmentUrls) {
        formData.append('AttachmentUrls', url)
      }
    }

    if (request.attachmentFiles?.length) {
      for (const file of request.attachmentFiles) {
        formData.append('AttachmentFiles', file)
      }
    }

    return this._http.post<void>(
      `${this.apiInvoice2Url}${invoiceUuid}/disputes`,
      formData,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  sendReplyCommunication(
    invoiceUuid: string,
    disputeId: string,
    request: AddReplyCommunicationRequest
  ) {
    const formData: FormData = new FormData()

    formData.append('Text', request.text)
    formData.append('Subject', request.subject)

    if (request.to?.length > 0) {
      for (const to of request.to) {
        formData.append('To', to)
      }
    }

    if (request.cc?.length > 0) {
      for (const cc of request.cc) {
        formData.append('Cc', cc)
      }
    }

    if (request.attachmentUrls?.length) {
      for (const url of request.attachmentUrls) {
        formData.append('attachmentUrls', url)
      }
    }

    if (request.attachmentFiles?.length) {
      for (const file of request.attachmentFiles) {
        formData.append('AttachmentFiles', file)
      }
    }

    return this._http.post<void>(
      `${this.apiInvoice2Url}${invoiceUuid}/disputes/${disputeId}/communications`,
      formData,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getEmailTemplates(): Observable<GetEmailTemplatesResponse> {
    return this._http.get<GetEmailTemplatesResponse>(
      `${this.apiV2Url}email/templates`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getDisputeReasons(): Observable<GetDisputeReasonsResponse> {
    return this._http.get<GetDisputeReasonsResponse>(
      `${this.apiV2Url}/dispute-reasons`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  downloadAttachment(url: string) {
    return this._http.post(`${this.apiV2Url}attachments/download`, `"${url}"`, {
      headers: {
        ...this.headersWithApiKey,
        'Content-Type': 'application/json'
      },
      observe: 'response',
      responseType: 'blob'
    })
  }

  manageLineItems(invoiceId: number, request: ManageLineItemsRequest) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${invoiceId}/line-items-batch`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getWorkInstructions(invoiceUuid: string): Observable<string[]> {
    return this._http.get<string[]>(
      `${this.apiInvoicesUrl}${invoiceUuid}/work-instructions`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getWorkInstructionsPhase2(
    invoiceUuid: string
  ): Observable<GetWorkInstructionsPhase2Response> {
    return this._http.get<GetWorkInstructionsPhase2Response>(
      `${this.apiInvoicesNewUrl}${invoiceUuid}/work-instructions`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addAttestation(invoiceUuid: string, workInstructionsIds: number[]) {
    return this._http.post<void>(
      `${this.apiInvoicesUrl}${invoiceUuid}/work-instructions/attestations`,
      workInstructionsIds,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getDuplicateInvoicesForGivenParentId(
    parentInvoiceId: number
  ): Observable<number[]> {
    return this._http.get<number[]>(
      `${this.apiInvoicesUrl}${parentInvoiceId}/duplicates`,
      {
        headers: this.headersWithApiKey
      }
    )
  }
}
