import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { UpdateInvoiceLineItemsRequest } from '../../domain/invoices/update-invoice-line-items.request'

export class ToUpdateInvoiceLineItemsRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'lineItems' | 'id'>,
      UpdateInvoiceLineItemsRequest
    >
{
  convert(
    request: Pick<InvoiceForm, 'lineItems' | 'id'>
  ): UpdateInvoiceLineItemsRequest {
    return {
      vendorInvoiceLineItemId: Number(request.lineItems.id),
      vendorInvoiceId: Number(request.id),
      class: request.lineItems.classCode?.toString() ?? undefined,
      description: request.lineItems.description,
      dimUnits: request.lineItems.messureUnitTypes,
      handlingUnit: Number(request.lineItems.handlingUnitTypeId),
      handlingUnitCount: request.lineItems.handlingUnitCount,
      height: request.lineItems.height,
      length: request.lineItems.length,
      nmfcCode:
        request.lineItems.nmfcCode === ''
          ? undefined
          : request.lineItems.nmfcCode,
      stack: request.lineItems.isStack,
      totalPieces: request.lineItems.totalPieces,
      weight: request.lineItems.weight,
      weightUnit: request.lineItems.weightUnit,
      width: request.lineItems.width
    }
  }
}
