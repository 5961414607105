enum MeasurementSystem {
  Imperial = 'Imperial',
  Metric = 'Metric'
}

enum WeightUnit {
  KG = 'KG',
  LBS = 'LBS'
}

enum DimensionUnit {
  CM = 'CM',
  IN = 'IN'
}

export const MeasurementUnits: Record<
  string,
  { weightUnit: WeightUnit; dimensionUnit: DimensionUnit }
> = {
  [MeasurementSystem.Imperial]: {
    weightUnit: WeightUnit.LBS,
    dimensionUnit: DimensionUnit.IN
  },
  [MeasurementSystem.Metric]: {
    weightUnit: WeightUnit.KG,
    dimensionUnit: DimensionUnit.CM
  }
}
