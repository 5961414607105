import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { fromGetAuditResponse } from '../../../adapter/audits/FromGetAuditResponse'
import { MS_BEFORE_RESET_LOADING_STATE } from '../audits.constants'
import { loadAuditActions } from './load-audit.actions'
import { platform_v2 } from '@navix/api-models'

export const loadAudits = createEffect(
  (actions$ = inject(Actions), service = inject(platform_v2.AuditService)) => {
    return actions$.pipe(
      ofType(loadAuditActions.loadAudit),
      exhaustMap(({ orderId }) =>
        service.getApiVAuditsOrder({ orderUuid: orderId, version: '2' }).pipe(
          map(response =>
            loadAuditActions.loadAuditSuccess({
              audit: fromGetAuditResponse(response),
              originalDocument: response
            })
          ),
          catchError(error => of(loadAuditActions.loadAuditFail({ error })))
        )
      )
    )
  },
  {
    functional: true
  }
)

export const resetGetAudit = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(loadAuditActions.loadAuditSuccess, loadAuditActions.loadAuditFail),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => loadAuditActions.resetGetAuditLoadingState())
    )
  },
  {
    functional: true
  }
)
