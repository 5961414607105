import { Invoice } from '../../domain/invoices/invoice.model'
import { GetInvoiceDisputesResponse } from '../../domain/invoices/get-invoice-disputes.response'

export function fromGetInvoiceDisputesResponse(
  response: GetInvoiceDisputesResponse,
  invoiceId: number
): Invoice {
  return {
    id: invoiceId,
    disputes: response
      .map(dispute => ({
        id: dispute.uuid,
        eTag: dispute.eTag,
        party: dispute.party,
        createdDate: dispute.createdAt,
        reasons: dispute.reasons.map(reason => ({
          description: reason.description,
          createdDate: reason.createdAt,
          resolvedDate: reason.resolvedAt
        })),
        recentCommunicationDate: dispute.lastCommunicationDate,
        numberOfTouches: dispute.touches,
        daysSinceLastTouch: dispute.daysSinceLastTouched,
        age: dispute.age,
        emailAddresses: {
          to: dispute.emailAddresses?.to ?? [],
          cc: dispute.emailAddresses?.cc ?? []
        },
        isCompleted: dispute.isCompleted
      }))
      .toSorted((compare, comparer) =>
        comparer.createdDate.localeCompare(compare.createdDate)
      ) as Invoice['disputes']
  } as Invoice
}
