import {
  DataFilters,
  StatusFilter
} from '../../../domain/invoices/data-filters.model'
import {
  APPROVED_STATUS,
  AUDIT_STATUS,
  DUPLICATE_UNPROCESSED_STATUS,
  READY_STATUS,
  TERMINATED_STATUS
} from './known-invoice-statuses'

export const defaultStatusFilters: {
  [key in StatusFilter]: Pick<
    DataFilters,
    | 'statuses'
    | 'disputedReasons'
    | 'typesOfExceptions'
    | 'vendors'
    | 'customers'
    | 'invoiceStatus'
    | 'status'
    | 'sources'
    | 'divisions'
    | 'hasNoExceptions'
    | 'disputeReasonsV2'
    | 'parties'
  >
} = {
  [StatusFilter.unmatched]: {
    statuses: [READY_STATUS, AUDIT_STATUS],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    invoiceStatus: 'unmatched',
    status: StatusFilter.unmatched,
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.audit]: {
    statuses: [READY_STATUS, AUDIT_STATUS],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    invoiceStatus: undefined,
    status: StatusFilter.audit,
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.auditMatched]: {
    statuses: [READY_STATUS, AUDIT_STATUS],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    invoiceStatus: 'matched',
    status: StatusFilter.auditMatched,
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.duplicates]: {
    statuses: [DUPLICATE_UNPROCESSED_STATUS],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    status: StatusFilter.duplicates,
    invoiceStatus: undefined,
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.disputes]: {
    statuses: [],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    status: StatusFilter.disputes,
    invoiceStatus: undefined,
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.approved]: {
    statuses: [APPROVED_STATUS],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    status: StatusFilter.approved,
    invoiceStatus: undefined,
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.all]: {
    statuses: [],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    status: StatusFilter.all,
    invoiceStatus: undefined,
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.terminated]: {
    statuses: [TERMINATED_STATUS],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    status: StatusFilter.terminated,
    invoiceStatus: undefined,
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.disputesMatched]: {
    statuses: [],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    status: StatusFilter.disputesMatched,
    invoiceStatus: 'matched',
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.disputesUnmatched]: {
    statuses: [],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    status: StatusFilter.disputesUnmatched,
    invoiceStatus: 'unmatched',
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.readyToApprove]: {
    statuses: [READY_STATUS, AUDIT_STATUS],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    invoiceStatus: undefined,
    status: StatusFilter.readyToApprove,
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.disputeList]: {
    statuses: [],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    status: StatusFilter.disputeList,
    invoiceStatus: 'disputeList',
    disputeReasonsV2: [],
    parties: []
  },
  [StatusFilter.ownedDisputeList]: {
    statuses: [],
    disputedReasons: [],
    typesOfExceptions: [],
    vendors: [],
    customers: [],
    divisions: [],
    sources: [],
    hasNoExceptions: false,
    status: StatusFilter.ownedDisputeList,
    invoiceStatus: 'ownedDisputeList',
    disputeReasonsV2: [],
    parties: []
  }
}
