import { Dialog } from '@angular/cdk/dialog'
import { Injectable, inject } from '@angular/core'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  catchError,
  debounceTime,
  exhaustMap,
  finalize,
  map,
  of,
  switchMap,
  tap,
  firstValueFrom
} from 'rxjs'
import { FromGetReferenceNumbersResponse } from '../../../adapter/invoices/FromGetReferenceNumbersResponse'
import { InvoicesAdapter } from '../../../adapter/invoices/InvoicesAdapter'
import { ToUpdateInvoiceReferenceNumbersRequest } from '../../../adapter/invoices/ToUpdateInvoiceReferenceNumbersRequest'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { invoiceDetailsActions } from '../invoice-details/invoice-details.actions'
import { InvoicesFacade } from '../invoices.facade'
import { invoiceReferenceNumbersActions } from './invoice-reference-numbers.actions'
import { MS_BEFORE_RESET_LOADING_STATE } from '../invoices.constants'

@Injectable()
export class InvoiceReferenceNumbersEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private invoicesFacade = inject(InvoicesFacade)
  private alertsFacade = inject(LegacyAlertsFacade)
  private dialog = inject(Dialog)

  private invoiceAdapter = new InvoicesAdapter()

  constructor() {
    this.invoiceAdapter.set(FromGetReferenceNumbersResponse)
    this.invoiceAdapter.set(ToUpdateInvoiceReferenceNumbersRequest)
  }

  loadInvoiceReferenceNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceReferenceNumbersActions.loadInvoiceReferenceNumbers),
      tap(() =>
        this.invoicesFacade.startLoading(
          AsyncOperations.getInvoiceReferenceNumbers
        )
      ),
      exhaustMap(({ invoiceId }) =>
        this.invoicesService.getReferenceNumbers(invoiceId).pipe(
          map(response =>
            this.invoiceAdapter.convert(
              FromGetReferenceNumbersResponse,
              response
            )
          ),
          map(referenceNumbers =>
            invoiceReferenceNumbersActions.loadInvoiceReferenceNumbersSuccess({
              referenceNumbers,
              invoiceId
            })
          ),
          //TODO(walther): refactor in the future to new architecture
          debounceTime(MS_BEFORE_RESET_LOADING_STATE),
          tap(() =>
            this.invoicesFacade.endLoading(
              AsyncOperations.getInvoiceReferenceNumbers
            )
          ),
          catchError(error =>
            of(
              invoiceReferenceNumbersActions.loadInvoiceReferenceNumbersFail({
                error
              })
            )
          )
        )
      )
    )
  )

  loadInvoiceReferenceNumbersFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceReferenceNumbersActions.loadInvoiceReferenceNumbersFail),
        tap(error => console.error(error)),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load invoice reference numbers."
          })
        })
      ),
    { dispatch: false }
  )

  updateInvoiceReferenceNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceReferenceNumbersActions.updateInvoiceReferenceNumbers),
      map(payload =>
        this.invoiceAdapter.convert(
          ToUpdateInvoiceReferenceNumbersRequest,
          payload
        )
      ),
      exhaustMap(request =>
        this.invoicesService.updateInvoiceReferenceNumbers(request).pipe(
          map(() =>
            invoiceReferenceNumbersActions.updateInvoiceReferenceNumbersSuccess(
              {
                invoiceId: request.invoiceId,
                dialogId: request.dialogId
              }
            )
          ),
          catchError(error =>
            of(
              invoiceReferenceNumbersActions.updateInvoiceReferenceNumbersFail({
                error
              })
            )
          ),
          finalize(async () => {
            this.invoicesFacade.resetLoading(
              AsyncOperations.updateInvoiceReferenceNumbers
            )
            const invoice = await firstValueFrom(
              this.invoicesFacade.selectedInvoice$
            )

            this.invoicesFacade.loadWorkInstructions(invoice.uuid, invoice.id)
            this.invoicesFacade.loadInvoiceExceptions(invoice.id)
          })
        )
      )
    )
  )

  updateInvoiceReferenceNumbersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceReferenceNumbersActions.updateInvoiceReferenceNumbersSuccess
      ),
      tap(({ dialogId }) => {
        this.dialog.getDialogById(dialogId)?.close()
        this.alertsFacade.addAlert({
          alertType: 'success',
          label: 'Reference numbers were successfully updated.'
        })
      }),
      switchMap(({ invoiceId }) =>
        of(
          invoiceReferenceNumbersActions.loadInvoiceReferenceNumbers({
            invoiceId
          }),
          invoiceDetailsActions.loadInvoiceDetails({
            invoiceId
          })
        )
      )
    )
  )
}
