import { IStrategy } from '@navix/utils/adapter'
import { Order } from '../../domain/orders/order.model'
import { GetReferenceNumbersResponse } from '../../domain/orders/get-reference-numbers.response'

export class FromGetReferenceNumbersResponse
  implements IStrategy<GetReferenceNumbersResponse, Order>
{
  convert(response: GetReferenceNumbersResponse): Order {
    return <Order>{
      referenceNumbers: response.value.map<Order['referenceNumbers'][number]>(
        referenceNumber => ({
          typeDescription: referenceNumber.ReferenceNumberType.Description,
          referenceNumber: referenceNumber.Value,
          typeId: referenceNumber.ReferenceNumberTypeId
        })
      )
    }
  }
}
