import { Injectable, inject } from '@angular/core'
import { invoiceDuplicatesActions } from './invoice-duplicates.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  tap,
  exhaustMap,
  map,
  catchError,
  of,
  zip,
  EMPTY,
  debounceTime
} from 'rxjs'
import { FromGetDuplicateInvoicesResponse } from '../../../adapter/invoices/FromGetDuplicateInvoicesResponse'
import { DuplicateActions } from '../../../domain/invoices/duplicate-action.model'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { MS_BEFORE_RESET_LOADING_STATE } from '../invoices.constants'
import { Router } from '@angular/router'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { InvoicesAdapter } from '../../../adapter/invoices/InvoicesAdapter'
import { InvoicesService } from '../../../infrastructure/invoices.service'
import { InvoicesFacade } from '../invoices.facade'
import { KnownInvoicesSortBy } from '../static'
import {
  DataFilters,
  StatusFilter
} from '../../../domain/invoices/data-filters.model'
import { fromGetInvoicesRestResponse } from '../../../adapter/invoices/FromGetInvoicesRestResponse'
import { TerminateInvoiceOperation } from '../../../domain/invoices/terminate-invoice-operation.model'

const DUPLICATE_STATUS_REASON_ID = 1

@Injectable()
export class InvoiceDuplicatesEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)
  private invoicesFacade = inject(InvoicesFacade)
  private router = inject(Router)

  private invoiceAdapter = new InvoicesAdapter()

  constructor() {
    this.invoiceAdapter.set(FromGetDuplicateInvoicesResponse)
  }

  loadDuplicateInvoicesRest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDuplicatesActions.loadInvoiceDuplicates),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.getDuplicates)
      ),
      exhaustMap(({ parentInvoiceId }) =>
        this.invoicesService
          .getInvoicesRest(<DataFilters>{
            page: 1,
            itemsPerPage: 1000,
            sortBy: KnownInvoicesSortBy.CreatedDate,
            sortDirection: 'desc',
            status: StatusFilter.all,
            parenteInvoiceId: parentInvoiceId,
            returnVendorNetCharge: true
          })
          .pipe(
            map(response =>
              fromGetInvoicesRestResponse(response).map(invoice => ({
                ...invoice,
                fromDuplicates: true
              }))
            ),
            map(duplicateInvoices =>
              invoiceDuplicatesActions.loadInvoiceDuplicatesSuccess({
                duplicateInvoices
              })
            ),
            catchError(error =>
              of(invoiceDuplicatesActions.loadInvoiceDuplicatesFail({ error }))
            ),
            tap(() =>
              this.invoicesFacade.endLoading(AsyncOperations.getDuplicates)
            )
          )
      )
    )
  )

  loadDuplicateInvoicesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoiceDuplicatesActions.loadInvoiceDuplicatesFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load duplicate invoices."
          })
        })
      ),
    { dispatch: false }
  )

  updateInvoiceDuplicates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDuplicatesActions.updateInvoiceDuplicates),
      exhaustMap(({ duplicates, useLegacy }) =>
        zip(
          duplicates.map(request => {
            switch (request.actionId) {
              case DuplicateActions.Keep:
                return this.invoicesService.updateInvoiceToDuplicateStatus({
                  id: request.invoiceId,
                  VendorInvoiceTypeId: Number(request.billTypeId)
                })
              case DuplicateActions.Termiante:
                return useLegacy
                  ? this.invoicesService.terminateInvoice({
                      uuid: request.invoiceUuid,
                      id: request.invoiceId,
                      vendorInvoiceStatusReasonId: DUPLICATE_STATUS_REASON_ID,
                      operation: TerminateInvoiceOperation.DuplicateResolution
                    })
                  : this.invoicesService.terminateInvoiceV2({
                      uuid: request.invoiceUuid,
                      id: request.invoiceId,
                      vendorInvoiceStatusReasonId: DUPLICATE_STATUS_REASON_ID,
                      operation: TerminateInvoiceOperation.DuplicateResolution
                    })
              default:
                return EMPTY
            }
          })
        ).pipe(
          map(() => invoiceDuplicatesActions.updateInvoiceDuplicatesSuccess()),
          catchError(httpError =>
            of(
              invoiceDuplicatesActions.updateInvoiceDuplicatesFail({
                error: httpError.error
              })
            )
          ),
          tap(() =>
            this.invoicesFacade.endLoading(
              AsyncOperations.updateInvoiceDuplicates
            )
          )
        )
      )
    )
  )

  resetUpdateInvoiceDuplicatesLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDuplicatesActions.updateInvoiceDuplicatesSuccess,
        invoiceDuplicatesActions.updateInvoiceDuplicatesFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        invoiceDuplicatesActions.resetUpdateInvoiceDuplicatesLoadingState()
      )
    )
  )

  loadInvoiceDuplicatesOnNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDuplicatesActions.loadInvoiceDuplicatesOnNavigation),
      exhaustMap(({ parentInvoiceId }) =>
        this.invoicesService
          .getInvoicesRest(<DataFilters>{
            page: 1,
            itemsPerPage: 1000,
            sortBy: KnownInvoicesSortBy.CreatedDate,
            sortDirection: 'desc',
            status: StatusFilter.all,
            parenteInvoiceId: parentInvoiceId,
            returnVendorNetCharge: true
          })
          .pipe(
            map(response =>
              fromGetInvoicesRestResponse(response).map(invoice => ({
                ...invoice,
                fromDuplicates: true
              }))
            ),
            map(duplicateInvoices =>
              invoiceDuplicatesActions.loadInvoiceDuplicatesOnNavigationSuccess(
                {
                  duplicateInvoices
                }
              )
            ),
            catchError(error =>
              of(
                invoiceDuplicatesActions.loadInvoiceDuplicatesOnNavigationFail({
                  error
                })
              )
            )
          )
      )
    )
  )

  resetLoadInvoiceDuplicatesOnNavigationLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoiceDuplicatesActions.loadInvoiceDuplicatesOnNavigationSuccess,
        invoiceDuplicatesActions.loadInvoiceDuplicatesOnNavigationFail
      ),
      debounceTime(100),
      map(() =>
        invoiceDuplicatesActions.resetLoadInvoiceDuplicatesOnNavigationLoadingState()
      )
    )
  )

  loadDuplicateInvoicesForInvoiceCentricRest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDuplicatesActions.loadInvoiceDuplicatesForInvoiceCentric),
      tap(() =>
        this.invoicesFacade.startLoading(AsyncOperations.getDuplicates)
      ),
      exhaustMap(({ parentInvoiceId }) =>
        this.invoicesService
          .getInvoicesRest(<DataFilters>{
            page: 1,
            itemsPerPage: 1000,
            sortBy: KnownInvoicesSortBy.CreatedDate,
            sortDirection: 'desc',
            status: StatusFilter.all,
            parenteInvoiceId: parentInvoiceId,
            returnVendorNetCharge: true
          })
          .pipe(
            map(response =>
              fromGetInvoicesRestResponse(response).map(invoice => ({
                ...invoice,
                fromDuplicates: true
              }))
            ),
            map(duplicateInvoices =>
              invoiceDuplicatesActions.loadInvoiceDuplicatesForInvoiceCentricSuccess(
                {
                  duplicateInvoices
                }
              )
            ),
            catchError(error =>
              of(
                invoiceDuplicatesActions.loadInvoiceDuplicatesForInvoiceCentricFail(
                  { error }
                )
              )
            ),
            tap(() =>
              this.invoicesFacade.endLoading(AsyncOperations.getDuplicates)
            )
          )
      )
    )
  )

  loadDuplicateInvoicesForInvoiceCentricFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          invoiceDuplicatesActions.loadInvoiceDuplicatesForInvoiceCentricFail
        ),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load duplicate invoices."
          })
        })
      ),
    { dispatch: false }
  )
}
