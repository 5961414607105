import { HttpErrorResponse } from '@angular/common/http'
import { createActionGroup, props } from '@ngrx/store'

export const invoiceCurrencyActions = createActionGroup({
  source: 'Invoice Currency',
  events: {
    'update invoice currency': props<{
      id: number
      newCurrency: { id: number }
    }>(),
    'update invoice currency success': props<{ invoiceId: number }>(),
    'update invoice currency fail': props<{ error: HttpErrorResponse }>()
  }
})
