import { on } from '@ngrx/store'
import { InvoicesState } from '../invoices.reducer'
import { operationsActions } from './operations.actions'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'

export const operationsReducers = [
  on(operationsActions.terminateMultipleInvoices, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.terminateMultipleInvoices,
      status: LoadingStatuses.InProgress,
      message: 'Terminating selected invoices...'
    })
  })),

  on(
    operationsActions.terminateMultipleInvoicesFail,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.terminateMultipleInvoices,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to terminate invoices'
      })
    })
  ),
  on(
    operationsActions.terminateMultipleInvoicesSuccess,
    (state: InvoicesState, { allFinished, failedInvoices }) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.terminateMultipleInvoices,
        status: LoadingStatuses.Completed,
        message: allFinished
          ? 'Invoice(s) were successfully terminated'
          : `${failedInvoices} invoice(s) could not be terminated`
      })
    })
  ),
  on(operationsActions.approveMultipleInvoices, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.approveMultipleInvoices,
      status: LoadingStatuses.InProgress,
      message: 'Approving selected invoices...'
    })
  })),
  on(
    operationsActions.approveMultipleInvoicesPartialFail,
    (state: InvoicesState, { failedApprovals }) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.approveMultipleInvoices,
        status: LoadingStatuses.Failed,
        message: `${failedApprovals.length} invoice(s) could not be approved`,
        metadata: {
          failedInvoiceApprovals: failedApprovals
        }
      })
    })
  ),
  on(operationsActions.approveMultipleInvoicesFail, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.approveMultipleInvoices,
      status: LoadingStatuses.Failed,
      message: 'There was an error trying to approve invoices'
    })
  })),
  on(
    operationsActions.approveMultipleInvoicesSuccess,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.approveMultipleInvoices,
        status: LoadingStatuses.Completed,
        message: 'Invoice(s) were successfully approved'
      })
    })
  ),
  on(
    operationsActions.resetApproveMultipleInvoicesLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.approveMultipleInvoices,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(operationsActions.reAuditMultipleInvoices, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.reAuditMultipleInvoices,
      status: LoadingStatuses.InProgress,
      message: 'Re-Auditing selected invoices...'
    })
  })),

  on(operationsActions.reAuditMultipleInvoicesFail, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.reAuditMultipleInvoices,
      status: LoadingStatuses.Failed,
      message: 'There was an error trying to re-audit invoices'
    })
  })),
  on(
    operationsActions.reAuditMultipleInvoicesSuccess,
    (state: InvoicesState, { allFinished, failedInvoices }) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.reAuditMultipleInvoices,
        status: LoadingStatuses.Completed,
        message: allFinished
          ? 'Invoice(s) were successfully re-audited'
          : `${failedInvoices} invoice(s) could not be re-audited`
      })
    })
  ),
  on(operationsActions.approveInvoice, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.approveInvoice,
      status: LoadingStatuses.InProgress
    })
  })),
  on(operationsActions.approveInvoiceSuccess, (state: InvoicesState, data) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.approveInvoice,
      status: LoadingStatuses.Completed,
      message: 'Invoice is approved.',
      metadata: data.invoiceId
    })
  })),
  on(
    operationsActions.approveInvoiceFail,
    (state: InvoicesState, httpError) => {
      const message = String(httpError.error)
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.approveInvoice,
          status: LoadingStatuses.Failed,
          message:
            message ?? "There's an error trying to set invoice as approved."
        })
      }
    }
  ),
  on(operationsActions.terminateInvoice, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.terminateInvoice,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    operationsActions.terminateInvoiceSuccess,
    (state: InvoicesState, data) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.terminateInvoice,
        status: LoadingStatuses.Completed,
        message: 'Invoice is terminated.',
        metadata: { invoiceUuid: data.invoiceUuid }
      })
    })
  ),
  on(
    operationsActions.terminateInvoiceFail,
    (state: InvoicesState, httpError) => {
      const message = String(httpError.error)
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.terminateInvoice,
          status: LoadingStatuses.Failed,
          message:
            message ?? "There's an error trying to set invoice as terminate."
        })
      }
    }
  ),
  on(
    operationsActions.resetTerminateInvoiceLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.terminateInvoice,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    operationsActions.resetApproveInvoiceLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.approveInvoice,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(operationsActions.reAudit, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.reAudit,
      status: LoadingStatuses.InProgress,
      message: 'Re-Audit process has been started.'
    })
  })),
  on(operationsActions.reAuditSuccess, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.reAudit,
      status: LoadingStatuses.Completed,
      message: 'Re-Audit process has finished successfully.'
    })
  })),
  on(operationsActions.reAuditFail, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.reAudit,
      status: LoadingStatuses.Failed,
      message:
        'There was an error in the exceptions process. Submit a ticket to Navix support'
    })
  })),
  on(operationsActions.resetReAuditLoadingState, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.reAudit,
      status: LoadingStatuses.NotStarted
    })
  })),
  on(
    operationsActions.resetTerminateMultipleInvoicesLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.terminateMultipleInvoices,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    operationsActions.resetReAuditMultipleInvoicesLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.reAuditMultipleInvoices,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(operationsActions.regenerateInvoice, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.regenerateInvoice,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    operationsActions.regenerateInvoiceSuccess,
    (state: InvoicesState, { invoiceId, previousInvoiceId }) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.regenerateInvoice,
        status: LoadingStatuses.Completed,
        message: 'Invoice regenerated successfully.',
        metadata: { invoiceId, previousInvoiceId }
      })
    })
  ),
  on(
    operationsActions.regenerateInvoiceFail,
    (state: InvoicesState, httpError) => {
      const message = String(httpError.error)
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.regenerateInvoice,
          status: LoadingStatuses.Failed,
          message: message ?? "There's an error trying to regenerate invoice."
        })
      }
    }
  ),
  on(
    operationsActions.resetRegenerateInvoiceLoadingState,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.regenerateInvoice,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
