import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { AddVendorAdjustedChargeRequest } from '../../domain/invoices/add-vendor-adjusted-charge.request'

export class ToAddVendorAdjustedChargeRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'id'> & {
        charge: InvoiceForm['chargesToMap'][number]
      },
      AddVendorAdjustedChargeRequest
    >
{
  convert(
    request: Pick<InvoiceForm, 'id'> & {
      charge: InvoiceForm['chargesToMap'][number]
    }
  ): AddVendorAdjustedChargeRequest {
    return {
      charge: request.charge.amount ?? 0,
      chargeTypeId: Number(request.charge.typeId),
      invoiceId: Number(request.id),
      tenantCode: request.charge.tenantCode ?? null,
      tenantDescription: request.charge.tenantDescription ?? null
    }
  }
}
