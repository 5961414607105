import { Injectable, computed, inject } from '@angular/core'
import { CurrentUserFacade } from '@navix/current-user/domain'
import { InvoicesFacade } from '../+state/invoices/invoices.facade'

@Injectable({
  providedIn: 'root'
})
export class InvoiceOwnershipService {
  private readonly invoicesFacade = inject(InvoicesFacade)
  private readonly currentUserFacade = inject(CurrentUserFacade)

  private invoiceOwnerEmail = computed(() => {
    const invoice = this.invoicesFacade.selectedInvoice()
    return invoice?.ownerUserEmail
  })

  IS_NOT_OWNER_MESSAGE = 'Take Ownership to Enable.'

  public isOwner = computed(() => {
    const ownerEmail = this.invoiceOwnerEmail()
    const currentUser = this.currentUserFacade.userProfile()

    if (!currentUser) return false

    return currentUser.email === ownerEmail || !ownerEmail
  })
}
