import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'

import { UpdateInvoiceCustomerOrderChargeRequest } from '../../domain/invoices/update-invoice-customer-order-charges.request'

export class ToUpdateCustomerOrderChargeRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'id'> & {
        charge: InvoiceForm['chargesToMap'][number]
      },
      UpdateInvoiceCustomerOrderChargeRequest | undefined
    >
{
  convert(
    request: Pick<InvoiceForm, 'id'> & {
      charge: InvoiceForm['chargesToMap'][number]
    }
  ): UpdateInvoiceCustomerOrderChargeRequest | undefined {
    if (isNaN(Number(request.charge.chargeId))) return undefined
    return {
      chargeTypeId: Number(request.charge.typeId),
      customerOrderChargeId: Number(request.charge.chargeId),
      orderId: Number(request.id),
      code: request.charge.code,
      description: request.charge.description
    }
  }
}
