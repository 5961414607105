import { on } from '@ngrx/store'
import { invoiceDocumentsActions } from './invoice-documents.actions'
import { InvoicesState } from '../invoices.reducer'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'

export const invoiceDocumentsReducers = [
  on(invoiceDocumentsActions.addInvoiceDocuments, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.addInvoiceDocuments,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    invoiceDocumentsActions.addInvoiceDocumentsSuccess,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceDocuments,
        status: LoadingStatuses.Completed,
        message: 'Documents were successfully added.'
      })
    })
  ),
  on(
    invoiceDocumentsActions.addInvoiceDocumentsFail,
    (state: InvoicesState, httpError) => {
      const message: string = httpError.error.error?.message ?? ''
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.addInvoiceDocuments,
          status: LoadingStatuses.Failed,
          message:
            message ?? "There's an error trying to add invoice documents."
        })
      }
    }
  ),
  on(
    invoiceDocumentsActions.generateDefaultInvoiceDocument,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.generateInvoiceDocument,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDocumentsActions.generateDefaultInvoiceDocumentSuccess,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.generateInvoiceDocument,
        status: LoadingStatuses.Completed,
        message: 'Invoice were successfully generated.'
      })
    })
  ),
  on(
    invoiceDocumentsActions.generateDefaultInvoiceDocumentFail,
    (state: InvoicesState, httpError) => {
      const message: string = httpError.error.error?.message ?? ''
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.generateInvoiceDocument,
          status: LoadingStatuses.Failed,
          message: message ?? "There's an error trying to generate invoice."
        })
      }
    }
  ),
  on(invoiceDocumentsActions.mergeInvoiceDocuments, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.mergeInvoiceDocuments,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    invoiceDocumentsActions.mergeInvoiceDocumentsSuccess,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.mergeInvoiceDocuments,
        status: LoadingStatuses.Completed,
        message: 'Document(s) were successfully updated.'
      })
    })
  ),
  on(
    invoiceDocumentsActions.mergeInvoiceDocumentsFail,
    (state: InvoicesState, httpError) => {
      const message: string = httpError.error.error?.message ?? ''
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.mergeInvoiceDocuments,
          status: LoadingStatuses.Failed,
          message:
            message ?? "There's an error trying to merged invoice documents."
        })
      }
    }
  ),
  on(
    invoiceDocumentsActions.attachDocumentsToInvoice,
    (state: InvoicesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.attachDocumentToInvoice,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDocumentsActions.attachDocumentsToInvoiceSuccess,
    (state: InvoicesState, { allFinished }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.attachDocumentToInvoice,
        status: LoadingStatuses.Completed,
        message: allFinished
          ? 'Documents were attached to the invoice successfully.'
          : 'Some documents could not be attached to the invoice.'
      })
    })
  ),
  on(
    invoiceDocumentsActions.attachDocumentsToInvoiceFail,
    (state: InvoicesState, { error }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.attachDocumentToInvoice,
        status: LoadingStatuses.Failed,
        message:
          'There was an error trying to attach the documents to the invoice. Please retry.'
      })
    })
  ),
  on(
    invoiceDocumentsActions.resetAttachDocumentsToInvoiceLoadingState,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.attachDocumentToInvoice,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(invoiceDocumentsActions.redactDocument, (state: InvoicesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.redactDocument,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    invoiceDocumentsActions.redactDocumentSuccess,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.redactDocument,
        status: LoadingStatuses.Completed,
        message: 'Document was successfully redacted.'
      })
    })
  ),
  on(
    invoiceDocumentsActions.redactDocumentFail,
    (state: InvoicesState, { error }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.redactDocument,
        status: LoadingStatuses.Failed,
        message:
          error ??
          'There was an error trying to redact the document. Please retry.'
      })
    })
  ),
  on(
    invoiceDocumentsActions.resetRedactDocumentLoadingState,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.redactDocument,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
