import { platform_v2 } from '@navix/api-models'
import { Invoice } from '../../domain/invoices/invoice.model'

export function fromGetInvoiceDisputesCommunicationsResponse(
  response: Array<platform_v2.Navix_Services_Sdk_Communication>,
  disputeId: string,
  invoiceId: number
): Invoice {
  return {
    id: invoiceId,
    communication: {
      disputeId,
      communications:
        response
          .map(
            communication =>
              ({
                createdDate: communication.createdAt,
                email: {
                  to: communication.email?.to ?? [],
                  from: communication.email?.from ?? '',
                  cc: communication.email?.cc ?? [],
                  subject: communication.email?.subject
                },
                text: communication.text,
                attachments: communication.attachments?.map(attachment => ({
                  name: attachment.fileName,
                  url: attachment.url,
                  size: attachment.fileSize,
                  type: attachment.contentType
                }))
              }) as Invoice['communication']['communications'][number]
          )
          .toSorted((compare, comparer) =>
            (comparer.createdDate as string).localeCompare(
              compare.createdDate as string
            )
          ) ?? []
    } as Invoice['communication']
  } as Invoice
}
