import { IStrategy } from '@navix/utils/adapter'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { UpdateInvoiceVendorChargesBatchRequest } from '../../domain/invoices/update-invoice-vendor-charges-batch.request'
import { KnownChageTypes } from '../../+state/invoices/static'

export class ToUpdateInvoiceVendorChargesBatchRequest
  implements
    IStrategy<
      Pick<InvoiceForm, 'charges' | 'id'>,
      UpdateInvoiceVendorChargesBatchRequest
    >
{
  convert(
    request: Pick<InvoiceForm, 'charges' | 'id'>
  ): UpdateInvoiceVendorChargesBatchRequest {
    const charges = request.charges
      .filter(charge => {
        const isCustomerFee = charge.typeId === KnownChageTypes.CustomerFees
        const isUnknownAccesorials =
          charge.typeId === KnownChageTypes.UnknownAccesorials
        const isAdjusted = charge.kind === 'adjusted'
        const isValidAdjustedAmount =
          Number(charge.adjustedAmount ?? 0) !== 0 ||
          (charge.originalAdjustment !== null &&
            charge.originalAdjustment !== undefined)
        const hasVarianceReasonId =
          charge.varianceReasonId !== null &&
          charge.varianceReasonId !== undefined
        const hasValidAdjustedChargeId =
          charge.adjustedChargeId !== undefined &&
          charge.adjustedChargeId !== null &&
          !isNaN(Number(charge.adjustedChargeId))

        const isValidUnknownAccesorial =
          isUnknownAccesorials && hasValidAdjustedChargeId
        const isValidAdjustedCharge =
          (isAdjusted || isValidAdjustedAmount) &&
          (isValidAdjustedAmount ||
            hasVarianceReasonId ||
            hasValidAdjustedChargeId)

        const isValidCharge = isUnknownAccesorials
          ? isValidUnknownAccesorial
          : isValidAdjustedCharge

        return !isCustomerFee && isValidCharge
      })
      .map<UpdateInvoiceVendorChargesBatchRequest['charges'][number]>(
        charge => {
          const chargeId =
            charge.adjustedChargeId === null ||
            isNaN(Number(charge.adjustedChargeId))
              ? null
              : Number(charge.adjustedChargeId)
          return {
            adjustedCharge: charge.adjustedAmount ?? null,
            chargeTypeId: charge.typeId ?? null,
            tenantCode: charge.tenantCode ?? null,
            tenantDescription: charge.tenantDescription ?? null,
            vendorInvoiceChargeId:
              charge.kind === 'adjusted' ? chargeId ?? null : null,
            varianceReasonId: charge.varianceReasonId ?? null
          }
        }
      )

    if (charges.length === 0) {
      console.error('Vendor charges list is empty', request)
    }
    return {
      charges,
      invoiceId: request.id as number
    }
  }
}
