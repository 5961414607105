import { IStrategy } from '@navix/utils/adapter'
import { GetMatchedInvoicesResponse } from '../../domain/orders/get-matched-invoices.response'
import { Order } from '../../domain/orders/order.model'

export class FromGetMatchedInvoicesResponse
  implements IStrategy<GetMatchedInvoicesResponse, Order>
{
  convert(response: GetMatchedInvoicesResponse): Order {
    return <Order>{
      invoices: response.map<Order['invoices'][0]>(x => ({
        vendorName: x.vendor,
        vendorUuid: x.vendorUuid ?? '',
        vendorId: x.vendorId ?? 0,
        invoiceId: x.vendorInvoiceId,
        invoiceUuid: x.uuid,
        invoiceType: x.type,
        invoiceTypeId: x.typeId,
        invoiceNumber: x.invoiceNumber,
        statusDate: new Date(x.statusDate),
        statusDescription: x.statusDescription,
        statusId: x.statusId,
        vendorExternalId: x.vendorExternalId
      }))
    }
  }
}
