import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { InvoiceForm } from '../../../domain/invoices/invoice-form.model'
import { Invoice, LineItem } from '../../../domain/invoices/invoice.model'
import { HttpErrorResponse } from '@angular/common/http'

export const invoiceLineItemsActions = createActionGroup({
  source: 'Invoice Line Items',
  events: {
    'add invoice line items': props<{
      invoice: Pick<InvoiceForm, 'lineItems' | 'id'>
    }>(),
    'add invoice line items success': props<{
      invoice: Pick<InvoiceForm, 'lineItems' | 'id'>
    }>(),
    'add invoice line items fail': props<{ error: HttpErrorResponse }>(),

    'update invoice line items': props<{
      invoice: Pick<InvoiceForm, 'lineItems' | 'id'>
    }>(),
    'update invoice line items success': props<{
      invoice: Pick<InvoiceForm, 'lineItems' | 'id'>
    }>(),
    'update invoice line items fail': props<{ error: HttpErrorResponse }>(),

    'delete invoice line items': props<{
      invoiceId: number
      lineItemId: number
    }>(),
    'delete invoice line items success': props<{
      invoiceId: number
      lineItemId: number
    }>(),
    'delete invoice line items fail': props<{ error: HttpErrorResponse }>(),

    'load invoice line items': props<{ invoiceId: number }>(),
    'load invoice line items success': props<{
      lineItems: Invoice['lineItems']
      invoiceId: number
    }>(),
    'load invoice line items  fail': props<{ error: unknown }>(),
    manageLineItems: props<{
      invoiceId: number
      lineItems: InvoiceForm['manageLineItems']
    }>(),
    manageLineItemsSuccess: props<{
      invoiceId: number
    }>(),
    manageLineItemsFail: props<{ error: HttpErrorResponse }>(),
    resetManageLineItems: emptyProps()
  }
})
