import { on } from '@ngrx/store'
import { InvoicesState, invoicesAdapter } from '../invoices.reducer'
import { invoiceDuplicatesActions } from './invoice-duplicates.actions'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'

export const invoiceDuplicatesReducers = [
  on(
    invoiceDuplicatesActions.loadInvoiceDuplicatesSuccess,
    (state: InvoicesState, { duplicateInvoices }): InvoicesState => {
      const newState = invoicesAdapter.upsertMany(duplicateInvoices, state)
      return invoicesAdapter.map(invoice => {
        const invoiceStateExceptions =
          state?.entities[invoice?.id]?.exceptions ?? []
        const hasCategoryExceptions =
          invoiceStateExceptions?.length > 0 &&
          invoiceStateExceptions?.some(ex => ex.categoryExceptions?.length > 0)

        return {
          ...invoice,
          withDetails: invoice.withDetails ?? false,
          belongsToPage: invoice.belongsToPage ?? 1,
          exceptions: hasCategoryExceptions
            ? invoiceStateExceptions
            : invoice.exceptions
        }
      }, newState)
    }
  ),
  on(
    invoiceDuplicatesActions.updateInvoiceDuplicates,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceDuplicates,
        status: LoadingStatuses.InProgress
      })
    })
  ),

  on(
    invoiceDuplicatesActions.updateInvoiceDuplicatesSuccess,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceDuplicates,
        status: LoadingStatuses.Completed,
        message: 'Invoices were successfully updated.'
      })
    })
  ),
  on(
    invoiceDuplicatesActions.updateInvoiceDuplicatesFail,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceDuplicates,
        status: LoadingStatuses.Failed,
        message: 'Failed to update invoice duplicates'
      })
    })
  ),
  on(
    invoiceDuplicatesActions.resetUpdateInvoiceDuplicatesLoadingState,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceDuplicates,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    invoiceDuplicatesActions.loadInvoiceDuplicatesOnNavigation,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDuplicatesOnNavigation,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDuplicatesActions.loadInvoiceDuplicatesOnNavigationSuccess,
    (state: InvoicesState, { duplicateInvoices }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDuplicatesOnNavigation,
        status: LoadingStatuses.Completed,
        metadata: {
          shouldBeRedirectedToDuplicatesList: duplicateInvoices.length >= 2
        }
      })
    })
  ),
  on(
    invoiceDuplicatesActions.loadInvoiceDuplicatesOnNavigationFail,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDuplicatesOnNavigation,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load duplicate invoices."
      })
    })
  ),
  on(
    invoiceDuplicatesActions.resetLoadInvoiceDuplicatesOnNavigationLoadingState,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDuplicatesOnNavigation,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    invoiceDuplicatesActions.loadInvoiceDuplicatesForInvoiceCentricSuccess,
    (state: InvoicesState, { duplicateInvoices }): InvoicesState => {
      const newState = invoicesAdapter.upsertMany(duplicateInvoices, state)
      return invoicesAdapter.map(invoice => {
        const invoiceStateExceptions =
          state?.entities[invoice?.id]?.exceptions ?? []
        const hasCategoryExceptions =
          invoiceStateExceptions?.length > 0 &&
          invoiceStateExceptions?.some(ex => ex.categoryExceptions?.length > 0)
        const notes = state?.entities[invoice?.id]?.notes ?? []

        return {
          ...invoice,
          withDetails: invoice.withDetails ?? false,
          belongsToPage: invoice.belongsToPage ?? 1,
          exceptions: hasCategoryExceptions
            ? invoiceStateExceptions
            : invoice.exceptions,
          notes: notes
        }
      }, newState)
    }
  )
]
