import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { AuditForm } from '../../../domain/audits/audit-form.model'

export const addAuditNoteActions = createActionGroup({
  source: 'Audit Note - Add',
  events: {
    addAuditNote: props<{
      audit: Pick<AuditForm, 'note' | 'id' | 'orderId'>
    }>(),
    addAuditNoteSuccess: props<{ orderId: string }>(),
    addAuditNoteFail: props<{ error: unknown }>(),
    resetAddAuditNoteLoadingState: emptyProps()
  }
})
